import React from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Input } from "reactstrap";

const NumberInputDecimal = ({ name, value, setValue, disabled, className }) => {

    const handleValueUpdate = (e, type) => {
        e.preventDefault();

        if (!disabled) {
            let newVal = parseFloat(value) || 0; // Default to 0 if value is empty or NaN

            if (type === 'minus') {
                newVal = parseFloat((newVal - 0.1).toFixed(1)); // Subtract 0.1 for decimal decrement
            } else {
                newVal = parseFloat((newVal + 0.1).toFixed(1)); // Add 0.1 for decimal increment
            }

            // Ensure the value stays positive
            if (newVal < 0) {
                newVal = 0;
            }

            setValue(newVal);
        }
    };

    const handleOnBlur = (e) => {
        let inputValue = e.target.value;

        // Allow only digits and a single decimal point
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (!regex.test(inputValue)) {
            e.target.value = value; // Revert to the current value if invalid
        } else {
            let newValue = parseFloat(inputValue);
            if (isNaN(newValue) || newValue < 0) {
                e.target.value = "0"; // Ensure no negative values or invalid input
                setValue(0);
            } else {
                setValue(newValue); // Update with valid decimal number
            }
        }
    };

    const handleInputChange = (e) => {
        let inputValue = e.target.value;

        // Allow only digits and a single decimal point
        if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
            setValue(inputValue);
        }
    };

    return (
        <>
            <div className='input_with_buttons'>
                <span className='up_down_btns'>
                    <span onClick={(e) => { handleValueUpdate(e, 'plus') }}>
                        <MdKeyboardArrowUp />
                    </span>
                    <span onClick={(e) => { handleValueUpdate(e, 'minus') }} className={(value === 0 || value === '') ? "opacity-50" : "''"}>
                        <MdKeyboardArrowDown />
                    </span>
                </span>
                <Input 
                    type="text"  // Set to text to allow custom filtering
                    className={`mb-1 ${className}`} 
                    id={name} 
                    name={name} 
                    value={value} 
                    disabled={disabled} 
                    onChange={handleInputChange} 
                    onBlur={handleOnBlur} 
                />
            </div>
        </>
    );
}

export default NumberInputDecimal;
