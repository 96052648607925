import React from 'react'
import PanelDesigner from '../../../../../components/DesignerComp/PanelDesigner';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllFrameCollection, getFrameStyles, getGlazingProductsData, getModelData, getProductDataService, getProfileDataService, getQuotationModelProperty, getTrasomsFrameService, verifyModelService } from '../../../../../services/3dModelServices';
import { useMessage } from '../../../../../context/messageContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getFrameStyleColletionDetails } from '../../../../../services/frameStylesService';
import { useDesigner } from '../../../../../context/designerContext';
import { modelData } from '../../../../../utility/data';
import AddNewFrame from '../../../../../components/AddNewFrame';

const Left = ({ loading, setLoading, setModelVisible, modelVisible, receivedDataLocal, captureImages, setCaptureImages, is_new_fame, isNewFrame, setIsNewFrame }) => {

    // const location = useLocation();

    // const receivedDataLocal = location.state ? location.state.data : null

    const { modelId, styleId } = useParams()

    const navigate = useNavigate()

    const accessToken = localStorage.getItem('access_token')

    const { setMessage, setMessageType } = useMessage()

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [allFrameCollection, setAllFrameCollection] = useState([])
    const [frameStyles, setFrameStyles] = useState([])
    const [collectionDetails, setCollectionDetails] = useState('')
    const [initialLoad, setInitialLoad] = useState(false)
    const [gotData, setGotData] = useState(false)
    const [receivedData, setReceivedData] = useState('')
    const [quotationId, setQuotationId] = useState('')
    const [receivedProductId, setReceivedProductId] = useState(0)
    const [productId, setProductId] = useState()
    const [showSillOptions, setShowSillOptions] = useState(false)
    const [verifyModels, setVerifyModels] = useState(null)


    const { setBayPostList, setInitialProfile, customModelData, setCouplerData, sashProductId, setCustomModelData, setSashHangingNo, newModelId, newStyleId, setNewModelId, setNewStyleId, setPanelObject, setExternalColor, setInternalColor, setExternalRAL, setInternalRAL, setDefaultSpec, threshHolData, setThreshHolData, setBeadList, setDisableUse, modelWidth, modelHeight, setModelWidth, setModelHeight } = useDesigner()

    useEffect(() => {
        setLoading(true)
        setGotData(false)
        setInitialLoad(false)
        getAllFrameService()
        setReceivedData('')
        setQuotationId('')
        setReceivedProductId(0)
        setCustomModelData(modelData)
        setNewModelId('')
        setNewStyleId('')
        setPanelObject([])
        getAllGlazingData()
    }, [])

    useEffect(() => {
        if (modelId) {
            getProductsData()
            profileListService()
        }
    }, [modelId])

    useEffect(() => {
        if (modelId && styleId) {
            verifyModel(modelId, styleId);
        }
    }, [modelId, styleId]);


    useEffect(() => {
        if (newModelId !== "" && newStyleId !== "") {
            setModelVisible(false)
            setLoading(true)
            setGotData(false)
            setInitialLoad(false)
            getAllFrameService()
            // setReceivedData('')
            // setQuotationId('')
            // setReceivedProductId(0)
            setCustomModelData(modelData)
            // setNewModelId('')
            // setNewStyleId('')
            setPanelObject([])
            setExternalColor()
            setInternalColor()
            setExternalRAL()
            setInternalRAL()

            setTimeout(() => {
                if (verifyModel !== null && verifyModel) {
                    getModelDataServices(newModelId, newStyleId)
                }
                else {
                    navigate("/error/404")
                }
            }, 1000);
        }
    }, [newModelId, newStyleId])

    useEffect(() => {
        if (modelId) {
            getFrameCollectionDetails(modelId)
            frameStyleService()
        }
    }, [modelId])

    useEffect(() => {
        if (modelId && productId) {
            getProfileData()
        }
    }, [modelId, productId])

    useEffect(() => {
        if (receivedDataLocal && receivedDataLocal.id) {
            setReceivedData(receivedDataLocal)
            setQuotationId(parseInt(receivedDataLocal.quotationId))
            setReceivedProductId(parseInt(receivedDataLocal.id))
        } else {
            setReceivedData('')
        }
    }, [receivedDataLocal])

    useEffect(() => {
        if (modelId) {
            sessionStorage.setItem("collectionId", modelId ? modelId : '')
        }
        if (quotationId) {
            sessionStorage.setItem("quotationId", quotationId ? quotationId : '')
        }
        if (receivedProductId) {
            sessionStorage.setItem("productId", receivedProductId ? receivedProductId : '')
        }
    }, [modelId, quotationId, receivedProductId])

    const profileListService = async () => {
        const res = await getTrasomsFrameService(accessToken, modelId);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setThreshHolData(res?.data?.entity?.threshold)
                setCouplerData(res?.data?.entity?.coupler)
                setBeadList(res?.data?.entity?.bead)
                setBayPostList(res?.data?.entity?.baypost)
                res?.data?.entity?.sill?.length > 0 ? setShowSillOptions(true) : setShowSillOptions(false)
            }
        }
    };


    useEffect(() => {
        setExternalColor()
        setInternalColor()
        setExternalRAL()
        setInternalRAL()

        setSashHangingNo(0)

        if (modelId && styleId && receivedDataLocal == undefined && !quotationId) {
            setTimeout(() => {
                getModelDataServices(modelId, styleId)
            }, 1000);
        }

        setModelVisible(false)
    }, [styleId])

    useEffect(() => {
        if (modelId && styleId && receivedDataLocal != undefined) {

            setModelVisible(false)

            if(!is_new_fame) {
                getQuotationModelDataService(receivedDataLocal.id)
            }
        }
    }, [receivedDataLocal])

    useEffect(() => {
        if (modelId && styleId && is_new_fame && receivedDataLocal != undefined) {
            setModelWidth(receivedDataLocal?.width)
            setModelHeight(receivedDataLocal?.height)

            getQuotationModelDataService(receivedDataLocal.id)
        }
    }, [receivedDataLocal, is_new_fame])

    // This is for disabling the customise frame style button
    useEffect(() => {
        if (quotationId) {
            setDisableUse(true)
        }
        else {
            setDisableUse(false)
        }
    }, [quotationId])

    const getAllFrameService = async () => {
        const res = await getAllFrameCollection(accessToken)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setAllFrameCollection(res?.data?.entity)
            } else {
                setMessage(res?.data?.message);
                setMessageType('error')
            }
        } else {
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }


    const getFrameCollectionDetails = async (fetchId) => {
        const res = await getFrameStyleColletionDetails(accessToken, fetchId)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setCollectionDetails(res?.data?.entity)
            } else {
                setMessage(res?.data?.message);
                setMessageType('error')
            }
        } else {
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const frameStyleService = async () => {
        const res = await getFrameStyles(accessToken, modelId)
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setFrameStyles(res?.data?.entity)
            } else {
                setMessage(res?.data?.message);
                setMessageType('error')
            }
        } else {
            setMessage(res?.message ? res.message : 'Something went wrong');
            setMessageType('error')
            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const getAllGlazingData = async () => {
        const res = await getGlazingProductsData(accessToken, modelId);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setDefaultSpec(res?.data?.entity)
            }
        }
    };

    const getModelDataServices = async (fetchModelId, fetchStyleId) => {
        const res = await getModelData(accessToken, fetchModelId, fetchStyleId)
        if (res?.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity?.jsonblob !== null) {

                    var modelJson = JSON.parse(res?.data?.entity?.jsonblob)
                    setCustomModelData(modelJson)

                    setModelWidth(modelJson?.layoutFrame?.width)
                    setModelHeight(modelJson?.layoutFrame?.height)

                    setTimeout(() => {
                        setInitialLoad(true)
                        setGotData(true)
                    }, 200);

                } else {
                    setCustomModelData(modelData)

                    setModelWidth(modelData?.layoutFrame?.width)
                    setModelHeight(modelData?.layoutFrame?.height)

                    setTimeout(() => {
                        setInitialLoad(true)
                        setGotData(false)
                    }, 200);
                }
            }
        }
    }

    const getQuotationModelDataService = async (fetchId) => {

        const res = await getQuotationModelProperty(accessToken, fetchId);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (res?.data?.entity?.jsonblob !== null) {

                    var modelJson = JSON.parse(res?.data?.entity?.jsonblob)

                    setCustomModelData(modelJson);
                    
                    if (!is_new_fame) {
                        setModelWidth(modelJson?.layoutFrame?.width)
                        setModelHeight(modelJson?.layoutFrame?.height)
                    }

                    setTimeout(() => {
                        setInitialLoad(true)
                        setGotData(true)
                    }, 200);
                } else {
                    setCustomModelData(modelData);
                    if (!is_new_fame) {
                        setModelWidth(modelData?.layoutFrame?.width)
                        setModelHeight(modelData?.layoutFrame?.height)
                    }

                    setTimeout(() => {
                        setInitialLoad(true)
                        setGotData(true)
                    }, 200);
                }
            }
        } else {
            setCustomModelData(modelData);
            setInitialLoad(true)
            setGotData(false)
        }
    };

    const getProductsData = async () => {
        const res = await getProductDataService(accessToken, modelId);
        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                if (customModelData.sashProd) {
                    setProductId(customModelData.sashProd)
                } else {
                    setProductId(res?.data?.entity[0])
                }
            }
        }
    };

    const getProfileData = async () => {
        const res = await getProfileDataService(accessToken, modelId, productId);

        if (res && res.status === 200) {
            if (res?.data?.statusCode === 200) {
                setInitialProfile(res?.data?.entity);
            }
        }
    };

    const handleAddFramePanel = () => {
        setLoading(true)
        setSidebarOpen(!sidebarOpen);
    };

    const verifyModel = async (modelId, styleId) => {
        const res = await verifyModelService(accessToken, modelId, styleId);
        if (res?.data?.statusCode == 200) {
            setVerifyModels(res?.data?.entity);
        } else {
            setMessageType("error");
            setMessage("Something went wrong");
        }
    };

    const currentModel = allFrameCollection.find((item) => item?.id == modelId)

    return (
        <>
            <PanelDesigner
                initialLoad={initialLoad}
                collectionDetails={collectionDetails}
                allFrameCollection={allFrameCollection}
                frameStyles={frameStyles}
                modelId={modelId}
                styleId={styleId}
                receivedData={receivedData}
                quotationId={quotationId}
                receivedProductId={receivedProductId}
                accessToken={accessToken}
                currentModel={currentModel}
                loading={loading}
                setLoading={setLoading}
                setMessage={setMessage}
                setMessageType={setMessageType}
                frameStyleService={frameStyleService}
                gotData={gotData}
                setGotData={setGotData}
                setInitialLoad={setInitialLoad}
                handleAddFramePanel={handleAddFramePanel}
                setModelVisible={setModelVisible}
                modelVisible={modelVisible}
                getQuotationModelDataService={getQuotationModelDataService}
                showSillOptions={showSillOptions}
                captureImages={captureImages}
                setCaptureImages={setCaptureImages}
                isNewFrame={isNewFrame}
                setIsNewFrame={setIsNewFrame}
            />

            {receivedDataLocal && receivedDataLocal.id && (
                <AddNewFrame accessToken={accessToken} setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} setMessage={setMessage}
                    setMessageType={setMessageType} setLoading={setLoading} quoteId={quotationId} frameCount={0} />
            )}
        </>
    )
}

export default Left