import axios from "axios";
import { servicePath } from "../utility/data";

export const getAllFrameCollection = (accessToken) => {
  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axios
    .get(`${servicePath}/ThreeJSModel/GetAllFrameStyleColletion`, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const uploadModelProfile = (accessToken, imageUrl) => {

  const formData = new FormData()
  formData.append('ProfilePath', imageUrl)
  formData.append('ProfilePathUrl', "")

  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axios
    .post(`${servicePath}/ThreeJSModel/UploadModelProfile`, formData, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getSillMaster = (accessToken, collectionId) => {

  const formData = new FormData();
  formData.append("FrameStyleId", collectionId);

  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }

  return axios
    .post(`${servicePath}/ThreeJSModel/GetSillMaster`, formData, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getFrameStyles = (accessToken, collectionId) => {

  const formData = new FormData()
  formData.append('FrameStyleCollectionId', collectionId)

  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axios
    .post(`${servicePath}/ThreeJSModel/GetAllFrameStyle`, formData, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const glazingTexture = (accessToken) => {
  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axios
    .get(`${servicePath}/ThreeJSModel/GetGlazing3DTexture`, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};


export const setModelData = (accessToken, frameStyleId, frameStyleProdId, json) => {

  const formData = new FormData()
  formData.append('Id', 0)
  formData.append('FrameStyleId', frameStyleProdId)
  formData.append('FrameStyleProductId', frameStyleId)
  formData.append('Jsonblob', JSON.stringify(json))


  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axios
    .post(`${servicePath}/ThreeJSModel/SetModelProperty`, formData, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getModelData = (accessToken, frameStyleId, frameStyleProdId) => {

  const formData = new FormData()
  formData.append('FrameStyleId', frameStyleProdId)
  // formData.append('FrameStyleProductId', frameStyleId)
  formData.append('FrameProductId', frameStyleId)


  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axios
    .post(`${servicePath}/ThreeJSModel/GetModelProperty`, formData, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const setQuotationModelData = (accessToken, modelId, frameStyleProdId, json, quotationId, receivedProductId) => {
  const formData = new FormData();

  formData.append('Id', 0)
  formData.append('FrameStyleProductId', modelId)
  formData.append('FrameStyleId', frameStyleProdId)
  formData.append('Jsonblob', JSON.stringify(json))
  formData.append('QuotationId', quotationId)
  formData.append("QuotationFrameId", receivedProductId)

  const headers = {
    Authorization: `Bearer ${accessToken}`
  }
  return axios
    .post(`${servicePath}/ThreeJSModel/SetQuotationModelProperty`, formData, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error
    })
}

export const setQuotationPriceModelProperty = (accessToken, receivedProductId, priceJson) => {
  const formData = new FormData();

  formData.append("QuotationFrameId", receivedProductId)
  formData.append("PriceJson", JSON.stringify(priceJson))

  const headers = {
    Authorization: `Bearer ${accessToken}`
  }
  return axios
    .post(`${servicePath}/ThreeJSModel/SetQuotationPriceModelProperty`, formData, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error
    })
}

export const updateQuotationInvoiceJsonData = (accessToken, quoteId) => {
  const formData = new FormData();

  formData.append("QuotationId", quoteId)

  const headers = {
    Authorization: `Bearer ${accessToken}`
  }
  return axios
    .post(`${servicePath}/ThreeJSModel/UpdateQuotationInvoiceJsonData`, formData, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error
    })
}

export const externalInternalColor = (accessToken, modelId) => {

  const formData = new FormData()
  formData.append('ModelId', modelId)

  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axios
    .post(`${servicePath}/ThreeJSModel/GetExtInternalModelColorList`, formData, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const getInternalColorsWithPrice = (accessToken, modelId, primaryId) => {

  const formData = new FormData()
  formData.append('ModelId', modelId)
  formData.append('ExternalcolourId', primaryId)

  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axios
    .post(`${servicePath}/ThreeJSModel/GetInternalColorsWithPrice`, formData, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const getApperturesService = (accessToken, collectionId, appertures) => {

  const formData = new FormData();
  formData.append("FrameStyleCollectionId", collectionId);
  formData.append("NoofApperture", appertures);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/GetAppertureFrameStyle`,
      formData,
      { headers }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getQuotationModelProperty = (
  accessToken,
  receivedProductId
) => {
  const formData = new FormData();
  // formData.append("FrameStyleId", frameProdId);
  // formData.append("FrameProductId", frameStyleId);
  // formData.append("QuotationId", quotationId);
  formData.append("QuotationFrameId", receivedProductId)

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/GetQuotationModelProperty`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};


export const getProfileDataService = (access_token, collectionId, sashProductId) => {
  const formData = new FormData();
  formData.append("FrameStyleCollectionId", collectionId);
  formData.append("SashProductId", sashProductId?.id);

  const headers = {
    Authorization: `Bearer ${access_token}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/GetSashProfileProductWithPrice`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getSashProfileData = (access_token, data) => {
  const formData = new FormData();
  formData.append("FrameStyleCollectionId", data?.collectionId);
  formData.append("SashProductId", data?.sashProductId);
  formData.append("FrameStyleId", data?.frameStyleId);
  formData.append("Type", data?.type);
  // formData.append("ReferencePoints", data?.referencePoint);
  data?.referencePoint.forEach((item) => {
    formData.append("ReferencePoints", item);
  })

  const headers = {
    Authorization: `Bearer ${access_token}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/GetSashProfileProductWithPrice`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};


export const getProductDataService = (access_token, collectionId) => {
  const formData = new FormData();
  formData.append("FrameStyleCollectionId", collectionId);

  const headers = {
    Authorization: `Bearer ${access_token}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/GetvisibleSubProducts`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getTrasomsFrameService = (
  accessToken,
  collectionId,
  type
) => {
  const formData = new FormData();
  formData.append("FrameStyleCollectionId", collectionId);
  // formData.append("ProfileMasterId", type);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/GetProfileProductWithPrice`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getFrameData = (
  accessToken,
  collectionId,
  frameStyleId,
  referencePoint,
  type
) => {
  const formData = new FormData();
  formData.append("FrameStyleCollectionId", collectionId);
  formData.append("FrameStyleId", frameStyleId);
  formData.append("Type", type);
  referencePoint.forEach((item) => {
    formData.append("ReferencePoints", item);
  })

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/GetProfileProductWithPrice`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getSashHangingDataService = (
  access_token,
  collectionId,
  sashProductId
) => {
  const formData = new FormData();
  formData.append("FrameStyleCollectionId", collectionId);
  formData.append("SashProductId", sashProductId?.id);


  const headers = {
    Authorization: `Bearer ${access_token}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/GetSashhanging`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getSashHangingData = (
  access_token,
  data
) => {
  const formData = new FormData();
  formData.append("FrameStyleCollectionId", data?.collectionId);
  formData.append("SashProductId", data?.sashProductId);
  formData.append("FrameStyleId", data?.frameStyleId)
  formData.append("ReferencePoint", data?.referencePoint)


  const headers = {
    Authorization: `Bearer ${access_token}`,
  };
  return axios
    .post(
      `${servicePath}/ThreeJSModel/GetSashhanging`,
      formData,
      {
        headers,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

// export const getHardwareStyleServices = (accessToken, frameProductId) => {
//   const formData = new FormData();
//   formData.append("FramePRoductId", frameProductId);

//   const headers = {
//     Authorization: `Bearer ${accessToken}`,
//   };
//   return axios
//     .post(`${servicePath}/ThreeJSModel/GetHardwareStyleWithPrice`, formData, {
//       headers,
//     })
//     .then((res) => {
//       return res;
//     })
//     .catch((error) => {
//       console.log(error);
//       return error;
//     });
// };

export const getHardwareColorServices = (accessToken, hardwareStyleId) => {
  const formData = new FormData();
  formData.append("HardwareStyleId", hardwareStyleId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/GetHardwareStyleColorWithPrice`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};


export const getModelHardware = (accessToken, frameCollectionId, type) => {
  const formData = new FormData();
  formData.append("FrameStyleCollectionId", frameCollectionId);
  formData.append("Type", type);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/GetHardwareStyleWithPrice`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getHardwareDataService = (accessToken, data) => {
  const formData = new FormData();

  formData.append("FrameStyleCollectionId", data?.frameCollectionId);
  formData.append("Type", data?.type);
  formData.append("ReferencePoint", data?.referencePoint);
  formData.append("FrameStyleId", data?.frameStyleId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/GetHardwareStyleWithPrice`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getGlazingProductsData = (accessToken, collectionId) => {
  const formData = new FormData();
  formData.append("FramestyleCollectionId", collectionId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/GetGlazingVisibleProductPriceList`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getSelectedFrameProduct = (accessToken, collectionId) => {

  const formData = new FormData();
  formData.append("FrameCollectionId", collectionId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/GetSelectedFrameProduct`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getVisiblePanelForFrameProductService = (accessToken, frameStyleCollectionId) => {
  const formData = new FormData();
  formData.append("FrameStyleCollectionId", frameStyleCollectionId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/GetVisiblePanelForFrameProduct`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const getPanelsWithPriceService = (accessToken, panelMasterId) => {
  const formData = new FormData();
  formData.append("PanelMasterId", panelMasterId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/GetPanelsWithPrice`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const getSashProductHardware = (accessToken, sashProductId) => {
  const formData = new FormData();
  formData.append("SashProductId", sashProductId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/GetSashProductHardwaresWithPrice`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const updateFrameSvg = (accessToken, frameStyleId, image) => {
  const formData = new FormData();
  formData.append("FrameStyleId", frameStyleId);
  formData.append("SvgIn64Base", image);
  formData.append("Svg", "");


  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(`${servicePath}/FrameStyle/UpdateFrameStyleSvg`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const verifyModelService = (accessToken, frameStyleId, framestyleId) => {
  const formData = new FormData();
  formData.append("FramestyleCollectionId", frameStyleId);
  formData.append("framestyleId", framestyleId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/VerifyDesignerScreenIds`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getCuttingFormulaService = (accessToken, frameStyleId) => {
  const formData = new FormData();
  formData.append("FramestyleId", frameStyleId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/GetFormulaFrameManufacture`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getSashhangingForQuote = (accessToken, frameStyleId) => {
  const formData = new FormData();
  
  // formData.append("FrameStyleCollectionId", FrameStyleCollectionId);
  // formData.append("SashProductId", SashProductId);
  formData.append("FrameStyleId", frameStyleId);
  // formData.append("ReferencePoint", ReferencePoint);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return axios
    .post(`${servicePath}/ThreeJSModel/GetSashhangingForQuote`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};