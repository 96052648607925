import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  NavLink,
} from "reactstrap";
import {
  setUpdateFormulaService,
  setUpdateSashHangingFormulaService,
  updateFormulaAdditionalgProfile,
  updateFormulaAdditionalHardware,
} from "../services/ManufacturingServices";
import { useMessage } from "../context/messageContext";
import { IoMdLink } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { handleQuantity } from "../utility/helper";

const ManufacturingEditComp = ({
  setIsEdit,
  frameStyleProdId,
  referencePoint,
  currentItem,
  accessToken,
  setLoading,
  getData,
  getSashHangingData,
  collectionId,
  frameProductDetails,
  activeTab,
  isAdditionProfile,
  refetchData,
  allTagData
}) => {

  const [heightFormula, setHeightFormula] = useState("");
  const [widthFormula, setWidthFormula] = useState("");
  const [toggleTagSizeWidth, setToggleTagSizeWidth] = useState(false);
  const [toggleTagSizHeight, setToggleTagSizHeight] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedIdsHeight, setSelectedIdsHeight] = useState([]);
  const [formulaWithIds, setFormulaWithIds] = useState([]);
  const [formulaWithIdsHeight, setFormulaWithIdsHeight] = useState([]);
  const [quantity, setQuantity] = useState(currentItem?.quantity)
  const [bothDimension, setBothDimension] = useState(false)

  const { setMessage, setMessageType } = useMessage();
  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");

  const updateAdditonalProfileFormulaService = async () => {
    let typeId = activeTab === "Frame" ? 1 : 2
    let data = {
      typeId,
      profileId: currentItem?.id,
      frameStyleId: frameStyleProdId?.id,
      referencePoint: referencePoint.index,
      heightFormula,
      widthFormula,
      tagIds: selectedIds?.length ? selectedIds : [0],
      status: currentItem?.status
    }

    const res = await updateFormulaAdditionalgProfile(accessToken, data)
    if (res.data.statusCode === 200) {
      refetchData(typeId, referencePoint?.index, frameStyleProdId?.id)
      setIsEdit(true)
    }

  }

  
  const updateAdditonalHardwareFormulaService = async () => {

    let data = {
      hardwareId: currentItem?.id,
      frameStyleId: frameStyleProdId?.id,
      referencePoint: referencePoint.index,
      heightFormula,
      widthFormula,
      tagIds: selectedIds?.length ? selectedIds : [0],
      status: currentItem?.status,
      quantity: quantity
    }

    const res = await updateFormulaAdditionalHardware(accessToken, data)
    if (res.data.statusCode === 200) {
      refetchData(referencePoint?.index)
      setIsEdit(true)
    }

  }

  const saveData = () => {
    if (activeTab === "Hardware") {
      updateAdditonalHardwareFormulaService()
    } else if (activeTab === "Sash" || activeTab === "Frame") {
      updateAdditonalProfileFormulaService()
    }
  }

  useEffect(() => {
    if (currentItem !== null) {
      // setWidthFormula(
      //   currentItem?.widthFormula !== null
      //     ? currentItem?.widthFormula
      //     : `P${referencePoint?.index + 1}`
      // );

      if (currentItem?.hasOwnProperty("defaultHanging")) {
        setBothDimension(true)
      } else {
        setBothDimension(false)
      }

      setSelectedIds(
        currentItem?.tagsIds?.length > 0 ? currentItem?.tagsIds : []
      );

      setSelectedIdsHeight(
        currentItem?.tagsIdsHeight?.length > 0 ? currentItem?.tagsIdsHeight : []
      );
    }
  }, [currentItem]);


  // This will create the array which will generate the persisting formula on the basis of ids and this if for default when the data is not saved
  useEffect(() => {
    if (
      allTagData &&
      selectedIds &&
      selectedIds?.length > 0 &&
      allTagData?.length > 0
    ) {
      let temp = [];
      selectedIds?.forEach((item) => {
        let element = allTagData?.find((ele) => ele?.id === item);
        if (element) {
          let obj = {
            id: element?.id,
            length: element?.length,
          };
          temp.push(obj);
        }
      });
      setFormulaWithIds(temp);
    } else {
      if (!referencePoint?.data?.name?.includes("Frame")) {
        
        const index = referencePoint?.data?.name
          ? Math.floor(referencePoint.index / 4)
          : referencePoint?.index;
         

        const formula = referencePoint?.data?.name?.includes("verticalBar")
          ? "H"
          : `P${index + 1}`;

        setWidthFormula(formula);

      } else {
        if (
          referencePoint?.data?.name?.includes("Top") ||
          referencePoint?.data?.name?.includes("Bottom")
        ) {
          setWidthFormula(`W`);
        } else {
          setWidthFormula("H");
        }
      }
    }
  }, [selectedIds, allTagData]);

  // This will create the array which will generate the persisting formula on the basis of ids and this if for default when the data is not saved
  useEffect(() => {
    if (bothDimension) {
      if (
        allTagData &&
        selectedIdsHeight &&
        selectedIdsHeight?.length > 0 &&
        allTagData?.length > 0
      ) {
        let temp = [];
        selectedIdsHeight?.forEach((item) => {
          let element = allTagData?.find((ele) => ele?.id === item);
          if (element) {
            let obj = {
              id: element?.id,
              length: element?.length,
            };
            temp.push(obj);
          }
        });
        setFormulaWithIdsHeight(temp);
      } else {
        setHeightFormula("H");
      }
    }
  }, [selectedIdsHeight, allTagData]);

  // useEffect(() => {
  //   if (frameProductDetails?.id) {
  //     console.log(frameProductDetails, "frameProductDetails")
  //     getTagNames(frameProductDetails?.collectionId);
  //   }
  // }, [frameProductDetails]);

  // This will generate the formula everytime on the basis of selected ids when the data is saved
  // useEffect(() => {
  //   if (formulaWithIds && formulaWithIds?.length > 0) {
  //     let tempFormula = "";
  //     if (!referencePoint?.data?.name?.includes("Frame")) {
  //       const index = referencePoint?.data?.name
  //         ? Math.floor(referencePoint.index / 4)
  //         : referencePoint.index;
  //       tempFormula = referencePoint?.data?.name?.includes("verticalBar")
  //         ? "H"
  //         : `P${index + 1}`;
  //     } else {
  //       if (
  //         referencePoint?.data?.name?.includes("Top") ||
  //         referencePoint?.data?.name?.includes("Bottom")
  //       ) {
  //         tempFormula = `W`;
  //       } else {
  //         tempFormula = "H";
  //       }
  //     }

  //     formulaWithIds?.forEach((item) => {
  //       tempFormula += `-${item?.length}`;
  //     });
  //     setWidthFormula(tempFormula);
  //   } else if (formulaWithIds?.length === 0) {
  //     const index = referencePoint?.data?.name
  //       ? Math.floor(referencePoint.index / 4)
  //       : referencePoint.index;

  //     if (!referencePoint?.data?.name?.includes("Frame")) {
  //       if (referencePoint?.data?.name?.includes("verticalBar")) {
  //         setWidthFormula('H')
  //       } else {
  //         setWidthFormula(`P${index + 1}`);
  //       }
  //     } else {
  //       if (referencePoint?.data?.name?.includes("Top") ||
  //         referencePoint?.data?.name?.includes("Bottom")) {
  //         setWidthFormula('W')
  //       } else {
  //         setWidthFormula('H')
  //       }
  //     }
  //   }
  // }, [formulaWithIds]);



  useEffect(() => {
    if (!referencePoint) return;
  
    let tempFormula = "";
    const isFrame = referencePoint?.data?.name?.includes("Frame");
    const isTopOrBottom =
      referencePoint?.data?.name?.includes("Top") ||
      referencePoint?.data?.name?.includes("Bottom");
    const isVerticalBar = referencePoint?.data?.name?.includes("verticalBar");
  
    const index = referencePoint?.data?.name
      ? Math.floor(referencePoint.index / 4)
      : referencePoint.index;
  
    if (!isFrame) {
      tempFormula = isVerticalBar ? "H" : `P${index + 1}`;
    } else {
      tempFormula = isTopOrBottom ? "W" : "H";
    }
  
    if (formulaWithIds?.length > 0) {
      formulaWithIds.forEach((item) => {
        tempFormula += `-${item?.length}`;
      });
      setWidthFormula(tempFormula);
    } else if (formulaWithIds?.length === 0) {
      setWidthFormula(tempFormula);
    }
  }, [formulaWithIds, referencePoint]);
  

  // This will generate the formula everytime on the basis of selected ids when the data is saved
  useEffect(() => {
    if(bothDimension) {
      if (formulaWithIdsHeight && formulaWithIdsHeight?.length > 0) {
        let tempFormula = "H";
  
        formulaWithIdsHeight?.forEach((item) => {
          tempFormula += `-${item?.length}`;
        });
  
        setHeightFormula(tempFormula);
      } else if (formulaWithIdsHeight?.length === 0) {
        setHeightFormula('H');
      }
    }
  }, [formulaWithIdsHeight]);

  const saveFormula = async () => {
    setLoading(true);
    const data = {
      id: 0,
      frameStyleId: frameStyleProdId?.id,
      referencePoint: referencePoint?.index,
      heightFormula,
      widthFormula,
      status: currentItem?.status,
      tagIds: selectedIds,
      tagIdsHeight: selectedIdsHeight,
      orientation: extractOrientation(referencePoint?.data?.name)
    };

    const isHanging = currentItem?.hasOwnProperty("hangingName");

    const service = isHanging
      ? setUpdateSashHangingFormulaService
      : setUpdateFormulaService;
    data[isHanging ? "sashHangingId" : "frameProfileId"] = currentItem?.id;

    try {
      const res = await service(accessToken, data);
      if (res && res?.data?.statusCode === 200) {
        setMessage(res?.data?.message);
        setMessageType("success");
      }
    } catch (error) {
      setMessage("An error occurred");
      setMessageType("error");
    } finally {
      setLoading(false);
      (isHanging ? getSashHangingData : getData)(referencePoint?.index);
    }
  };

  const handleCuttingFormula = (item) => {
    if (!selectedIds?.includes(item?.id)) {
      setSelectedIds((prevIds) => {
        return [...prevIds, item?.id];
      });
      let obj = {
        id: item?.id,
        length: item?.length,
      };
      setFormulaWithIds((prevIds) => {
        return [...prevIds, obj];
      });
    } else {
      const filteredItems = selectedIds?.filter((ele) => ele !== item?.id);
      const filterItemsWithLenth = formulaWithIds?.filter(
        (ele) => ele?.id !== item?.id
      );
      setSelectedIds(filteredItems);
      setFormulaWithIds(filterItemsWithLenth);
    }
  };
  
  const handleCuttingFormulaHeight = (item) => {
    if (!selectedIdsHeight?.includes(item?.id)) {
      setSelectedIdsHeight((prevIds) => {
        return [...prevIds, item?.id];
      });
      let obj = {
        id: item?.id,
        length: item?.length,
      };
      setFormulaWithIdsHeight((prevIds) => {
        return [...prevIds, obj];
      });
    } else {
      const filteredItems = selectedIdsHeight?.filter((ele) => ele !== item?.id);
      const filterItemsWithLenth = formulaWithIds?.filter(
        (ele) => ele?.id !== item?.id
      );
      setSelectedIdsHeight(filteredItems);
      setFormulaWithIdsHeight(filterItemsWithLenth);
    }
  };

  const extractOrientation = (inputString) => {
    // Define an array of possible orientations
    const orientations = ["Left", "Right", "Top", "Bottom"];

    // Iterate over the orientations array and check if the input string ends with one of them
    for (let orientation of orientations) {
      if (inputString.endsWith(orientation)) {
        return orientation;
      }
    }

    // If no orientation is found, return null or some default value
    return null;
  }

  return (
    <div className="d-flex flex-column text-dark pe-3 manufacturing_scroll">
      <>
        <NavLink onClick={() => setIsEdit(false)} className="text-link mb-3">
          <BiArrowBack size={20} />
          <span className="ms-1">Back</span>
        </NavLink>

        {/* <Accordion
            open={open}
            toggle={toggleAccordion}
            onClick={(e) => handleAccordionClick(e)}
            tag="div"
          > */}
        {/* <AccordionItem tag="div"> */}
        {/* <AccordionHeader targetId="1">
                Cutting Formula
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropDown}
                  className="ms-auto"
                >
                  <DropdownToggle
                    color="none"
                    className="border-0 p-0 py-1 d-block text-muted"
                    tag="span"
                  ></DropdownToggle>
                </Dropdown>
              </AccordionHeader> */}

        {/* <AccordionBody accordionId="1"> */}

        <div className="text-dark">
          <div className="cutting_section d-flex align-items-center gap-3">
            {(referencePoint?.data?.name?.includes("horizontal") ||
              referencePoint?.data?.name?.includes("vertical") ||
              referencePoint?.data?.name === "" ||
              referencePoint?.data?.name?.includes("Frame")) && (
                <>
                  {(activeTab === "Hardware" && currentItem?.priceType === 2) ?
                    <>
                      <FormGroup className="mb-3 w-100">
                        <Label for="quantity">Quantity</Label>
                        <Input
                          type='number'
                          name="quantity"
                          min="1" max="999" step="1"
                          value={quantity}
                          onInput={(e) => handleQuantity(e)}

                          onChange={(e) => { setQuantity(e.target.value) }}
                        />
                      </FormGroup>
                    </>
                    :
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex align-items-center gap-3">
                        <FormGroup className="mb-0 d-flex align-items-center gap-1 w-75">
                          <Label for="width" className="mb-0 mini_text">
                            {bothDimension ? 'Width' : 'Length'}
                          </Label>
                          <Input
                            name="width"
                            disabled={true}
                            // onChange={handleWidthFormula}
                            value={widthFormula}
                            id="width"
                          />
                        </FormGroup>

                        <FormGroup className="mb-0 d-flex align-items-center">

                          <Dropdown
                            isOpen={toggleTagSizeWidth}
                            toggle={() => {
                              setToggleTagSizeWidth(!toggleTagSizeWidth);
                            }}
                          >
                            <DropdownToggle
                              color="primary"
                              className="btn-icon px-2"
                              outline
                            >
                              <IoMdLink size={22} />
                            </DropdownToggle>

                            <DropdownMenu
                              className={`${window?.innerHeight < 900 ? "has_scroll" : ""
                                } body_dropdown hardware_list`}
                              container="body"
                            >
                              {allTagData &&
                                allTagData?.length > 0 &&
                                allTagData?.map((item, index) => {
                                  return (
                                    <DropdownItem
                                      onClick={() => {
                                        handleCuttingFormula(item);
                                      }}
                                      key={index}
                                    >
                                      {/* {item?.name} */}
                                      <div className="d-flex align-items-center gap-5">
                                        <span className="w-100" name="checkTag">
                                          {item?.name}
                                        </span>
                                        <div className="form-check mb-0 ms-1">
                                          <Input
                                            className="check_box"
                                            type="checkbox"
                                            id="checkTag"
                                            checked={selectedIds?.includes(
                                              item?.id
                                            )}
                                            readOnly={true}
                                          />
                                        </div>
                                      </div>
                                    </DropdownItem>
                                  );
                                })}
                            </DropdownMenu>
                          </Dropdown>
                        </FormGroup>
                      </div>

                      {bothDimension && (
                        <div className="d-flex align-items-center gap-3">
                          <FormGroup className="mb-0 d-flex align-items-center gap-1 w-75">
                            <Label for="width" className="mb-0 mini_text">
                              Height
                            </Label>
                            <Input
                              name="width"
                              disabled={true}
                              value={heightFormula}
                              id="width"
                            />
                          </FormGroup>

                          <FormGroup className="mb-0 d-flex align-items-center">
                            <Dropdown
                              isOpen={toggleTagSizHeight}
                              toggle={() => {
                                setToggleTagSizHeight(!toggleTagSizHeight);
                              }}
                            >
                              <DropdownToggle
                                color="primary"
                                className="btn-icon px-2"
                                outline
                              >
                                <IoMdLink size={22} />
                              </DropdownToggle>

                              <DropdownMenu
                                className={`${window?.innerHeight < 900 ? "has_scroll" : ""
                                  } body_dropdown hardware_list`}
                                container="body"
                              >
                                {allTagData &&
                                  allTagData?.length > 0 &&
                                  allTagData?.map((item, index) => {
                                    return (
                                      <DropdownItem
                                        onClick={() => {
                                          handleCuttingFormulaHeight(item);
                                        }}
                                        key={index}
                                      >
                                        {/* {item?.name} */}
                                        <div className="d-flex align-items-center gap-5">
                                          <span className="w-100" name="checkTag">
                                            {item?.name}
                                          </span>
                                          <div className="form-check mb-0 ms-1">
                                            <Input
                                              className="check_box"
                                              type="checkbox"
                                              id="checkTag"
                                              checked={selectedIdsHeight?.includes(
                                                item?.id
                                              )}
                                              readOnly={true}
                                            />
                                          </div>
                                        </div>
                                      </DropdownItem>
                                    );
                                  })}
                              </DropdownMenu>
                            </Dropdown>
                          </FormGroup>
                        </div>
                      )}
                    </div>
                  }

                </>
              )}
          </div>
        </div>

        {/* {open === "1" && ( */}

        <div className="d-flex gap-2 gap-xl-3 main_buttons w-100 mt-3 mb-3">
          <Button
            color="primary"
            size="md"
            // disabled={open === "1" && (!heightFormula || !widthFormula)}
            className="px-4"
            onClick={() => {
              isAdditionProfile ? saveData() : saveFormula();
            }}
          >
            Save
          </Button>

          <Button
            color="primary"
            size="md"
            outline
            className="px-4"
            onClick={() => {
              setIsEdit(false);
            }}
          >
            Cancel
          </Button>
        </div>
        {/* )} */}
        {/* </AccordionBody>
            </AccordionItem>
          </Accordion> */}
      </>
    </div>
  );
};

export default ManufacturingEditComp;
