import axios from "axios";
import { servicePath } from "../utility/data";

// get all right menu item list based on page/type
export const getAllGlazingOversizeCollection = (accessToken) => {

    let config = {
        method: 'GET',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .get(`${servicePath}/Glazing/GetAllGlazingOversizeCollection`, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
}

// get details of selected item in right menu
export const getItemDetails = (accessToken, id) => {
    const formData = new FormData();
    formData.append("Id", id);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }
    };

    return axios
        .post(`${servicePath}/Glazing/GetGlazingOversizeCollection`, formData, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// delete selected item
export const deleteRightItem = (accessToken, id) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    }

    const formData = new FormData()
    formData.append("Id", id)
    return axios
        .post(`${servicePath}/Glazing/DeleteGlazingOversizeCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update order of selected item
export const updateOrder = (accessToken, id, direction) => {

    console.log(id, direction, "order test")

    var requestBody = {
        "id": id,
        "sequenceNo": 0,
        "type": direction
    };

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    }

    return axios
        .post(`${servicePath}/Glazing/UpdateGlazingOversizeCollectionOrder`, requestBody, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

// update details of selected item
export const updateItemDetails = (accessToken, id, data) => {

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    const formData = new FormData();

    formData.append("Id", id);
    formData.append('Name', data.name);

    return axios
        .post(`${servicePath}/Glazing/AddUpdateGlazingOversizeCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

// add new right menu item based on page/type
export const addNewRightItem = (accessToken, data) => {
    const formData = new FormData();

    formData.append("Id", '');
    formData.append('Name', data);

    const headers = {
        'accept': 'text/plain',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
    }

    return axios
        .post(`${servicePath}/Glazing/AddUpdateGlazingOversizeCollection`, formData, { headers })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};


//////////// OVERSIZE API's //////////////////

export const addOversizeGlazingService = (accessToken, data) => {
    // const formData = new FormData();

    // formData.append("Id", data.id);
    // formData.append("CollectionId", data.collectionId);
    // formData.append("MinValue", data?.minArea);
    // formData.append("MaxValue", data?.maxArea);
    // formData.append("Percentage", data?.percentage);
    // formData.append("specifications", data?.specs);
    // formData.append("SpecificationName", '');

    let config = {
        method: "POST",
        maxBodyLength: Infinity,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
    };

    return axios
        .post(
            `${servicePath}/Glazing/AddUpdateGlazingOversizePrice`,
            data,
            config
        )
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
};

export const getAllGlazingOversizePrice = (accessToken, fetch_id) => {
    const formData = new FormData();

    formData.append("CollectionId", fetch_id);

    let config = {
        method: "POST",
        maxBodyLength: Infinity,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
        },
    };

    return axios
        .post(
            `${servicePath}/Glazing/GetAllGlazingOversizePrice`,
            formData,
            config
        )
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const deleteOverSizeGlazingService = (id, accessToken) => {
    const formData = new FormData();
    formData.append("Id", id);

    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };

    return axios
        .post(`${servicePath}/Glazing/DeleteGlazingOversizePrice`, formData, {
            headers,
        })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};

export const getOverSizeGlazingIndividualService = (accessToken, id) => {
    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };

    const formData = new FormData();
    formData.append("Id", id);
    
    return axios
        .post(`${servicePath}/Glazing/GetGlazingOversizePrice`, formData, {
            headers,
        })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
};
