// function to convert hex to RGB and set dynamic primary color from glazingo
export function setColor(newColor) {
    if (newColor) {
        // hex to rgb values
        const r = parseInt(newColor?.slice(1, 3), 16);
        const g = parseInt(newColor?.slice(3, 5), 16);
        const b = parseInt(newColor?.slice(5, 7), 16);

        var hexRGB = `${r}, ${g}, ${b}`;

        document.documentElement.style.setProperty('--theme-blue', newColor);
        document.documentElement.style.setProperty('--theme-blue-rgb', hexRGB);
    }
}