import React, { useEffect, useState } from 'react'
import { Checkbox, FormControl, FormControlLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from 'material-ui-core';
import { Form } from 'react-bootstrap';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { MdKeyboardArrowDown, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { GrDocument } from "react-icons/gr";
import NumberInput from '../../../../../../components/NumberInput';
import { MenuProps } from '../../../../../../utility/data';
import { useRightContext } from '../../../../../../context/rightMenuContext';
import PricingHeader from '../../../../../../components/Pricing/PricingHeader';


const PromotionsLeft = ({ setLoading, accessToken, selectItemDetails, updateItemDetailsServices, handleFormReset, discountableItemsOptions }) => {

    const { timestamp, selected, setSelected, handleEdit, handleDescEdit, setDescUpdate } = useRightContext()

    const [itemId, setItemId] = useState('')

    const [selectedPricing, setSelectedPricing] = useState()
    const [selectedSupplyOnly, setSelectedSupplyOnly] = useState('')

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [defaultNum, setDefaultNum] = useState('')

    const [discountableItems, setDiscountableItems] = useState([])

    const [max, setMax] = useState('')
    const [discountType, setDiscountType] = useState("Percentage")

    const [addAuto, setAddAuto] = useState(false)
    const [perName, setPerName] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [hidePer, setHidePer] = useState(false)

    const [nameError, setNameError] = useState(false)
	const [descriptionError, setDescriptionError] = useState(false)

    useEffect(() => {
        setLoading(false)

        setSelected(selectItemDetails?.name || '')
        setName(selectItemDetails?.name || '')
        setItemId(selectItemDetails?.id || '')

        setDescription(selectItemDetails?.description || '')
        setDefaultNum(selectItemDetails?.default || 0)
        setMax(selectItemDetails?.maximum || 0)

        setDiscountType(selectItemDetails?.discountType || "Percentage")
        setAddAuto(selectItemDetails?.addAutomatically || false)
        setPerName(selectItemDetails?.addPercentageToName || false)
        setDisabled(selectItemDetails?.disabled || false)
        setHidePer(selectItemDetails?.hidePercentage || false)

        // Filtering and setting here the items in dropdown
        if (selectItemDetails && selectItemDetails?.discountableItems?.length > 0) {

            const selectedNames = selectItemDetails?.discountableItems?.map((item) => {
                const proj = discountableItemsOptions?.find((p) => p.id == item)
                return proj ? proj.name : null
            })?.filter(id => id !== null);
            
            setDiscountableItems(selectedNames);
        } else {
            setDiscountableItems([]);
        }

        setNameError(false)
        setDescriptionError(false)
    }, [selectItemDetails])

    const handleNameChange = (e) => {
		const value = e.target.value

		if (e.target.value?.length <= 50) {
			setName(value)
			handleEdit(value)
			setSelected(value)
		}

		if(value.length > 50) {
			setNameError(true)
		} else {
			setNameError(false)
		}
	}

	const handleDescriptionChange = (e) => {
		const value = e.target.value

		if (e.target.value?.length <= 80) {
			setDescription(value)
			handleDescEdit(value)
			setDescUpdate(value)
		}

		if(value.length > 80) {
			setDescriptionError(true)
		} else {
			setDescriptionError(false)
		}
	}

    const handleDiscountableItemsChange = (e) => {
        const value = e.target.value

        setDiscountableItems(typeof value === 'string' ? value.split(',') : value)
    }

    const handleFormSubmit = () => {

        const getSelectedItemIds = discountableItems?.map((name) => {
            const itemData = discountableItemsOptions.find((item) => item?.name === name)
            return itemData ? itemData?.id : null;
        })

        const formDataValues = {
            'id': selectItemDetails?.id,
            "name": name,
            "Description": description,
            "Default": defaultNum,
            "Maximum": max,
            'DiscountableItems': getSelectedItemIds,
            "DiscountType": discountType,
            "AddAutomatically": addAuto,
            "AddPercentageToName": perName,
            "Disabled": disabled,
            "HidePercentage": hidePer,
        }

        console.log(formDataValues, "test")

        updateItemDetailsServices(formDataValues)
    }

    return (
        <div className='d-flex flex-column h-100'>

            <PricingHeader
                setSelectedSupplyOnly={setSelectedSupplyOnly}
                setSelectedPricing={setSelectedPricing}
                selectedPricing={selectedPricing}
                selectedSupplyOnly={selectedSupplyOnly}
            />

            <div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
                {selected && (
                    <h5 className="mb-0 text-wrap text-break main_title">{selected}</h5>
                )}
            </div>

            <Form className='h-100 d-flex flex-column flex-grow-1 justify-content-between'>
                <div className='inside_scroll has_btns'>
                    <Row>
                        <Col lg="6">
                            <FormGroup className="form-group mb-3">
                                <Label for="name">
                                    Name
                                </Label>

                                <Input className='mb-1' name='name' value={name || ''} onChange={(e) => handleNameChange(e)} />

                                {nameError && timestamp && itemId && (
                                    <div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
                                )}

                                {!name && itemId && (
                                    <div className='mt-1 text-danger text-small'>Name is required</div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group mb-3">
                                <Label for="desc">
                                    Description
                                </Label>
                                <Input className='mb-1' name='description' value={description || ''} onChange={(e) => handleDescriptionChange(e)} />

                                {(descriptionError && timestamp && itemId) && (
                                    <div className='mt-1 text-muted text-small'>Max 80 characters are allowed</div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="percent">Default</Label>
                                <NumberInput
                                    name="percent"
                                    value={defaultNum}
                                    setValue={setDefaultNum}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <Label for="percent">Maximum</Label>
                                <NumberInput
                                    name="percent"
                                    value={max}
                                    setValue={setMax}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormGroup className="form-group">
                                <FormControl fullWidth>
                                    <Label for="discountableItems">
                                        Discountable items
                                    </Label>

                                    <Select
                                        labelId="discountableItems-label"
                                        id="discountableItems"
                                        multiple
                                        value={discountableItems || ''}
                                        onChange={(e) => handleDiscountableItemsChange(e)}
                                        input={<OutlinedInput name='discountableItems' />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}

                                        IconComponent={() => (
                                            <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
                                        )}
                                    >
                                        {discountableItemsOptions && discountableItemsOptions?.map((name, index) => (
                                            <MenuItem key={index} value={name.name} className='check_list'>
                                                <Checkbox checked={discountableItems?.indexOf(name.name) > -1} />
                                                <ListItemText primary={name.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </Col>

                        <Col lg="6">
                            <FormControl className="mb-3 w-100">
                                <Label></Label>
                                <RadioGroup
                                    aria-labelledby="discountType-label"
                                    name="discountType"
                                    className='mt-2'
                                    value={discountType}
                                    onChange={(e) => setDiscountType(e.target.value)}
                                >
                                    <FormControlLabel value="Percentage" control={<Radio />} label="Percentage" />
                                    <FormControlLabel className='' value="Lump sum" control={<Radio />} label="Lump sum" />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                        <Col lg="12" >
                            <Row>
                                <Col lg={4} md={6} sm={6} xl={3}>
                                    <FormGroup className="form-group">
                                        <Label for="installation">Add automatically</Label>
                                        <Form.Check
                                            type="switch"
                                            id="installation"
                                            className='form-switch-lg'
                                            onChange={() => { setAddAuto(!addAuto) }}
                                            checked={addAuto}
                                            label={addAuto ? 'On' : 'Off'}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg={3} md={6} sm={6} xl={3}>
                                    <FormGroup className="form-group">
                                        <Label for="installation">Add % to name</Label>
                                        <Form.Check
                                            type="switch"
                                            id="installation"
                                            className='form-switch-lg'
                                            onChange={() => { setPerName(!perName) }}
                                            checked={perName}
                                            label={perName ? 'On' : 'Off'}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg={3} md={6} sm={6} xl={3}>
                                    <FormGroup className="form-group">
                                        <Label for="disabled">Disabled</Label>
                                        <Form.Check
                                            type="switch"
                                            id="disabled"
                                            className='form-switch-lg'
                                            onChange={() => setDisabled(!disabled)}
                                            checked={disabled}
                                            label={disabled ? 'On' : 'Off'}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg={2} md={6} sm={6} xl={3}>
                                    <FormGroup className="form-group">
                                        <Label for="installation">Hide %</Label>
                                        <Form.Check
                                            type="switch"
                                            id="installation"
                                            className='form-switch-lg'
                                            onChange={() => setHidePer(!hidePer)}
                                            checked={hidePer}
                                            label={hidePer ? 'On' : 'Off'}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </div>

                {/* Start: space for buttons at the bottom */}
                <div className="d-flex gap-2 gap-xl-3 main_buttons">
                    <Button color="primary" size="md" className="px-4"
                        onClick={() => handleFormSubmit()}
                        disabled={!timestamp || !name.trim()}
                    >
                        Save Changes
                    </Button>

                    <Button color="primary" size="md"
                        outline
                        className="px-4"
                        disabled={!timestamp}
                        onClick={() => handleFormReset()} >
                        Reset
                    </Button>
                </div>
                {/* End: space for buttons at the bottom */}
            </Form>
        </div>
    )
}

export default PromotionsLeft