import React, { useEffect, useState } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup } from 'material-ui-core';
import { FormGroup } from 'react-bootstrap';
import { Button, Form, Input, Label, NavLink } from 'reactstrap';
import axios from "axios";

const EditQuotationDetails = ({ data, toggleTabDropdown, quoteId, userDataContext, accessToken, getQuotationDetailsService, setLoading, setMessage, setMessageType, quoteUserHostUrl }) => {

    const [name, setName] = useState('');
    const [quoteName, setQuoteName] = useState('');
    const [jobType, setJobType] = useState('Supply only');
    const [reference, setReference] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [postCode, setPostCode] = useState('');
    const [comments, setComments] = useState('');

    const [showMore, setShowMore] = useState(false);

    // const handleNavigate = () => {
    //     if (hasState) {
    //         navigate(data.url,
    //             { state: { data: data.stateValue } }
    //         )
    //     } else {
    //         console.log(hasState, data, "hasState2")
    //         navigate(data.url)
    //     }
    // }

    const handleUpdateQuote = () => {
        setShowMore(false)
        toggleTabDropdown()

        updateQuotationDetails()
    }

    const handleUpdateCancel = () => {

        setShowMore(false)
        toggleTabDropdown()
    }

    useEffect(() => {

        if (data && data.id) {
            // setName(data.name)
            setQuoteName(data.quotation_name || '')
            setJobType(data.project_type || '')
            setReference(data.reference || '')
            setEmail(data.email || '')
            setPhone(data.phone || '')
            setAddress(data.address || '')
            setPostCode(data.pin_code || '')
            setComments(data.comments || '')
        } else {
            setQuoteName('')
            setJobType('')
            setReference('')
            setEmail('')
            setPhone('')
            setAddress('')
            setPostCode('')
            setComments('')
        }
    }, [data]);

    const updateQuotationDetails = () => {

        const FormData = require("form-data");

        let data = new FormData();

        data.append("employee_id", userDataContext?.user_id);
        data.append("quotation_id", quoteId);
        data.append("quotation_name", quoteName);
        data.append("job_type", jobType);
        data.append("phone", phone);
        data.append("email", email);
        data.append("address", address);
        data.append("pin_code", postCode);
        data.append("comments", comments);

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `https://${quoteUserHostUrl}/api/orbit/quotations/update`,
            headers: {
                accept: "text/plain",
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "multipart/form-data",
            },
            data: data,
        };

        axios
            .request(config)
            .then((res) => {

                if (res?.status === 200) {
                    if (res?.data?.status) {
                        setMessage(res?.data?.message)
                        setMessageType("success")

                        if (quoteUserHostUrl !== '' && quoteId) {
                            setTimeout(() => {
                                getQuotationDetailsService(quoteUserHostUrl, quoteId)
                            }, 500);
                        }
                    }
                } else {
                    setTimeout(() => {
                        if (quoteUserHostUrl !== '' && quoteId) {
                            getQuotationDetailsService(quoteUserHostUrl, quoteId)
                        }

                        setLoading(false);
                    }, 500);
                }
            })
            .catch((error) => {

                if (quoteUserHostUrl !== '' && quoteId) {
                    setTimeout(() => {
                        getQuotationDetailsService(quoteUserHostUrl, quoteId)
                    }, 500);
                }

                setLoading(false);

                setMessage(error.message ? error.message : "Something went wrong");
                setMessageType("error");
            });
    };

    return (
        <Form className='px-3 py-2'>
            <div className='scroll_area mb-3'>
                {/* <FormGroup className="mb-3">
                    <Label for="name">Name</Label>
                    <Input name="name" value={name || ''} onChange={(e) => setName(e.target.value)} />
                    {!name && <div className='text-danger mt-1'>Name is required</div>}
                </FormGroup> */}

                <FormGroup className="mb-3">
                    <Label for="quoteName">Quotation name</Label>
                    <Input name="quoteName" value={quoteName || ''} onChange={(e) => setQuoteName(e.target.value)} />
                    {!quoteName && <div className='text-danger mt-1'>Quotation name is required</div>}
                </FormGroup>

                <FormGroup className="mb-3">
                    <Label for="reference">Reference</Label>
                    <Input name="reference" value={reference || ''} onChange={(e) => setReference(e.target.value)} />
                    {!reference && <div className='text-danger mt-1'>Reference is required</div>}
                </FormGroup>

                <FormGroup className="mb-3">
                    <FormControl className="mb-0">
                        <Label for="jobType">
                            Job type
                        </Label>

                        <RadioGroup
                            aria-labelledby="jobType-label"
                            name="jobType"
                            value={jobType}
                            onChange={(e) => setJobType(e.target.value)}
                        >
                            <FormControlLabel
                                value="Supply only" control={<Radio />} label="Supply only" />
                            <FormControlLabel
                                value="Supply and installation" control={<Radio />} label="Supply & Install" />

                        </RadioGroup>
                    </FormControl>
                </FormGroup>

                {showMore && (
                    <>
                        <FormGroup className="mb-3">
                            <Label for="email">Email</Label>
                            <Input name="email" value={email || ''} onChange={(e) => setEmail(e.target.value)} />
                            {!email && <div className='text-danger mt-1'>Email is required</div>}
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <Label for="phone">Phone</Label>
                            <Input name="phone" value={phone || ''} onChange={(e) => setPhone(e.target.value)} />
                            {!phone && <div className='text-danger mt-1'>Phone is required</div>}
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <Label for="address">Address</Label>
                            <Input name="address" value={address || ''} onChange={(e) => setAddress(e.target.value)} />
                            {!address && <div className='text-danger mt-1'>Address is required</div>}
                        </FormGroup>

                        <FormGroup className="mb-3">
                            <Label for="postCode">Post code</Label>
                            <Input name="postCode" value={postCode || ''} onChange={(e) => setPostCode(e.target.value)} />
                            {!postCode && <div className='text-danger mt-1'>Post code is required</div>}
                        </FormGroup>
                    </>
                )}

                <FormGroup className="mb-3">
                    <Label for="comments">Comments</Label>
                    <Input type="textarea" rows={3} name="comments" value={comments || ''} onChange={(e) => setComments(e.target.value)} />
                </FormGroup>

                {!showMore && (!quoteName || !reference || !jobType || !email || !phone || !address || !postCode) && (
                    <p className='text-danger mt-4'>Please fill all required fields</p>
                )}

                {!showMore && (
                    <NavLink className='d-flex text-primary mb-3' onClick={() => setShowMore(true)}>
                        Show more
                    </NavLink>
                )}

            </div>

            <div className='d-flex justify-content-end gap-2'>
                {(userDataContext?.role !== "Super Admin" && userDataContext.role !== 'Supplier') && (
                    <Button
                        color="primary"
                        size="md"
                        className='px-4'
                        disabled={!quoteName || !reference || !jobType || !email || !phone || !address || !postCode}
                        onClick={() => handleUpdateQuote()}
                    >
                        Save
                    </Button>
                )}

                <Button
                    color="primary"
                    outline
                    size="md"
                    className='px-4'
                    onClick={() => handleUpdateCancel()}
                >
                    Cancel
                </Button>
            </div>
        </Form>
    )
}

export default EditQuotationDetails