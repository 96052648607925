import React, { useEffect, useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "material-ui-core";
import { useDesigner } from "../context/designerContext";
import { deepEqual } from "../utility/helper";
import NumberInput from "./NumberInput";

const OverideHeightAndWidthComp = ({ design }) => {
  const {
    setOverrideWidth,
    setOverrideHeight,
    overrideHeight,
    overrideWidth,
    frameAngle,
    setFrameAngle,
    applyAngle,
    setApplyAngle,
    addedFrameData,
    addedFrameList,
    multiSelectRefPoints,
    setMultiSelectRefPoints,
    customModelData,
  } = useDesigner();

  // console.log(addedFrameData,"jjhj")
  const [rotationAngle, setRotationAngle] = useState(0);
  const [size, setSize] = useState("External");

  // console.log(addedFrameList, "2111111111111111");

  // console.log("check--->", multiSelectRefPoints);

  useEffect(() => {
    if (multiSelectRefPoints?.length > 1) {
      let lastElement = multiSelectRefPoints.pop();
      setMultiSelectRefPoints([lastElement]);
    }
  }, [multiSelectRefPoints]);

  useEffect(() => {
    if (applyAngle) {
      setTimeout(() => {
        setApplyAngle(false);
      }, 100);      
    }
  }, [applyAngle]);

  useEffect(() => {
    if(customModelData && customModelData?.addedFrames?.length>0){
      const multiSelectData = multiSelectRefPoints[0]?.data;
      const isScene = multiSelectData?.name === "Scene";

      const foundItem = customModelData?.addedFrames?.find((item) =>
        deepEqual(item?.position, multiSelectData?.position)
      );

      // if(foundItem && !isScene){
      //   customModelData?.addedFrames?.forEach((item=>{
      //     if(item?.id==foundItem?.frameStyle?.id){
      //       setOverrideHeight(item?.height)
      //       setOverrideWidth(item?.width)
      //     }
      //   }))
      // }

      if (foundItem && !isScene) {
        setOverrideHeight(foundItem?.height);
        setOverrideWidth(foundItem?.width);
      } 
      
      else if (isScene) {
        setOverrideHeight(customModelData?.layoutFrame?.height);
        setOverrideWidth(customModelData?.layoutFrame?.width);
      }
    }
    // if (addedFrameList?.length > 0) {
    //   const multiSelectData = multiSelectRefPoints[0]?.data;
    //   const isScene = multiSelectData?.name === "Scene";

    //   const foundItem = addedFrameList?.find((item) =>
    //     deepEqual(item?.position, multiSelectData?.position)
    //   );

    //   if (foundItem && !isScene) {
    //     setOverrideHeight(foundItem?.layoutFrame?.height);
    //     setOverrideWidth(foundItem?.layoutFrame?.width);
    //   } else if (isScene) {
    //     setOverrideHeight(customModelData?.layoutFrame?.height);
    //     setOverrideWidth(customModelData?.layoutFrame?.width);
    //   }
    // }
  }, [multiSelectRefPoints]);

  useEffect(() => {
    if (addedFrameData) {
      setOverrideHeight(parseFloat(addedFrameData?.layoutFrame?.height));
      setOverrideWidth(parseFloat(addedFrameData?.layoutFrame?.width));
    }
  }, [addedFrameData]);

  return (
    <>
      <div className="">
        {(design === "baypost" || design === "frameAndBayPost") && (
          <FormGroup className="form-group">
            <Label for="size">Sizes</Label>

            <FormControl className="mb-0 w-100">
              <RadioGroup
                aria-labelledby="feature-label"
                name="size"
                className="flex-row flex-wrap custom_x_gap"
                value={size}
                onChange={(e) => setSize(e?.target?.value)}
              >
                <FormControlLabel
                  value="Internal"
                  control={<Radio />}
                  label="Internal"
                />
                <FormControlLabel
                  value="External"
                  control={<Radio />}
                  label="External"
                />
              </RadioGroup>
            </FormControl>
          </FormGroup>
        )}
        {(design === "frame" || design === "frameAndBayPost") && (
          <>
            <FormGroup className="form-group">
              <Label for="overideWidth">Width override</Label>
              <NumberInput
                name="overideWidth"
                id="overideWidth"
                value={overrideWidth}
                setValue={(value) => setOverrideWidth(value)}
              ></NumberInput>
            </FormGroup>

            <FormGroup className="form-group">
              <Label for="overrideHeight">Height override</Label>
              <NumberInput
                name="overrideHeight"
                id="overrideHeight"
                value={overrideHeight}
                setValue={(value) => setOverrideHeight(value)}
              ></NumberInput>
            </FormGroup>

            <FormGroup className="form-group">
              <Label for="overrideHeight">Angle</Label>
              <NumberInput
                name="overrideHeight"
                id="overrideHeight"
                value={frameAngle}
                setValue={(value) => setFrameAngle(value)}
              ></NumberInput>
            </FormGroup>
            <div className="d-flex gap-2 gap-xl-3 main_buttons mb-4 w-100">
              <Button
                color="primary"
                size="md"
                className="px-4"
                onClick={() => setApplyAngle(true)}
              >
                Apply
              </Button>
            </div>
          </>
        )}
        {(design === "baypost" || design === "frameAndBayPost") && (
          <FormGroup className="form-group">
            <Label for="rotationAngle">Rtation angle</Label>
            <NumberInput
              name="rotationAngle"
              id="rotationAngle"
              value={rotationAngle}
              setValue={(value) => setRotationAngle(value)}
            ></NumberInput>
            <span className="ms-2 mt-2 text-small text-primary">
              Align start and end
            </span>
          </FormGroup>
        )}
      </div>
    </>
  );
};

export default OverideHeightAndWidthComp;
