import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";

// this rightMenuContext is common for all pages having right side menu except ancillary and acocunt pages
const rightMenuContext = createContext();

const useRightContext = () => {
    const value = useContext(rightMenuContext);
    return value;
}

const CustomContextRightMenu = ({ children }) => {

    const [quoteFrameData, setQuoteFrameData] = useState({
        frameIds: [],
        quotationId: '',
    });

    const [panelMenuList, setPanelMenuList] = useState([{
        title: "Panel products",
        parent: 'Products',
        submenu: 'Panels',
        level: 3,
        to: "/product/panels/panel-products",
    }]);

    const [userDataContext, setUserDataContext] = useState('')
    
    const [generalTax, setGeneralTax] = useState('')
    const [projectDefault, setProjectDefault] = useState('')
    const [mainMenuActive, setMainMenuActive] = useState('Quotations')
    const [subMenuActive, setSubMenuActive] = useState('')
    const [subMenu2Active, setSubMenu2Active] = useState('')

    const [frameMenuList, setFrameMenuList] = useState([{
        id: 0,
        title: "Collections",
        parent: 'Products',
        submenu: 'Frame Styles',
        items: [],
        level: 3,
        to: "/product/frame-styles",
    }]);

    const [frameProductsMenuList, setFrameProductsMenuList] = useState([{
        id: 0,
        title: "Collections",
        parent: 'Products',
        submenu: 'Systems',
        submenu2: 'Frame',
        level: 4,
        to: "/product/systems/frame-products",
        items: [],
    }]);

    const [pricingColoursMenuList, setPricingColoursMenuList] = useState([]);
    const [pricingPanelMenuList, setPricingPanelMenuList] = useState([
        {
            id: "1",
            title: "Aluminium Flat Panel",
            parent: "Pricing",
            submenu: "Panels",
            level: 3,
        }
    ]);
    const [pricingFrameStylesMenuList, setPricingFrameStylesMenuList] = useState([{
        id: "1",
        title: "GS20 Sliding door",
        parent: "Pricing",
        submenu: "Frame styles",
        submenu2: "",
        level: 3,
        to: "/pricing/frame-styles/1"
    }]);

    const [pricingInstallFrameStylesMenuList, setPricingInstallFrameStylesMenuList] = useState([]);
    const [pricingFrameProductsMenuList, setPricingFrameProductsMenuList] = useState([]);
    const [pricingSashProductsMenuList, setPricingSashProductsMenuList] = useState([]);

    const [newItemList, setNewItemList] = useState([]);
    const [selected, setSelected] = useState();
    const [descUpdate, setDescUpdate] = useState();
    const [timestamp, setTimestamp] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [fetchFlag, setFetchFlag] = useState(true);
    const [toDelete, setToDelete] = useState('');
    const [selectedRightItem, setSelectedRightItem] = useState('');

    // Compute the last index of the item data
    const lastIndex = newItemList?.length - 1;

    // Function to add a new item item
    const addNewItem = (currentData) => {
        setNewItemList((prevItem) => [...prevItem, currentData]);
    };

    // Function to fetch and select a specific item
    const fetchSelected = (index, uniqueId) => {
        const data = newItemList?.find((item) => item.id === uniqueId);
        setSelected(data?.name);
        setDescUpdate(data?.description)
        setTimestamp(uniqueId);
        setFetchFlag(true);
        setActiveIndex(index);
        setToDelete('')
    };

    // Function to fetch and select a specific item
    const fetchDeleteData = (index, uniqueId) => {
        const data = newItemList?.find((item) => item.id === uniqueId);
        setToDelete(data)
    };

    // Function to edit and update an item name
    const handleEdit = (value) => {
        const updateIndex = newItemList?.findIndex((item) => item.id === timestamp);

        if (updateIndex !== -1) {
            let updatedItem = [...newItemList];
            updatedItem[updateIndex] = {
                ...updatedItem[updateIndex],
                name: value,
            };
            setNewItemList(updatedItem);
        }
    };

    // Function to edit and update an item description
    const handleDescEdit = (value) => {
        const updateIndex = newItemList?.findIndex((item) => item.id === timestamp);

        if (updateIndex !== -1) {
            let updatedItem = [...newItemList];
            updatedItem[updateIndex] = {
                ...updatedItem[updateIndex],
                description: value,
            };

            setNewItemList(updatedItem);
        }
    };

    // Function to edit and update an item description
    const handleFileUpdate = (value) => {
        const updateIndex = newItemList?.findIndex((item) => item.id === timestamp);

        if (updateIndex !== -1) {
            let updatedItem = [...newItemList];
            updatedItem[updateIndex] = {
                ...updatedItem[updateIndex],
                imageUrl: value,
            };

            setNewItemList(updatedItem);
        }
    };

    // Function to handle navigating up the active index
    const handleActiveIndexUp = () => {
        if (activeIndex === 0) {
            setActiveIndex(lastIndex);
        } else {
            setActiveIndex(activeIndex - 1);
        }
    };

    // Function to handle navigating down the active index
    const handleActiveIndexDown = () => {
        if (activeIndex === lastIndex) {
            setActiveIndex(0);
        } else {
            setActiveIndex(activeIndex + 1);
        }
    };

    return (
        <rightMenuContext.Provider value={{
            newItemList,
            selected,
            timestamp,
            activeIndex,
            fetchFlag,
            addNewItem,
            fetchSelected,
            handleEdit,
            handleActiveIndexUp,
            handleActiveIndexDown,
            setSelected,
            descUpdate,
            setDescUpdate,
            handleDescEdit,
            setFetchFlag,
            setActiveIndex,
            setNewItemList,
            setTimestamp,
            panelMenuList,
            setPanelMenuList,
            frameMenuList,
            setFrameMenuList,
            frameProductsMenuList,
            setFrameProductsMenuList,
            pricingColoursMenuList,
            setPricingColoursMenuList,
            pricingPanelMenuList,
            setPricingPanelMenuList,
            pricingFrameStylesMenuList,
            setPricingFrameStylesMenuList,
            pricingInstallFrameStylesMenuList,
            setPricingInstallFrameStylesMenuList,
            pricingFrameProductsMenuList,
            setPricingFrameProductsMenuList,
            pricingSashProductsMenuList,
            setPricingSashProductsMenuList,
            handleFileUpdate,
            // handeListClick,
            // previouseSelected,
            // currentSelected,
            // arrayOfObjects,

            mainMenuActive,
            setMainMenuActive,
            subMenuActive,
            setSubMenuActive,
            subMenu2Active,
            setSubMenu2Active,
            userDataContext,
            setUserDataContext,
            setGeneralTax,
            generalTax,
            setProjectDefault,
            projectDefault,
            setSelectedRightItem,
            selectedRightItem,
            setQuoteFrameData,
            quoteFrameData,
            fetchDeleteData,
            setToDelete,
            toDelete,
        }}>
            {children}
        </rightMenuContext.Provider>
    )
}

export { useRightContext }
export default CustomContextRightMenu;

