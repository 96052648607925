import React, { useEffect, useState } from 'react'
import InnerLayout from "../../../../../../layouts/internalLayout"
import SpecificationsLeft from './specifications-left'
import SpecificationsRight from './specifications-right'
import { allGlazingCollection, allGlazingColours, allGlazingPalette } from '../../../../../../services/glazingServices'
import { useProductPaletteContext } from '../../../../../../context/productPaletteContext'
import { useMessage } from '../../../../../../context/messageContext'
import { Oval } from 'react-loader-spinner'

const Specifications = () => {
    const [tabType, setTabType] = useState('colours')
    const [allColours, setAllColours] = useState([])
    const [allPalette, setAllPalette] = useState([])
    const [allCollection, setAllCollection] = useState([])
    const [loading, setLoading] = useState(false)
    const { setNewItemList, setTimestamp, setActiveIndex } = useProductPaletteContext();
    const { setMessage, setMessageType } = useMessage();
    const [commonHeading, setCommonHeading] = useState('')
    const [usedProducts, setUsedProducts] = useState([])
    const accessToken = localStorage.getItem('access_token')

    useEffect(() => {
        if (tabType === "colours") {
            getAllColoursServices()
            setTimestamp(null)
        } else {
            console.log("collections");
        }
    }, [tabType])

    useEffect(() => {
        if (tabType === 'palette') {
            getAllPaletteServices()
        }
    }, [tabType])

    useEffect(() => {
        if (tabType === 'collections') {
            getAllCollectionServices()
            setTimestamp(null)
        }
    }, [tabType])

    const getAllColoursServices = async (type) => {
        setLoading(true)
        const res = await allGlazingColours(1, accessToken)
        if (res?.data?.statusCode === 200) {
            let data = res?.data?.entity;
            setNewItemList(res?.data.entity)
            setAllColours(res?.data.entity)
            if (type === 'added') {
                setTimestamp(data[data.length - 1]?.id)
                setActiveIndex(data.length - 1)
            }
            setLoading(false)
            setMessageType('success')
            setMessage(res.message)
        } else {
            setLoading(false)
            setMessageType('error')
            setMessage(res.message)
        }
    }

    const getAllPaletteServices = async (type) => {
        setLoading(true)
        const res = await allGlazingPalette(1, accessToken)
        if (res?.data?.statusCode === 200) {
            let data = res?.data?.entity;
            setNewItemList(res?.data?.entity)
            setAllPalette(res?.data?.entity)
            if (type === 'added') {
                setTimestamp(data[data?.length - 1]?.id)
                setActiveIndex(data?.length - 1)
            }
            setLoading(false)
            setMessageType('success')
            setMessage(res.message)
        } else {
            setLoading(false)
            setMessageType('error')
            setMessage(res.message)
        }
    }

    const getAllCollectionServices = async (type) => {
        setLoading(true)
        const res = await allGlazingCollection(1, accessToken)
        if (res?.data?.statusCode === 200) {
            let data = res?.data?.entity;
            setNewItemList(res?.data.entity)
            setAllCollection(res?.data.entity)
            if (type === 'added') {
                setTimestamp(data[data?.length - 1]?.id)
                setActiveIndex(data?.length - 1)
            }
            setLoading(false)
            setMessageType('success')
            setMessage(res.message)
        } else {
            setLoading(false)
            setMessageType('error')
            setMessage(res.message)
        }
    }
    
    const handleUsedProducts = (data) => {
        setUsedProducts(data);
    }
    const handleUsedProductsForPalettes = (data) => {
        setUsedProducts(data);
    }

    return (
        <div>
            {loading && (
                <div className='loader_main full_loader'>
                    <Oval
                        height={50}
                        width={50}
                        color="#44C8F5"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#44C8F5"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}
            <InnerLayout pageTitle="Specification" layoutType="" leftContent={<SpecificationsLeft tabType={tabType} getAllCollectionServices={getAllCollectionServices} setTabType={setTabType} allColours={allColours} setLoading={setLoading} getAllColoursServices={getAllColoursServices} getAllPaletteServices={getAllPaletteServices} commonHeading={commonHeading} setCommonHeading={setCommonHeading} allPalette={allPalette} allCollection={allCollection} setUsedProductsFromLeft={handleUsedProducts} setUsedProductsFromLeftForPalette={handleUsedProductsForPalettes} />} rightContent={<SpecificationsRight getAllColoursServices={getAllColoursServices} getAllCollectionServices={getAllCollectionServices} getAllPaletteServices={getAllPaletteServices} tabType={tabType} setLoading={setLoading} usedProducts={usedProducts} allCollection={allCollection} allColours={allColours} allPalette={allPalette} />} />
        </div>
    )
}

export default Specifications