import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from '../components/header'
import Sidebar from '../components/sidebar'
import axios from 'axios'
import { servicePath } from '../utility/data'
import Processing from '../views/app/pages/errors/processing'
import { useRightContext } from '../context/rightMenuContext'
import { getAllVat } from '../services/pricingServices'
import { getOrbitUserDetails } from '../services/commonServices'
import { setColor } from '../utility/commonHelper'

const Layout = () => {

    const location = useLocation();
    const navigate = useNavigate()

    const { userDataContext, setUserDataContext, setGeneralTax } = useRightContext('')

    const query = new URLSearchParams(location.search);
    const tokenString = query.get('token')

    var access_token = localStorage.getItem("access_token")

    var logoutStatus = localStorage.getItem("logged_out")

    const [processing, setProcessing] = useState(false)
    const [sidebarActive, setSidebarActive] = useState(false)
    const [fetchSidebar, setFetchSidebar] = useState(false)
    const [userData, setUserData] = useState('')

    const toggleSiderbar = () => {
        setSidebarActive(!sidebarActive)
    }

    useEffect(() => {
        if (!navigator.onLine) {
            navigate('/error/network')
            localStorage.setItem("network_error", true)
        }
    }, [navigator.onLine])

    useEffect(() => {
        localStorage.setItem("initial_fetch", true)
    }, [])

    useEffect(() => {
        if (tokenString !== null) {
            setProcessing(true)

            localStorage.setItem("access_token", tokenString)
            access_token = localStorage.getItem("access_token")

            if (location?.pathname?.includes('quotation')) {
                navigate(location?.pathname)
            } else {
                navigate('/')
            }
        }
    }, [tokenString])

    useEffect(() => {
        if (sidebarActive) {
            document.body.classList.add('sidebar_active');
            setFetchSidebar(true)
        } else {
            document.body.classList.remove('sidebar_active');
        }
    }, [sidebarActive])

    useEffect(() => {
        if (access_token !== null && !location?.pathname.includes("error")) {
            getUserId(access_token)
            localStorage.removeItem("logged_out")
        }

        if (logoutStatus !== 'true' && (!access_token || access_token === 'null')) {
            setProcessing(false)
            navigate('/unauthorized')
            setUserData('');
            setUserDataContext('');
        }

        if (logoutStatus === 'true') {
            setProcessing(false)
            navigate('/logout')
        }
    }, [access_token])

    useEffect(() => {
        if (userData && userDataContext && userDataContext.user_id) {
            getUserServiceOrbit(userDataContext.user_id, userDataContext.website_url)
        }

        if (!location?.pathname.includes("error")) {
            localStorage.setItem('lastVisitedPage', location.pathname);
        }
    }, [location])

    const getUserId = async (tokenVal) => {

        const config = {
            headers: {
                'accept': "text/plain",
                'Authorization': `Bearer ${tokenVal}`
            }
        };

        const url = `${servicePath}/Account/GetLoggedInUserInformation`;

        try {
            const res = await axios.get(url, config)

            if (res && res?.data?.statusCode === 200) {

                localStorage.removeItem("network_error")

                const resData = res?.data?.entity

                var userJson = {
                    "role": resData.role,
                    "user_name": resData.userName,
                    "website_url": resData.hostUrl,
                    "profile_image": resData.profileImage,
                    "user_id": resData.userId,
                    "company_color": resData.themeColor,
                    "company_logo": resData.companyLogo,
                    "company_icon": resData.companyIcon,
                }

                if (res && res?.data?.entity?.themeColor) {
                    setColor(res?.data?.entity?.themeColor);
                } else {
                    setColor('#44C8F5');
                }

                if (res && resData) {
                    setUserData(userJson)
                    setUserDataContext(userJson)
                } else {
                    setUserData('')
                    setUserDataContext('')
                }

                setTimeout(() => {
                    setProcessing(false)
                }, 1000);
            } else {
                setUserData('')
                setUserDataContext('')
                setProcessing(false)
            }
        } catch (error) {
            access_token = localStorage.getItem("access_token")

            if (logoutStatus !== 'true' && (error?.response?.status == "401")) {
                navigate('/unauthorized')
                localStorage.removeItem("access_token")
                setUserData('')
                setUserDataContext('')

                window.location.reload();
            }

            if (logoutStatus === 'true') {
                navigate('/logout')
            }

            if (error?.response?.status === 404) {
                navigate('/error/503')
                localStorage.setItem("network_error", true)

                window.location.reload();
            }

            if (error?.message === "Network Error") {
                navigate('/error/network')
                localStorage.setItem("network_error", true)
                window.location.reload();
            }
        }
    }

    // get general taxes
    const getGeneralTax = async () => {

        const res = await getAllVat(access_token);

        if (res && res && res.status === 200) {
            if (res && res?.data?.statusCode === 200) {

                if (res?.data?.entity && res?.data?.entity?.length > 0) {
                    const defaultValues = res?.data?.entity?.find((p) => p.default == true)
                    setGeneralTax(defaultValues)
                } else {
                    setGeneralTax('')
                }
            } else {
                setGeneralTax([])
            }
        } else {
            if (res?.response?.status === 401) {
                navigate('/unauthorized')

                localStorage.removeItem("access_token")
            }

            if (res?.response?.status == 404) {
                navigate('/error/503')
            }

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    const getUserServiceOrbit = async () => {

        const res = await getOrbitUserDetails(access_token);

        var initial_fetch = localStorage.getItem("initial_fetch")
        var access_tokenRealTime = localStorage.getItem("access_token")

        if (res && res && res.status === 200) {
            if (res && res?.data?.statusCode === 200) {

                const resData = res?.data?.entity
                
                var userJson = {
                    "role": resData.role,
                    "user_name": resData.userName,
                    "website_url": resData.hostUrl,
                    "profile_image": resData.profileImage,
                    "user_id": resData.userId,
                    "company_color": resData.themeColor,
                    "company_logo": resData.companyLogo,
                    "company_icon": resData.companyIcon,
                }

                if (res && res?.data?.entity?.themeColor) {
                    setColor(res?.data?.entity?.themeColor);
                } else {
                    setColor('#44C8F5');
                }

                if (access_tokenRealTime !== null && !location?.pathname.includes("error") && initial_fetch == 'false') {
                    getGeneralTax(access_tokenRealTime)
                }

                if (res && resData) {
                    setUserData(userJson)
                    setUserDataContext(userJson)
                } else {
                    setUserData('')
                    setUserDataContext('')
                }

                setTimeout(() => {
                    setProcessing(false)
                }, 1000);

            } else {
                setUserData('')
                setUserDataContext('')
                setProcessing(false)

                if (access_tokenRealTime !== null && !location?.pathname.includes("error") && initial_fetch == 'false') {
                    getGeneralTax(access_tokenRealTime)
                }
            }
        } else {
            window.location.reload();
            
            if (res?.response?.status === 401) {
                navigate('/unauthorized')

                localStorage.removeItem("access_token")
            }

            if (res?.response?.status == 404) {
                navigate('/error/503')
            }

            if (res?.message === "Network Error") {
                navigate('/error/network')
            }
        }
    }

    function toggleSidebarFun(value) {
        if (sidebarActive) {
            let isTouch = 'ontouchstart' in window || navigator.maxTouchPoints

            if (!isTouch) {
                setSidebarActive(value)
            }
        }
    }

    return (
        <>
            {processing && (
                <Processing />
            )}

            <div id="layout-wrapper" className={sidebarActive ? 'sidebar-lg' : 'sidebar-sm'}>
                <div className='page-main'>
                    <Header toggleSiderbar={() => toggleSiderbar()} userData={userData} accessToken={access_token} />
                    <Sidebar accessToken={access_token} fetchSidebar={fetchSidebar} setFetchSidebar={setFetchSidebar} toggleSiderbar={() => toggleSiderbar()} sidebarActive={sidebarActive} setSidebarActive={setSidebarActive} userData={userData} />
                    <div className="main-content" onMouseEnter={() => toggleSidebarFun(false)}>
                        <div className="page-content">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Layout