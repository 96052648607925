import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDesigner } from "../context/designerContext";
import { formatValue, getAnicllaryPricing, getOrientation } from "../utility/helper";
import { useNavigate } from "react-router-dom";
import { useMessage } from "../context/messageContext";
import { addTotalPricingKey, getPricingObject, getTransomsData } from "../utility/pricingHelper";
import { saveModelDataQuotation, saveQuotationPriceData } from "../utility/designerHelper";
import AncillaryComponent from "./AncillaryComponent";
import DisplayPricingDataComp from "./DisplayPricingDataComp";
import DisplayDataOrientationComp from "./DisplayDataOrientationComp";
import { getFrameAncillariesPricelist, GetQuotationProjectType } from "../services/pricingServices";
import { getAdditionalProfiles } from "../services/ManufacturingServices";

const QuotationPricingPosition = ({ accessToken, modelVisible, leftPanelOpen, quotationId, collectionId, frameStyleProdId, receivedProductId }) => {

  const navigate = useNavigate()
  const { setMessage, setMessageType } = useMessage()
  const { customModelData, pricingData, setPricingData, setCustomModelData } = useDesigner();

  const [savePriceData, setSavePriceData] = useState(false)
  const [ancillaryModal, setAncillaryModal] = useState(false)
  const [discountSwitch, setDiscountSwitch] = useState(false)
  const [hidePrintSwitch, setHidePrintSwitch] = useState(false)
  const [addedAncillaries, setAddedAncillaries] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [ancillyPricing, setAncillaryPricing] = useState(0)
  const [isUnlinked, setIsUnLinked] = useState(false)
  const [ancillaryData, setAncillaryData] = useState([])
  const [isInstall, setIsInstall] = useState(false)
  const [priceReCalc, setPriceReCalc] = useState(true);

  const [modalData, setModalData] = useState({
    id: 0,
    lumpSum: 0,
    quantity: 1,
    percentage: 0,
    description: "",
    preset: -1,
  })

  const [totalEachPrice, setTotalEachPrice] = useState({
    frameProfile: [],
    glazing: [],
    sill: {
      total: 0.0,
    },
    sash: [],
    texture: [],
    handles: [],
    knocker: [],
    spyhole: [],
    escutcheon: [],
    panels: [],
    numeral: [],
    letterPlate: [],
    transoms: [],
    hinge: [],
    coupler: [],
    bayPost: [],
    floatingMullions: [],
    barHandleOffset: [],
    bead: [],
    trickleVent: [],
    design: [],
    sandblasted: [],
    cylinder: [],
    slideAndTurnHardware: [],
    threshold: 0.0,
    color: 0.0,
    addedFrames: [],
    installationPrice: [],
  });

  const [isAncillarySaved, setIsAnicillarySaved] = useState(false);
  const [gotPricingObject, setGotPricingObject] = useState(false);
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    if (quotationId) {
      setIsAnicillarySaved(false)
      if (quotationId) {
        getJobType(quotationId)
      }
    }
  }, [quotationId]);

  useEffect(() => {
    if (leftPanelOpen) {
      getAncillariesData()
    }
  }, [leftPanelOpen])

  useEffect(() => {
    setAncillaryPricing(getAnicllaryPricing(addedAncillaries, totalPrice));

    setCustomModelData((prevAdded) => ({
      ...prevAdded,
      ancillaryData: addedAncillaries
    }))

    setIsAnicillarySaved(true)

    setPriceReCalc(false)
  }, [totalPrice, priceReCalc]);

  useEffect(() => {
    if (isAncillarySaved && quotationId && leftPanelOpen && frameStyleProdId?.id) {
      saveModelDataQuotation(accessToken, collectionId, frameStyleProdId?.id, customModelData, quotationId, receivedProductId, navigate, setMessage, setMessageType)
      setIsAnicillarySaved(false)
    }
  }, [isAncillarySaved])

  useEffect(() => {
    if (customModelData?.ancillaryData?.length > 0) {
      setAddedAncillaries(customModelData?.ancillaryData)
    }
  }, [customModelData])

  const additionalHardwarePrice = (pricingData?.additionHardwares && pricingData?.additionHardwares.length > 0) ? pricingData?.additionHardwares?.reduce((sum, item) => sum + item.totalPrice, 0) : 0
  const additionalSashProfilePrice = (pricingData?.additionalSashPricing && pricingData?.additionalSashPricing.length > 0) ? pricingData?.additionalSashPricing?.reduce((sum, item) => sum + item.totalPrice, 0) : 0
  const additionalFrameProfilePrice = (pricingData?.additionalFramePricing && pricingData?.additionalFramePricing.length > 0) ? pricingData?.additionalFramePricing?.reduce((sum, item) => sum + item.totalPrice, 0) : 0

  const totalAddProfilePrice = additionalSashProfilePrice + additionalFrameProfilePrice

  useEffect(() => {
    // This function will be called after 1000ms if none of the dependencies change
    const debounceFunction = setTimeout(() => {
      if (!savePriceData && !gotPricingObject) {
        if (pricingData && customModelData && modelVisible && quotationId) {
          getPricingObject(customModelData, pricingData, setTotalEachPrice, setTotalPrice, accessToken);
          setGotPricingObject(true)
        } else {
          setGotPricingObject(false)
        }
      }
    }, 1500);

    // Cleanup the timeout if any of the dependencies change before the timeout
    return () => clearTimeout(debounceFunction);
  }, [pricingData, customModelData])

  // This function will add the pricing key in the pricing JSON.
  useEffect(() => {
    if (gotPricingObject) {
      addTotalPricingKey(pricingData, totalEachPrice, setSavePriceData)
    }
  }, [gotPricingObject])

  const getAncillaryPrice = (item) => {
    let price = 0;

    if (item.percentage > 0) {
      price += ((parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) + (totalPrice * (item.percentage / 100))) * (item?.quantity > 0 ? item.quantity : 0));
    } else {
      price += (parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) * (item?.quantity > 0 ? item.quantity : 0));
    }

    return formatValue(price);
  };

  const getTotalAncillaryPrice = (arr) => {
    let total = 0;

    arr.forEach((item) => {
      if (item.percentage > 0) {
        total += ((parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) + (totalPrice * (item.percentage / 100))) * (item?.quantity > 0 ? item.quantity : 0));
      } else {
        total += (parseFloat(item?.ancillariesPrice ? item.ancillariesPrice : 0) * (item?.quantity > 0 ? item.quantity : 0));
      }
    });

    return total;
  };

  useEffect(() => {
    if (totalPrice !== 0) {
      setPricingData((prevPricing) => ({
        ...prevPricing,
        'totalPrice': formatValue(parseFloat(totalPrice) + parseFloat(ancillyPricing) + parseFloat(isInstall ? getTotalPricing(pricingData?.installationPrices) : 0) + parseFloat(additionalHardwarePrice) + parseFloat(totalAddProfilePrice)),
        'totalFramePrice': totalPrice > 0 ? formatValue(parseFloat(totalPrice) + parseFloat(additionalHardwarePrice) + parseFloat(totalAddProfilePrice)) : formatValue(0 + parseFloat(additionalHardwarePrice) + parseFloat(totalAddProfilePrice)),
        'ancillyTotalPrice': ancillyPricing > 0 ? formatValue(parseFloat(ancillyPricing)) : 0,
      }));
    } else {
      setPricingData((prevPricing) => ({
        ...prevPricing,
        'totalPrice': 0,
        'totalFramePrice': totalPrice > 0 ? formatValue(parseFloat(totalPrice) + parseFloat(additionalHardwarePrice) + parseFloat(totalAddProfilePrice)) : formatValue(0 + parseFloat(additionalHardwarePrice) + parseFloat(totalAddProfilePrice)),
        'ancillyTotalPrice': ancillyPricing > 0 ? formatValue(parseFloat(ancillyPricing)) : 0,
      }));
    }
  }, [totalPrice, ancillyPricing, additionalHardwarePrice, totalAddProfilePrice]);

  // useEffect(() => {
  //   if (receivedProductId && pricingData && frameStyleProdId?.id) {
  //     saveModelDataQuotation(accessToken, collectionId, frameStyleProdId?.id, customModelData, quotationId, receivedProductId, navigate, setMessage, setMessageType)
  //     // setSavePriceData(true)
  //   }
  // }, [pricingData?.totalPrice]);

  useEffect(() => {
    if (savePriceData && receivedProductId && pricingData && frameStyleProdId?.id) {
      setSavePriceData(false)
      setGotPricingObject(false)

      console.log(pricingData, "pricingData final")
      saveModelDataQuotation(accessToken, collectionId, frameStyleProdId?.id, customModelData, quotationId, receivedProductId, navigate, setMessage, setMessageType)
      saveQuotationPriceData(accessToken, receivedProductId, pricingData, navigate, setMessage, setMessageType)
    }
  }, [savePriceData]);

  const deleteAncillary = (idToRemove, indexToRemove) => {

    setAddedAncillaries((prevData) => {
      const newData = prevData.filter(
        (item, index) => index !== indexToRemove || item.id !== idToRemove
      );

      setPriceReCalc(true)
      return newData;
    });

  };

  const getAncillariesData = async () => {
    const res = await getFrameAncillariesPricelist(
      accessToken
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setAncillaryData(res?.data?.entity);
      } else if (res?.data?.statusCode === 404) {
        navigate("/error/404");
      } else {
        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  const getJobType = async () => {
    const res = await GetQuotationProjectType(accessToken, quotationId);

    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {

        if (res?.data?.entity && res?.data?.entity?.details?.project_type === "Supply and installation") {
          setIsInstall(true);
        } else {
          setIsInstall(false);
        }

      } else if (res?.data?.statusCode === 404) {

        navigate("/error/404");
      } else {

        setMessage(
          res?.data?.message ? res.data.message : "Something went wrong"
        );
        setMessageType("error");
      }
    } else {
      setMessage(res?.message ? res.message : "Something went wrong");
      setMessageType("error");
      if (res?.message === "Network Error") {
        navigate("/error/network");

      }
    }
  };

  const handlePresetModalValue = (e) => {
    const value = e.target.value;

    if (value === "-1") {
      setModalData((prevData) => ({
        ...prevData,
        preset: value,
        description: "",
        lumpSum: 0,
        quantity: 1,
        percentage: 0,
      }));
    } else {
      let item = ancillaryData.find((item) => item?.id === value);

      setModalData((prevData) => ({
        ...prevData,
        preset: value,
        description: item?.description,
        lumpSum: item?.totalPlusVat,
        quantity: 1,
        percentage: item?.percentage || 0,
      }));
    }

    setIsUnLinked(false);
  };

  const handleAncillaryModal = () => {
    setIsUnLinked(false);

    setModalData((prevData) => ({
      ...prevData,
      id: 0,
      preset: -1,
      description: '',
      lumpSum: 0,
      quantity: 1,
      percentage: 0,
    }));

    setAncillaryModal(true);
  };

  const addNewFrameAncillary = (modalData) => {

    const uniqueNumber = generateUniqueNumber(customModelData.ancillaryData);

    let data = {
      id: modalData?.id || uniqueNumber,
      ancillariesId: modalData?.preset,
      ancillariesName: modalData?.description,
      ancillariesPrice: modalData?.lumpSum,
      quotationId: quotationId,
      percentage: modalData?.percentage || 0,
      quantity: modalData?.quantity,
      discountable: discountSwitch,
      hideWithInFrameWhenPrinting: hidePrintSwitch,
    };

    let dataWithPrice = {
      id: modalData?.id || uniqueNumber,
      ancillariesId: modalData?.preset,
      ancillariesName: modalData?.description,
      ancillariesPrice: modalData?.lumpSum,
      quotationId: quotationId,
      percentage: modalData?.percentage || 0,
      quantity: modalData?.quantity,
      discountable: discountSwitch,
      hideWithInFrameWhenPrinting: hidePrintSwitch,
      totalPrice: getAncillaryPrice(data)
    }

    console.log(data, dataWithPrice, "dataWithPrice 2")

    // Check if the object already exists in the ancillary data by id
    if (customModelData?.ancillaryData.some(obj => obj.id === modalData.id)) {

      // Replace the existing object with the updated data
      const updatedAncillaries = addedAncillaries.map(obj =>
        obj.id === modalData.id ? dataWithPrice : obj
      );

      setAddedAncillaries(updatedAncillaries);
      setPriceReCalc(true)

    } else {
      // Add new data if it doesn't exist
      setAddedAncillaries([...addedAncillaries, dataWithPrice]);
      setPriceReCalc(true)
    }

    setIsEdit(false);
    setAncillaryModal(false);
  };

  const getEachPrice = (arr, id) => {
    const item = arr.find(ele => ele.id == id)

    return formatValue(item?.total ? item?.total : 0);
  }

  const getTotalPricing = (arr) => {
    let total = 0;
    arr.forEach((item => {
      total += parseFloat(item?.price)
    }))
    return formatValue(total)
  }

  const handleAncillaryEdit = (item) => {
    setIsUnLinked(true);

    setModalData((prevModalData) => ({
      ...prevModalData,
      id: item?.id || 0,
      preset: item?.ancillariesId,
      description: item?.ancillariesName,
      percentage: item?.percentage || 0,
      lumpSum: item?.ancillariesPrice,
      quantity: item?.quantity,
    }));

    setDiscountSwitch(item?.discountable ? item?.discountable : false);

    setHidePrintSwitch(
      item?.hideWithInFrameWhenPrinting
        ? item?.hideWithInFrameWhenPrinting
        : false
    );

    setIsEdit(true);
    setAncillaryModal(true);
  };

  function generateUniqueNumber(array) {
    let number;

    do {
      number = Math.floor(10000 + Math.random() * 90000); // Generates a 5-digit number
    } while (array?.some(obj => obj.id === number)); // Check if any object's id matches the generated number

    return number;
  }

  const handleAncillary = (item) => {

    const uniqueNumber = generateUniqueNumber(customModelData.ancillaryData);

    let data = {
      id: uniqueNumber,
      ancillariesId: item?.id,
      ancillariesName: item.description,
      ancillariesPrice: item.totalPlusVat,
      quotationId: quotationId,
      percentage: item?.percentage || 0,
      quantity: 1,
      discountable: false,
      hideWithInFrameWhenPrinting: hidePrintSwitch,
    };

    let dataWithPrice = {
      id: uniqueNumber,
      ancillariesId: item?.id,
      ancillariesName: item.description,
      ancillariesPrice: item.totalPlusVat,
      quotationId: quotationId,
      percentage: item?.percentage || 0,
      quantity: 1,
      discountable: false,
      hideWithInFrameWhenPrinting: hidePrintSwitch,
      totalPrice: getAncillaryPrice(data)
    }

    setAddedAncillaries([...addedAncillaries, dataWithPrice]);

    console.log(data, dataWithPrice, "dataWithPrice 2")

    setPriceReCalc(true)
    setIsUnLinked(true);
  };

  const AdditionalItemsPrice = ({ price, type }) => {
    return (
      <>
        <tr className="border-bottom">
          <td className="text-start text-wrap">
            Additional {type}
          </td>
          <td className="fw-bold text-wrap text-start"></td>
          <td className="text-end">
            £{price}
          </td>
        </tr>

      </>
    )
  }

  // const getOverSizeGlazinPercentSlab = async (accessToken, area, spceId) => {
  //   const res = await getOverGlazingPriceSlabService(accessToken, area, spceId);

  //   if (res?.data?.statusCode === 200) {
  //     setOverSizePercentage(res?.data?.entity?.percentage)
  //   }
  // }

  return (
    <>
      <div className="fixed_m_scroll full_h">
        <h5 className="mt-5 mb-2">Frame</h5>
        <Table className="pricing_table no_td_bg">
          <tbody>

            {/* frame profile pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.frameProfile} totalEachPrice={totalEachPrice?.frameProfile} />

            {/* sash profile pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.sash} totalEachPrice={totalEachPrice?.sash} />

            {/* transoms pricing */}
            {getTransomsData(Object.values(pricingData?.transoms)).map((item, index) => (
              <tr key={index} className="border-bottom">
                <td
                  className="text-start text-wrap"
                >
                  {pricingData?.transoms && pricingData?.transoms.length > 1 ? (
                    <>
                      {item.frameType} {item?.orientation !== "" && ``}
                    </>
                  ) : (
                    <>
                      {item.frameType}
                    </>
                  )}
                </td>
                <td
                  className="fw-bold text-wrap text-start"
                >
                  {item.name}
                </td>
                <td className="text-end">
                  £{item?.price}
                </td>
              </tr>
            ))}

            {/* bead pricing */}
            {getTransomsData(Object.values(pricingData?.bead)).map((item, index) => (
              <tr key={index} className="border-bottom">
                <td
                  className="text-start text-wrap"
                >
                  {pricingData?.bead && pricingData?.bead.length > 1 ? (
                    <>
                      {item.frameType} {item?.orientation !== "" && ``}
                    </>
                  ) : (
                    <>
                      {item.frameType}
                    </>
                  )}
                </td>
                <td
                  className="fw-bold text-wrap text-start"
                >
                  {item.name}
                </td>
                <td className="text-end">
                  £{getEachPrice(totalEachPrice?.bead, item?.id)}
                </td>
              </tr>
            ))}

            {/* sill profile pricing */}
            {pricingData?.sill.length > 0 && <DisplayDataOrientationComp getEachPrice={getEachPrice} pricingData={pricingData?.sill} totalEachPrice={totalEachPrice?.sill?.total} type="sill" />}

            {(pricingData.additionalFramePricing && pricingData?.additionalFramePricing?.length > 0) ? <AdditionalItemsPrice price={totalAddProfilePrice} type={"Profile"} /> : <></>}

            {/* {(pricingData?.additionalSashPricing && pricingData?.additionalSashPricing?.length > 0) ? <AdditionalItemsPrice price={additionalSashProfilePrice} type={"Sash Profile"} /> : <></>} */}

            {(pricingData?.additionHardwares && pricingData?.additionHardwares?.length > 0) ? <AdditionalItemsPrice price={additionalHardwarePrice} type={"Hardware"} /> : <></>}


            {/* Mullion pricing */}
            <DisplayDataOrientationComp getEachPrice={getEachPrice} pricingData={pricingData?.floatingMullion} totalEachPrice={totalEachPrice?.floatingMullions} />

            {/* Threshhold pricing */}
            {pricingData?.threshold &&
              <tr className="border-bottom">
                <td
                  className="text-start text-wrap"
                >
                  {pricingData?.threshold.frameType}
                  {pricingData?.threshold?.orientation !== "" &&
                    `(${getOrientation(pricingData?.threshold?.orientation)})`}
                </td>
                <td
                  className="fw-bold text-wrap text-start"
                >
                  {pricingData?.threshold.name}
                </td>
                <td className="text-end">£{totalEachPrice?.threshold}</td>
              </tr>
            }

            {/* coupler profile pricng */}
            <DisplayDataOrientationComp getEachPrice={getEachPrice} pricingData={pricingData?.coupler} totalEachPrice={totalEachPrice?.coupler} />

            {/* baypost profile pricng */}
            <DisplayDataOrientationComp getEachPrice={getEachPrice} pricingData={pricingData?.bayPost} totalEachPrice={totalEachPrice?.bayPost} />

            {/* glazing pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.glazing} totalEachPrice={totalEachPrice?.glazing} />

            {/* texture pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.texture} totalEachPrice={totalEachPrice?.texture} />

            {/* design pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.design} totalEachPrice={totalEachPrice?.design} />

            {/* sandblasted numerals pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.sandblasted} totalEachPrice={totalEachPrice?.sandblasted} />

            {/* panel pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.panel} totalEachPrice={totalEachPrice?.panels} />

            {/* Handles pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.handles} totalEachPrice={totalEachPrice?.handles} />

            {/* Cylinder pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.cylinder} totalEachPrice={totalEachPrice?.cylinder} />

            {/* bar handle offset pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.barHandleOffset} totalEachPrice={totalEachPrice?.barHandleOffset} />

            {/* slide and turn hardware pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.slideAndTurnHardware} totalEachPrice={totalEachPrice?.slideAndTurnHardware} />

            {/* Knocker pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.knocker} totalEachPrice={totalEachPrice?.knocker} />

            {/* spyhole pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.spyhole} totalEachPrice={totalEachPrice?.spyhole} />

            {/* escutcheon pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.escutcheon} totalEachPrice={totalEachPrice?.escutcheon} />

            {/* Hinge pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.hinge} totalEachPrice={totalEachPrice?.hinge} />

            {/* numeral pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.numeral} totalEachPrice={totalEachPrice?.numeral} />

            {/* letetr plate  pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.letterPlate} totalEachPrice={totalEachPrice?.letterPlate} />

            {/* trickle vent pricing */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.trickleVent} totalEachPrice={totalEachPrice?.trickleVent} />

            {/* Color pricing */}
            {pricingData?.color &&
              <tr className="border-bottom">
                <td
                  className="text-start text-wrap"
                >
                  Colour <br /> (External + Internal)
                </td>
                <td
                  className="fw-bold text-wrap text-start"
                >
                  {pricingData?.color?.extColorName}, {pricingData?.color?.intColorName}
                </td>
                <td className="text-end">£{(totalEachPrice?.color)}</td>
              </tr>
            }

            {/* extra added frame pricng */}
            <DisplayPricingDataComp getEachPrice={getEachPrice} pricingData={pricingData?.addedFrames} totalEachPrice={totalEachPrice?.addedFrames} />
          </tbody>
        </Table>

        {isInstall && pricingData?.installationPrices?.length > 0 && <>
          <h5 className="mt-2 mb-2">Installation</h5>
          <Table className="pricing_table no_td_bg">
            {pricingData?.installationPrices.map((item, index) => (
              <tr key={index} className="border-bottom">
                <td
                  className="text-start text-wrap"
                >
                  {item.frameType}
                </td>
                <td
                  className="fw-bold text-wrap text-start"
                >
                  {item.orientation}
                </td>
                <td className="text-end">
                  £{formatValue(item?.price)}
                </td>
              </tr>
            ))}
          </Table>
        </>}

        <AncillaryComponent
          accessToken={accessToken}
          addNewFrameAncillary={addNewFrameAncillary}
          addedAncillaries={addedAncillaries}
          ancillaryData={ancillaryData}
          ancillaryModal={ancillaryModal}
          deleteAncillary={deleteAncillary}
          discountSwitch={discountSwitch}
          getAncillariesData={getAncillariesData}
          handleAncillary={handleAncillary}
          handleAncillaryEdit={handleAncillaryEdit}
          handleAncillaryModal={handleAncillaryModal}
          setIsUnLinked={setIsUnLinked}
          handlePresetModalValue={handlePresetModalValue}
          hidePrintSwitch={hidePrintSwitch}
          isEdit={isEdit}
          isUnlinked={isUnlinked}
          leftPanelOpen={leftPanelOpen}
          modalData={modalData}
          setAncillaryData={setAncillaryData}
          setAncillaryModal={setAncillaryModal}
          setDiscountSwitch={setDiscountSwitch}
          setHidePrintSwitch={setHidePrintSwitch}
          setIsEdit={setIsEdit}
          setModalData={setModalData}
          getAncillaryPrice={getAncillaryPrice}
          removeDiscountToggle={true}
        />

        <h5 className="mt-3">Total</h5>
        <Table className="td_p_2 no_td_bg">
          <tbody>
            <tr className="border-bottom">
              <td className="text-wrap">Frame</td>
              <td className="text-wrap text-end">£{pricingData.totalFramePrice}</td>
            </tr>
            {isInstall && pricingData?.installationPrices?.length > 0 && (
              <tr className="border-bottom">
                <td className="text-wrap">Installation</td>
                <td className="text-wrap text-end">£{(getTotalPricing(pricingData?.installationPrices))}</td>
              </tr>
            )}
            {addedAncillaries.length > 0 ? (
              <tr className="border-bottom">
                <td className="text-wrap">Ancillaries</td>
                <td className="text-wrap text-end"> £{formatValue(getTotalAncillaryPrice(addedAncillaries))}</td>
              </tr>
            ) : null}
            <tr>
              <td className="text-wrap fw-bold">Total</td>
              <td className="text-wrap text-end fw-bold">
                £{pricingData.totalPrice}
                {/* £{formatValue(getTotalAncillaryPrice(addedAncillaries) + parseFloat(totalPrice) + parseFloat(isInstall ? getTotalPricing(pricingData?.installationPrices) : 0) + parseFloat(additionalHardwarePrice))} */}
              </td>
            </tr>
            .
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default QuotationPricingPosition;