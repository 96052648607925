import React, { useEffect, useRef, useState } from "react";
import BasicDocument from "./BasicDocument";
import { Button, NavLink } from "reactstrap";
import { servicePath } from "../../../../utility/data";
import { useMessage } from "../../../../context/messageContext";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { useRightContext } from "../../../../context/rightMenuContext";
import {
  getAllVat,
  pricingDefault,
} from "../../../../services/pricingServices";
import { BiArrowBack } from "react-icons/bi";
import { updateQuotationInvoiceJsonData } from "../../../../services/3dModelServices";

const DocumentPDF = () => {
  var accessToken = localStorage.getItem("access_token");

  const { setMessage, setMessageType } = useMessage();
  const { setProjectDefault, setGeneralTax, userDataContext } = useRightContext();

  const [loading, setLoading] = useState(false);
  const [docsData, setDocsData] = useState(false);
  const [pageType, setPageType] = useState(false);
  const [pageOrientation, setPageOrientation] = useState(false);
  const [pageMargin, setPageMargin] = useState(false);
  const [isPdfView, setIsPdfView] = useState(false);

  const printableRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  let { printId, quoteId } = useParams();

  useEffect(() => {
    if (location && location.pathname && location.pathname.includes("user/quotation/")) {
      document.body.classList.add('pdf_view');
      setIsPdfView(true)
    }

    return () => {
      document.body.classList.remove('pdf_view');
      setIsPdfView(false)
    }
  }, [])


  useEffect(() => {
    if (printId && quoteId && accessToken) {
      getGeneralTax();
      getProjectDefault();
      getDocsSettings(printId, quoteId);
    }
  }, [printId, quoteId, accessToken]);

  // get general taxes
  const getGeneralTax = async () => {
    const res = await getAllVat(accessToken);

    if (res && res && res.status === 200) {
      if (res && res?.data?.statusCode === 200) {
        if (res.data.entity && res.data.entity.length > 0) {
          const defaultValues = res.data.entity?.find((p) => p.default == true);
          setGeneralTax(defaultValues);
        } else {
          setGeneralTax("");
        }
      } else {
        setGeneralTax([]);
      }
    } else {
      if (res?.response?.status === 401) {
        navigate("/unauthorized");

        localStorage.removeItem("access_token");
      }

      if (res?.response?.status == 404) {
        navigate("/error/503");
      }

      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  const getProjectDefault = async () => {
    const res = await pricingDefault(accessToken);

    if (res && res && res.status === 200) {
      if (res && res?.data?.statusCode === 200) {
        setProjectDefault(res.data.entity);
      } else {
        setProjectDefault("");
      }
    } else {
      if (res?.response?.status === 401) {
        navigate("/unauthorized");

        localStorage.removeItem("access_token");
      }

      if (res?.response?.status == 404) {
        navigate("/error/503");
      }

      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };

  const getDocsSettings = (fetchId, fetchQuoteId) => {
    setLoading(true);

    const FormData = require("form-data");

    let data = new FormData();

    data.append("PrintId", fetchId);
    data.append("QuotationId", fetchQuoteId);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${servicePath}/Document/GetDocumentinformation`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setDocsData(res.data?.entity);

          setPageType(res.data.entity?.general?.paperSize);
          setPageOrientation(res.data.entity?.general?.paperOrientation);

          var pageMarginSet = res.data.entity?.general?.margin + "px";

          if (res.data.entity?.general?.margin) {
            setPageMargin(pageMarginSet);
          } else {
            setPageMargin("20px");
          }

          // document.documentElement.style.setProperty('--page-type', res.data.entity?.general?.paperSize);
          // document.documentElement.style.setProperty('--page-dimention', res.data.entity?.general?.paperOrientation);
          // document.documentElement.style.setProperty('--page-margin', res.data.entity?.general?.margin);

          setLoading(false);
        } else if (res?.data?.statusCode === 404) {
          setLoading(false);
          navigate("/error/404");
        } else {
          setLoading(false);
          setMessage(
            res?.data?.message ? res.data.message : "Something went wrong"
          );
          setMessageType("error");
        }
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error.message ? error.message : "Something went wrong");
        setMessageType("error");

        if (error?.message === "Network Error") {
          navigate("/error/network");
        }
      });
  };

  const handlePrint = () => {
    const printableContent = printableRef.current;

    if (printableContent) {
      // const printWindow = window.open('', '_blank');
      const printWindow = window.open("", "_blank");

      if (printWindow) {
        const contentToPrint = printableContent.innerHTML;
        printWindow.document.write(`
                <html>
                    <head>
                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
                    <link href="https://cdn.jsdelivr.net/npm/typeface-poppins@1.1.13/index.min.css" rel="stylesheet">
                    <style>
                        h1, h2, h3, h4, h5, h6, p {
                            margin-top: 0 !important;
                        }

                        .row.custom_row_pdf {
                            margin-left: -3px;
                            margin-right: -3px;
                        }

                        .row.custom_row_pdf > div {
                            padding-left: 3px;
                            padding-right: 3px;
                        }

                        .unsetPrintHeight {
                            height: unset !important;
                        }

                        .page_wrap {
                            margin: ${pageMargin} auto !important;
                        }

                        @page {
                            size: ${pageType} ${pageOrientation};
                            margin: ${pageMargin} !important;
                        }

                        .page_header {
                            display: none;
                        }

                        #pageFooter {
                            display: table-footer-group; 
                        }
                        
                        #pageFooter:after {
                            counter-increment: page;
                            content: counter(page) ' of ' counter(pages);
                        }

                        @media print {
                            .page_layout {
                                display: 'block',
                                box-shadow: none !important;
                                margin-bottom: 0 !important;
                                height: unset !important;
                                padding: 0 !important;
                            }

                            .page_section {
                                padding: 0 !important;
                            }

                            .page_wrap {
                                margin: 0 !important;
                            }

                            /* Show the div only on the first page */
                            page_header:first-of-type {
                                display: block;
                            }
                        }

                        .ball_shadow {
                            position: relative;
                            &:after {
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 8px;
                                bottom: -7px;
                                left: 0px;
                                background: radial-gradient(farthest-side, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
                                opacity: 0.5;
                            }
                        }

                        .glaze_img {
                            position: absolute;
                            width: 90% !important;
                            height: 90% !important;
                            object-fit: contain !important;
                            margin: 5%;
                        }
                    </style>
                    </head>
                    <body>
                        <div id="printable-content">${contentToPrint}</div>
                    </body>
                </html>
                `);

        printWindow.document.close();

        setTimeout(() => {
          printWindow.print();
        }, 400);

        printWindow.onafterprint = () => {
          printWindow.close();
        };

        setTimeout(() => {
          let quoteId = sessionStorage.getItem("QuotationId");

          if (location.pathname.includes("user/quotation/")) {
            navigate(-1);
          } else {
            if (quoteId) {
              navigate(`/quotation/${quoteId}/print-view/${printId}`);
            } else {
              navigate("/");
            }
          }
        }, 500);
      }
    }
  };

  const printCRM = () => {
    setLoading(true);
    if (quoteId) {
      updateQuotationInvoiceJsonDataService(quoteId)
    }
  }

  const updateQuotationInvoiceJsonDataService = async (quotationId) => {
    const res = await updateQuotationInvoiceJsonData(accessToken, quotationId)
    if (res?.data?.statusCode === 200) {
      // setMessage(res?.data?.message)
      // setMessageType("success")
      setLoading(false);
    }
    else {
      // setMessageType("error")
      // setMessage(res?.data?.message ? res?.data?.message : "Something went wrong !")
      setLoading(false);
      
      if (res?.response?.status === 401) {
        navigate('/unauthorized')
        localStorage.removeItem("access_token")
      }
      if (res?.response?.status == 404) {
        navigate('/error/503')
      }
      if (res?.message === "Network Error") {
        navigate('/error/network')
      }
    }
  }

  return (
    <>
      {loading && (
        <div className="loader_main full_loader">
          <Oval
            height={50}
            width={50}
            color="#44C8F5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#44C8F5"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <div className="d-flex justify-content-between no_print mb-4 px-lg-3">

        <div>
          {!isPdfView && (
            <NavLink
              className="btn btn-primary d-flex align-items-center gap-1 fw-bold back_btn"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/quotation/${quoteId}`);
              }}
            >
              <BiArrowBack size={22} />
              Back
            </NavLink>
          )}
        </div>

        <div className="d-flex gap-2">
          <Button
            outline
            color="primary"
            size="md"
            onClick={() => handlePrint()}
            className="px-lg-4"
          >
            Print PDF
          </Button>

          {!isPdfView && (
            <Button
              outline
              color="primary"
              size="md"
              onClick={() => printCRM()}
              className="px-lg-4"
            >
              Print CRM
            </Button>
          )}
        </div>
      </div>

      <div className="has_x_scroll quote_pdf">
        <BasicDocument docsData={docsData} printableRef={printableRef} />
      </div>
    </>
  );
};

export default DocumentPDF;
