import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, FormGroup, InputGroup, Row } from "react-bootstrap";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Button, Input, Label } from "reactstrap";
import { MenuProps, ProfileLinkCutType, ProfileLinkType } from "../../../../utility/data";
import { ListSubheader, MenuItem, OutlinedInput, Select } from "material-ui-core";
import { MdKeyboardArrowDown, MdOutlineArticle, MdOutlineKeyboardArrowDown, MdOutlineSearch } from "react-icons/md";
import NumberInput from "../../../../components/NumberInput";
import NumberInputDecimal from "../../../../components/NumberInputDecimal";
import DataTable from "react-data-table-component";
import { BiSort, BiTrashAlt } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import { MdOutlineModeEdit } from "react-icons/md";

const Left = ({ setTableShowDeleteModal, showTableDeleteModal, isDeleting, allFrameStyle, getFrameStyleData, frameCollectionList, selectedFrameStyle, setSelectedFrameStyle, frameCollectionSelected, setFrameCollectionSelected, deleteProfileLinkingServices, getProfileLinkingServices, profileLinkingListData, allProfileLinkingListData, getAllProfileLinkingServices, addProfileLinkingServices, profilesListData, getProfilesDropdownServices }) => {

  const [frameStyleCollection, setFrameStyleCollection] = useState();
  const [frameStyle, setFrameStyle] = useState();
  const [profile1, setProfile1] = useState('');
  const [profile2, setProfile2] = useState('');
  const [typeId, setTypeId] = useState('');
  const [glass, setGlass] = useState('');
  const [dimension, setDimension] = useState('');
  const [cutType, setCutType] = useState('');
  const [individualProfileLinkingId, setIndividualProfileLinkingId] = useState('');
  const [deleteProfileLinkingId, setDeleteProfileLinkingId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProfiles, setFilteredProfiles] = useState(profilesListData);
  const [filteredProfiles2, setFilteredProfiles2] = useState(profilesListData);
  const [activeTab, setActiveTab] = useState("Height")

  const [filteredData, setFilteredData] = useState([])

  const handleActiveTab = (tab) => {
    setActiveTab(tab)
  }

  useEffect(() => {
    setSearchQuery('')
    setFilteredProfiles(profilesListData)
    setFilteredProfiles2(profilesListData)
  }, [profilesListData]);

  useEffect(() => {
    if (frameCollectionSelected && frameCollectionSelected.id) {
      getFrameStyleData(frameCollectionSelected?.id);
      getProfilesDropdownServices(frameCollectionSelected?.id);
    }
  }, [frameCollectionSelected]);

  useEffect(() => {
    if (allFrameStyle && allFrameStyle.length > 0) {
      setSelectedFrameStyle(allFrameStyle[0]);
    } else {
      setSelectedFrameStyle('');
    }
  }, [allFrameStyle]);

  useEffect(() => {
    if (selectedFrameStyle) {
      console.log(selectedFrameStyle, "selectedFrameStyle")
      getAllProfileLinkingServices(selectedFrameStyle?.id)
    }
  }, [selectedFrameStyle])

  useEffect(() => {
    individualProfileLinkingId &&
      getProfileLinkingServices(individualProfileLinkingId)
  }, [individualProfileLinkingId])

  useEffect(() => {
    if (profileLinkingListData) {
      setTypeId(profileLinkingListData?.typeId);
      setProfile1(profileLinkingListData?.firstProfile);
      setProfile2(profileLinkingListData?.secondProfile);
      setDimension(profileLinkingListData?.dimension);
      setCutType(profileLinkingListData?.cutType);
      setGlass(profileLinkingListData?.glass);
    }
  }, [profileLinkingListData]);

  useEffect(() => {
    if (allProfileLinkingListData && allProfileLinkingListData.length > 0) {
      setFilteredData(getData(activeTab))
    } else {
      setFilteredData([])
    }

    console.log(allProfileLinkingListData, "allProfileLinkingListData")
  }, [activeTab, allProfileLinkingListData])

  useEffect(() => {
    if (activeTab) {
      if (individualProfileLinkingId) {
        setIndividualProfileLinkingId("")
      }

      setProfile1('')
      setProfile2('')
      setCutType('')
      setTypeId('')
      setGlass('')
      setDimension('')
    }
  }, [activeTab])

  const handleFrameStyleDrop = () => {
    setFrameStyleCollection((prev) => !prev);
  };

  const handleModelStyleDrop = () => {
    setFrameStyle((prev) => !prev);
  };

  const handleFrameCollection = (data) => {
    setFrameCollectionSelected(data)
  };

  const handleSelectedFrameStyle = (data) => {
    setSelectedFrameStyle(data);
  };

  const columns = [
    {
      name: <span>profile</span>,
      selector: row => row.firstProfile,
    },
    {
      name: <span>Profile</span>,
      selector: row => row.secondProfile,
    },
    {
      name: <span>Glass</span>,
      selector: row => row.glass,
    },
    {
      name: <span>Dimension</span>,
      selector: row => row.dimension,
    },
    {
      name: <span>Cut</span>,
      selector: row => row.cutType,
    },
    {
      name: <span>Action</span>,
      cell: (row) => (
        <div className='d-flex gap-2'>
          <Link onClick={() => setIndividualProfileLinkingId(row?.id)}>
            <MdOutlineModeEdit size={20} />
          </Link>

          <Link >
            <BiTrashAlt size={20} onClick={() => {
              setTableShowDeleteModal(true);
              setDeleteProfileLinkingId(row);
            }} />
          </Link>
        </div >
      ),
    }
  ]

  const allProfileLinkingList = filteredData?.map((item) => {
    return {
      cutType: ProfileLinkCutType?.find(ele => ele.id == item?.cutType).name,
      dimension: item?.dimension,
      firstProfile: profilesListData?.find((ele) => ele?.id === item?.firstProfile)?.name,
      frameStyleCollectionId: item?.frameStyleCollectionId,
      id: item?.id,
      frameStyleId: item?.frameStyleId,
      glass: item?.glass,
      secondProfile: profilesListData?.find((ele) => ele?.id === item?.secondProfile)?.name,
      type: item?.typeId,
    };
  })

  const handleFormSubmit = () => {

    const formDataValues = {
      "id": individualProfileLinkingId ? individualProfileLinkingId : "",
      "FirstProfile": profile1,

      "SecondProfile": profile2,
      "Dimension": dimension,

      'Glass': glass,
      'CutType': cutType,

      "TypeId": typeId,
      "LengthType": activeTab == "Height" ? 1 : 2,
      "FrameStyleCollectionId": frameCollectionSelected?.id,
      "FrameStyleId": selectedFrameStyle?.id,

    }

    addProfileLinkingServices(formDataValues)

    if (individualProfileLinkingId) {
      setIndividualProfileLinkingId("")
    }

    setTimeout(() => {
      setProfile1('')
      setProfile2('')
      setCutType('')
      setTypeId('')
      setGlass('')
      setDimension('')
    }, 500);
  }

  const handleSearchInputChange = (e, setMethod) => {
    console.log("test123")
    e.preventDefault()

    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = profilesListData.filter(item => {
      return (
        item?.name?.toLowerCase().includes(query) ||
        item?.productCode?.toString().toLowerCase().includes(query)
      );
    });
    setMethod(filteredData);
  };

  const filteredProfile2Options = filteredProfiles2.filter(item => item.id !== profile1);

  const getData = (activeTab) => {
    let filteredProfileLinkingList = []
    if (activeTab === "Height")
      filteredProfileLinkingList = allProfileLinkingListData.filter(item => item?.lengthType === 1);
    else {
      filteredProfileLinkingList = allProfileLinkingListData.filter(item => item?.lengthType === 2)
    }
    return filteredProfileLinkingList
  }

  return (
    <>
      <div className="d-flex justify-content-between pb-3 border-bottom mb-3">
        <div className="d-flex align-items-center gap-3 gap-lg-5">
          <Dropdown isOpen={frameStyleCollection} toggle={handleFrameStyleDrop}>
            <DropdownToggle color="none" className="border-0 p-0 lh-1 h-auto">
              <div className="d-flex align-items-center">
                <span className="fw-semibold">
                  <MdOutlineArticle size={20} className="me-1" />
                  {frameCollectionSelected?.name}

                  <MdKeyboardArrowDown className="ms-1" size={20} />
                </span>
              </div>
            </DropdownToggle>

            <DropdownMenu>
              {frameCollectionList?.map((item, index) => {
                return (
                  <DropdownItem
                    key={index}
                    onClick={() => {
                      handleFrameCollection(item);
                    }}
                    className={`${frameCollectionSelected?.id === item.id && "active text-primary"}`}
                  >
                    {item.name}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>

          <Dropdown isOpen={frameStyle} toggle={handleModelStyleDrop}>
            <DropdownToggle color="none" className="border-0 p-0 lh-1 h-auto">
              <div className="d-flex align-items-center">
                <span className="fw-semibold">
                  <MdOutlineArticle size={20} className="me-1" />
                  {selectedFrameStyle?.name}
                  <MdKeyboardArrowDown className="ms-1" size={20} />
                </span>
              </div>
            </DropdownToggle>

            <DropdownMenu>
              {allFrameStyle && allFrameStyle?.map((item, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => handleSelectedFrameStyle(item)}
                  className={`${selectedFrameStyle?.id === item.id && "active text-primary"}`}
                >
                  {item.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <Nav tabs className="details_header border-0 nav-tabs-custom">
        <NavItem>
          <NavLink
            className={`${activeTab === "Height" && 'active'}`}
            onClick={() => handleActiveTab("Height")}
          >
            Height
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${activeTab === "Width" && 'active'}`}
            onClick={() => handleActiveTab("Width")}
          >
            Width
          </NavLink>
        </NavItem>
      </Nav>
      <Row className="mb-4">
        <Col lg="4">
          <FormGroup className="form-group modal_select">
            <span className="d-flex">
              <Label for="profile">
                Profile
              </Label>
              <span className="text-danger">*</span>
            </span>
            <Select
              labelId="printdoc-label"
              id="profile"
              value={profile1}
              label="profile"
              onChange={(e) => setProfile1(e.target.value)}
              input={<OutlinedInput className='w-100' name='profile' />}
              MenuProps={MenuProps}

              IconComponent={() => (
                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
              )}
            >
              <ListSubheader>
                <InputGroup className="border rounded w-100">
                  <Input
                    type="text"
                    placeholder="Search Profile"
                    className="search-input border-0"
                    value={searchQuery}
                    onChange={(e) => handleSearchInputChange(e, setFilteredProfiles)}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text bg-transparent border-0 px-2">
                      <MdOutlineSearch size={16} />
                    </span>
                  </div>
                </InputGroup>
              </ListSubheader>

              {filteredProfiles?.map((item, index) => {
                return (
                  <MenuItem value={item?.id} key={index}>
                    <div className="d-flex align-items-center dropdown_with_icon">
                      <span className='modal_select_hide color_circle dot_lg'></span>

                      <div className="d-flex flex-column w-100">
                        <span className="lh-1 d-flex gap-1 align-items-center">
                          {item?.number}

                          <span className='modal_select_show'>
                            {item?.productCode} - {item?.name}
                          </span>
                        </span>
                        <span className="text-small lh-1 modal_select_hide">
                          {item?.productCode} - {item?.name}
                        </span>
                      </div>
                    </div>
                  </MenuItem>
                )
              })}
            </Select>
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup className="form-group modal_select">
            <span className="d-flex">
              <Label for="profile">
                Profile
              </Label>
              <span className="text-danger">*</span>
            </span>
            <Select
              labelId="printdoc-label"
              id="profile"
              value={profile2}
              label="profile"
              onChange={(e) => setProfile2(e.target.value)}
              input={<OutlinedInput className='w-100' name='profile' />}
              MenuProps={MenuProps}

              IconComponent={() => (
                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
              )}
            >
              <ListSubheader>
                <InputGroup className="border rounded w-100">
                  <Input
                    type="text"
                    placeholder="Search Profile"
                    className="search-input border-0"
                    value={searchQuery}
                    onChange={(e) => handleSearchInputChange(e, setFilteredProfiles2)}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text bg-transparent border-0 px-2">
                      <MdOutlineSearch size={16} />
                    </span>
                  </div>
                </InputGroup>
              </ListSubheader>
              {filteredProfile2Options?.map((item, index) => {
                return (
                  <MenuItem value={item?.id} key={index}>
                    <div className="d-flex align-items-center dropdown_with_icon">
                      <span className='modal_select_hide color_circle dot_lg'></span>

                      <div className="d-flex flex-column w-100">
                        <span className="lh-1 d-flex gap-1 align-items-center">

                          <span className='modal_select_show'>
                            {item?.productCode} - {item?.name}
                          </span>
                        </span>
                        <span className="text-small lh-1 modal_select_hide">
                          {item?.productCode} - {item?.name}
                        </span>
                      </div>
                    </div>
                  </MenuItem>
                )
              })}
            </Select>
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup className="form-group">

            <span className="d-flex">
              <Label for="profileType">
                Type
              </Label>
              <span className="text-danger">*</span>
            </span>

            <Select
              labelId="profileType"
              id="profileType"
              value={typeId}
              label="profileType"
              onChange={(e) => { setTypeId(e.target.value) }}
              input={<OutlinedInput className='w-100' name='profileType' />}

              MenuProps={MenuProps}

              IconComponent={() => (
                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
              )}
            >
              {ProfileLinkType && ProfileLinkType.map((o, index) => (
                <MenuItem key={index} value={o?.id}>
                  {o?.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup className="form-group">
            <span className="d-flex">
              <Label for="glass">
                Glass
              </Label>
              <span className="text-danger">*</span>
            </span>
            <NumberInput className='mb-1' name='productCode' value={glass} setValue={setGlass} />

          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup className="form-group">
            <span className="d-flex">
              <Label for="dimension">
                Dimension
              </Label>
              <span className="text-danger">*</span>
            </span>
            <NumberInputDecimal className='mb-1' name='dimensions' value={dimension} setValue={setDimension} />

          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup className="form-group">
            <span className="d-flex">
              <Label for="cutType">
                Cut type
              </Label>
              <span className="text-danger">*</span>
            </span>
            <Select
              labelId="cutType"
              id="cutType"
              value={cutType}
              label="cutType"
              onChange={(e) => { setCutType(e.target.value) }}
              input={<OutlinedInput className='w-100' name='cutType' />}

              MenuProps={MenuProps}

              IconComponent={() => (
                <MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
              )}
            >
              {ProfileLinkCutType && ProfileLinkCutType?.map((o, index) => (
                <MenuItem key={index} value={o?.id}>
                  {o?.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
        </Col>
        <div className='d-flex gap-2 gap-xl-3 main_buttons'>
          {!individualProfileLinkingId ?
            <Button
              color="primary"
              size="md"
              className='px-4 d-flex gap-1'
              onClick={() => handleFormSubmit()}
              disabled={!profile1 || !profile2 || !typeId || !glass || !dimension || !cutType}
            >
              <FaPlus size={16} />
              <div>Add</div>
            </Button> :
            <Button
              color="primary"
              size="md"
              className='px-4 d-flex gap-1'
              onClick={() => handleFormSubmit()}
              disabled={!profile1 || !profile2 || !typeId || !glass || !dimension || !cutType}
            >
              <div>Update</div>
            </Button>
          }
        </div>
      </Row>

      <div className='d-flex flex-column flex-grow-1 justify-content-between border border-1'>
        <div className=''>
          <DataTable
            columns={columns}
            data={allProfileLinkingList}
            className="custom_datatable clickable"
            sortIcon={<BiSort size={18} className="text-muted" />}
            highlightOnHover
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 30]}
          />
        </div>
      </div>

      <Modal isOpen={showTableDeleteModal} toggle={() => setTableShowDeleteModal(false)}>
        <ModalHeader >
          Delete Selected Item
        </ModalHeader>
        <ModalBody>
          Are you sure you want delete the link between <strong>{deleteProfileLinkingId?.firstProfile}</strong> and <strong>{deleteProfileLinkingId?.secondProfile}</strong> ?
        </ModalBody>
        <ModalFooter>
          <Button color="outline-danger" size="md" disabled={isDeleting} onClick={() => deleteProfileLinkingServices(deleteProfileLinkingId?.id)}>Delete</Button>
          <Button onClick={() => setTableShowDeleteModal(false)} color="primary" size="md">Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Left;
