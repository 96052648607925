import React, { memo, useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import astragalColumnLeft from "../../assets/models/AstragalVertical.glb";
import { useDesigner } from '../../context/designerContext';
import { assignOrientations, deepEqual, getHandleObject, getObjectData, getPosition, removeDuplicates, removeDuplicatesByName, removeDuplicatesByPosition, rgbStringToHex, setInitialColors } from '../../utility/helper';
import { modelData, servicePath } from '../../utility/data';
import { getFrameStyles, getInternalColorsWithPrice, getModelData, setModelData, setQuotationModelData } from '../../services/3dModelServices';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import ConfigurationPoints from './configurationPoints';
import CloudTexturePng from "../../assets/img/cloud.png"
import doorBaseModel from "../../assets/models/shapedFrame.glb"
import windowHangingModel from "../../assets/models/CasementHanging.glb"
import cornerJointModel from "../../assets/models/JointMitre.glb"
import { updateFrameStyleAppenture } from '../../services/commonServices';
import { getFramesProfilesDefault, saveModelData, saveModelDataInit, saveModelDataQuotation, storeExtColor } from '../../utility/designerHelper';

import radiusModel from '../../assets/base_models/GS20Bones.glb'
import { addCornerJoint, addFrameStyle, addProfileNumber } from '../../utility/threeJsHelpers/frameHelper';
import { captureAndDownloadExt, captureAndSaveImages, getModelRenderedSize, setFrameCameraView, updateOverlayPosition } from '../../utility/threeJsHelpers/general';
import radiusModelWithoutBones from '../../assets/base_models/GS20TwinTrack.glb'
import { addSill, removeSill, setHornLength } from '../../utility/threeJsHelpers/sillHelper';
import { setGlazing, addGlazingPanel, glazingPanelDelete, removeAllPanels, setGlassTexture } from '../../utility/threeJsHelpers/glazingHelper';
import { resizeAdditionalFrame } from './ResizeAdditionalFrame';
import { addUiLinesBottom, addUiLinesBottomTransom, addUiLinesFrameH, addUiLinesFrameV, getAllProfileRef, removeFrameLines, removeLayoutLines, resizeSash, resizeSashInit, sortTransomFrame } from '../../utility/threeJsHelpers/uiLinesHelper';
import { useRightContext } from '../../context/rightMenuContext';
import EditSashSizeModel from './EditSashSizeModel';
import useFrameModifier from './CreateUpdateAddedFrames';
import EditFrameSizeModel from './EditFrameSizeModel';
import SeqNumberRefs from './SeqNumberRefs';
import useShapedCorner from './ShapedCorner';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useMessage } from '../../context/messageContext';
// import GS20TwinTrackbase from "../../assets/models/GS20TwinTrackbase.glb"

const PanelDesign = memo(({ manufacturingToggle, initialLoad, setInitialLoad, receivedProductId, modelVisible, setModelVisible, collectionDetails, cameraRef, currentModel, isPanelOpen, loading, setLoading, layoutSizing, hardwareType, setHardwareType, allFrameCollection, setLayoutSizing, selectedAnimation, quotationId, modelId, styleId, headerSelectedItem, allHardware, setHardwareData, numeralsText, numeralsFont, newAllHardware, maxZoom, setSaveAllImages, captureImages, setCaptureImages, setAllProfileRefs }) => {

	const modelWrap = useRef(null);
	const containerRef = useRef(null);
	const tempBar = useRef(null);
	const frameStyleRef = useRef([]);
	const navigate = useNavigate()
	const { setMessage, setMessageType } = useMessage()

	const { userDataContext } = useRightContext()

	const { updateAddedFrame, changeAddedFrameAngle, saveAdditionalFrameData } = useFrameModifier()

	const {
		colorActiveTab,
		internalColor,
		externalColor,
		frameAngle,
		applyAngle,
		internalRAL,
		glazingSpec,
		texture,
		externalRAL,
		frameObject,
		modelHeight,
		modelWidth,
		shapedHeight,
		shapedWidth,
		saveHeightWidth,
		defaultSpec,
		design,
		couplerData,
		rows,
		cols,
		saveGlazingDesign,
		setNumHardware,
		hardwareColor,
		hardwareElPos,
		horizontalPos,
		verticalPos,
		handleSides,
		isHardwareSave,
		frameObjectSaving,
		transomDistance,
		productsData,
		hornLength,
		threshHolData,
		deleteFrameObject,
		setSaveHeightWidth,
		deleteHardware,
		isSettingPos,
		transomType,
		setToggleHelper,
		toggleHelper,
		cancelChanges,
		initialProfile,
		bayPostList,
		deletePressed,
		setDeletePressed,
		overrideWidth,
		overrideHeight,
		multiSelectRefPoints, setMultiSelectRefPoints,
		checkSingleSide, setCheckSingleSide, setDeleteRefPoints, setFrameObject, transomsOrientation, checkClickedProfile, setCheckClickedProfile,
		frameProfiles, sashProfileType, saveFrameCustomization, sashHangingNo, sashHanging, deleteItem, delete3DItems, beadList, spacerBar, hardwareObject, sashRadioActive, setSashRadioActive, setWindowHandleOrientation,
		windowHandleOrientation, deleteSashHanging, setDeleteSashHanging, setSashHangingNo, applyPanel, setApplyPanel,
		deletePanel, setDeletePanel, panelObject, panelDataSave, setPanelDataSave, setPanelObject, setDeleteItem, setPricingData, visibleInternalColors, setVisibleInternalColors, setInternalColor, setInternalRAL,
		thresholdType, shapeFrame, sandblastPricing, hardwareReferencePoint, profileJointDetails, uiLinesLocation, setUiLinesLocation, setApplyPartialTrigger, applyPartialTrigger, lockRefIndex, applyPartialObj, setLockRefIndex, setLockRefPoints, setModelImages,setGlazingSpec } = useDesigner();

	const [modelInitialied, setModelInitialied] = useState(false)
	const [tickleVent, setTickleVent] = useState([])
	const [elementData, setElementData] = useState([])
	const [doorHandleData, setDoorHandleData] = useState([])
	const [uniqueSideRefernce, setUniqueSideRefernce] = useState([])
	const [newFrameData, setNewFrameData] = useState([])
	const [frameDrop, setFrameDrop] = useState(false)
	const [styleDrop, setStyleDrop] = useState(false)
	const [frameType, setFrameType] = useState()
	const [styleType, setStyleType] = useState()
	const [popoverIndex, setPopoverIndex] = useState(null)
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const [sashProfileRef, setSashProfileRef] = useState([])
	const [isUniqueSide, setIsUniqueSide] = useState(false)
	const [tansomsReference, setTansomsReference] = useState([])
	const [panelRefPoints, setPanelRefPoints] = useState([])
	const [transomFrameRef, setTransomFrameRef] = useState([])
	const [checkDeleteSide, setCheckDeleteSide] = useState()
	const [spyHoleRefPoints, setSpyHoleRefPoints] = useState([])
	const [defaultSillScale, setDefaultSillScale] = useState()
	const [escutcheonRefPoints, setEscutcheonRefPoints] = useState([])
	const [handleDeleted, setHandleDeleted] = useState(false)
	const [elementDeleted, setElementDeleted] = useState(false)
	const [modelTypeHanging, setModelTypeHanging] = useState(false)
	const [masterSlave, setMasterSlave] = useState(false)
	const [frameProfileDefault, setFrameProfileDefault] = useState('')
	const [isHangingLoaded, setIsHangingLoaded] = useState(false)
	const [saveColors, setSaveColors] = useState(false)
	const [intColorInit, setIntColorInit] = useState(false)
	const [hingeRefPoints, setHingeRefPoints] = useState([])
	const [transomEditPoints, setTransomEditPoints] = useState([])
	const [panelInitAdded, setPanelInitAdded] = useState(false)
	const [shapeAdded, setShapeAdded] = useState()
	const [bayPostDeg, setBayPostDeg] = useState(bayPostList[0]?.id)
	const [bayPostAngleManual, setBayPostAngleManual] = useState(120)
	const [allStyleCollection, setAllStyleCollection] = useState([])
	const [refreshUiLines, setRefreshUiLines] = useState(false)
	const [refreshAfterSave, setRefreshAfterSave] = useState(false)
	const [partialWidthInit, setPartialWidthInit] = useState(false)
	const [savePartialWidths, setSavePartialWidths] = useState(false)
	const [resizeSashStyles, setResizeSashStyles] = useState(false)
	const [jointsApplied, setJointsApplied] = useState(false)
	const [renderedSize, setRenderedSize] = useState({
		width: 2500,
		height: 2100,
	})

	const { customModelData, setCustomModelData, addedFrameData, setAddedFrameData, setAddedFrameList, addedFrameList } = useDesigner()



	const [isHardwareAdded, setIsHardwareAdded] = useState({
		knocker: false,
		escutcheon: false,
		spyhole: false,
		trickleVent: false,
		numeral: false,
		handle: false,
	})

	let mixer;
	let gltfModel = useRef(null)
	// let glass1, glass2; 
	let loader;
	let tickleVentReference = [];
	const tickleVentRef = useRef([]);
	const handleRef = useRef([]);
	const shapeRef1 = useRef();
	const shapeRef2 = useRef();
	let handleReference = [];
	let heightRef = [];
	let widthRef = [];
	let sashRef = [];
	const addFrameRef = useRef([]);
	const transomRelativePosition = useRef([]);
	const testRef = useRef([]);
	const uiLinesRef = useRef([]);
	const uiLinesRefBottom = useRef([]);
	const uiLinesRefFrame = useRef([]);
	let allProfileRefSeq = useRef([]);
	let glassRefSeq = useRef([]);
	// const hardwareTypeRef = useRef(null);
	const internalFrameRef = useRef([]);
	const externalFrameRef = useRef([]);
	const defaultGlassMaterial = useRef(null);
	const defaultFrameMaterial = useRef(null);
	const windowRef = useRef([]);
	const handleInternal = useRef([]);
	const handleExternal = useRef([]);
	const tickleVentModels = useRef([]);
	const handlePosition = useRef([]);
	const removeReplacedSash = useRef([]);
	// const handlePositionInternal = useRef([]);
	const tickleVentPosition = useRef([]);
	const cylinderPosition = useRef([]);
	const handleCylinder = useRef([]);
	const sillRef = useRef(null);
	const glass1 = useRef(null);
	const glass2 = useRef(null);
	const glassGroup = useRef([]);
	const frameStyle = useRef([]);
	const addedFrame = useRef([]);
	const transoms = useRef([]);
	const bead = useRef([]);
	const hingeModels = useRef([]);
	const addedFramePosition = useRef([]);
	const frameStyleTop = useRef(null);
	const frameStyleBottom = useRef(null);
	const frameStyleLeft = useRef(null);
	const newFrameStyleSlant = useRef(null);
	const frameStyleRight = useRef(null);
	const windowHeight = useRef(null);
	const windowWidth = useRef(null);
	const frameSash = useRef([]);
	const Frame = useRef([]);
	const SpaceBar = useRef([]);
	const sashGroup = useRef([]);
	const transomFrame = useRef([]);
	const transomFrameSortedX = useRef([]);
	const transomFrameSortedY = useRef([]);
	const replacedSashProfile = useRef([]);
	const handleObjects = useRef([]);
	const tickleObjects = useRef([]);
	const addedSash = useRef([]);
	const sceneRef = useRef(null);
	const widthLine = useRef(null);
	const heightLine = useRef(null);
	const shadowPlaneRef = useRef(null);
	const orbitControlRef = useRef(null);
	const elementRef = useRef(null);
	const objectGroupCol = useRef(null);
	const objectGroupRow = useRef(null);
	const rowStorage = useRef([])
	const colStorage = useRef([]);
	const beadData = useRef(null);
	const handlePath = useRef(null);
	const ticklePath = useRef(null);
	const rendererInitialised = useRef(null);
	const sashHangingAmount = useRef(null);
	const spyHoleModel = useRef([]);
	const spyHolePosition = useRef([]);
	const letterPlateModel = useRef([]);
	const letterPlatePos = useRef([]);
	const knockerModel = useRef([]);
	const knockerPos = useRef([]);
	const sashList = useRef([]);
	const textMeshRef = useRef(null);
	const textMeshPos = useRef(null);
	const previousTransomFrameRef = useRef(null);
	const orientationClicked = useRef(null);
	const radioButtonClicked = useRef(null);
	const transomData = useRef(null);
	const mullionData = useRef(null)
	const escutcheonExternalModel = useRef([]);
	const escutcheonInternalModel = useRef([]);
	const transomPosition = useRef([]);
	const escutcheonExternalPos = useRef([])
	const addedHandles = useRef([]);
	const addedBarHandles = useRef([])
	const windowSash = useRef([]);
	const addedBarHandlesPos = useRef([]);
	const barHandlesOffsetExternal = useRef([])
	const barHandlesOffsetInternal = useRef([])
	const barHandlesOffsetInternalPos = useRef([])
	const barHandlesOffsetExternalPos = useRef([])
	const newFrameRefPoints = useRef([])
	const allLockingPlates = useRef([])
	const storeGrooveCylinder = useRef([])
	const storeGroveCube = useRef([])
	const storeSashes = useRef([])
	const clippedPlanes = useRef([])
	const getUpdatedFrameRefs = useRef([])
	const extraStorage = useRef([])
	const storeBayPost = useRef(null)
	const topLeftCorner = useRef(null);
	const topRightCorner = useRef(null)
	const cornersLeftFrame = useRef({
		currentTopR: null,
		currentBottomR: null,
		currentBottom: null,
		glassScale: null,
		frameLeftBoundingBox: null,
		frameTopBoundingBox: null,
		frameRightBoundBox: null
	})

	const cornersRightFrame = useRef({
		currentTopL: null,
		currentBottomL: null,
		currentBottom: null,
		glassScale: null,
		frameLeftBoundingBox: null,
		frameTopBoundingBox: null,
		frameRightBoundBox: null
	})

	const perpFrameTopBoundingBox = useRef({
		leftProfile: null,
		rightProfile: null
	})


	const sliderRef = useRef(null)
	const modifiedFrameLeft = useRef(null)
	const initialAddedFrameLeftHt = useRef(null)

	let sashHandles = useRef([])
	let masterSlaveElements = useRef([])
	const isHeritageDoor = useRef([])
	const glazeModelRef = useRef();
	const glazeModelRef2 = useRef();
	const [glazePosX, setGlazePosX] = useState()
	const [glazePosZ, setGlazePosZ] = useState()
	const [glazeScale, setGlazeScale] = useState()
	const [modelScene, setModelScene] = useState()
	const [clipGlaze, setClipGlaze] = useState([])
	const [lineRef, setLineRef] = useState([]);

	const [frameSize, setFrameSize] = useState()
	const [modelSize, setModelSize] = useState({
		height: 0,
		width: 0,
	})
	// const escutcheonInternalPos = useRef([])

	// const beadHangingTop = useRef([]);
	// const beadHangingBottom = useRef([]);
	// const beadHangingRight = useRef([]);
	// const beadHangingLeft = useRef([]);
	const windowHandleRefPoint = useRef([]);
	const windowHangingSash = useRef([]);
	// const windowHandleOrientation = useRef('right');
	const glazingRef = useRef([]);
	let beadDefaultScale = null;
	let hingePosition = useRef([])
	// const panelGlazingRef = useRef([])

	const glassText = useRef([]);

	let font;
	// const outerContainerRef = useRef(null);
	let pointInScreen;
	const accessToken = localStorage.getItem('access_token')
	let renderer;
	var camera;
	let scene;
	var newFrameStyles

	if (rendererInitialised.current != 4) {
		renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
		camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.25, 30);
		scene = new THREE.Scene();
		renderer.localClippingEnabled = true;
	}

	useEffect(() => {
		if (!manufacturingToggle) {
			testRef.current = []
		}
	}, [manufacturingToggle])


	useEffect(() => {
		setIntColorInit(false)
		setLockRefPoints([])
		setLockRefIndex([])
	}, [selectedAnimation?.id])

	useEffect(() => {
		if (allFrameCollection && allFrameCollection?.length > 0 && modelId) {
			frameStyleService(allFrameCollection[0]?.id)
		}
	}, [allFrameCollection, modelId])




	useEffect(() => {

		if (modelInitialied) {
			testRef.current = []
			let sashProfileRef = []
			let profileJoints = []
			let sillRefs = []
			sceneRef?.current?.traverse((item) => {
				if (item?.name.includes("verticalBarRight") || item?.name.includes("verticalBarLeft") || item?.name.includes("horizontalBarTop") || item?.name.includes("horizontalBarBottom")) {
					sashProfileRef.push(item)
				}
				if (item?.name.includes("ProfileJoints")) {
					profileJoints.push(item)
					const uniqueData = removeDuplicates(profileJoints)
					profileJoints = uniqueData
				}

				if (item?.name?.includes("sill")) {
					sillRefs.push(item)
					const uniqueData = removeDuplicates(sillRefs)
					sillRefs = uniqueData
				}
			})
			let refData = [...frameStyleRef.current, ...sashProfileRef, ...profileJoints, ...sillRefs]
			setJointsApplied(false)

			setAllProfileRefs(refData)
			if (hardwareType === "additionalHardware") {
				testRef.current = refData
			} else if (hardwareType === "") {
				testRef.current = []
			}
		}

	}, [hardwareType, modelInitialied, sashGroup.current, sillRef.current, frameStyleRef.current ,jointsApplied])


	// useEffect(() => {
	// 	if(customModelData?.numberOfSash && customModelData?.numberOfSash?.number && addedSash.current.length > 4 && modelInitialied){
	// 		console.log(331);
	// 		if (customModelData.storedTransoms && customModelData.storedTransoms.length > 0) {
	// 			console.log(332);
	// 			customModelData.storedTransoms.forEach(item => {
	// 					addTransom(item.index, item.horizontal, true, true, item, item.position);
	// 			});
	// 		}
	// 	}
	// }, [addedSash.current,modelInitialied])


	useEffect(() => {
		if (customModelData?.frame && customModelData?.frame?.framesAndCoupler && customModelData?.frame?.framesAndCoupler?.length > 0 || customModelData?.frame?.bayPost && customModelData?.frame?.bayPost?.length > 0) {

			let transparentMaterial = new THREE.MeshStandardMaterial({
				color: glazingSpec?.hexValue,
				transparent: true,
				opacity: 0.3, // 50% transparency
			});

			if (glazingSpec) {
				sceneRef.current.traverse((child) => {
					if ((child instanceof THREE.Mesh)) {
						if (child.name.includes("GlassPanel")) {
							child.material = transparentMaterial
						}
					}
				})
			}
		}

	}, [glazingSpec])

	useEffect(() => {
		if (modelInitialied && glazingRef.current && glazingRef.current.length > 2) {
			setTransomRefs(true)
		}
	}, [modelInitialied, glazingRef.current.length])

	const modifyDefaulModelData = (data) => {
		if (customModelData?.frame?.externalColor && customModelData?.frame?.internalColor) {
			data.externalColor = customModelData?.frame?.externalColor;
			data.internalColor = customModelData?.frame?.internalColor;
		}
		if (customModelData.frameProfileData) {
			data.frameProfileData = customModelData?.frameProfileData;
		}

		if (customModelData.layoutFrame) {
			data.layoutFrame = { ...customModelData.layoutFrame }
		}
		return data
	}

	useEffect(() => {
		if (hardwareType === "Shaped Frame") {
			let oldData = null
			// This is for storing the data if there is already a data in the json.
			if (customModelData?.shapedFrame && customModelData?.shapedFrame?.length > 0) {
				oldData = customModelData?.shapedFrame
			}
			const modeifiedData = modifyDefaulModelData(modelData)
			if (oldData !== null) {
				const updatedData = {
					...modeifiedData,
					shapedFrame: oldData,
				};
				setCustomModelData(updatedData)
			}
			else {
				setCustomModelData(modeifiedData)
			}
			setTimeout(() => {
				init(doorBaseModel, "Shaped Frame")
			}, 100);
		}
	}, [hardwareType])

	useEffect(() => {
		if (multiSelectRefPoints.length > 0 && applyAngle) {
			let isTop;
			let isLeft;
			multiSelectRefPoints.forEach((item) => {
				if (item.data.name.includes("topPerpendicular")) {
					isTop = "top"
				} else if (item.data.name.includes("bottomPerpendicular")) {
					isTop = "bottom"
				} else if (item.data.name.includes("rightPerpendicular")) {
					isLeft = true
				} else if (item.data.name.includes("leftPerpendicular")) {
					isLeft = false
				}

				changeAddedFrameAngle(storeBayPost.current, item.data, frameAngle, isTop, isLeft, gltfModel.current, frameStyleTop)

			})
		}
	}, [applyAngle])


	useEffect(() => {
		if ((customModelData && (customModelData?.frame?.framesAndCoupler && customModelData?.frame?.framesAndCoupler?.length > 0) || (customModelData?.frame?.bayPost && customModelData?.frame?.bayPost?.length > 0)) || (customModelData && customModelData?.addedFrames?.length)) {
			let isPerpendicular = false
			let isTop = false
			let isBottom = false
			if (multiSelectRefPoints && multiSelectRefPoints.length) {
				multiSelectRefPoints.forEach((item) => {
					if (item.data.name.includes("rightPerpendicular") || item.data.name.includes("leftPerpendicular")) {
						isPerpendicular = true
						isTop = false
						isBottom = false
					} else if (item.data.name.includes("topPerpendicular")) {
						isPerpendicular = true
						isTop = true
						isBottom = false
					} else if (item.data.name.includes("bottomPerpendicular")) {
						isPerpendicular = true
						isTop = false
						isBottom = true
					}



					if (overrideHeight || overrideWidth) {
						resizeAdditionalFrame(item.data, isPerpendicular, isTop, isBottom, overrideHeight, overrideWidth, headerSelectedItem, topLeftCorner, sceneRef, topRightCorner)

						// const foundItem = customModelData?.addFrames?.find((ele) =>
						// 	deepEqual(ele?.position, item?.data?.position)
						// );

						setCustomModelData((prevData) => {
							const updatedFrames = prevData.addedFrames.map((frame) => {
								if (deepEqual(frame?.position, item?.data?.position)) {
									return {
										...frame,
										height: overrideHeight !== frame?.height ? overrideHeight : frame.height,
										width: overrideWidth !== frame?.width ? overrideWidth : frame.width,
									};
								}
								return frame;
							});

							return {
								...prevData,
								addedFrames: updatedFrames,
							};
						});
					}

				})
			}

			// temporarily using extra storage to refeed the ref as it was getting emptied on save
			if (hardwareType && hardwareType?.name === "Frame") {
				newFrameRefPoints.current = extraStorage.current
			}
		}

	}, [saveHeightWidth])


	useEffect(() => {
		if (design && design?.name === "Sandblasted numerals" || design === 'None') {
			clearDesigns()
		}
	}, [design])


	const clearDesigns = () => {	
		if (colStorage.current.length) {
			clearDesignCols()
		}
		if (rowStorage.current.length) {
			clearDesignRows()
		}
		setCustomModelData((prevModelData) => ({
			...prevModelData,
			glazing: {
				...prevModelData.glazing,
				design: []
			}
		}))
	}


	useEffect(() => {
		if (deleteItem === "sash") {
			multiSelectRefPoints.forEach((item) => {
				removeSash(item.index, item.data)
			})
		}
	}, [deletePressed])

	useEffect(() => {
		if (modelInitialied && spacerBar) {
			modifySpacerBar(spacerBar, true)
		}
	}, [spacerBar])

	// useEffect(() => {
	// 	if(spacerBar){
	// 		let obj = {
	// 			id: spacerBar.id,
	// 			name: spacerBar.name,
	// 			color: spacerBar.hexValue,
	// 			price: spacerBar.price,
	// 			modelName: spacerBar.modelName

	// 		}
	// 		setCustomModelData((prevModelData) => ({
	// 			...prevModelData,
	// 			glazing: {
	// 				...prevModelData.glazing,
	// 				spacerbars: customModelData.glazing.spacerbars.map((item) => {
	// 					return {
	// 						...obj
	// 					}
	// 				})
	// 			}
	// 		}))
	// 	}
	// }, [spacerBar])

	useEffect(() => {
		if (thresholdType) {
			addThreshold(thresholdType)
			let data = {
				...thresholdType,
				isThresholdAdded: true,
			}
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				frame: {
					...prevModelData.frame,
					threshold: data
				}
			}))
		}
	}, [thresholdType])

	useEffect(() => {
		if (customModelData?.numberOfSash?.number === "0") {
			setCustomModelData((prevData) => ({
				...prevData,
				sashData: []
			}))
			replacedSashProfile.current = []
		}
	}, [customModelData?.numberOfSash?.number])


	useEffect(() => {
		if (initialLoad && modelWidth && modelHeight && collectionDetails) {
			getFramesProfilesDefault(accessToken, modelId, setFrameProfileDefault)
			setSaveColors(false)

			setWindowHandleOrientation('')

			if (collectionDetails && collectionDetails.typeId == 2) {
				if (customModelData && customModelData.windowData && customModelData.windowData.isHangingAdded) {
					init(doorBaseModel, 'Hanging')
				} else {
					init(doorBaseModel, 'window')
				}
			} else if (collectionDetails && collectionDetails.typeId == 1) {
				init(doorBaseModel, "door");
			}

			handleResize();

			// Attach resize event listener
			window.addEventListener("resize", handleResize);

			return () => {
				if (renderer) {
					renderer.dispose();
				}
			};
		}
	}, [initialLoad]);

	// Handle window resize
	const handleResize = () => {
		const newWidth = containerRef.current?.clientWidth;
		const newHeight = containerRef.current?.clientHeight;

		// Update renderer size
		renderer?.setSize(newWidth, newHeight);

		if (camera) {
			// Update camera aspect ratio
			camera.aspect = newWidth / newHeight;
			camera.updateProjectionMatrix();
		}
	};

	useEffect(() => {
		if (collectionDetails && collectionDetails.typeId == 1) {
			if (customModelData?.sashAlign?.length > 0 && sashGroup?.current?.length && gltfModel.current) {
				customModelData?.sashAlign.forEach((item) => setSashGroupAlignment(item.sashIndex, item?.sashAlignment))
			}
		}
	}, [sashGroup.current, gltfModel.current])


	useEffect(() => {
		if (collectionDetails && collectionDetails.typeId == 1) {
			if (sashHanging && sashHanging?.sashAlignmentIndex > -1 && checkSingleSide > -1) {
				setSashGroupAlignment(checkSingleSide, sashHanging?.sashAlignmentIndex)
				setCustomModelData((prevModelData) => {
					// Check if an element with the same sashIndex already exists in the array
					let existingIndex
					if (prevModelData?.sashAlign?.length) {
						existingIndex = prevModelData.sashAlign.findIndex(item => item.sashIndex === checkSingleSide);
					}
					let updatedSashAlign;

					// Create a copy of the existing sashAlign array
					if (prevModelData.sashAlign) {
						updatedSashAlign = [...prevModelData.sashAlign];
					}

					if (existingIndex) {
						if (existingIndex !== -1) {
							// If an element with the same sashIndex exists, replace it with the new one
							updatedSashAlign[existingIndex] = { sashIndex: checkSingleSide, sashAlignment: sashHanging?.sashAlignmentIndex };
						} else {
							// Otherwise, add a new element to the array
							updatedSashAlign.push({ sashIndex: checkSingleSide, sashAlignment: sashHanging?.sashAlignmentIndex });
						}
					}
					// Create an updated modelData object with the updated sashAlign array
					const updatedModelData = {
						...prevModelData,
						sashAlign: updatedSashAlign
					};

					return updatedModelData;
				});

			}
		}
	}, [sashHanging])

	useEffect(() => {
		if (tickleVent && tickleVent.length) {
			setNumHardware(tickleVent.length)
		}
	}, [tickleVent])

	useEffect(() => {
		if (textMeshRef.current && !isSettingPos && !isHardwareAdded?.numeral) {
			const numeralNumbersString = customModelData?.hardware?.numeral[0]?.numbers.join("").toString();
			let horizontal = customModelData?.hardware?.numeral[0].horizontalPos;
			let item = ""
			if (typeof (horizontal) === "number" || !horizontal) {
				item = "no"
			}
			else {
				item = customModelData?.hardware?.numeral[0]
			}
			if (numeralNumbersString) {
				createText(numeralNumbersString, customModelData.hardware?.numeral[0]?.position, hardwareColor?.hex, false, item);
			}
		}
	}, [customModelData, hardwareColor, isHardwareSave, hardwareType, isHardwareAdded?.numeral]);

	useEffect(() => {
		if (customModelData?.hardware && customModelData?.hardware?.hardware?.numeral[0]?.numbers)
			setIsHardwareAdded((prevAdded) => ({
				...prevAdded,
				numeral: false
			}))
	}, [customModelData])

	useEffect(() => {
		setSashRadioActive()
		setModelTypeHanging(false)
		// Set the initial value of the slider to 0
		// sliderRef.current.value = 0;
	}, []);


	useEffect(() => {
		if (gltfModel.current) {
			forceUpdate()
		}
	}, [gltfModel.current])

	// add sash function for sashanging number
	useEffect(() => {
		if (saveFrameCustomization && hardwareType === 'sashHanging' && (customModelData?.numberOfSash?.number !== sashHangingNo)) {

			setLockRefPoints([])
			setLockRefIndex([])

			if (collectionDetails && collectionDetails.typeId == 1) {
				const defaultProfile = initialProfile?.sash?.find((ele => ele?.defaultProfile === true))
				updateSash(sashHangingNo, `${servicePath}/ThreeJSModel/Glb/${defaultProfile?.customePath}`, transoms?.current?.length >= 1 ? transomFrame?.current[checkSingleSide] : glassGroup?.current[0], true, null, true);

				replacedSashProfile.current = []

				// setCustomModelData((prevData) => ({
				// 	...prevData,
				// 	sashData: []
				// }))

				let xScaleVal = []
				let sashSize = []

				let sashValue = (parseFloat(customModelData?.layoutFrame?.width) / parseInt(sashHangingNo))

				for (let i = 0; i < sashHangingNo; i++) {
					xScaleVal.push(1)
					sashSize.push(sashValue)
				}

				if (!receivedProductId) {
					setCustomModelData((prevModelData) => ({
						...prevModelData,
						sashData: [],
						numberOfSash: {
							number: sashHangingNo.toString(),
						},
						apertures: {
							number: sashHangingNo.toString()
						},
						xScale: xScaleVal,
						sashSize: sashSize,
					}))
				} else {
					setCustomModelData((prevModelData) => ({
						...prevModelData,
						sashData: [],
						numberOfSash: {
							number: sashHangingNo.toString(),
						},
						xScale: xScaleVal,
						sashSize: sashSize,
					}))
				}
			}

			if (!receivedProductId) {
				if (sashHangingNo > 0) {
					updateFrameStyleAppenture(accessToken, selectedAnimation?.id, sashHangingNo)
				} else {
					updateFrameStyleAppenture(accessToken, selectedAnimation?.id, 1)
				}
			}
		}
	}, [saveFrameCustomization])

	// useEffect(() => {
	// 	if (collectionDetails && collectionDetails.typeId == 1 && modelInitialied) {
	// 		if (customModelData) {
	// 			const defaultProfile = initialProfile?.sash?.find((ele => ele?.defaultProfile === true))
	// 			updateSash(parseInt(customModelData?.numberOfSash?.number || 0), `${servicePath}/ThreeJSModel/Glb/${defaultProfile?.customePath}`, transoms?.current?.length >= 1 ? transomFrame?.current[checkSingleSide] : glassGroup?.current[0], true, null, false)
	// 		}
	// 	}
	// }, [modelInitialied && collectionDetails])

	useEffect(() => {
		if (checkSingleSide > -1 && transomsOrientation == "Horizontal") {
			addTransom(checkSingleSide, true, false, false, transomType, checkClickedProfile?.position)
		} else if (checkSingleSide > -1 && transomsOrientation == "Vertical") {
			addTransom(checkSingleSide, false, false, false, transomType, checkClickedProfile?.position)
		}
	}, [checkSingleSide])


	useEffect(() => {
		if (deleteItem === 'transoms') {
			// multiSelectRefPoints.forEach((item, index) => {
			// 	removeTransom(item.index)
			// })
			removeAllTransom()
		}
	}, [deleteItem])


	const modifySpacerBar = (data, storeData) => {
		const material = new THREE.MeshStandardMaterial({
			color: data.hexValue,
			metalness: 1,
			roughness: 0.5
		});

		sceneRef.current.traverse((child) => {
			if (child.name.includes("Spacer")) {
				child.material = material
			}
		})
	}

	const handleDeleteObject = (index) => {
		setCheckDeleteSide(index)
	}

	useEffect(() => {
		if (!quotationId && modelId && (handleDeleted || elementDeleted)) {
			saveModelData(accessToken, modelId, selectedAnimation?.id, customModelData, navigate, setMessage, setMessageType)
		}

		if (quotationId && modelId && receivedProductId && (handleDeleted || elementDeleted)) {
			saveModelDataQuotation(accessToken, modelId, selectedAnimation?.id, customModelData, quotationId, receivedProductId, navigate, setMessage, setMessageType)
		}
	}, [handleDeleted, elementDeleted]);

	// Debounce function to delay calling addTextOnGlass
	let debounceTimer;

	const debounceAddTextOnGlass = (font, text) => {
		clearTimeout(debounceTimer);

		debounceTimer = setTimeout(() => {
			if (multiSelectRefPoints.length > 0) {
				multiSelectRefPoints.forEach((item) => {
					if (numeralsText != '') {
						addTextOnGlass(font, text, item.index);
					} else {
						if (numeralsText == '') {
							removeTextOnGlass(item.index)
						}
					}
				})
			} else {
				if (numeralsText != '') {
					addTextOnGlass(font, text, null);
				} else {
					if (numeralsText == '') {
						removeTextOnGlass(null)
					}
				}
			}
		}, 1000); // Adjust the delay time as needed (e.g., 1000 milliseconds)
	};

	useEffect(() => {
		debounceAddTextOnGlass(numeralsFont, numeralsText);
	}, [numeralsText, numeralsFont])

	useEffect(() => {
		if (!layoutSizing) {
			// forceUpdate()
			testRef.current = []
		}
	}, [layoutSizing])

	useEffect(() => {
		if (modelInitialied) {
			handleHardwares()

			if (hardwareType) {
				forceUpdate()
			}

			if (hardwareType && newAllHardware?.length > 0 && hardwareReferencePoint !== null) {
				const selectedHardware = newAllHardware.filter((item) => item?.name === hardwareType?.name)
				setElementData(selectedHardware[0]?.hardwareStyles)
			}
			// newFrameRefPoints.current = []

		}
	}, [hardwareType, modelInitialied, hardwareReferencePoint, newAllHardware])


	useEffect(() => {
		if (headerSelectedItem.name === "French Door" && hardwareType === "sashHanging") {
			setElementData(productsData)
		}
	}, [headerSelectedItem, hardwareType])


	useEffect(() => {
		if (hardwareType && hardwareType?.name === "Frame" && modelInitialied) {
			handleHardwares()
		}
	}, [newFrameData, modelInitialied])

	useEffect(() => {
		if (hardwareType !== "sashHanging") {
			if (modelInitialied && customModelData?.hardware && customModelData?.hardware?.handle?.length > 0) {
				customModelData?.hardware?.handle?.forEach((obj, index) => {
					addHandle(obj.file, sashGroup.current[obj.index], true, "", "", obj.id)
				})
			}
		}
	}, [modelInitialied, sashGroup.current])


	useEffect(() => {
		if (customModelData?.hardware?.handle && modelInitialied) {
			setTimeout(() => {
				customModelData?.hardware?.handle?.forEach((item) => {
					setHandlePosition(handleSides, true, item?.index, !item?.horizontalPos ? 0 : parseInt(item?.horizontalPos), true)
					setHandlePosition(handleSides, false, item?.index, !item.verticalPos ? 0 : parseInt(item?.verticalPos), true)
				})

			}, 300);
		}
	}, [isHardwareAdded?.handle, modelInitialied])


	useEffect(() => {
		if (modelInitialied) {
			if (hardwareColor && hardwareType?.name === '3 Star') {
				setHandleCylinderColor(hardwareElPos, hardwareColor?.hex)
			} else if (hardwareColor && hardwareColor?.hex && hardwareType?.name === "Slim Security Flip Lock") {
				setHandleColor(hardwareElPos, hardwareColor?.hex)
			} else if (hardwareColor && hardwareType?.type === "trickle") {
				setTrickleVentColor(hardwareElPos, hardwareColor?.hex)
			} else if (hardwareColor && hardwareType?.type === "spyhole") {
				setSpyHoleColor(hardwareColor?.hex)
			} else if (hardwareColor && hardwareType?.type === 'letterplate') {
				setLetterPlateColor(hardwareColor?.hex)
			} else if (hardwareColor && hardwareType?.type === 'knocker') {
				setKnockerColors(hardwareColor?.hex)
			} else if (hardwareColor && hardwareType?.type === "escutcheon") {
				setEscutcheonColor(hardwareElPos, hardwareColor?.hex)
			} else if (hardwareColor && hardwareType?.type === "hinge") {
				setHingeColor(hardwareColor.hex)
			}
		}
	}, [hardwareType, hardwareColor, modelInitialied])


	useEffect(() => {
		if (deleteHardware && hardwareType?.type === "handle") {
			deleteHandle(hardwareElPos)
			deleteHandleCylinder(hardwareElPos)
		} else if (deleteHardware && hardwareType?.type === "cylinder") {
			deleteHandleCylinder(hardwareElPos)
		} else if (deleteHardware && hardwareType?.type === "trickle") {
			deleteTrickleVent(hardwareElPos)
		} else if (deleteHardware && hardwareType?.type === 'spyhole') {
			deleteElement('spyhole', 0)
		} else if (deleteHardware && hardwareType?.type === 'letterplate') {
			deleteElement('letterPlate', 0)
		} else if (deleteHardware && hardwareType?.type === 'numeral') {
			deleteElement('numeral', 0)
		} else if (deleteHardware && hardwareType?.type === "escutcheon") {
			deleteTwoSidedElement(hardwareElPos, 'escutcheon')
		} else if (deleteHardware && hardwareType?.type === 'knocker') {
			deleteElement('knocker', 0)
		} else if (deleteHardware && hardwareType?.type === 'hinge') {
			deleteHinge(hardwareElPos)
		} else if (deleteHardware && hardwareType?.type === "bar handles offset") {
			deleteBarHandles(hardwareElPos, handleSides)
		}
	}, [deleteHardware])


	useEffect(() => {
		if (frameObjectSaving && hornLength) {
			setHornLength(hornLength, sillRef, defaultSillScale)
		}
	}, [frameObjectSaving])

	useEffect(() => {
		if (transomDistance != null) {
			transomDistanceAdded(transomDistance)
		}
	}, [transomDistance])

	useEffect(() => {
		if (deleteFrameObject) {
			removeSill(sillRef, gltfModel, sceneRef, setFrameObject, setPricingData)
		}
	}, [deleteFrameObject])

	useEffect(() => {
		if (isHardwareSave && hardwareType?.name === "Slim Security Flip Lock" && horizontalPos) {
			setHandlePosition(handleSides, true, hardwareElPos, parseInt(horizontalPos), false)
			// setHandleCylinderPosition(true, parseInt(horizontalPos), hardwareElPos)
		} else if (isHardwareSave && hardwareType?.type === "trickle" && horizontalPos) {
			setTrickleVentPosition(true, hardwareElPos, parseInt(horizontalPos))
		} else if (isHardwareSave && hardwareType?.type === 'spyhole' && horizontalPos) {
			setSpyHolePosition(true, horizontalPos)
		} else if (isHardwareSave && hardwareType?.type === 'letterplate' && horizontalPos) {
			setLetterPlatePosition(true, horizontalPos)
		} else if (isHardwareSave && hardwareType?.type === 'knocker' && horizontalPos) {
			setKnockerPosition(true, horizontalPos)
		} else if (isHardwareSave && hardwareType?.type === 'numeral' && horizontalPos) {
			setTextPosition(true, parseInt(horizontalPos))
		} else if (isHardwareSave && hardwareType?.type === "escutcheon") {
			setEscutecheonPosition(handleSides, true, hardwareElPos, parseInt(horizontalPos))
		} else if (isHardwareSave && hardwareType?.type === "hinge") {
			setHingePosition(true, hardwareElPos, parseInt(horizontalPos))
		} else if (isHardwareSave && hardwareType.type === "bar handles offset" && horizontalPos) {
			setBarHandlePostion(handleSides, true, hardwareElPos, parseInt(horizontalPos))
		}
	}, [isHardwareSave])


	useEffect(() => {
		if (isHardwareSave && hardwareType?.name === "Slim Security Flip Lock" && verticalPos) {
			setHandlePosition(handleSides, false, hardwareElPos, parseInt(verticalPos), false)
			// setHandleCylinderPosition(false, parseInt(verticalPos), hardwareElPos)
		} else if (isHardwareSave && hardwareType?.type === "trickle" && verticalPos) {
			setTrickleVentPosition(false, hardwareElPos, parseInt(verticalPos))
		} else if (isHardwareSave && hardwareType?.type === 'spyhole' && verticalPos) {
			setSpyHolePosition(false, verticalPos)
		} else if (isHardwareSave && hardwareType?.type === 'letterplate' && verticalPos) {
			setLetterPlatePosition(false, verticalPos)
		} else if (isHardwareSave && hardwareType?.type === 'knocker' && verticalPos) {
			setKnockerPosition(false, verticalPos)
		} else if (isHardwareSave && hardwareType?.type === 'numeral' && verticalPos) {
			setTextPosition(false, parseInt(verticalPos))
		} else if (isHardwareSave && hardwareType?.type === "escutcheon") {
			setEscutecheonPosition(handleSides, false, hardwareElPos, parseInt(verticalPos))
		} else if (isHardwareSave && hardwareType?.type === "hinge") {
			setHingePosition(false, hardwareElPos, parseInt(verticalPos))
		} else if (isHardwareSave && hardwareType.type === "bar handles offset" && verticalPos) {
			setBarHandlePostion(handleSides, false, hardwareElPos, parseInt(verticalPos))
		}
	}, [isHardwareSave])

	// const hardwarePositionChange = (index,horizontal , vertical, type) => {
	// 	customModelData.hardware[type].forEach((item) => {
	// 		if(item.index === index){
	// 			setCustomModelData((prevModelData) => ({
	// 				...prevModelData,
	// 				hardware : {
	// 					...prevModelData.hardware,
	// 					[type] : 
	// 				}
	// 			}))
	// 		}
	// 	})
	// }

	// console.log(panelDataSave );
	

	useEffect(() => {
		if (modelInitialied) {
			if (design && design?.name !== "Sandblasted numerals" && design !== 'None') {
				if (hardwareType == "glazing") {
					multiSelectRefPoints?.forEach((item) => {
						clearDesignRows(item.index)
						clearDesignCols(item.index)
						setGlassDesignColumn(cols, `${servicePath}/ThreeJSModel/Glb/${design?.modelName[1]}`, item.index);
						updateGlassDesignRow(`${servicePath}/ThreeJSModel/Glb/${design?.modelName[0]}`, rows, item.index);
						storeGlazingDesign(`${servicePath}/ThreeJSModel/Glb/${design?.modelName[0]}`, rows, cols, item.index, design)
						glazingPanelDelete(item?.data, item.index, sceneRef, panelDataSave, setPanelDataSave, externalFrameRef, internalFrameRef, setDeletePanel)
					})

				} else {
					if (hardwareType == "design" || hardwareType == "Design") {					
						clearDesignRows()
						clearDesignCols()
						updateGlassDesignRow(`${servicePath}/ThreeJSModel/Glb/${design?.modelName[0]}`, rows);
						setGlassDesignColumn(cols, `${servicePath}/ThreeJSModel/Glb/${design?.modelName[1]}`);
						storeGlazingDesign(`${servicePath}/ThreeJSModel/Glb/${design?.modelName[0]}`, rows, cols, null, design)
					}
				}
			}
		}
	}, [design, saveGlazingDesign])

	const storeGlazingDesign = (modelUrl, rows, cols, index, data) => {

		if (index === null) {
			let glazingDesign = []

			if (sashGroup?.current?.length > 0) {
				let partitions = sashGroup.current?.length
				sashGroup.current.forEach((item, index) => {
					glazingDesign.push({
						modelUrl,
						rows: rows,
						cols: Math.ceil(cols / partitions),
						name: data.name,
						index: index + 1,
						price: data?.price,
						id: data?.id,
					})
				})
			
			} else {
				
				glazingDesign.push({
					modelUrl,
					rows: rows,
					cols,
					name: data.name,
					index: null,
					price: data?.price,
					id: data?.id,
				})
			}


			setCustomModelData((prevModelData) => ({
				...prevModelData,
				glazing: {
					...prevModelData.glazing,
					design: glazingDesign
				}
			}))
		} else {

			if (customModelData?.glazing?.design?.length) {
				const removeIndex = customModelData.glazing.design.findIndex(
					(item) => item.index === null || item.index === index
				);

				if (removeIndex !== -1) {
					const updatedDesign = [...customModelData.glazing.design];
					updatedDesign.splice(removeIndex, 1);

					// Update the state to remove the item first
					setCustomModelData((prevModelData) => ({
						...prevModelData,
						glazing: {
							...prevModelData.glazing,
							design: updatedDesign
						}
					}));
				}
			}

			// Create new design entry
			let indexData = {
				modelUrl,
				rows: rows.toString(),
				cols,
				name: data.name,
				index: index,
				price: data?.price,
				id: data?.id
			};

			// Add the new entry into the updated state
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				glazing: {
					...prevModelData.glazing,
					design: [...prevModelData?.glazing?.design || [], indexData]
				}
			}));
		}
	}

	useEffect(() => {
		if (checkClickedProfile) {
			setCheckClickedProfile()
		}
	}, [])


	// useEffect(() => {
	// 	if (saveHeightWidth && shapeFrame) {
	// 		chamferedCorner(shapedHeight, shapedWidth, modelHeight, modelWidth, shapeFrame, checkClickedProfile)
	// 	}
	// }, [saveHeightWidth, shapeFrame])


	useEffect(() => {
		if (saveHeightWidth && modelInitialied && !customModelData?.frame?.framesAndCoupler && !customModelData?.frame?.framesAndCoupler?.length > 0 || !customModelData?.frame?.bayPost && !customModelData?.frame?.bayPost?.length > 0) {
			if (customModelData?.windowData?.hangingType == "Master/Slave" || customModelData?.windowData?.hangingType == "Slave/Master") {
				// init(windowHangingModel, 'Hanging')
			} else {
				// when both width and height is updated
				if (saveHeightWidth && modelWidth !== windowWidth?.current && modelHeight !== windowHeight?.current) {
					resizeWindow(modelWidth, modelHeight, sashRadioActive, false)

					// if (shapeFrame && hardwareType === "Shaped Frame") {
					// 	setTimeout(() => {
					// 		const parts = checkClickedProfile.name.split(' ')
					// 		if (parts.length >= 3) {
					// 			try {
					// 				addShapedCornerResize(parts[1], parts[2], shapedHeight, shapedWidth, modelHeight, modelWidth)
					// 					.then(async () =>
					// 						setTimeout(async () => {
					// 							await addShapedFrame(parts[1], parts[2], shapedHeight, shapedWidth, modelWidth, modelHeight, shapeFrame)

					// 						}, 50))
					// 					.catch(error => console.error('Failed to add shaped frame:', error));
					// 			} catch (error) {
					// 				console.error('Failed to add shaped frame:', error);
					// 			}
					// 		}
					// 	}, 100);
					// }
				}

				// if((saveHeightWidth &&  shapedHeight !== prevShapedHeight.current)){
				// 	if (shapeFrame && hardwareType === "Shaped Frame") {
				// 		setTimeout(() => {
				// 			const parts = checkClickedProfile.name.split(' ')
				// 			if (parts.length >= 3) {
				// 				try {
				// 					addShapedCornerResize(parts[1], parts[2], shapedHeight, shapedWidth, modelHeight, modelWidth)
				// 						.then(async () =>
				// 							setTimeout(async () => {

				// 								await addShapedFrame(parts[1], parts[2], shapedHeight, shapedWidth, modelWidth, modelHeight, shapeFrame)
				// 							}, 1))
				// 						.catch(error => console.error('Failed to add shaped frame:', error));
				// 				} catch (error) {
				// 					console.error('Failed to add shaped frame:', error);
				// 				}
				// 			}
				// 		}, 1);
				// 	}
				// }

				// when only width is updated
				if (saveHeightWidth && (modelWidth !== windowWidth?.current) && (modelHeight == windowHeight?.current)) {
					resizeWindow(modelWidth, windowHeight.current, sashRadioActive, false)
					// if (shapeFrame && hardwareType === "Shaped Frame") {
					// 	setTimeout(() => {
					// 		const parts = checkClickedProfile.name.split(' ')
					// 		if (parts.length >= 3) {
					// 			try {
					// 				addShapedCornerResize(parts[1], parts[2], shapedHeight, shapedWidth, modelHeight, modelWidth)
					// 					.then(async () =>
					// 						setTimeout(async () => {

					// 							await addShapedFrame(parts[1], parts[2], shapedHeight, shapedWidth, modelWidth, modelHeight, shapeFrame)
					// 						}, 1))
					// 					.catch(error => console.error('Failed to add shaped frame:', error));
					// 			} catch (error) {
					// 				console.error('Failed to add shaped frame:', error);
					// 			}
					// 		}
					// 	}, 1);
					// }
				}

				// when only height is updated
				if (saveHeightWidth && (modelWidth == windowWidth?.current) && (modelHeight !== windowHeight?.current)) {
					resizeWindow(windowWidth.current, modelHeight, sashRadioActive, false);
					// if (shapeFrame && hardwareType === "Shaped Frame") {
					// 	setTimeout(() => {
					// 		const parts = checkClickedProfile.name.split(' ')
					// 		if (parts.length >= 3) {
					// 			try {
					// 				addShapedCornerResize(parts[1], parts[2], shapedHeight, shapedWidth, modelHeight, modelWidth)
					// 					.then(async () =>
					// 						setTimeout(async () => {
					// 							await addShapedFrame(parts[1], parts[2], shapedHeight, shapedWidth, modelWidth, modelHeight, shapeFrame)
					// 						}, 1))
					// 					.catch(error => console.error('Failed to add shaped frame:', error));
					// 			} catch (error) {
					// 				console.error('Failed to add shaped frame:', error);
					// 			}
					// 		}
					// 	}, 1);
					// }
				}
			}
			// setTimeout(() => {
			// 	
			// 	getCornerRefPoints()
			// }, 100);
		}
	}, [customModelData, modelInitialied])

	useEffect(() => {
		if (customModelData?.numberOfSash?.number === "0") {
			setCustomModelData((prevData) => ({
				...prevData,
				sashData: []
			}))
			replacedSashProfile.current = []
		}
	}, [customModelData?.numberOfSash?.number])

	// Function add external and RAL COLOR
	useEffect(() => {
		if (externalColor && modelId) {
			if (externalRAL !== undefined) {
				setExternal(rgbStringToHex(externalRAL?.rgbValue))
			} else {
				setExternal(externalColor?.colour)
			}

			if (externalColor?.primaryId) {
				internalColorService(modelId, externalColor?.primaryId)
			}

			// setPricingData((prevPricing) => ({
			// 	...prevPricing,
			// 	frameColorExt: externalColor,
			// }))
		}
	}, [externalColor, externalRAL])

	// function to add internal and ral color
	useEffect(() => {
		if (internalColor !== undefined) {
			if (internalRAL !== undefined) {
				setInternal(rgbStringToHex(internalRAL?.rgbValue));
			} else {
				setInternal(internalColor?.colour);
			}

			// setPricingData((prevPricing) => ({
			// 	...prevPricing,
			// 	frameColorInt: internalColor,
			// }))
		}
	}, [internalColor, internalRAL])

	const internalColorService = async (fetchId, primaryId) => {
		const res = await getInternalColorsWithPrice(accessToken, fetchId, primaryId)

		if (res && res.status === 200) {
			if (res?.data?.statusCode === 200) {
				setIntColorInit(true)
				setVisibleInternalColors(res.data?.entity)
			}
		}
	}

	useEffect(() => {
		if (modelInitialied) {
			if (modelId && visibleInternalColors && visibleInternalColors.length > 0 && customModelData) {
				var getSavedColor = customModelData?.frame?.internalColor

				if (customModelData?.frame?.internalColor.itemId !== 0) {
					if (customModelData?.frame?.internalColor?.name === 'Custom RAL') {
						var jsonColor = {
							id: getSavedColor?.itemId,
							name: getSavedColor?.name,
							colour: getSavedColor?.hex,
							primaryId: getSavedColor?.primaryId,
							price: getSavedColor?.price,
							priceInpercentage: getSavedColor?.priceInpercentage,
							barLengthPrice: getSavedColor?.barLengthPrice,
						}

						var json = {
							id: getSavedColor?.custom_Id,
							name: getSavedColor?.customRALName,
							rgbValue: getSavedColor?.rgbValue,
							number: getSavedColor?.customRALCode,
						}

						setInternalColor(jsonColor)
						setInternalRAL(json)
					} else {
						var json = {
							id: getSavedColor?.itemId,
							name: getSavedColor?.name,
							colour: getSavedColor?.hex,
							primaryId: getSavedColor?.primaryId,
							price: getSavedColor?.price,
							priceInpercentage: getSavedColor?.priceInpercentage,
							barLengthPrice: getSavedColor?.barLengthPrice,
						}

						setInternalRAL()
						setInternalColor(json)
					}
					setIntColorInit(true)
				} else {
					if (!quotationId) {
						const defaultIntColor = visibleInternalColors?.find((p) => p.default === true)

						var testKey = customModelData?.frame?.internalColor?.hasOwnProperty("custom_Id")

						setTimeout(() => {
							if (!testKey) {
								if (defaultIntColor === undefined) {
									setInternalColor(visibleInternalColors[0])

									storeExtColor(externalColor, undefined, visibleInternalColors[0], undefined, rgbStringToHex, setCustomModelData, setSaveColors)
								} else {
									setInternalColor(defaultIntColor)

									storeExtColor(externalColor, undefined, defaultIntColor, undefined, rgbStringToHex, setCustomModelData, setSaveColors)
								}
							}
						}, 100);
					}
					setIntColorInit(true)
				}
			}
		}
	}, [visibleInternalColors, modelInitialied])

	useEffect(() => {
		if (saveColors !== '' && saveColors && customModelData && modelId && selectedAnimation?.id) {
			saveModelDataInit(accessToken, modelId, selectedAnimation?.id, customModelData, setSaveColors)
		}
	}, [saveColors]);


	// Function to add sill 
	useEffect(() => {
		if (modelInitialied && frameObject?.modelFilePath) {
			addSill(`${servicePath}/ThreeJSModel/Glb/${frameObject?.modelFilePath}`, frameObject, sillRef, gltfModel, sceneRef, collectionDetails, frameStyleBottom, defaultFrameMaterial, setDefaultSillScale, setHardwareType, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)
		}
	}, [frameObject, modelInitialied])

	useEffect(() => {
		if (modelInitialied) {
			if (customModelData?.glazing?.spec && customModelData?.glazing?.spec?.length) {
				customModelData?.glazing?.spec?.forEach((item) => setGlazing(item.color, item.index, shapeFrame, setClipGlaze, glazingRef, clipGlaze))
			}

			// calculation glass dimentions
			var glassSizeData = []

			if (glazingRef.current) {
				glazingRef.current.forEach((glassItem, index) => {

					const glassBoundingBox = new THREE.Box3().setFromObject(glassItem);

					var glassItemSize = {
						width: glassBoundingBox.max.x - glassBoundingBox.min.x,
						height: glassBoundingBox.max.y - glassBoundingBox.min.y,
						index: index
					}

					glassSizeData.push(glassItemSize)

					setCustomModelData((prevModelData) => ({
						...prevModelData,
						'glassSizeData': glassSizeData
					}));
				});
			} else {
				setCustomModelData((prevModelData) => ({
					...prevModelData,
					'glassSizeData': []
				}));
			}
		}
	}, [modelInitialied, glazingRef.current])


	// useEffect(() => {
	// 	if (modelInitialied) {
	// 		let tempArr = glazingRef.current.filter((item) => item !== glassGroup.current[0])
	// 		if (tempArr.length) {
	// 			tempArr.forEach((child, index) => {
	// 				settransomFrameMap((prevMap) => new Map(prevMap).set(index, child))
	// 			})
	// 		}
	// 	}
	// }, [modelInitialied, glazingRef.current])


	useEffect(() => {
		if (modelInitialied && customModelData.storedTransoms) {

			var beadLength = 0

			sceneRef.current.traverse((child) => {
				if (child.name.includes("Bead")) {
					const beadBoundingBox = new THREE.Box3().setFromObject(child);

					// child.position.z += .2

					const beadWidth = beadBoundingBox.max.x - beadBoundingBox.min.x
					const beadHeigth = beadBoundingBox.max.y - beadBoundingBox.min.y

					if (beadWidth > beadHeigth) {
						beadLength += beadWidth

						var boxHelper = new THREE.BoxHelper(child, 0xffff00);

						if (boxHelper && boxHelper.visible) {
							scene?.add(boxHelper);
						}
					} else {
						beadLength += beadHeigth
					}
				}
			})

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				beadLength: beadLength,
			}))

		}
	}, [customModelData.storedTransoms, customModelData.bead, glazingRef.current])

	// This will persist the added hardware on page load.
	useEffect(() => {
		if (modelInitialied && hardwareObject) {
			if (hardwareObject?.escutcheon?.length > 0 && !hardwareType && gltfModel.current) {
				hardwareObject?.escutcheon?.forEach((item) => addDoubleSidedElement(item?.file, item?.position, item, true))
			}
			if (hardwareObject?.knocker?.length > 0 && !hardwareType) {
				hardwareObject?.knocker?.forEach((item) => addTopElements(item?.file, item?.position, item, true))
			}
			// if (hardwareObject?.handle?.length > 0 && !hardwareType) {
			// 	hardwareObject?.handle?.forEach((item) => {
			// 		// if (item?.index) {
			// 			addHandle(item?.file, item?.position, true)
			// 		// }
			// 	})
			// }
			if (hardwareObject?.trickleVent?.length > 0 && !hardwareType) {
				hardwareObject?.trickleVent?.forEach((item) => addElement(item?.file, item?.position, true, item))
			}
			if (hardwareObject?.spyhole?.length > 0 && !hardwareType) {
				hardwareObject?.spyhole?.forEach((item) => addTopElements(item?.file, item?.position, item, true))
			}
			if (hardwareObject?.numeral?.length > 0 && !hardwareType) {
				hardwareObject?.numeral?.forEach((item) => createText(item?.numbers?.join("").toString(), item?.position, "", true, item))
			}
		}
	}, [hardwareObject, modelInitialied]);


	// This chunk will add the hardware on their respective positions.
	useEffect(() => {
		if (modelInitialied && hardwareObject) {

			if (isHardwareAdded?.knocker) {
				hardwareObject?.knocker?.forEach((item) => setKnockerPosition(false, item?.verticalPos))
				hardwareObject?.knocker?.forEach((item) => setKnockerPosition(true, item?.horizontalPos))
			}
			if (isHardwareAdded?.escutcheon) {
				hardwareObject?.escutcheon?.forEach((item) => setEscutecheonPosition(handleSides, false, hardwareElPos, parseFloat(item?.verticalPos)))
				hardwareObject?.escutcheon?.forEach((item) => setEscutecheonPosition(handleSides, true, hardwareElPos, parseFloat(item?.horizontalPos)))
			}
			if (isHardwareAdded?.spyhole) {
				hardwareObject?.spyhole?.forEach((item) => setSpyHolePosition(true, item?.horizontalPos))
				hardwareObject?.spyhole?.forEach((item) => setSpyHolePosition(false, item?.verticalPos))
			}
			if (isHardwareAdded?.trickleVent) {
				hardwareObject?.trickleVent?.forEach((item) => setTrickleVentPosition(true, hardwareElPos, parseInt(item?.horizontalPos)))
				hardwareObject?.trickleVent?.forEach((item) => setTrickleVentPosition(false, hardwareElPos, parseInt(item?.verticalPos)))
			}
			if (isHardwareAdded?.numeral) {
				hardwareObject?.numeral?.forEach((item) => setTextPosition(true, parseInt(item?.horizontalPos)))
				hardwareObject?.numeral?.forEach((item) => setTextPosition(false, parseInt(item?.verticalPos)))
			}

			// if (isHardwareAdded?.handle && modelInitialied) {
			// 	hardwareObject?.handle?.forEach((item) => {
			// 		setHandlePosition(handleSides, true, item?.index, !item?.horizontalPos ? 0 : parseInt(item?.horizontalPos))
			// 	})
			// 	hardwareObject?.handle?.forEach((item) => {
			// 		setHandlePosition(handleSides, false, item?.index, !item.verticalPos ? 0 : parseInt(item?.verticalPos))

			// 	})
			// }
		}
	}, [isHardwareAdded, hardwareObject, modelInitialied])

	const toggleFrameDrop = () => {
		setFrameDrop(!frameDrop)
	}

	const toggleStyleDrop = () => {
		setStyleDrop(!styleDrop)
	}

	const handleFrameDrop = (data) => {
		setFrameType(data)
	}

	const handleStyleDrop = (data) => {
		setStyleType(data)
	}

	useEffect(() => {
		if (modelInitialied && defaultSpec?.specification?.length) {
			if (!customModelData?.glazing?.spec || customModelData?.glazing?.spec?.length != glazingRef.current.length && !customModelData?.glazing?.isSpecModified) {
				let newData = []
				const defaulSpecExist = defaultSpec.specification.find((spec) => spec.default === true)
				glazingRef.current.forEach((item, index) => {
					const data = {
						id: defaulSpecExist?.id,
						name: defaulSpecExist?.name,
						color: defaulSpecExist?.hexValue,
						price: defaulSpecExist?.price,
						imagePath: defaulSpecExist?.capturedImage,
						index: index,
						oversizeArea: defaulSpecExist?.maximumsquare,
						description: defaulSpecExist?.description
					}
					newData.push(data)
				})
				const uniqueIndices = new Set(newData.map(item => item.index));
				const uniqueData = Array.from(uniqueIndices).map(index =>
					newData.find(item => item.index === index)
				);
				setCustomModelData((prevModelData) => ({
					...prevModelData,
					glazing: {
						...prevModelData?.glazing,
						spec: uniqueData.length ? uniqueData : [],
						isSpecModified: false
					}
				}))
			}
		}

	}, [defaultSpec, glazingRef.current, modelInitialied, customModelData?.glazing?.spec, customModelData?.glazing?.isSpecModified])

	// This will get the data for the frame to be added on the basis of the selected frame collection and style
	useEffect(() => {
		if (modelInitialied && frameType && styleType) {
			getModelDataServices(frameType?.id, styleType?.id)
		}
		else if (modelInitialied && !frameType && styleType) {
			getModelDataServices(allFrameCollection[0]?.id, styleType?.id)
		}
		else if (modelInitialied && frameType && !styleType) {
			getModelDataServices(frameType?.id, allStyleCollection[0]?.id)
		}
		else {
			if (allFrameCollection && allFrameCollection.length > 0 && allStyleCollection && allStyleCollection?.length > 0) {
				getModelDataServices(allFrameCollection[0]?.id, allStyleCollection[0]?.id)
			}
		}
	}, [frameType, styleType, allStyleCollection, allFrameCollection, allStyleCollection])

	useEffect(() => {
		// if (allStyleCollection?.length > 0) {
		// 	setStyleType(allStyleCollection[0])
		// }
		if (frameType) {
			frameStyleService(frameType?.id)
		}
	}, [frameType])

	useEffect(() => {
		if (addedFrameData && addedFrameData.hasOwnProperty('position')) {
			setAddedFrameList(prevAddedFrameList => {
				if (!prevAddedFrameList.includes(addedFrameData)) {
					return [...prevAddedFrameList, addedFrameData];
				}
				return prevAddedFrameList;
			});
		}
	}, [addedFrameData]);

	useEffect(() => {
		if (modelInitialied) {
			if (customModelData?.glazing && customModelData.glazing?.design?.length > 0) {
		
				customModelData.glazing.design.forEach((item) => {
					setTimeout(() => {
						clearDesignCols(item?.index)
						clearDesignRows(item?.index)
					}, 700);
					setTimeout(() => {						
						updateGlassDesignRow(item.modelUrl, Number(item.rows), item.index);
						setGlassDesignColumn(Number(item.cols), item.modelUrl, item.index);
					}, 750);
				})
				
			}
		}
	}, [sashGroup.current , resizeSashStyles])


	const getModelDataServices = async (frameId, styleId) => {
		// setLoading(true)
		const res = await getModelData(accessToken, frameId, styleId)
		if (res?.status === 200) {
			if (res?.data?.statusCode === 200) {
				if (res?.data?.entity?.jsonblob !== null) {
					setAddedFrameData(JSON.parse(res?.data?.entity?.jsonblob))
					//   setLoading(false)
				} else {
					setAddedFrameData(modelData)
					//   setLoading(false)
				}
			}
		}
	}

	const frameStyleService = async (modelId) => {
		const res = await getFrameStyles(accessToken, modelId)
		if (res && res.status === 200) {
			if (res?.data?.statusCode === 200) {
				setAllStyleCollection(res?.data?.entity)
				setStyleType(res?.data?.entity[0])
			}
		}
		else {
			setAllStyleCollection([])
		}
	}



	useEffect(() => {
		if (modelInitialied) {
			if (!customModelData?.glazing?.texture || customModelData?.glazing?.texture?.length != glazingRef.current.length && !customModelData?.glazing?.isTextureModified) {
				let newData = []
				glazingRef.current.forEach((item, index) => {
					const data = {
						id: null,
						name: '',
						modelName: ``,
						price: '',
						index: index
					}
					newData.push(data)
				})
				const uniqueIndices = new Set(newData.map(item => item.index));
				const uniqueData = Array.from(uniqueIndices).map(index =>
					newData.find(item => item.index === index)
				);
				setCustomModelData((prevModelData) => ({
					...prevModelData,
					glazing: {
						...prevModelData.glazing,
						texture: uniqueData?.length ? uniqueData : [],
						isTextureModified: false
					}
				}))
			}

		}
	}, [glazingRef.current, modelInitialied, customModelData?.glazing?.texture, customModelData?.glazing?.isTextureModified])


	useEffect(() => {
		if (modelInitialied) {
			if (customModelData?.glazing?.texture && customModelData?.glazing?.texture?.length && hardwareType !== "sashHanging") {
				customModelData?.glazing?.texture?.forEach((item) => setGlassTexture(item.modelName, item.index, item.glazingColor, glazingRef, clipGlaze))
			}
		}
	}, [modelInitialied, glazingRef.current])


	const storeGlazingSpec = (color, index) => {
		
		let appliedTexture = customModelData?.glazing?.texture[index]

		if (index === null) {
			appliedTexture = customModelData?.glazing?.texture[0]
		}

		if (appliedTexture && appliedTexture?.id && appliedTexture?.modelName) {
			setGlassTexture(`${appliedTexture?.modelName}`, index, color, glazingRef, clipGlaze)
		} else {
			setGlazing(color, index, shapeFrame, setClipGlaze, glazingRef, clipGlaze);
		}

		if (index === null) {
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				glazing: {
					...prevModelData.glazing,
					spec: prevModelData?.glazing?.spec && prevModelData?.glazing?.spec.map(item => ({
						...item,
						id: glazingSpec?.name !== "None" ? glazingSpec?.id : 0,
						name: glazingSpec?.name !== "None" ? glazingSpec?.name : "None",
						color: color,
						imagePath: glazingSpec?.name !== "None" ? glazingSpec?.capturedImage : null,
						price: glazingSpec?.name !== "None" ? glazingSpec?.price : 0,
						oversizeArea: glazingSpec?.maximumsquare,
						description: glazingSpec?.description
					}))
				}
			}));
		} else {
			let indexToRemove = -1;

			if (customModelData?.glazing?.spec && customModelData?.glazing?.spec?.length > 0) {
				indexToRemove = customModelData?.glazing?.spec.findIndex((item) => item.index === index)
			}

			if (indexToRemove !== -1) {
				customModelData?.glazing?.spec.splice(indexToRemove, 1)
			}

			let data = {
				id: glazingSpec?.name !== "None" ? glazingSpec?.id : 0,
				name: glazingSpec?.name !== "None" ? glazingSpec?.name : "None",
				color: color,
				imagePath: glazingSpec?.name !== "None" ? glazingSpec?.capturedImage : null,
				price: glazingSpec?.name !== "None" ? glazingSpec?.price : 0,
				index: index,
				oversizeArea: glazingSpec?.maximumsquare,
				description: glazingSpec?.description
			}

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				glazing: {
					...prevModelData.glazing,
					spec: [
						...prevModelData?.glazing?.spec || [],
						data,
					],
					isSpecModified: true
				}
			}))
		}
		setMultiSelectRefPoints([])
		setGlazingSpec('')
	}

	useEffect(() => {
		if (multiSelectRefPoints && multiSelectRefPoints.length && texture) {
			multiSelectRefPoints.forEach((item) => {
				let selectedSpec = customModelData?.glazing && customModelData?.glazing?.spec?.find((spec) => spec.index === item.index)
				if (texture && texture !== "None") {
					storeGlassTexture(item.index, selectedSpec?.color)
				} else if (texture === "None") {
					storeGlassTexture(item.index, selectedSpec.color)
					setGlazing(selectedSpec.color, item.index, shapeFrame, setClipGlaze, glazingRef, clipGlaze)
				}
			})
		} else {
			if (texture && texture !== "None") {
				customModelData.glazing.spec.forEach((spec) => {
					storeGlassTexture(null, spec.color)
				})
			} else if (texture === "None") {
				customModelData.glazing.spec.forEach((spec) => {
					setGlazing(spec.color, null, shapeFrame, setClipGlaze, glazingRef, clipGlaze, clipGlaze)
					storeGlassTexture(null, spec.color)
				})
			}
		}
	}, [texture])


	useEffect(() => {
		if (modelInitialied && !customModelData?.frame?.framesAndCoupler && !customModelData?.frame?.framesAndCoupler?.length > 0) {
			if (multiSelectRefPoints && multiSelectRefPoints.length > 0 && glazingSpec && glazingSpec?.name !== "None") {
				multiSelectRefPoints.forEach((item) => {
					storeGlazingSpec(glazingSpec?.hexValue, item?.index)
				})
			} else if (glazingSpec && glazingSpec?.name !== "None") {
				storeGlazingSpec(glazingSpec?.hexValue, null)
			} else if (multiSelectRefPoints && multiSelectRefPoints.length > 0 && glazingSpec?.name === "None") {
				multiSelectRefPoints.forEach((item) => storeGlazingSpec("#B7DBE3", item?.index))
			} else if (glazingSpec && glazingSpec?.name === "None") {
				storeGlazingSpec("#B7DBE3", null)
			}
		}
	}, [glazingSpec, modelInitialied]);


	const storeGlassTexture = (index, specColor) => {
		if (texture !== "None") {
			setGlassTexture(`${servicePath}/ThreeJSModel/Glb/${texture?.modelName?.[0]}`, index, specColor, glazingRef, clipGlaze)
		}
		if (index === null) {
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				glazing: {
					...prevModelData.glazing,
					texture: prevModelData?.glazing?.texture.map(item => ({
						...item,
						id: texture !== "None" ? texture?.id : 0,
						name: texture !== "None" ? texture?.name : "None",
						modelName: texture !== "None" ? `${servicePath}/ThreeJSModel/Glb/${texture?.modelName?.[0]}` : "",
						price: texture !== "None" ? texture?.price : 0,
						modelImage: texture !== "None" ? texture?.modelLinked : "None",
						glazingColor: specColor,
						description: texture !== "None" ? texture?.description : null
					}))
				}
			}));
		} else {
			let indexToRemove = -1;
			if (customModelData?.glazing?.spec && customModelData?.glazing?.spec?.length > 0) {
				indexToRemove = customModelData?.glazing?.texture.findIndex((item) => item.index === index)
			}

			if (indexToRemove !== -1) {
				customModelData?.glazing?.texture.splice(indexToRemove, 1)
			}

			let data = {
				id: texture !== "None" ? texture?.id : 0,
				name: texture !== "None" ? texture?.name : "None",
				modelName: texture !== "None" ? `${servicePath}/ThreeJSModel/Glb/${texture?.modelName?.[0]}` : "",
				price: texture !== "None" ? texture?.price  : 0,
				modelImage: texture !== "None" ? texture?.modelLinked : "None",
				index: index,
				glazingColor: specColor,
				description: texture !== "None" ? texture?.description : null
			}

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				glazing: {
					...prevModelData.glazing,
					texture: [
						...prevModelData?.glazing?.texture || [],
						data
					],
					isTextureModified: true
				}
			}))
		}
	}


	useEffect(() => {
		if (windowHandleOrientation != '' && sashRadioActive == 'Hanging') {
			if (windowHandleOrientation != 'Master/Slave' && windowHandleOrientation != "Slave/Master") {
				orientationClicked.current = true;
				removeMasterSlaveElement(checkSingleSide);

				const defaultProfile = initialProfile?.sash?.find((ele => ele?.defaultProfile === true))
				if (collectionDetails && collectionDetails.typeId == 2) {
					updateSash(1, `${servicePath}/ThreeJSModel/Glb/${defaultProfile?.customePath}`, transoms?.current?.length > 0 ? transomFrame?.current[checkSingleSide] : glassGroup?.current[0], false, checkClickedProfile?.position, true);
				}
				// else {
				// 	updateSash(sashHangingNo, `${servicePath}/ThreeJSModel/Glb/${defaultProfile?.customePath}`, transoms?.current?.length > 0 ? transomFrame?.current[checkSingleSide] : glassGroup?.current[0], false, true);
				// }

				if (headerSelectedItem.name !== "Tilt And Turn Window") {
					setTimeout(() => {
						addWindowHandleOnSash(windowHandleOrientation, checkSingleSide)
					}, 500);
				}
			}
			if (windowHandleOrientation == 'Master/Slave' || windowHandleOrientation === "Slave/Master") {
				orientationClicked.current = false;
				removeMasterSlaveElement(checkSingleSide);
				const defaultProfile = initialProfile?.sash?.find((ele => ele?.defaultProfile === true))

				if (collectionDetails && collectionDetails.typeId == 2) {
					updateSash(1, `${servicePath}/ThreeJSModel/Glb/${defaultProfile?.customePath}`, transoms?.current?.length >= 1 ? transomFrame?.current[checkSingleSide] : glassGroup?.current[0], false, checkClickedProfile?.position, true);
				} else {
					updateSash(sashHangingNo, `${servicePath}/ThreeJSModel/Glb/${defaultProfile?.customePath}`, transoms?.current?.length >= 1 ? transomFrame?.current[checkSingleSide] : glassGroup?.current[0], false, null, true);
				}

				setTimeout(() => {
					hangingMasterSlave(checkSingleSide)
				}, 500);
			}
		}
	}, [windowHandleOrientation, headerSelectedItem])

	useEffect(() => {
		if (deleteSashHanging && collectionDetails && collectionDetails.typeId == 2 && modelInitialied) {
			init(doorBaseModel, 'window')
			setDeleteSashHanging(false)
		}
	}, [deleteSashHanging, modelInitialied])

	useEffect(() => {
		if (deleteSashHanging && collectionDetails && collectionDetails.typeId == 1) {
			deleteSashGroup()
		}
	}, [deleteSashHanging])




	useEffect(() => {
		if (multiSelectRefPoints && multiSelectRefPoints.length > 0 && frameProfiles) {
			multiSelectRefPoints.forEach((item) => storeFrameStyle(item, frameProfiles, false))
			setToggleHelper(false)
		}
	}, [frameProfiles, toggleHelper])



	const storeFrameStyle = (item, checkedItem) => {

		var indexToRemove = -1;

		if (customModelData?.frameProfileData && customModelData?.frameProfileData.length > 0) {
			indexToRemove = customModelData?.frameProfileData?.findIndex(obj => {
				return obj.index === item.index;
			});
		}

		if (indexToRemove !== -1) {
			customModelData?.frameProfileData.splice(indexToRemove, 1);
		}

		var finalIndex = 0;

		frameStyleRef.current.forEach((child, index) => {
			if (child == item?.data) {
				finalIndex = index;
			}
		});

		const orientationType = item?.data?.name.split("Frame")[1];

		const orientation = orientationType === "Right"
			? "Left"
			: orientationType === "Left"
				? "Right"
				: orientationType;

		let newData = {
			id: checkedItem?.id,
			index: finalIndex,
			url: `${servicePath}/ThreeJSModel/Glb/${checkedItem?.customePath}`,
			width: checkedItem?.width,
			height: checkedItem?.height,
			shapedWidth: checkedItem?.width / 2,
			shapedHeight: checkedItem?.height / 2,
			side: item?.data?.name,
			orientation: orientation,
			name: checkedItem?.name,
			description: frameProfileDefault?.description,
			price: checkedItem?.price,
			frameType: "Frame profile",
			externalVisibleSurface: checkedItem?.externalVisibleSurface,
			internalVisibleSurface: checkedItem?.internalVisibleSurface,
			paintSurfaceArea: checkedItem?.paintSurfaceArea,
			barLength: checkedItem?.barLength,
			weight: checkedItem?.weight,
			usableLength: checkedItem?.usableLength,
			imagePath: checkedItem?.imagePath,
			externalPaintSurfaceArea: checkedItem?.externalPaintSurfaceArea,
			internalPaintSurfaceArea: checkedItem?.internalPaintSurfaceArea,
			profileTypePrice: checkedItem?.profileTypePrice,
		}


		setCustomModelData((prevData) => ({
			...prevData,
			frameProfileData: [
				...prevData.frameProfileData || [],
				newData
			]
		}));

		let thresholdObj = {
			...newData,
			isThresholdAdded: false
		}

		if (threshHolData?.length > 0 && orientation === "Bottom") {
			setCustomModelData((prevData) => ({
				...prevData,
				frame: {
					...prevData.frame,
					threshold: thresholdObj
				}
			}))
		}

		addFrameStyle(`${servicePath}/ThreeJSModel/Glb/${checkedItem?.customePath}`, item?.data?.position, item?.data?.name, checkedItem, gltfModel, sceneRef, Frame, profileJointDetails, frameStyleTop, frameStyleLeft, frameStyleBottom, frameStyleRight, setMultiSelectRefPoints, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal, headerSelectedItem)
	}

	useEffect(() => {
		if (modelInitialied && frameProfileDefault && !customModelData?.frameProfileData?.length) {
			loadDefaultFrameProfile()
		}
	}, [[modelInitialied, frameStyleRef, frameProfileDefault, threshHolData]])


	useEffect(() => {
		if (modelInitialied && frameStyleRef && frameStyleRef.current.length > 0 && frameProfileDefault) {
			if (customModelData?.frameProfileData && customModelData?.frameProfileData?.length > 0) {
				customModelData?.frameProfileData.forEach((item) => {
					addFrameStyle(item.url, frameStyleRef.current[item?.index]?.position, item.side, item, gltfModel, sceneRef, Frame, profileJointDetails, frameStyleTop, frameStyleLeft, frameStyleBottom, frameStyleRight, setMultiSelectRefPoints, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal, headerSelectedItem)
				})
			}
		}
	}, [modelInitialied, frameStyleRef, frameProfileDefault, threshHolData])


	useEffect(() => {
		setFrameSize(customModelData?.frameProfileData)
	}, [customModelData])


	const loadDefaultFrameProfile = () => {
		let thresholdObj = {}
		var allDefaultFrame = []
		let newData;

		frameStyleRef.current.forEach((child, index) => {
			const orientationType = child?.name.split("Frame")[1];

			const orientation = orientationType === "Right"
				? "Left"
				: orientationType === "Left"
					? "Right"
					: orientationType;

			let newData = {
				id: frameProfileDefault?.id,
				index: index,
				url: `${servicePath}/ThreeJSModel/Glb/${frameProfileDefault?.customePath}`,
				width: frameProfileDefault?.width,
				height: frameProfileDefault?.height,
				side: child?.name,
				orientation: orientation,
				name: frameProfileDefault?.name,
				price: frameProfileDefault?.price,
				description: frameProfileDefault?.description,
				imagePath: frameProfileDefault?.imagePath,
				frameType: "Frame profile",
				externalVisibleSurface: frameProfileDefault?.externalVisibleSurface,
				internalVisibleSurface: frameProfileDefault?.internalVisibleSurface,
				// paintSurfaceArea: frameProfileDefault?.paintSurfaceArea,
				barLength: frameProfileDefault?.barLength,
				weight: frameProfileDefault?.weight,
				usableLength: frameProfileDefault?.usableLength,
				externalPaintSurfaceArea: frameProfileDefault?.externalPaintSurfaceArea,
				internalPaintSurfaceArea: frameProfileDefault?.internalPaintSurfaceArea,
				profileTypePrice: frameProfileDefault?.profileTypePrice,

			}
			if (threshHolData?.length > 0 && orientation === "Bottom") {
				thresholdObj = {
					...newData,
					isThresholdAdded: false
				}
			}
			if (threshHolData?.length > 0 && orientation === "Bottom") {
				thresholdObj = {
					...newData,
					isThresholdAdded: false
				}
			}

			allDefaultFrame.push(newData);

			// if(headerSelectedItem.name === "Oriel Window"){
			// 	allDefaultFrame = allDefaultFrame.filter((item) =>  item.orientation !== "Bottom")
			// }

			addFrameStyle(newData.url, frameStyleRef.current[index]?.position, frameStyleRef?.current[index]?.name, newData, gltfModel, sceneRef, Frame, profileJointDetails, frameStyleTop, frameStyleLeft, frameStyleBottom, frameStyleRight, setMultiSelectRefPoints, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal, headerSelectedItem)

			if (threshHolData?.length > 0 && orientation === "Bottom") {
				setCustomModelData((prevData) => ({
					...prevData,
					frame: {
						...prevData.frame,
						threshold: thresholdObj
					}
				}))
			}
		});

		if (allDefaultFrame && allDefaultFrame.length === 4) {

			setCustomModelData((prevData) => ({
				...prevData,
				frameProfileData: headerSelectedItem.name === "Oriel Window"
					? allDefaultFrame.map((frame) =>
						frame.orientation === "Bottom"
							? {
								...frame,
								// Set all properties to empty except `orientation` and `url`
								...Object.keys(frame).reduce((acc, key) => {
									if (key === "side" || key === "url") {
										acc[key] = frame[key];
									} else {
										acc[key] = ""; // You can also use null or undefined
									}
									return acc;
								}, {})
							}
							: frame
					)
					: allDefaultFrame
			}));

			setTimeout(() => {
				if (!quotationId && modelId) {
					saveModelData(accessToken, modelId, selectedAnimation?.id, customModelData, navigate, setMessage, setMessageType)
				}

				if (quotationId && modelId && receivedProductId) {
					saveModelDataQuotation(accessToken, modelId, selectedAnimation?.id, customModelData, quotationId, receivedProductId, navigate, setMessage, setMessageType)
				}
			}, 500);
		}
	}

	const sashSashStyleDataStore = (item) => {

		const worldPosition = new THREE.Vector3();
		item?.data?.getWorldPosition(worldPosition);
		addSashStyle(`${servicePath}/ThreeJSModel/Glb/${sashProfileType?.customePath}`, item.data, item?.data?.name, sashProfileType, false, item.index)

		var indexToRemove = -1;
		indexToRemove = replacedSashProfile.current.findIndex(obj => {
			return obj.index === item.index;
		});

		// If the index is found, remove the object at that index
		if (indexToRemove !== -1) {
			replacedSashProfile.current.splice(indexToRemove, 1);
		}

		var finalIndex = 0;
		sashList.current.forEach((child, index) => {
			if (child == item?.data) {
				finalIndex = index;
			}
		});

		var sideName = item?.data?.name

		const orientations = sideName.includes('Right') ? "Right" : sideName.includes('Left') ? "Left" : sideName.includes('Left') ? "Left" : sideName.includes('Top') ? "Top" : sideName.includes('Bottom') ? "Bottom" : '';

		// let sashDataIndex = -1
		// if (customModelData && customModelData.sashData && customModelData.sashData.length) {
		// 	sashDataIndex = customModelData.sashData.findIndex((obj) => obj.index === item.index)
		// }
		// 

		// if (sashDataIndex !== -1) {
		// 	customModelData.sashData.splice(sashDataIndex, 1)
		// }

		// console.log("DESSS11--->", sashProfileType);

		let data = {
			id: sashProfileType.id,
			index: finalIndex,
			url: `${servicePath}/ThreeJSModel/Glb/${sashProfileType?.customePath}`,
			width: sashProfileType.width,
			height: sashProfileType.height,
			side: item?.data?.name,
			price: sashProfileType?.price,
			description: sashProfileType?.description,
			name: sashProfileType?.name,
			frameType: "Sash profile",
			orientation: orientations,
			imagePath: sashProfileType?.imagePath,
			internalPaintSurfaceArea: sashProfileType?.internalPaintSurfaceArea,
			externalPaintSurfaceArea: sashProfileType?.externalPaintSurfaceArea,
			weight: sashProfileType?.weight,
			maxWeight: sashProfileType?.maxWeight,
			alert: false,
			visible: true,
			widthFormula : sashProfileType?.widthFormula
		}


		replacedSashProfile.current.push(data);

		setCustomModelData((prevData) => ({
			...prevData,
			sashData: replacedSashProfile.current
		}))
	}


	useEffect(() => {
		if (multiSelectRefPoints && multiSelectRefPoints?.length && sashProfileType) {
			multiSelectRefPoints.forEach((item) => {
				sashSashStyleDataStore(item)
			})
			setToggleHelper(false)
		}
	}, [sashProfileType, toggleHelper, sashList.current])

	useEffect(() => {
		if (multiSelectRefPoints && multiSelectRefPoints?.length && sashProfileType && sashProfileType.name === "Locking Plate") {
			multiSelectRefPoints.forEach((item) => addLockingPlate(item, sashProfileType))
			setToggleHelper(false)
		}
	}, [sashProfileType, toggleHelper, sashList.current])

	useEffect(() => {
		if (resizeSashStyles && (customModelData?.sashData?.length && modelInitialied && sashList?.current?.length > 0 && customModelData?.sashSize && customModelData?.sashSize.length > 0)) {
			setResizeSashStyles(false)

			customModelData.sashData.forEach((item) => {
				let data = {
					height: item.height,
					width: item.width
				}
				addSashStyle(item.url, sashList.current[item.index], "adf", data, true, item.index);
			})
		}
	}, [resizeSashStyles])


	const getCornerRefPoints = () => {
		testRef.current = []
		newFrameRefPoints.current = []
		sceneRef.current && sceneRef.current.traverse((child) => {
			if (!manufacturingToggle) {
				let jointRef = []
				if (child.name.includes("ProfileJoints")) {
					jointRef.push(child)
					testRef.current.push(child)
					const uniqueData = removeDuplicates(testRef.current)
					testRef.current = uniqueData
				}
			} else {
				if (child.name.includes("ProfileJoints") && manufacturingToggle) {
					let refPointsData = []
					refPointsData = [...frameStyleRef.current]
					testRef.current.push(child)
					const uniqueData = removeDuplicates(testRef.current)
					testRef.current = uniqueData
					newFrameRefPoints.current = refPointsData
				}
			}

		})
	}





	const createGlassMaterial = (glassOutline, rotation, leftOffset, topOffset, frameType) => {
		const extrudeSettings = {
			steps: 10,
			depth: 0.01,
			bevelEnabled: false,
		};


		const glassGeometry = new THREE.ExtrudeGeometry(glassOutline, extrudeSettings);

		// Create a material and mesh
		const glassMaterialOld = sceneRef.current.getObjectByProperty('name', "GlassPanel034").material;
		const glassMaterial = new THREE.MeshPhongMaterial({
			color: glassMaterialOld.color,
			transparent: true,
			needsUpdate: true
		});

		glassMaterial.opacity = 0.3;
		const glassMesh = new THREE.Mesh(glassGeometry, glassMaterial);
		glassMesh.name = "svgGlass";

		const glassMeshBoundingBox = new THREE.Box3().setFromObject(glassMesh);
		const depth = glassMeshBoundingBox.max.x - glassMeshBoundingBox.min.x

		glassMesh.scale.x -= leftOffset - 0.01
		glassMesh.scale.y -= topOffset

		glassMesh.position.z -= leftOffset;


		if (frameType === "right") {
			glassMesh.position.x += depth / 2
		} else {
			glassMesh.position.x -= depth / 2
		}


		// Apply rotation if necessary
		if (rotation) {
			glassMesh.rotation.set(rotation.x, -(Math.PI / 2), rotation.z);
		}
		sceneRef.current.add(glassMesh);
	}



	function createGlassOutline(a, b, c, d) {

		const path = new THREE.Shape();
		// Add top frame
		path.moveTo(a.z, a.y); // Start point of topFrame
		path.lineTo(b.z, b.y); // End point of topFrame
		path.lineTo(c.z, c.y); // End point of topFrame
		path.lineTo(d.z, d.y)
		path.lineTo(a.z, a.y)


		return path;
	}

	const scaleAddedFrames = (isLeft, isTop) => {

		const topFrame = addedFrame.current.find((item) => item.name.includes("topPerpendicular"))
		const leftFrame = addedFrame.current.find((item) => item.name.includes("rightPerpendicular"))
		const rightFrame = addedFrame.current.find((item) => item.name.includes("leftPerpendicular"))

		if (topFrame) {
			topFrame.traverse((child) => {
				if (child.name.includes("FrameLeft")) {
					const topLeftBB = new THREE.Box3().setFromObject(child);
					perpFrameTopBoundingBox.current.leftProfile = topLeftBB
				}

				if (child.name.includes("FrameRight")) {
					const topRightBB = new THREE.Box3().setFromObject(child);
					perpFrameTopBoundingBox.current.rightProfile = topRightBB
				}
			})
		}

		if (leftFrame && !isLeft) {

			leftFrame.traverse((child) => {

				if (child.name.includes("GlassPanel")) {
					child.scale.set(0, 0, 0)
				}

				if (child.name === "FrameLeft") {
					const profileBoundingBox = new THREE.Box3().setFromObject(child);

					const currentTop = profileBoundingBox.max.y;
					cornersLeftFrame.current.currentBottom = new THREE.Vector3(profileBoundingBox.min.x, profileBoundingBox.min.y, profileBoundingBox.max.z)
					const requiredScale = (topLeftCorner.current.y - profileBoundingBox.min.y) / (currentTop - profileBoundingBox.min.y);
					let initialHeight = profileBoundingBox.min.y;
					child.scale.x *= requiredScale;
					cornersLeftFrame.current.glassScale = requiredScale

					const updatedBoundingBox = new THREE.Box3().setFromObject(child);

					const bottomOffset = initialHeight - updatedBoundingBox.min.y;
					child.position.y += bottomOffset;

					const updatedBoundingBox2 = new THREE.Box3().setFromObject(child);
					cornersLeftFrame.current.frameLeftBoundingBox = updatedBoundingBox2

				}


				if (child.name === "FrameRight") {
					const profileBoundingBox = new THREE.Box3().setFromObject(child);
					cornersLeftFrame.current.frameRightBoundBox = profileBoundingBox
					cornersLeftFrame.current.currentBottomR = new THREE.Vector3(profileBoundingBox.min.x, profileBoundingBox.min.y, profileBoundingBox.max.z)

				}


				if (child.name === "FrameTop") {
					const topProfileBoundingBox = new THREE.Box3().setFromObject(child);
					const topFrameRight = new THREE.Vector3(topProfileBoundingBox.max.x, topProfileBoundingBox.max.y, topProfileBoundingBox.max.z)
					cornersLeftFrame.current.currentTopR = topFrameRight
					const diffX = topLeftCorner.current.x - topFrameRight.x;
					const diffY = topLeftCorner.current.y - topFrameRight.y;

					const angleRadians = Math.atan2(diffX - diffY);
					const angleDegrees = THREE.MathUtils.radToDeg(angleRadians);

					child.rotation.z *= angleDegrees

					const newBoundingBox = new THREE.Box3().setFromObject(child);
					cornersLeftFrame.current.frameTopBoundingBox = newBoundingBox
					const newTopFrameRight = new THREE.Vector3(newBoundingBox.max.x, newBoundingBox.max.y, newBoundingBox.max.z);

					const offsetX = topFrameRight.x - newTopFrameRight.x;
					const offsetY = topFrameRight.y - newTopFrameRight.y;

					child.position.x -= offsetX;
					child.position.y -= offsetY;

				}


				if (child.name === "SpaceBarTop011") {
					child.scale.set(0, 0, 0)
				}


			});



			const leftOffset = cornersLeftFrame.current.frameRightBoundBox.max.x - cornersLeftFrame.current.frameRightBoundBox.min.x
			const topOffset = perpFrameTopBoundingBox.current.leftProfile.max.x - perpFrameTopBoundingBox.current.leftProfile.min.x

			const rotation = new THREE.Vector3(0, 0, 0);
			const glassOutline = createGlassOutline(cornersLeftFrame.current.currentTopR, topLeftCorner.current, cornersLeftFrame.current.currentBottom, cornersLeftFrame.current.currentBottomR)

			createGlassMaterial(glassOutline, rotation, leftOffset, topOffset, "left")
		}

		if (rightFrame) {
			rightFrame.traverse((child) => {
				if (child.name.includes("GlassPanel")) {
					child.scale.set(0, 0, 0)
				}
				if (child.name.includes("FrameLeft")) {
					const profileBoundingBox = new THREE.Box3().setFromObject(child);
					const currentTop = profileBoundingBox.max.y;

					cornersRightFrame.current.frameRightBoundBox = profileBoundingBox

					cornersRightFrame.current.currentBottom = new THREE.Vector3(profileBoundingBox.min.x, profileBoundingBox.min.y, profileBoundingBox.max.z)
					const requiredScale = (topLeftCorner.current.y - profileBoundingBox.min.y) / (currentTop - profileBoundingBox.min.y);
					let initialHeight = profileBoundingBox.min.y;
					child.scale.x *= requiredScale;
					const updatedBoundingBox = new THREE.Box3().setFromObject(child);
					const bottomOffset = initialHeight - updatedBoundingBox.min.y;
					child.position.y += bottomOffset;
				}

				if (child.name === "FrameRight") {
					const profileBoundingBox = new THREE.Box3().setFromObject(child);
					cornersRightFrame.current.currentBottomL = new THREE.Vector3(profileBoundingBox.min.x, profileBoundingBox.min.y, profileBoundingBox.max.z)
				}

				if (child.name === "FrameTop") {
					let topProfileBoundingBoxR = new THREE.Box3().setFromObject(child);
					let topFrameLeftR = new THREE.Vector3(topProfileBoundingBoxR.max.x, topProfileBoundingBoxR.max.y, topProfileBoundingBoxR.max.z)
					cornersRightFrame.current.currentTopL = topFrameLeftR

					let diffXR = topFrameLeftR.x - topRightCorner.current.x
					let diffYR = topFrameLeftR.y - topRightCorner.current.y

					let angleRadiansR = Math.atan2(diffXR - diffYR);
					let angleDegreesR = THREE.MathUtils.radToDeg(angleRadiansR);
					child.rotation.z *= angleDegreesR
				}

				if (child.name === "SpaceBarTop011") {
					child.scale.set(0, 0, 0)
				}
			})

			const leftOffset = cornersRightFrame.current.frameRightBoundBox.max.z - cornersRightFrame.current.frameRightBoundBox.min.z
			const topOffset = perpFrameTopBoundingBox.current.rightProfile.max.x - perpFrameTopBoundingBox.current.rightProfile.min.x

			const rotation = new THREE.Vector3(0, 0, 0);
			const glassOutline = createGlassOutline(cornersRightFrame.current.currentTopL, topRightCorner.current, cornersRightFrame.current.currentBottom, cornersRightFrame.current.currentBottomL)
			createGlassMaterial(glassOutline, rotation, leftOffset, topOffset, "right")

		}


	}

	useEffect(() => {
		if (widthLine.current) {
			widthLine.current.visible = false;
			heightLine.current.visible = false;
		}
	}, [layoutSizing])

	const handleSliderChange = () => {
		const inputValue = sliderRef.current.value;
		// rotateObjectAroundPoint(inputValue)
		openWindow(inputValue)
	}

	const storeHardware = (element, pos, type) => {
		if (type === "door handle") {
			let cylinder = allHardware?.find((item) => item?.name?.toLowerCase() === "cylinder")
			var indexToRemove = -1;
			indexToRemove = addedHandles.current.findIndex(obj => obj.hasOwnProperty(pos));

			// If the index is found, remove the object at that index
			if (indexToRemove !== -1) {
				addedHandles.current.splice(indexToRemove, 1);
			}
			var finalIndex = 0;
			sashGroup?.current?.forEach((child, index) => {
				if (child == pos) {
					finalIndex = index;
				}
			});

			let data = {
				id: element?.id,
				index: finalIndex,
				file: `${servicePath}/ThreeJSModel/Glb/${element?.modelFilePath}`,
				price: element?.price,
				name: element?.name,
				color: "",
				position: pos.position,
				side: 0,
				horizontalPos: 0,
				verticalPos: 0,
				imagePath: "",
				type: "handle"

			}

			let cylinderData = {
				id: cylinder?.hardwareStyles[0]?.id,
				index: finalIndex,
				file: `${servicePath}/ThreeJSModel/Glb/${cylinder?.hardwareStyles[0]?.modelFilePath}`,
				price: cylinder?.hardwareStyles[0]?.price,
				name: cylinder?.hardwareStyles[0]?.name,
				color: "",
				position: pos.position,
				side: 0,
				horizontalPos: 0,
				verticalPos: 0,
				imagePath: cylinder?.hardwareStyles[0].image,
				type: "cylinder"
			}

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					handle: [
						...(prevModelData.hardware?.handle || []),
						data
					],
					cylinder: [
						...(prevModelData.hardware?.cylinder || []),
						cylinderData
					]
				},
			}));
		}

		if (type === 'bar handles offset') {

			let finalIndex = 0;
			sashGroup.current.forEach((child, index) => {
				if (child.position === pos) {
					finalIndex = index
				}
			})

			let availableIndexesArr = addedBarHandles.current && addedBarHandles?.current?.filter((obj) => obj.position === pos)

			let indexToRemove = -1;

			if (availableIndexesArr?.length > 1 && addedBarHandles?.current?.length) {
				indexToRemove = addedBarHandles?.current?.findIndex((obj) => obj.position === pos)
				addedBarHandles.current.splice(indexToRemove, 1)
			}

			let data = {
				id: 0,
				file: `${servicePath}/ThreeJSModel/Glb/${element?.modelFilePath}`,
				name: element.name,
				color: "",
				type: type,
				index: finalIndex,
				side: 0,
				position: pos,
				horizontalPos: 0,
				verticalPos: 0,
				price: element.price,
				imagePath: "",
				isOutside: availableIndexesArr.length ? false : true
			}

			addedBarHandles.current.push(data)

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					barHandlesOffset: addedBarHandles.current
				}
			}))
		}
	}


	const addLockingPlate = (refs, data) => {

		const gltfLoader = new GLTFLoader()
		//add lock plate
		gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${data?.customePath}`, function (gltf) {
			gltf.scene.position.y = refs.data.position.y;

			const boundingBoxPlate = new THREE.Box3().setFromObject(gltf.scene);
			const plateHeight = boundingBoxPlate.max.y - boundingBoxPlate.min.y;
			const plateWidth = boundingBoxPlate.max.x - boundingBoxPlate.min.x

			const boundingBoxGlass = new THREE.Box3().setFromObject(refs.data);

			if (Math.abs(boundingBoxGlass.min.x) < Math.abs(boundingBoxGlass.max.x)) {
				gltf.scene.position.x = boundingBoxGlass.min.x + (plateWidth / 2)
			} else {
				gltf.scene.position.x = boundingBoxGlass.max.x - (plateWidth / 2)
			}

			const glassHeight = boundingBoxGlass.max.y - boundingBoxGlass.min.y;

			gltf.scene.scale.y = glassHeight / plateHeight;
			allLockingPlates.current.push(gltf.scene)
			setDoorHandleData((prevData) => [...prevData, gltf.scene])

			// applying sash colors for internal and external layers
			gltf.scene.traverse((child) => {
				if (child.isMesh) {
					if (child.name.includes("External")) {
						externalFrameRef.current.push(child);
					} else {
						internalFrameRef.current.push(child);
					}
				}
			})

			setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

			sceneRef.current.add(gltf.scene);
		})
	}

	const handleAddHardware = (element, pos) => {
		if (hardwareType?.name?.toLowerCase() === "door handle") {
			addHandle(`${servicePath}/ThreeJSModel/Glb/${element?.modelFilePath}`, pos, false, element?.price, element?.imagePath, element?.id)
			storeHardware(element, pos, "door handle")
		} else if (hardwareType?.name?.toLowerCase() === "trickle vent") {
			addElement(`${servicePath}/ThreeJSModel/Glb/${element?.modelFilePath}`, pos, false, element)
		} else if (hardwareType?.name?.toLowerCase() === "spyhole" || hardwareType?.name?.toLowerCase() === "letterplate" || hardwareType?.name?.toLowerCase() === "knocker") {
			addTopElements(`${servicePath}/ThreeJSModel/Glb/${element?.modelFilePath}`, pos, element, false)
		} else if (hardwareType?.name?.toLowerCase() === 'escutcheon') {
			addDoubleSidedElement(`${servicePath}/ThreeJSModel/Glb/${element?.modelFilePath}`, pos, element, false)
		} else if (hardwareType?.name?.toLowerCase() === "numeral") {
			addNumerals("0", pos, false, element)
		} else if (hardwareType.name === 'hinge') {
			if (pos?.name.includes("verticalBarRight")) {
				addHinge(pos, true, element)
			} else if (pos.name.includes("verticalBarLeft")) {
				addHinge(pos, false, element)
			}
		} else if (hardwareType.name === 'bar handles offset') {
			addBarHandleOffset(`${servicePath}/ThreeJSModel/Glb/${element?.modelFilePath}`, pos, element)
			storeHardware(element, pos.position, "bar handles offset")
		}
		else {
			frameClick(element, pos);
			setFrameDrop(false)
			setStyleDrop(false)
		}
	}

	// function to initialize model
	function init(baseModel, modelType) {

		setModelInitialied(false)

		if (scene == null) {
			renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
			renderer.localClippingEnabled = true;
			camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.25, 30);

			scene = new THREE.Scene();
			renderer.localClippingEnabled = true;
		}

		windowWidth.current = 2500;
		windowHeight.current = 2100;
		rendererInitialised.current = 4;
		cameraRef.current = camera;
		const container = containerRef.current;
		sceneRef.current = scene;
		initRenderer(container);
		initScene();
		setLoading(true);
		loader = new GLTFLoader();
		glassGroup.current = [];
		glazingRef.current = [];
		frameStyle.current = [];
		frameSash.current = [];
		windowRef.current = [];
		frameStyleRef.current = [];
		bead.current = [];
		Frame.current = [];
		transoms.current = [];
		sashGroup.current = [];
		SpaceBar.current = [];
		handleExternal.current = [];
		handleInternal.current = [];
		handleCylinder.current = [];
		handlePosition.current = [];
		handleObjects.current = [];
		tickleObjects.current = [];
		tickleVentPosition.current = [];
		tickleVentModels.current = [];
		cylinderPosition.current = [];
		addedFrame.current = [];
		addedFramePosition.current = [];
		transomFrame.current = [];
		addedSash.current = [];
		glassText.current = [];
		orientationClicked.current = false;
		radioButtonClicked.current = false;

		// if (customModelData?.numberOfSash && customModelData?.numberOfSash?.number) {
		// 	sashHangingAmount.current = customModelData?.numberOfSash?.number;
		// }
		setUniqueSideRefernce([]);
		// servicePath}/ThreeJSModel/Glb/${currentModel?.modelLinked

		if (baseModel) {
			loader.load(baseModel, function (gltf) {

				setInitialLoad(false)

				setModelScene(gltf.scene)

				gltfModel.current = gltf.scene;

				const scale = new THREE.Vector3(1, 1, 1);

				gltf.scene.scale.copy(scale);

				gltf.scene.position.set(0, 0, 0);

				const cloudTexture = new THREE.TextureLoader().load(CloudTexturePng);
				cloudTexture.wrapS = THREE.RepeatWrapping;
				cloudTexture.wrapT = THREE.RepeatWrapping;
				cloudTexture.repeat.set(0, 0);

				const glassMaterial = new THREE.MeshPhongMaterial({
					transparent: true,
					opacity: 0.3,
					reflectivity: 0,
					map: cloudTexture,
					// color: 0xE0F2F8,
				});

				// Enable shadow for the GLTF model
				gltf.scene.traverse((child) => {


					if (child instanceof THREE.Mesh) {
						child.castShadow = true;
						if (child.name.includes("GlassPanel")) {
							glass1.current = child;
							glassGroup.current.push(child);
							glazingRef.current.push(child);
							defaultGlassMaterial.current = child.material;

							// Set glass material to the created material
							child.material = glassMaterial;
							child.scale.y += 3

							const newObject = new THREE.Object3D();
							const worldPosition = new THREE.Vector3();
							child.getWorldPosition(worldPosition);
							newObject.position.copy(worldPosition);
							setUniqueSideRefernce((prevReference) => [...prevReference, newObject])
							setPanelRefPoints((prevPanelRef) => [...prevPanelRef, child])
						}

						if (child.name.includes("Internal")) {
							internalFrameRef.current.push(child);
						}

						if (child.name.includes("External")) {
							externalFrameRef.current.push(child);
							defaultFrameMaterial.current = child.material;
						}
					}

					if (child.name.includes("InterlockSashX")) {
						windowRef.current.push(child);
					}

					// if (child.name.includes("HandleExt")) {
					//   handleExternal.current.push(child);
					//   handlePosition.current.push(new THREE.Vector3(child.position.x, child.position.y, child.position.z));
					//   setNumHardware(handleExternal.current.length)
					// }

					// if (child.name.includes("HandleInt")) {
					//   handleInternal.current.push(child);
					// }

					if (child.name.includes("Space")) {
						SpaceBar.current.push(child);
					}

					if (child.name.includes("FrameRight") || child.name.includes("FrameLeft") || child.name.includes("FrameTop") || child.name.includes("FrameBottom")) {
						frameStyleRef.current.push(child);
						Frame.current.push(child);
					}
					if (child.name.includes('SashProfileRef')) {
						setSashProfileRef(prevSashProfile => [...prevSashProfile, child])
					}


					if (child.name.includes("FrameTop")) {
						tickleVentReference.push(child);
						tickleVentRef.current = tickleVentReference;
						setTickleVent(prevTickleVent => [...prevTickleVent, child]);
						setSpyHoleRefPoints(prevSpyHole => [...prevSpyHole, child])
						setEscutcheonRefPoints(prevEsc => [...prevEsc, child])
						if (hardwareType?.type === "trickle") {
							setNumHardware(tickleVentRef?.current?.length)
						}
					}

					if (child.name.includes("FrameRight") || child.name.includes("FrameLeft") || child.name.includes("FrameTop") || child.name.includes("FrameBottom")) {
						// testRef.current = tickleVentReference
						addFrameRef.current.push(child)
						setNewFrameData(prevFrameData => [...prevFrameData, child]);
						addPerpendicularFrame(addFrameRef)
					}

					if (child.name.includes("FrameTop")) {
						frameStyleTop.current = child
					}

					if (child.name.includes("FrameBottom")) {
						frameStyleBottom.current = child
					}

					if (child.name.includes("FrameLeft")) {
						frameStyleLeft.current = child
					}

					if (child.name.includes("FrameRight")) {
						frameStyleRight.current = child
					}
					// if (hardwareType && hardwareType?.name === "Door Handle") {
					if (child.name.includes("FrameTop")) {
						handleReference.push(child);
						handleRef.current = handleReference;
						// testRef.current = handleReference
						setDoorHandleData(prevDoorHandle => [...prevDoorHandle, child])
					}
					// }

					// if (child.name.includes("HandleRef")) {
					//   handleReference.push(child);
					//   // testRef.current = handleReference
					// }

					if (child.name.includes("HeightRef")) {
						heightRef.push(child);
					}

					if (child.name.includes("WidthRef")) {
						widthRef.push(child);
					}
				});

				if (sceneRef.current) {
					sceneRef.current.add(gltf.scene);
				}

				if (customModelData && customModelData.windowData) {
					const defaultProfile = initialProfile?.sash?.find((ele => ele?.defaultProfile === true))

					// if (customModelData?.windowData && customModelData?.windowData?.length) {
					// 	customModelData?.windowData.forEach((item) => {
					// 		// if (item.hangingType === "Master/Slave" || item.hangingType === "Master/Slave") {
					// 		// 	updateSash(1, `${servicePath}/ThreeJSModel/Glb/${defaultProfile?.customePath}`, transoms?.current?.length >= 1 ? transomFrame?.current[checkSingleSide] : glassGroup?.current[0], true, null, false)
					// 		// 	setTimeout(() => {
					// 		// 		hangingMasterSlave(customModelData?.windowData?.index)
					// 		// 	}, 1000);
					// 		// }
					// 	})
					// }
				}

				if (customModelData && modelWidth && modelHeight) {
					resizeWindow(parseInt(modelWidth), parseInt(modelHeight), modelType, true)
				}

				// setTimeout(() => {
				// 	if (customModelData?.frame?.framesAndCoupler && customModelData?.frame?.framesAndCoupler.length > 0) {
				// 		customModelData?.frame?.framesAndCoupler.forEach((item) => {
				// 			if (item.frameDirection === "FrameLeft") {
				// 				addFrame(true, item.coupler)
				// 			} else if (item.frameDirection === "FrameRight") {
				// 				addFrame(true, item.coupler)
				// 			} else if (item.frameDirection === "FrameTop" && headerSelectedItem?.name !== "Eaves Frame") {
				// 				addFrameTop(true, item.coupler)
				// 			} else if (item.frameDirection === "FrameBottom") {
				// 				addFrameTop(false, item.coupler)
				// 			} else if (item.frameDirection === "FrameLeftPerpendicular") {
				// 				addFramePerpendicular(false, item.coupler, item.angle)
				// 			} else if (item.frameDirection === "FrameRightPerpendicular") {
				// 				addFramePerpendicular(true, item.coupler, item.angle)
				// 			} else if (item.frameDirection === "FrameTop" && headerSelectedItem?.name === "Eaves Frame") {
				// 				addEvesFrame(item.angle)
				// 			}
				// 		})
				// 	}
				// }, 1000);

				if (modelType != 'Hanging') {
					setModelTypeHanging(false)
				}

				animate();
				setFrameCameraView(0, 1, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom);
				render();
				addInitialTransomFrame()
				setTansomsReference(transomFrame.current)

				setLoading(false);
				setModelInitialied(true)

				// if (customModelData.storedTransoms && customModelData.storedTransoms.length > 0 && glazingRef?.current?.length) {
				// 	customModelData.storedTransoms.forEach(item => {
				// 		// Call addTransom for each item, passing its index and horizontal values
				// 		setTimeout(() => {
				// 			addTransom(item.index, item.horizontal, true, true, item, item.position);
				// 		}, 1000);
				// 	});
				// }

				if (customModelData.storedTransoms && customModelData.storedTransoms.length > 0) {

					// if(glazingRef?.current?.length === 1){
					// 	transomFrame.current = glazingRef.current[0]
					// }
					customModelData.storedTransoms.forEach(item => {
						if (transomFrame.current.length) {
							setTimeout(() => {
								addTransom(item.index, item.horizontal, true, true, item, item.position);
							}, 1000);
						}
					});
				}

				if (customModelData?.frame?.threshold && customModelData?.frame?.threshold?.isThresholdAdded) {
					addThreshold(customModelData?.frame?.threshold);
				}





			});
		}

		initControls();

		setTimeout(() => {
			setModelVisible(true)
		}, 2000); // 1500
	}

	useEffect(() => {
		if (modelInitialied && customModelData?.frameProfileData?.length > 0 && frameStyleTop.current && frameStyleLeft.current && frameStyleBottom.current && frameStyleRight.current) {
			setTimeout(() => {
				addCornerJoint(modelScene, cornerJointModel, sceneRef, frameStyleTop, frameStyleLeft, frameStyleBottom, frameStyleRight, profileJointDetails, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal, headerSelectedItem)
				setJointsApplied(true)
			}, 200); // 100
		}
	}, [modelInitialied, customModelData?.frameProfileData, frameStyleBottom.current])

	useEffect(() => {
		if (hardwareType === "Shaped Frame" && modelInitialied) {
			setTimeout(() => {
				getCornerRefPoints()
			}, 500);
		}
	}, [modelInitialied, hardwareType])

	const setTransomRefs = (initialLoad) => {
		transomFrame.current = []

		if (glazingRef?.current?.length === 1) {

			const newGeometry = new THREE.BoxGeometry();
			const newMaterial = new THREE.MeshBasicMaterial({ visible: false }); // { visible: false } { color: 0x00bfff }
			const newObject = new THREE.Mesh(newGeometry, newMaterial);

			const worldPosition = new THREE.Vector3();

			glazingRef?.current[0].getWorldPosition(worldPosition);

			newObject?.position?.set(worldPosition.x, worldPosition.y, worldPosition.z);

			const boundingBox = new THREE.Box3().setFromObject(glazingRef?.current[0]);
			const height = boundingBox.max.y - boundingBox.min.y;
			const width = boundingBox.max.x - boundingBox.min.x;

			const boundingBox2 = new THREE.Box3().setFromObject(newObject);
			const height2 = boundingBox2.max.y - boundingBox2.min.y;
			const width2 = boundingBox2.max.x - boundingBox2.min.x;

			newObject.scale.set(width / width2, height / height2, 0.1);


			transomFrame.current.push(newObject);
			if (!initialLoad) {
				testRef.current.push(newObject)
			}

		} else {
			glazingRef.current.forEach((child, index) => {
				if (child != glassGroup.current[0]) {
					// Create a new object with the same bounding box
					const newGeometry = new THREE.BoxGeometry();
					const newMaterial = new THREE.MeshBasicMaterial({ visible: false }); // { visible: false } { color: 0x00bfff }
					const newObject = new THREE.Mesh(newGeometry, newMaterial);

					const worldPosition = new THREE.Vector3();

					child.getWorldPosition(worldPosition);

					newObject?.position?.set(worldPosition.x, worldPosition.y, worldPosition.z);

					const boundingBox = new THREE.Box3().setFromObject(child);
					const height = boundingBox.max.y - boundingBox.min.y;
					const width = boundingBox.max.x - boundingBox.min.x;

					const boundingBox2 = new THREE.Box3().setFromObject(newObject);
					const height2 = boundingBox2.max.y - boundingBox2.min.y;
					const width2 = boundingBox2.max.x - boundingBox2.min.x;

					newObject.scale.set(width / width2, height / height2, 0.1);
					transomFrame.current.push(newObject);
					if (!initialLoad) {
						testRef.current.push(newObject)
					}
				}
			});
		}
	}


	function addInitialTransomFrame() {
		transomFrame.current = [];
		var length = 0;
		length = glazingRef.current?.length;
		if (length <= 1) {
			// Create a new object with the same bounding box
			const newGeometry = new THREE.BoxGeometry();
			const newMaterial = new THREE.MeshBasicMaterial({ visible: false }); // { visible: false } { color: 0x00bfff }
			const newObject = new THREE.Mesh(newGeometry, newMaterial);

			newObject?.position?.set(glassGroup.current[0]?.position.x, glassGroup.current[0]?.position.y, glassGroup.current[0]?.position.z);

			const boundingBox = new THREE.Box3().setFromObject(glassGroup.current[0]);
			const height = boundingBox.max.y - boundingBox.min.y;
			const width = boundingBox.max.x - boundingBox.min.x;

			const boundingBox2 = new THREE.Box3().setFromObject(newObject);
			const height2 = boundingBox2.max.y - boundingBox2.min.y;
			const width2 = boundingBox2.max.x - boundingBox2.min.x;

			newObject.scale.set(width / width2, height / height2, 0.1);

			transomFrame.current.push(newObject);
			sceneRef.current.add(newObject);
		} else {
			glazingRef.current.forEach((child, index) => {
				if (child != glassGroup.current[0]) {

					// Create a new object with the same bounding box
					const newGeometry = new THREE.BoxGeometry();
					const newMaterial = new THREE.MeshBasicMaterial({ visible: false }); // { visible: false } { color: 0x00bfff }
					const newObject = new THREE.Mesh(newGeometry, newMaterial);

					const worldPosition = new THREE.Vector3();

					child.getWorldPosition(worldPosition);

					newObject?.position?.set(worldPosition.x, worldPosition.y, worldPosition.z);

					const boundingBox = new THREE.Box3().setFromObject(child);
					const height = boundingBox.max.y - boundingBox.min.y;
					const width = boundingBox.max.x - boundingBox.min.x;

					const boundingBox2 = new THREE.Box3().setFromObject(newObject);
					const height2 = boundingBox2.max.y - boundingBox2.min.y;
					const width2 = boundingBox2.max.x - boundingBox2.min.x;

					newObject.scale.set(width / width2, height / height2, 0.1);

					transomFrame.current.push(newObject);
					sceneRef.current.add(newObject);
				}
			});
		}
	}


	function addTextOnGlass(fontSize, textToAdd, index) {
		// Add text on glass
		if (sceneRef.current) {
			removeTextOnGlass(index)
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			context.font = `${fontSize}px sans-serif`;
			context.fillStyle = '#1a1a1a';

			// Measure the text width
			const textWidth = context.measureText(textToAdd).width;

			// Calculate the center coordinates of the canvas
			const centerX = canvas.width / 2;
			const centerY = canvas.height / 2;

			// Calculate the starting position for the text to be centered
			const textX = centerX - (textWidth / 2);
			const textY = centerY + (fontSize / 2) - 10;

			context.fillText(textToAdd, textX, textY); // Text and position

			// Create texture from canvas
			const textureNew = new THREE.CanvasTexture(canvas);

			// Create plane geometry to apply the texture
			const geometry = new THREE.PlaneGeometry(10, 0.5); // Adjust the size as needed
			const materialText = new THREE.MeshBasicMaterial({ map: textureNew, transparent: true, metalness: 1, side: THREE.DoubleSide });


			if (index !== null) {
				textOnSelectedGlass(glazingRef.current[index], materialText, geometry, index, textToAdd)
			} else {
				glazingRef.current.forEach((item, i) => {
					textOnSelectedGlass(item, materialText, geometry, i, textToAdd)
				})
			}
		}
	}

	function textOnSelectedGlass(glazing, materialText, geometry, index, text) {
		const textMesh = new THREE.Mesh(geometry, materialText);

		// Scale the text mesh to fit the glass
		const boundingBox = new THREE.Box3().setFromObject(glazing);
		const center = new THREE.Vector3();
		boundingBox.getCenter(center);

		textMesh.position.set(center.x, center.y, center.z + 0.01);

		const boundingBox2 = new THREE.Box3().setFromObject(textMesh);
		const height = boundingBox.max.y - boundingBox.min.y;
		const width = boundingBox.max.x - boundingBox.min.x;
		const height2 = boundingBox2.max.y - boundingBox2.min.y;
		const width2 = boundingBox2.max.x - boundingBox2.min.x;
		textMesh.scale.set(width / width2, height / height2, 1);

		// Add text mesh to the scene and store a reference
		sceneRef.current.add(textMesh);
		glassText.current.push({ index, text3D: textMesh, text, price: sandblastPricing, name: "Sandblasted numerals" });
		setCustomModelData((prevModelData) => ({
			...prevModelData,
			glazing: {
				...prevModelData.glazing,
				sandblasted: glassText.current
			}
		}))
	}

	function removeTextOnGlass(index) {
		// Add text on glass
		if (sceneRef.current) {
			// Check if the text model already exists and remove it
			if (glassText.current.length > 0) {
				if (index === null) {
					glassText.current.forEach((item) => {
						sceneRef.current.remove(item.text3D);
					})
					glassText.current = []; // Clear the array
				} else {
					glassText.current = glassText.current.filter(textObj => {
						if (textObj.index === index) {
							sceneRef.current.remove(textObj.text3D);
							return false;
						}
						return true;
					});
				}

			}
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				glazing: {
					...prevModelData.glazing,
					sandblasted: glassText.current
				}
			}))
		}
	}

	function addWindowHanging() {
		if (!modelTypeHanging) {
			setTimeout(() => {
				init(windowHangingModel, 'Hanging')
			}, 100);
		}
	}

	const removeMasterSlaveElement = (currIndex) => {
		// masterSlaveElements.current.forEach((item) => {
		// 	sceneRef.current.remove(item)
		// })

		const removeMasterSlave = masterSlaveElements.current.find((item, index) => index === currIndex)
		if (removeMasterSlave) {
			removeMasterSlave.scale.x = 0
			removeMasterSlave.scale.y = 0
			removeMasterSlave.scale.z = 0

			sceneRef.current.remove(removeMasterSlave)
		}
		if (currIndex > -1) {
			masterSlaveElements.current = masterSlaveElements.current.filter((item, index) => index !== currIndex)

		}

		const removeWindowHanging = windowHangingSash.current.find((item, index) => index === currIndex)
		if (removeWindowHanging) {
			sceneRef.current.remove(removeWindowHanging)
		}

		if (currIndex > -1) {
			windowHangingSash.current = windowHangingSash.current.filter((item, index) => index !== currIndex)
		}

		// windowHangingSash.current.forEach((item) => {
		// 	sceneRef.current.remove(item)
		// })
		// masterSlaveElements.current = []
		// windowHangingSash.current = []
		if (glassGroup.current[0]) {
			glassGroup.current[0].visible = true
		}
		removeHinge();
	}

	const removeHinge = () => {
		hingeModels.current.forEach((item) => {
			sceneRef.current.remove(item)
		})
		hingeModels.current = [];
	}


	const beadSize = () => {
		bead.current = []
		sceneRef.current.traverse((child) => {
			if (child.name.includes("Bead")) {
				bead.current.push(child)
				beadDefaultScale = beadDefaultScale === null ? child.scale : beadDefaultScale
			}
		})
		bead.current.forEach((item) => {
			if (beadData.current) {
				item.scale.y = (beadData.current.y / 20) * beadDefaultScale.y;
				item.scale.z = (beadData.current.z / 20) * beadDefaultScale.z;
			}
		})

	}


	function hangingMasterSlave(transomFrameIndex) {

		let mullion = initialProfile?.floatingMullion[0]
		removeSashHandles(transomFrameIndex)
		setMasterSlave(true)
		var group = [];
		var baseFrame

		if (!(transoms.current.length > 0)) {
			transomFrameIndex = null;
		}

		if (transomFrameIndex == null) {
			baseFrame = glassGroup.current[0];
		} else {
			baseFrame = transomFrame.current[transomFrameIndex];

		}

		let glassClone = baseFrame.clone()
		baseFrame.visible = false
		group.push(glassClone);

		const boundingBox = new THREE.Box3().setFromObject(baseFrame);
		const width = boundingBox.max.x - boundingBox.min.x;
		const height = boundingBox.max.y - boundingBox.min.y;
		let isMax = false
		sceneRef.current.traverse((item) => {
			if (item != null && item.name != null) {
				if (item.name.includes("Bar")) {

					const newWorldPosition = new THREE.Vector3();
					item.getWorldPosition(newWorldPosition);

					const newScale = new THREE.Vector3();
					item.getWorldScale(newScale);

					const newWorldRotation = new THREE.Quaternion();
					item.getWorldQuaternion(newWorldRotation);

					group.push(item);

					const newWorldPositionBead = new THREE.Vector3(newWorldPosition.x, newWorldPosition.y, newWorldPosition.z);

					item.position.copy(newWorldPositionBead);
					item.scale.copy(newScale);
					item.quaternion.copy(newWorldRotation);
					//todo : fix the condition for vertical sash bars!
					if (item.name.includes('vertical')) {
						//	const boundingBox2 = new THREE.Box3().setFromObject(item);
						//	const width2 = boundingBox2.max.x - boundingBox2.min.x;
						//	const height2 = boundingBox2.max.y - boundingBox2.min.y;
						let hingeModel = allHardware?.find((item) => item?.name?.toLowerCase() === "hinge")
						item.scale.y *= 2;
						if (hingeModel) {
							addHinge(item, isMax, hingeModel)
						}
						isMax = !isMax
						setHingeRefPoints((prev) => [...prev, item])

					}
				}
			}
		})

		var groupThree = new THREE.Group();

		group.forEach((child, index) => {
			groupThree.add(child);
		});

		const gltfLoader = new GLTFLoader();
		gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${mullion?.customePath}`, function (gltf) {

			masterSlaveElements.current.push({ ...gltf.scene, index: transomFrameIndex })

			gltf.scene.name = 'window_mullion'

			sceneRef.current.add(gltf.scene);

			gltf.scene.traverse((child) => {
				if (child.name.includes("Internal")) {
					internalFrameRef.current.push(child);
				}

				if (child.name.includes("External")) {
					externalFrameRef.current.push(child);
				}
			})

			var base;
			gltf.scene.traverse((child) => {

				if (child.name.includes("Base")) {
					base = child;

					if (mullion?.width && mullion?.height) {
						child.scale.z = customModelData?.floatingMullion ? customModelData?.floatingMullion?.width / 70 : mullion?.width / 70
						child.scale.y = customModelData?.floatingMullion ? customModelData?.floatingMullion?.height / 70 : mullion?.height / 70
					} else {
						child.scale.z = 1
						child.scale.y = 1
					}

				}
			})

			const baseBoundingBox = new THREE.Box3().setFromObject(
				base
			);

			gltf.scene.traverse((child) => {

				if (child.name.includes("Front")) {
					//	child.scale.y = transomData.current.widthScale
					const childBoundingBox = new THREE.Box3().setFromObject(
						child
					);
					child.position.z = baseBoundingBox.min.z;
				}
			})


			setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

			gltf.scene.position.z = 0;
			gltf.scene.position.y = baseFrame.position.y;
			gltf.scene.position.x = baseFrame.position.x

			const boundingBox3 = new THREE.Box3().setFromObject(groupThree);
			const width3 = boundingBox3.max.x - boundingBox3.min.x;

			const boundingBox4 = new THREE.Box3().setFromObject(gltf.scene);
			const width4 = boundingBox4.max.x - boundingBox4.min.x;
			const height4 = boundingBox4.max.y - boundingBox4.min.y;

			const scaleRatio = ((width3 - (width4 / 2)) / width3);

			const boundingBox5 = new THREE.Box3().setFromObject(baseFrame);
			const height = boundingBox5.max.y - boundingBox5.min.y;

			gltf.scene.scale.y = height / height4;

			var n = 1;

			for (let i = -n / 2; i <= n / 2; i++) {
				const clone = groupThree.clone();
				clone.scale.x = scaleRatio / 2;
				clone.position.x = (i * (width + (width4 / 2)) / (n + 1)) + baseFrame.position.x;
				clone.visible = true;
				// clone.position.y = baseFrame.position.y;


				clone.traverse((child) => {
					if (child.name.includes("Internal")) {
						internalFrameRef.current.push(child);
					}

					if (child.name.includes("External")) {
						externalFrameRef.current.push(child);
					}

				})

				if (transomFrameIndex !== null) {
					const cloneBoundingBox = new THREE.Box3().setFromObject(clone)
					let cloneWidth = cloneBoundingBox?.max?.x - cloneBoundingBox?.min?.x
					cloneWidth.position.x = gltf?.scene?.position?.x + (i * cloneWidth)
				}

				windowHangingSash.current.push(clone)


				setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

				sceneRef.current.add(clone);

				const redTransparentMaterial = new THREE.MeshBasicMaterial({
					color: "#0000FF",
					transparent: true,
					opacity: 0.3, // 50% transparency
				});
				sceneRef.current.traverse((child) => {
					if (child.name.includes('Glass')) {
						child.material = redTransparentMaterial
						if (!glazingRef.current.includes(child)) {
							glazingRef.current.push(child)
						}
					}
				})
				setGlazing("#ADD8E6", null, shapeFrame, setClipGlaze, glazingRef, clipGlaze, clipGlaze)
			}

			//set min z aligned with frame min
			const boundingBox6 = new THREE.Box3().setFromObject(addFrameRef.current[0]);
			const boundingBox7 = new THREE.Box3().setFromObject(gltf.scene);
			if (boundingBox7.max.z > boundingBox6.max.z) {
				const newWidth = (boundingBox7.max.z - boundingBox7.min.z) / 2;
				gltf.scene.position.z = boundingBox6.max.z - newWidth;

			}

			let mullionObj = {
				id: mullion?.id,
				model: mullion?.customePath,
				imagePath: mullion?.imagePath,
				name: mullion?.name,
				price: mullion?.price,
				height: mullion?.height,
				width: mullion?.width,
				profileTypePrice: mullion?.profileTypePrice,
				internalPaintSurfaceArea: mullion?.internalPaintSurfaceArea,
				externalPaintSurfaceArea: mullion?.externalPaintSurfaceArea,
				frameType: "Mullion"
			}

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				floatingMullion: mullionObj
			}))

			addWindowHandleOnSashMaster(transomFrameIndex)

			setIsHangingLoaded(false)
			setCheckSingleSide()
		});
	}


	function addWindowHandleOnSash(orientation, index) {
		try {

			let handleModel = allHardware?.find((item) => (item?.name?.toLowerCase() === "window handle" || item?.name?.toLowerCase() === "door handle"))
			let hingeModel = allHardware.find((item) => item?.name?.toLowerCase() === 'hinge')

			removeSashHandles(index)
			windowHandleRefPoint.current = []

			if (hingeModel) {
				sceneRef?.current?.traverse((child) => {
					if (child?.name?.includes('verticalBarLeft') && orientation?.toLowerCase() === 'left') {
						addHinge(child, false, hingeModel)
					} else if (child?.name?.includes('verticalBarRight') && orientation?.toLowerCase() === 'right') {
						addHinge(child, true, hingeModel)
					}
				})
			}

			var handleRotation = Math.PI

			if (orientation != 'Top') {
				handleRotation = - (Math.PI / 180) * 90
			}

			if (orientation == 'top') {
				handleRotation = (Math.PI / 180) * 360
			}

			if (orientation?.toLowerCase() === 'right') {
				handleRotation = Math.PI * 2
			}


			if (orientation?.toLowerCase() === 'left' && handleModel?.hardwareStyles[0]?.name !== "Espag Cranked") {
				handleRotation = Math.PI * 2
			} else if ((orientation?.toLowerCase() === 'left' || orientation?.toLowerCase() === 'right') && handleModel?.hardwareStyles[0]?.name === "Espag Cranked") {
				handleRotation = -(Math.PI / 2)
			}

			if (orientation == 'Bottom' || orientation == 'Reversible') {
				handleRotation = (Math.PI / 180) * 360
			}

			var targetElement;
			const orientationMap = {
				"left": "verticalBarRight",
				"right": "verticalBarLeft",
				"stable left": "horizontalBarBottom",
				"reversible": "horizontalBarBottom",
				"bottom": "horizontalBarTop",
				"top": "horizontalBarBottom"
			};


			const targetKey = orientation.toLowerCase();
			if (orientationMap[targetKey]) {
				sashGroup?.current[index]?.traverse((item) => {
					if (item.name.includes(orientationMap[targetKey])) {
						targetElement = item;
					}
				});
			}


			// if (orientation == "top") {
			// 	sceneRef?.current?.traverse((item) => {
			// 		if (item.name.includes("horizontalBarBottom")) {
			// 			targetElement = item;
			// 		}
			// 	})
			// }


			if (targetElement == null) {
				return;
			}

			if (orientation != 'Fixed') {
				const boundingBoxBead = new THREE.Box3().setFromObject(targetElement);

				const beadWidth = boundingBoxBead.max.z - boundingBoxBead.min.z;

				const gltfLoader = new GLTFLoader();

				if (handleRotation && headerSelectedItem?.name !== "Heritage Door") {

					gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${handleModel?.hardwareStyles[0]?.modelFilePath}`, function (gltf) {

						const boundingBoxHandle = new THREE.Box3().setFromObject(gltf.scene);
						const handleWidth = boundingBoxHandle.max.z - boundingBoxHandle.min.z;

						const newWorldPosition = new THREE.Vector3();
						const handleOpposite = gltf.scene.clone()

						targetElement.getWorldPosition(newWorldPosition);
						let xCoordinatePos = orientation?.toLowerCase() === 'left' ? newWorldPosition.x - 0.013 : newWorldPosition.x + 0.020
						// windowHangingBox.max.x + 0.045, 0, (windowHangingBox.min.z - (handleWidth / 2)) + 0.05

						const newWorldPositionBead = new THREE.Vector3(xCoordinatePos, transomFrame?.current[checkSingleSide]?.position?.y, (boundingBoxBead.min.z - (handleWidth / 2)) + 0.12);
						// gltf.scene.position.z = -1

						gltf.scene.position.copy(newWorldPositionBead);
						handleOpposite.position.x = xCoordinatePos
						handleOpposite.position.y = transomFrame?.current[checkSingleSide]?.position?.y
						handleOpposite.scale.z = -2
						handleOpposite.position.z -= 0.035
						// gltf.scene.rotation.x = -Math.PI / 2;
						gltf.scene.rotation.z = handleRotation;
						handleOpposite.rotation.z = handleRotation
						gltf.scene.rotation.x = Math.PI * 2;
						// handleOpposite.rotation.z = -(Math.PI * 2)



						// handleOpposite.rotation.z = handleRotation
						gltf.scene.name = 'window_handle';
						sashHandles.current.push({ ...gltf.scene, index })
						sceneRef.current.add(gltf.scene);

						if (handleModel?.hardwareStyles[0]?.name != "Slim Security Flip Lock") {
							sashHandles.current.push({ ...handleOpposite, index })
							sceneRef.current.add(handleOpposite)
						}


						if (orientation == 'Top') {
							windowHandleRefPoint.current.push(targetElement)
						}
					});
					setCheckSingleSide()
				}
			}

			setWindowHandleOrientation('')

		} catch (error) {
			console.error(error);
		}
	}

	const removeSash = (selectedIndex, data) => {
		addedSash.current.forEach(obj => {
			if (obj.index === selectedIndex) {
				obj.scene.visible = false
				obj.scene.scale.set(0, 0, 0)
				setDeletePressed(false)
			}
		});

		setCustomModelData((prevModelData) => ({
			...prevModelData,
			sashData: prevModelData.sashData.map((item) => {
				if (selectedIndex === item?.index) {
					return {
						...item,
						visible: false
					}
				}
				return item;
			})
		}))
	}

	const removeSashHandles = (index) => {
		if (sashHandles.current.length) {
			let handles = sashHandles.current.find((child) => child.index === index)
			if (handles) {
				handles.visible = false
				handles.scale.x = 0
				handles.scale.y = 0
				handles.scale.z = 0
				sceneRef.current.remove(handles)
			}

			sashHandles.current = sashHandles.current.filter((child) => child.index !== index)
		}
	}


	function addWindowHandleOnSashMaster(index) {
		let handleModel = allHardware?.find((item) => (item?.name?.toLowerCase() === "window handle" || item?.name?.toLowerCase() === "door handle"))

		windowHandleRefPoint.current = []
		removeSashHandles(index)

		var handleRotation = - (Math.PI / 180) * 90

		// removing old handles
		// if (sceneRef.current) {
		// 	var handleLocal
		// 	sceneRef.current.traverse(function (object) {
		// 		if (object.name.includes("window_handle")) {
		// 			object.visible = false;
		// 			handleLocal = object
		// 		}
		// 	});

		// 	if (handleLocal) {
		// 		sceneRef.current.remove(handleLocal);
		// 	}
		// }

		const gltfLoader = new GLTFLoader();

		if (handleRotation && headerSelectedItem?.name !== "Heritage Door" && handleModel?.hardwareStyles[0]) {
			gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${handleModel?.hardwareStyles[0]?.modelFilePath}`, function (gltf) {

				isHeritageDoor.current = true;
				// handle width
				const boundingBoxHandle = new THREE.Box3().setFromObject(gltf.scene);
				const handleWidth = boundingBoxHandle.max.z - boundingBoxHandle.min.z;

				let windowHangingBox;
				let windowHangingBox2;

				if (index) {
					windowHangingBox = new THREE.Box3().setFromObject(windowHangingSash?.current[index]);
					windowHangingBox2 = new THREE.Box3().setFromObject(windowHangingSash?.current[index]);
				} else {
					windowHangingBox = new THREE.Box3().setFromObject(windowHangingSash?.current[0]);
					windowHangingBox2 = new THREE.Box3().setFromObject(windowHangingSash?.current[0]);
				}

				gltf.scene.scale.set(1, 1, 1);
				var handle = gltf.scene.clone();

				// defining position for 1st handle and adding in scene
				const handlePosition1 = new THREE.Vector3(windowHangingBox.max.x + 0.045, transomFrame?.current[checkSingleSide]?.position?.y, (windowHangingBox.min.z - (handleWidth / 2)) + 0.08);
				gltf.scene.position.copy(handlePosition1);


				// gltf.scene.rotation.y = Math.PI;
				// gltf.scene.rotation.z = handleRotation;
				gltf.scene.name = 'window_handle';


				const handlePosition2 = new THREE.Vector3(windowHangingBox2.max.x - 0.025, transomFrame?.current[checkSingleSide]?.position?.y, (windowHangingBox.min.z - (handleWidth / 2)) + 0.08);
				handle.position.copy(handlePosition2);
				handle.scale.x = -1


				var handle2 = gltf.scene.clone()
				handle2.scale.z = -1
				handle2.position.z = -handle2.position.z
				// handle2.position.x += 0.013

				handle2.rotation.y = Math.PI * 2



				var handle3 = handle.clone()
				handle3.scale.z = -1
				handle3.position.z = -handle3.position.z
				// handle3.position.x -= 0.015
				handle.name = 'window_handle';


				sashHandles.current.push(handle3)
				sceneRef.current.add(handle3)

				sashHandles.current.push(handle)
				sceneRef.current.add(handle);

				sashHandles.current.push(gltf.scene)
				sceneRef.current.add(gltf.scene);

				sashHandles.current.push(handle2)
				sceneRef.current.add(handle2)


			});
		}

		// setIsWindowHaning(() => ({
		// 	isHangingAdded: true,
		// 	hangingType: orientation,
		// 	handleDirection: 'right',
		// }))

		// setCustomModelData(prevData => ({
		// 	...prevData,
		// 	windowData: isWindowHaning,
		// }));
	}

	function reParentWindowHanging(frameParent) {

		if (frameParent) {
			gltfModel.current.traverse((child) => {

				if (child.name.includes("BeadTop") || child.name.includes("SashTop")) {

					// Store child's world position and rotation before reparenting
					const originalWorldPosition = new THREE.Vector3();
					const originalWorldQuaternion = new THREE.Quaternion();
					child.getWorldPosition(originalWorldPosition);
					child.getWorldQuaternion(originalWorldQuaternion);

					// Change parent
					child.parent = frameStyleTop.current;

					// Calculate the difference in position and rotation caused by the reparenting
					const newWorldPosition = new THREE.Vector3();
					const newWorldQuaternion = new THREE.Quaternion();
					child.getWorldPosition(newWorldPosition);
					child.getWorldQuaternion(newWorldQuaternion);

					const positionOffset = originalWorldPosition.clone().sub(newWorldPosition);
					const rotationOffset = new THREE.Quaternion().copy(originalWorldQuaternion).invert().multiply(newWorldQuaternion);

					// Apply the offset to retain original position and rotation
					//	child.position.add(positionOffset);
					child.quaternion.multiply(rotationOffset);

					const localPosition = frameStyleTop.current.worldToLocal(originalWorldPosition);

					// Set the local position of the object
					child.position.copy(localPosition);
				}

				if (child.name.includes("BeadBottom") || child.name.includes("SashBottom")) {

					const originalWorldPosition = new THREE.Vector3();
					const originalWorldQuaternion = new THREE.Quaternion();
					child.getWorldPosition(originalWorldPosition);
					child.getWorldQuaternion(originalWorldQuaternion);

					// Change parent
					child.parent = frameStyleBottom.current;

					// Calculate the difference in position and rotation caused by the reparenting
					const newWorldPosition = new THREE.Vector3();
					const newWorldQuaternion = new THREE.Quaternion();
					child.getWorldPosition(newWorldPosition);
					child.getWorldQuaternion(newWorldQuaternion);

					const positionOffset = originalWorldPosition.clone().sub(newWorldPosition);
					const rotationOffset = new THREE.Quaternion().copy(originalWorldQuaternion).invert().multiply(newWorldQuaternion);

					// Apply the offset to retain original position and rotation
					child.position.add(positionOffset);
					child.quaternion.multiply(rotationOffset);

					const localPosition = frameStyleBottom.current.worldToLocal(originalWorldPosition);

					// Set the local position of the object
					child.position.copy(localPosition);
				}

				if (child.name.includes("BeadRight") || child.name.includes("SashLeft")) {

					const originalWorldPosition = new THREE.Vector3();
					const originalWorldQuaternion = new THREE.Quaternion();
					child.getWorldPosition(originalWorldPosition);
					child.getWorldQuaternion(originalWorldQuaternion);

					// Change parent
					child.parent = frameStyleRight.current;

					// Calculate the difference in position and rotation caused by the reparenting
					const newWorldPosition = new THREE.Vector3();
					const newWorldQuaternion = new THREE.Quaternion();
					child.getWorldPosition(newWorldPosition);
					child.getWorldQuaternion(newWorldQuaternion);

					const positionOffset = originalWorldPosition.clone().sub(newWorldPosition);
					const rotationOffset = new THREE.Quaternion().copy(originalWorldQuaternion).invert().multiply(newWorldQuaternion);

					// Apply the offset to retain original position and rotation
					//		child.position.set(originalWorldPosition);
					child.quaternion.multiply(rotationOffset);

					const localPosition = frameStyleRight.current.worldToLocal(originalWorldPosition);

					// Set the local position of the object
					child.position.copy(localPosition);
				}

				if (child.name.includes("BeadLeft") || child.name.includes("SashRight")) {

					const originalWorldPosition = new THREE.Vector3();
					const originalWorldQuaternion = new THREE.Quaternion();
					child.getWorldPosition(originalWorldPosition);
					child.getWorldQuaternion(originalWorldQuaternion);

					// Change parent
					child.parent = frameStyleLeft.current;

					// Calculate the difference in position and rotation caused by the reparenting
					const newWorldPosition = new THREE.Vector3();
					const newWorldQuaternion = new THREE.Quaternion();
					child.getWorldPosition(newWorldPosition);
					child.getWorldQuaternion(newWorldQuaternion);

					const positionOffset = originalWorldPosition.clone().sub(newWorldPosition);
					const rotationOffset = new THREE.Quaternion().copy(originalWorldQuaternion).invert().multiply(newWorldQuaternion);

					// Apply the offset to retain original position and rotation
					//		child.position.set(originalWorldPosition);
					child.quaternion.multiply(rotationOffset);

					const localPosition = frameStyleLeft.current.worldToLocal(originalWorldPosition);

					// Set the local position of the object
					child.position.copy(localPosition);
				}
			})
		} else {
			gltfModel.current.traverse((child) => {

				if (child.name.includes("BeadTop") || child.name.includes("SashTop")) {

					// Store child's world position, rotation, and scale before detaching from parent
					const originalWorldPosition = new THREE.Vector3().copy(child.getWorldPosition(new THREE.Vector3()));
					const originalWorldQuaternion = new THREE.Quaternion().copy(child.getWorldQuaternion(new THREE.Quaternion()));
					const originalWorldScale = new THREE.Vector3().copy(child.getWorldScale(new THREE.Vector3()));

					// Detach child from parent
					child.parent = null;

					// Calculate the child's position, rotation, and scale relative to the world coordinates
					const worldPosition = originalWorldPosition.clone();
					const worldQuaternion = originalWorldQuaternion.clone().normalize();
					const worldScale = originalWorldScale.clone();

					// Apply the world position, rotation, and scale to the child
					child.position.copy(worldPosition);
					child.quaternion.copy(worldQuaternion);
					child.scale.copy(worldScale);

				}

				if (child.name.includes("BeadBottom") || child.name.includes("SashBottom")) {

					// Store child's world position, rotation, and scale before detaching from parent
					const originalWorldPosition = new THREE.Vector3().copy(child.getWorldPosition(new THREE.Vector3()));
					const originalWorldQuaternion = new THREE.Quaternion().copy(child.getWorldQuaternion(new THREE.Quaternion()));
					const originalWorldScale = new THREE.Vector3().copy(child.getWorldScale(new THREE.Vector3()));

					// Detach child from parent
					child.parent = null;

					// Calculate the child's position, rotation, and scale relative to the world coordinates
					const worldPosition = originalWorldPosition.clone();
					const worldQuaternion = originalWorldQuaternion.clone().normalize();
					const worldScale = originalWorldScale.clone();

					// Apply the world position, rotation, and scale to the child
					child.position.copy(worldPosition);
					child.quaternion.copy(worldQuaternion);
					child.scale.copy(worldScale);
				}

				if (child.name.includes("BeadRight") || child.name.includes("SashLeft")) {

					// Store child's world position, rotation, and scale before detaching from parent
					const originalWorldPosition = new THREE.Vector3().copy(child.getWorldPosition(new THREE.Vector3()));
					const originalWorldQuaternion = new THREE.Quaternion().copy(child.getWorldQuaternion(new THREE.Quaternion()));
					const originalWorldScale = new THREE.Vector3().copy(child.getWorldScale(new THREE.Vector3()));

					// Detach child from parent
					child.parent = null;

					// Calculate the child's position, rotation, and scale relative to the world coordinates
					const worldPosition = originalWorldPosition.clone();
					const worldQuaternion = originalWorldQuaternion.clone().normalize();
					const worldScale = originalWorldScale.clone();

					// Apply the world position, rotation, and scale to the child
					child.position.copy(worldPosition);
					child.quaternion.copy(worldQuaternion);
					child.scale.copy(worldScale);
				}

				if (child.name.includes("BeadLeft") || child.name.includes("SashRight")) {

					// Store child's world position, rotation, and scale before detaching from parent
					const originalWorldPosition = new THREE.Vector3().copy(child.getWorldPosition(new THREE.Vector3()));
					const originalWorldQuaternion = new THREE.Quaternion().copy(child.getWorldQuaternion(new THREE.Quaternion()));
					const originalWorldScale = new THREE.Vector3().copy(child.getWorldScale(new THREE.Vector3()));

					// Detach child from parent
					child.parent = null;

					// Calculate the child's position, rotation, and scale relative to the world coordinates
					const worldPosition = originalWorldPosition.clone();
					const worldQuaternion = originalWorldQuaternion.clone().normalize();
					const worldScale = originalWorldScale.clone();

					// Apply the world position, rotation, and scale to the child
					child.position.copy(worldPosition);
					child.quaternion.copy(worldQuaternion);
					child.scale.copy(worldScale);
				}
			})
		}


		if (windowHandleOrientation === '') {
			if (customModelData && customModelData?.windowData?.length) {
				customModelData.windowData.forEach((item) => {
					if (item?.hangingType != 'Master/Slave' && item.hangingType != "Slave/Master") {
						const defaultProfile = initialProfile?.sash?.find((ele => ele?.defaultProfile === true))

						updateSash(1, `${servicePath}/ThreeJSModel/Glb/${defaultProfile?.customePath}`, transoms?.current?.length >= 1 ? transomFrame?.current[item.index] : glassGroup?.current[0], true, item.position, false)
						setTimeout(() => {
							addWindowHandleOnSash(item.hangingType, item.index);
						}, 1000);
					}
				})
			}
		}
		else {
			if (windowHandleOrientation != 'Master/Slave' && windowHandleOrientation != "Slave/Master") {
				addWindowHandleOnSash(windowHandleOrientation, checkSingleSide);
			}
		}
	}

	function resizeWindow(width, height, modelType, initLoad) {

		setLoading(true)
		setPartialWidthInit(false)
		setModelVisible(false)

		setUiLinesLocation('')
		setRefreshUiLines(false)

		setTimeout(() => {

			removeSill(sillRef, gltfModel, sceneRef, setFrameObject, setPricingData)

			if (objectGroupCol.current) {
				clearDesignCols()
			}

			if (objectGroupRow.current) {
				clearDesignRows()
			}

			// remove

			// if(windowHandleOrientation !== '' && collectionDetails && collectionDetails.typeId == 2 && modelType == 'Hanging') {
			// 	if(customModelData && customModelData.windowData && customModelData?.windowData?.hangingType) {
			// 		if (customModelData?.windowData?.hangingType != 'Master/Slave' && customModelData?.windowData?.hangingType != "Slave/Master") {
			// 			reParentWindowHanging(true);

			// 			setModelTypeHanging(true)
			// 		}
			// 	}
			// }

			// if (collectionDetails && collectionDetails.typeId == 2 && modelType == 'Hanging') {
			// 	reParentWindowHanging(true);

			// 	setModelTypeHanging(true)
			// }

			windowHeight.current = height;
			windowWidth.current = width;

			const scalex = width / 1250;
			const scaley = height / 2058;

			// if(collectionDetails && collectionDetails.typeId == 2) {
			// 	scalex = width / 2083;
			// 	scaley = height / 3430;
			// }

			//resize glass
			glassGroup.current.forEach((window, index) => {
				window.scale.x = scalex;
				window.scale.y = scaley;
			});

			const boundingBox = new THREE.Box3().setFromObject(glassGroup.current[0]);
			const modelWidth = boundingBox.max.x - boundingBox.min.x;
			const modelHeight = boundingBox.max.y - boundingBox.min.y;
			//set position of frames

			Frame.current.forEach((frame, index) => {
				if (frame.name.includes("Top") || frame.name.includes("Bottom")) {
					const boundingBox2 = new THREE.Box3().setFromObject(frame);
					const modelHeight2 = boundingBox2.max.y - boundingBox2.min.y;
					const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;

					var scaleFactor = (modelWidth + modelHeight2 + 0.01) / modelWidth2;
					frame.scale.x *= scaleFactor;

					if (frame.name.includes("Top")) {
						frame.position.y = boundingBox.max.y;
					}
					if (frame.name.includes("Bottom")) {
						frame.position.y = boundingBox.min.y;
					}
				} else {
					const boundingBox2 = new THREE.Box3().setFromObject(frame);
					const modelHeight2 = boundingBox2.max.x - boundingBox2.min.x;
					const modelWidth2 = boundingBox2.max.y - boundingBox2.min.y;

					var scaleFactor = (modelHeight + modelHeight2 + 0.01) / modelWidth2;
					frame.scale.x *= scaleFactor;

					if (frame.name.includes("Right")) {
						frame.position.x = boundingBox.min.x;
					}
					if (frame.name.includes("Left")) {
						frame.position.x = boundingBox.max.x;
					}
				}
			});

			if (saveHeightWidth && modelScene && cornerJointModel && customModelData?.frameProfileData?.length > 0 && frameStyleTop.current && frameStyleLeft.current && frameStyleBottom.current && frameStyleRight.current) {
				addCornerJoint(modelScene, cornerJointModel, sceneRef, frameStyleTop, frameStyleLeft, frameStyleBottom, frameStyleRight, profileJointDetails, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal, headerSelectedItem)
			}

			SpaceBar.current.forEach((frame, index) => {
				//asd.scale.y=10;  
				if (frame.name.includes("Top") || frame.name.includes("Bottom")) {
					const boundingBox2 = new THREE.Box3().setFromObject(frame);
					const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;
					const scaleFactor = modelWidth / modelWidth2;
					//  frame.position.x = glassGroup.current[0]?.position.x;
					frame.scale.x *= scaleFactor;
					// frame.position.x -=0.02;
					if (frame.name.includes("Top")) {
						frame.position.y = boundingBox.max.y;
					}
					if (frame.name.includes("Bottom")) {
						frame.position.y = boundingBox.min.y;
					}
				} else {
					frame.visible = false;
					const boundingBox2 = new THREE.Box3().setFromObject(frame);
					const modelWidth2 = boundingBox2.max.y - boundingBox2.min.y;
					const scaleFactor = modelHeight / modelWidth2;
					frame.scale.x *= scaleFactor;
					if (frame.name.includes("Right")) {
						frame.position.x = boundingBox.min.x;
					}
					if (frame.name.includes("Left")) {
						frame.position.x = boundingBox.max.x;
					}
				}
			});

			setFrameCameraView(0, 1, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom);
			resetAddedFrame();

			if (hardwareType !== "Shaped Frame") {
				if (collectionDetails && collectionDetails.typeId == 1 && customModelData?.numberOfSash?.number) {
					const defaultProfile = initialProfile?.sash?.find((ele => ele?.defaultProfile === true))
					updateSash(parseInt(customModelData?.numberOfSash?.number) || 0, `${servicePath}/ThreeJSModel/Glb/${defaultProfile?.customePath}`, transoms?.current?.length >= 1 ? transomFrame?.current[checkSingleSide] : glassGroup?.current[0], true, null, false);
					if (customModelData?.windowData && customModelData?.windowData?.length) {
						customModelData.windowData.forEach((item) => {
							setTimeout(() => {
								addWindowHandleOnSash(item.hangingType, item.index);
							}, 1000);
						})
					}

				}
			}

			replacedSashProfile.current.forEach(obj => {
				// Iterate over each object and log key-value pairs
				let data = {
					height: obj?.height,
					width: obj?.width
				}
				if (obj.visible) {
					addSashStyle(obj?.url, sashList.current[obj.index], "wdf", data, true, obj.index);
				}
			});

			var handleCopy = [];
			handleObjects.current.forEach((object, index) => {
				handleCopy.push(object);
				deleteHandle(index);
			});

			handleCopy.forEach((object, index) => {
				if (handlePath.current) {
					addHandle(handlePath.current, object, false, "", "", "");
				}
			});

			var tickleCopy = [];
			tickleObjects.current.forEach((object, index) => {
				tickleCopy.push(object);
				deleteTrickleVent(index);
			});

			//if number of sash is 0
			addInitialTransomFrame();



			if (frameObject?.modelFilePath) {
				addSill(`${servicePath}/ThreeJSModel/Glb/${frameObject?.modelFilePath}`, frameObject, sillRef, gltfModel, sceneRef, collectionDetails, frameStyleBottom, defaultFrameMaterial, setDefaultSillScale, setHardwareType, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)
			}

			if (windowHandleOrientation === '' && customModelData?.windowData?.length) {
				const defaultProfile = initialProfile?.sash?.find((ele => ele?.defaultProfile === true))
				customModelData.windowData.forEach((item) => {
					if (item.hangingType === "Master/Slave" || item.hangingType === "Master/Slave") {
						updateSash(1, `${servicePath}/ThreeJSModel/Glb/${defaultProfile?.customePath}`, transoms?.current?.length >= 1 ? transomFrame?.current[item.index] : glassGroup?.current[0], true, item.position, false)
						hangingMasterSlave(item?.index)
					}

				})
			}

			/*
			const elementLoader = new GLTFLoader()
				elementLoader.load(Sash, function (gltf) {
			
				//cuboid = gltf.scene;
				sceneRef.current.add(gltf.scene); 
			});
			*/

			//  cloneAndPlace(glassGroup.current[0],1); 

			// if (customModelData?.glazing && customModelData.glazing?.design?.length > 0) {
			// 	customModelData.glazing.design.length && customModelData.glazing.design.forEach((item) => {
			// 		setTimeout(() => {
			// 			updateGlassDesignRow(item.modelUrl, Number(item.rows), item.index);
			// 			setGlassDesignColumn(Number(item.cols), item.modelUrl, item.index);
			// 		}, 4000);
			// 	})
			// }

			// if (customModelData?.glazing && customModelData.glazing?.design?.length > 0) {
			// 	customModelData.glazing.design.length && customModelData.glazing.design.forEach((item) => {
			// 		console.log(4354);
			// 		setTimeout(() => {
			// 			updateGlassDesignRow(item.modelUrl, Number(item.rows), item.index);
			// 		}, 1200);
			// 	})
			// }

			// if (transoms.current.length) {
			// 	setTimeout(() => {
			// 		removeAllTransom()
			// 	}, 1000);
			// }


			if (!initLoad) {
				if (customModelData.storedTransoms && customModelData.storedTransoms.length > 0) {

					// if(glazingRef?.current?.length === 1){
					// 	transomFrame.current = glazingRef.current[0]
					// }
					customModelData.storedTransoms.forEach(item => {
						if (transomFrame.current.length) {
							setTimeout(() => {
								addTransom(item.index, item.horizontal, true, true, item, item.position);
							}, 1000);
						}
					});
				}
			}

			setTimeout(() => {
				if (customModelData && customModelData?.sashSize && customModelData?.sashSize.length > 0) {
					setPartialWidthInit(true)
				}
				setRefreshUiLines(true)
			}, 1000);

			setTimeout(() => {
				setModelVisible(true)
				setLoading(false)
			}, 1500);
		}, 100);
	}


	function updateSash(n, sashModel, glass, isBeadAdded, position, store) {
		let allSashMesh = [];
		let hangingStore = [];
		addedSash.current = [];
		sashList.current = []
		replacedSashProfile.current = []

		// remove all panels from all states, color refs, custom model data
		if (panelInitAdded) {
			removeAllPanels(sceneRef, externalFrameRef, setPanelDataSave, setCustomModelData)
		}

		if (n) {
			setSashHangingNo(n)
		} else {
			setSashHangingNo(0)
		}

		if (!n) {
			return;
		}

		// if (customModelData?.numberOfSash && !Array.isArray(customModelData.numberOfSash)) {
		// 	transoms.current.forEach((child, index) => {
		// 		sceneRef.current.remove(transoms.current[index]);
		// 	});
		// }

		removeReplacedSash.current.forEach((item) => {
			sceneRef.current.remove(item)
		})


		transoms.current = []
		removeReplacedSash.current = [];

		sashHangingAmount.current = n;

		// transomFrame.current = [];

		//add sash at all 4 edges 
		const gltfLoader = new GLTFLoader()
		if (sashModel.includes(undefined)) {
			return
		}

		if (sashModel) {
			gltfLoader.load(sashModel, function (gltf) {

				gltf.scene.traverse((child) => {
					if (child.name.includes("Bead")) {
						bead.current.push(child)

						if (!isBeadAdded) {
							let data = {
								id: beadList[0]?.id,
								name: beadList[0].name,
								price: beadList[0]?.price,
								imagePath: beadList[0]?.imagePath,
								filePath: beadList[0]?.filePath,
								externalPaintSurfaceArea: beadList[0]?.externalPaintSurfaceArea,
								internalPaintSurfaceArea: beadList[0]?.internalPaintSurfaceArea,
								profileTypePrice: beadList[0]?.profileTypePrice,
								frameType: "Bead",
							}
							setCustomModelData((prevModelData) => ({
								...prevModelData,
								bead: [
									...prevModelData.bead || [],
									data
								]
							}))
						}

					}
				})

				// Create mesh with geometry and material
				var cuboid = gltf.scene;//new THREE.Mesh(geometry, mat); 

				if (glass) {
					const boundingBox = new THREE.Box3().setFromObject(glass);
					const height = boundingBox.max.y - boundingBox.min.y;
					const width = boundingBox.max.x - boundingBox.min.x;

					const boundingBoxCuboid = new THREE.Box3().setFromObject(cuboid);
					const heightCuboid = boundingBoxCuboid.max.y - boundingBoxCuboid.min.y;

					var cuboidYPosition = boundingBox.min.y + heightCuboid / 2;// -(height / 2 -  (heightCuboid/2)); 
					cuboid.position.set(0, cuboidYPosition, 0);
					cuboid.name = "horizontalBarBottom";

					const boundingBox2 = new THREE.Box3().setFromObject(cuboid);
					const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;
					const height2 = boundingBox2.max.y - boundingBox2.min.y;
					const scaleFactor = width / modelWidth2;
					cuboid.scale.x = scaleFactor;
					// cuboid.rotation.y = Math.PI;
					//	cuboid.position.set(0, cuboidYPosition, 0);
					tickleVentRef.current.push(cuboid)

					// Clone the original cuboid
					var clonedCuboid = cuboid.clone();

					cuboidYPosition = boundingBox.max.y - heightCuboid / 2;
					// Set the position of the cloned cuboid to the origin (0, 0, 0)
					clonedCuboid.position.set(0, cuboidYPosition, 0);
					clonedCuboid.rotation.z += Math.PI;
					clonedCuboid.name = "horizontalBarTop"

					//  sceneRef.current.add(clonedCuboid);
					// Clone the original cuboid
					var clonedCuboid2 = cuboid.clone();

					clonedCuboid2.scale.y *= n

					var cuboidXPosition = -(width / 2 - (height2 * n / 2));

					// Set the position of the cloned cuboid to the origin (0, 0, 0)
					clonedCuboid2.position.set(cuboidXPosition, 0, 0);

					clonedCuboid2.rotation.z = Math.PI / 2;

					// clonedCuboid2.scale.y = -1;

					clonedCuboid2.scale.x = height / modelWidth2;

					clonedCuboid2.name = "verticalBarLeft";

					clonedCuboid2.position.y = glass.position.y;

					// sceneRef.current.add(clonedCuboid2);

					// Clone the original cuboid
					var clonedCuboid3 = cuboid.clone();

					clonedCuboid3.scale.y *= n

					var cuboidXPosition = -(width / 2 - (height2 * n / 2));

					// Set the position of the cloned cuboid to the origin (0, 0, 0)
					clonedCuboid3.position.set(-cuboidXPosition, 0, 0);

					clonedCuboid3.rotation.z = Math.PI / 2;

					clonedCuboid3.scale.x = height / modelWidth2;

					clonedCuboid3.position.y = glass.position.y;

					clonedCuboid3.name = "verticalBarRight";

					// sceneRef.current.add(clonedCuboid3);

					var group = new THREE.Group();
					const glassClone = glass.clone();

					if (transoms.current.length === 0) {
						group.add(glassClone);
					}
					group.add(cuboid);
					group.add(clonedCuboid);
					group.add(clonedCuboid2);
					group.add(clonedCuboid3);
					group.visible = false;
					n = n - 1;


					if (glass === glassGroup.current[0]) {

						sashGroup.current.forEach((group, index) => {
							sceneRef.current.remove(group);
						});
						sashGroup.current = []

					} else {

						const sashToRemove = sashGroup?.current?.length && sashGroup.current.find((item) => item.position === glass.position)
						if (sashToRemove) {
							sceneRef.current.remove(sashToRemove);
						}
						if (sashGroup?.current?.length) {
							sashGroup.current = sashGroup.current.filter((item) => item.position !== glass.position)
						}

					}

					for (let i = -n / 2; i <= n / 2; i++) {
						const clone = group.clone();
						clone.scale.x = 1 / (n + 1);
						clone.position.x = i * width / (n + 1);
						clone.visible = true;
						clone.position.x += glass.position.x;
						// clone.position.y += glass.position.y;

						// applying sash colors for internal and external layers
						clone.traverse((child) => {
							if (child.isMesh) {
								if (!child.name.includes("GlassPanel")) {
									if (child.name.includes("External")) {
										externalFrameRef.current.push(child);
									} else {
										internalFrameRef.current.push(child);
									}
								}
							}
						})

						setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)
						sceneRef.current.add(clone);
						clone.name = "SashGroupH"
						sashGroup.current.push(clone);
					}

					sashGroup.current.forEach((child) => {
						child.traverse((item) => {
							if (item?.name.includes("horizontalBarTop")) {
								const newObj = new THREE.Object3D()
								newObj.position.set(child.position.x, item.position.y, item.position.z)
								setTickleVent((prevTrickleVent) => [...prevTrickleVent, newObj])
							}
							if (item?.name.includes('verticalBarLeft')) {
								var worldPosition = new THREE.Vector3();
								var emptyObject = new THREE.Object3D();
								item.getWorldPosition(worldPosition);
								emptyObject.position.set(worldPosition)
								//	setDoorHandleData(prevData => [...prevData, emptyObject])
							}
							setTransomFrameRef((prevTransomRef) => [...prevTransomRef, item])
						})
						let refPoints = []
						refPoints.push(child)
						setNumHardware(refPoints?.length)
						setDoorHandleData(prevData => [...prevData, child])
						setEscutcheonRefPoints(prevEsc => [...prevEsc, child])
						if (store) {
							getSashRef();
						}
					})

					glazingRef.current = []

					sceneRef.current.traverse((child) => {
						if (child.name.includes("GlassPanel")) {
							glazingRef.current.push(child);
						}
					})

					sceneRef.current.traverse

						((child) => {
							if (child.name.includes("Bar") && !child.name.includes("Space")) {
								allSashMesh.push(child)
							}
						})

					sashList.current = allSashMesh


					let defaultProfile = initialProfile?.sash?.find((ele => ele?.defaultProfile === true))
					removeDuplicatesByPosition(sashList.current).forEach((item, index) => {
						hangingStore.push({
							id: defaultProfile?.id,
							index: index,
							url: `${servicePath}/ThreeJSModel/Glb/${defaultProfile?.customePath}`,
							width: defaultProfile?.width,
							height: defaultProfile?.height,
							side: "",
							price: defaultProfile?.price,
							name: defaultProfile?.name,
							frameType: "Sash profile",
							orientation: "",
							description: defaultProfile?.description,
							imagePath: defaultProfile?.imagePath,
							internalPaintSurfaceArea: defaultProfile?.internalPaintSurfaceArea,
							externalPaintSurfaceArea: defaultProfile?.externalPaintSurfaceArea,
							weight: defaultProfile?.weight,
							maxWeight: defaultProfile?.maxWeight,
							alert: false,
							visible: true,
							widthFormula : defaultProfile?.widthFormula
						})
						addedSash.current.push({ finalItemIndex: index, scene: item, index })
					})

					replacedSashProfile.current = hangingStore

					if (store) {
						setCustomModelData((prevModelData) => ({
							...prevModelData,
							// Dont remove this method
							sashData: assignOrientations(hangingStore)
						}))
					}

					beadSize()

					const transparentMaterial = new THREE.MeshBasicMaterial({
						color: "#FFFFFF",
						transparent: true,
						opacity: 0,
					});
					glassGroup.current[0].material = transparentMaterial
				}

				setIsHangingLoaded(true)

				setCustomModelData((prevModelData) => ({
					...prevModelData,
					glazing: {
						...prevModelData.glazing,
						isSpecModified: false,
						isTextureModified: false
					}
				}))

				setTimeout(() => {
					if (customModelData?.sashData?.length && sashList?.current?.length) {

						customModelData.sashData.forEach((item) => {
							let data = {
								height: item.height,
								width: item.width
							}
							if (item.visible) {
								addSashStyle(item.url, sashList.current[item.index], "adf", data, true, item.index);
							}
						})
					} else if (customModelData?.sashData?.length === 0 && sashList?.current?.length > 0) {
						hangingStore.forEach((item) => {
							let data = {
								height: item.height,
								width: item.width
							}

							if (item.visible) {
								addSashStyle(item.url, sashList.current[item.index], "adf", data, true, item.index);
							}
						})
					}
				}, 200);

				//check if slide and turn door
				if (headerSelectedItem.name === "Slide and Turn Door") {
					addSlideAndTurnGrooves(isBeadAdded);
				}
			});
		}
	}



	useEffect(() => {
		if (applyPartialTrigger && sashGroup.current && sashGroup?.current?.length > 0) {
			const savedData = {
				width: customModelData?.layoutFrame?.width,
				height: customModelData?.layoutFrame?.height,
				count: customModelData?.numberOfSash?.number,
				xScale: (customModelData?.sashSize && customModelData?.sashSize.length > 0) ? customModelData?.xScale : [1],
				sashSize: (customModelData?.sashSize && customModelData?.sashSize.length > 0) ? customModelData?.sashSize : [customModelData?.layoutFrame?.width]
			}

			resizeSash(sashGroup, frameStyleRight, applyPartialObj, savedData, setApplyPartialTrigger, setSavePartialWidths, setCustomModelData, lockRefIndex, setResizeSashStyles);
		}

	}, [applyPartialTrigger]);

	const resizeTransom = (horizontal) => {

		const savedData = {
			width: customModelData?.layoutFrame?.width,
			height: customModelData?.layoutFrame?.height,
			count: customModelData?.numberOfSash?.number,
			xScale: (customModelData?.sashSize && customModelData?.sashSize.length > 0) ? customModelData?.xScale : [1],
			sashSize: (customModelData?.sashSize && customModelData?.sashSize.length > 0) ? customModelData?.sashSize : [customModelData?.layoutFrame?.width]
		}

		sortTransomFrame(horizontal, transomFrame, transomFrameSortedX, transomFrameSortedY, frameStyleRight, applyPartialObj, savedData, setApplyPartialTrigger, setSavePartialWidths, setCustomModelData, lockRefIndex);

		//case 1 : do it similar to sash size change

		//case 2 : do it for the case when multiple transom frame at same X/y

		//case 3 : do it when having mullion at center 
	}

	useEffect(() => {
		if (partialWidthInit && sashGroup.current && sashGroup?.current?.length > 0) {
			const savedData = {
				width: customModelData?.layoutFrame?.width,
				height: customModelData?.layoutFrame?.height,
				count: customModelData?.numberOfSash?.number,
				xScale: (customModelData?.sashSize && customModelData?.sashSize.length > 0) ? customModelData?.xScale : [1],
				sashSize: (customModelData?.sashSize && customModelData?.sashSize.length > 0) ? customModelData?.sashSize : [customModelData?.layoutFrame?.width]
			}

			resizeSashInit(sashGroup, frameStyleRight, savedData, setPartialWidthInit, setResizeSashStyles);
		}
	}, [partialWidthInit, sashGroup.current]);

	useEffect(() => {
		if (savePartialWidths && customModelData) {
			setSavePartialWidths(false)
			setRefreshAfterSave(true)

			if (quotationId && receivedProductId) {
				const res = setQuotationModelData(accessToken, modelId, selectedAnimation?.id, customModelData, quotationId, receivedProductId)
			} else {
				const res = setModelData(accessToken, modelId, selectedAnimation?.id, customModelData)
			}
		}
	}, [savePartialWidths]);

	const removeGrooveElements = () => {
		if (storeGrooveCylinder.current.length) {
			storeGrooveCylinder.current.forEach((item) => {
				sceneRef.current.remove(item)
			})
		}

		if (storeGroveCube.current.length) {
			storeGroveCube.current.forEach((item) => {
				sceneRef.current.remove(item)
			})
		}
	}


	function addSlideAndTurnGrooves(storeData) {
		removeGrooveElements()
		let doorTopHardware;

		let cylinderStore = []
		allHardware.forEach((item) => {
			doorTopHardware = item.hardwareStyles.find((hardware) => hardware.name.includes("Slide and Turn Hardware"))
		})

		sashGroup.current.forEach((child, index) => {
			const gltfLoader = new GLTFLoader();
			if (doorTopHardware) {
				gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${doorTopHardware.modelFilePath}`, function (gltf) {
					const boundingBox = new THREE.Box3().setFromObject(child);
					const cube = gltf.scene;
					sceneRef.current.add(cube);
					cube.position.y = boundingBox.max.y - 0.01;
					cube.position.x = boundingBox.min.x + 0.2;
					storeGrooveCylinder.current.push(cube)

					if (index == sashGroup?.current?.length - 1) {
						const geometry = new THREE.BoxGeometry(0.05, 0.04, 0.05);
						const material = new THREE.MeshBasicMaterial({ color: customModelData?.frame?.externalColor?.hex, metalness: 0.9, roughness: 0.2 });
						const cube2 = new THREE.Mesh(geometry, material);
						cube2.position.y = boundingBox.max.y - 0.005;
						cube2.position.x = boundingBox.min.x + 0.2;
						cube2.position.z = 0.04;
						sceneRef.current.add(cube2);
						storeGroveCube.current.push(cube2)

					}

					if (!storeData) {
						cylinderStore.push(doorTopHardware)
						setCustomModelData((prevModelData) => ({
							...prevModelData,
							doorTopHardware: cylinderStore
						}))
					}

				});
			}
		})
	}

	function deleteSashGroup() {
		sashGroup.current.forEach((child) => {
			sceneRef.current.remove(child);
		})

		sashGroup.current = [];
		setDeleteSashHanging(false)
	}

	function updateSashOnPart(sashModel, glassPartIndex, sashHangingNumber) {
		//load sash
		const gltfLoader = new GLTFLoader();
		gltfLoader.load(sashModel, function (gltf) {


			var topSash = gltf.scene;

			//add sash on all 4 sides of glass!
			const boundingBox = new THREE.Box3().setFromObject(transomFrame.current[glassPartIndex]);
			const widthX = boundingBox.max.x - boundingBox.min.x;
			const widthY = boundingBox.max.y - boundingBox.min.y;

			const boundingBox2 = new THREE.Box3().setFromObject(topSash);
			const sashWidthY = boundingBox2.max.y - boundingBox2.min.y;
			const sashWidthX = boundingBox2.max.x - boundingBox2.min.x;

			topSash.position.y = boundingBox.max.y - (sashWidthY / 2);
			topSash.scale.x = widthX / sashWidthX;

			var bottomSash = topSash.clone();
			bottomSash.position.y = boundingBox.min.y + (sashWidthY / 2);

			var leftSash = topSash.clone();
			leftSash.rotation.z = Math.PI / 2;
			leftSash.scale.x = widthY / sashWidthX;
			leftSash.position.x = boundingBox.min.x + (sashWidthY / 2);
			leftSash.position.y = transomFrame.current[glassPartIndex].position.y;

			var rightSash = leftSash.clone();
			rightSash.position.x = boundingBox.max.x - (sashWidthY / 2);


			sceneRef.current.add(topSash);
			sceneRef.current.add(bottomSash);
			sceneRef.current.add(leftSash);
			sceneRef.current.add(rightSash);
		})

	}


	const handleUpdateSashOperation = () => {
		getSashRef()
		// if (sashGroup?.current?.length < 1 && headerSelectedItem?.name !== "French Door") {
		// 	getSashRef()
		// radioButtonClicked.current = true;
		// }
	}


	const addThreshold = (model) => {

		//make bottom frame inactive
		var frameTop, base;

		if (!model) {
			return
		}

		Frame.current.forEach((item) => {
			if (item.name === "FrameBottom") {
				item.traverse((child) => {
					child.visible = false;
					//		child.scale.set(0,0,0);
				})
				item.visible = false;
				item.scale.set(0, 0, 0);
			}

			if (item.name === "FrameTop") {
				frameTop = item;
			}
		})

		//add threshold
		const gltfLoader = new GLTFLoader();
		gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${model?.customePath}`, function (gltf) {
			sceneRef.current.add(gltf.scene);
			const thresholdModel = gltf.scene;
			const boundingBox = new THREE.Box3().setFromObject(thresholdModel);
			const widthX = boundingBox.max.x - boundingBox.min.x;

			const boundingBoxGlass = new THREE.Box3().setFromObject(glassGroup.current[0]);
			const widthXGlass = boundingBoxGlass.max.x - boundingBoxGlass.min.x;

			thresholdModel.scale.x = widthXGlass / widthX;

			thresholdModel.traverse((item) => {
				if (item.name.includes("Base")) {

					//use thresholdData.current here
					item.scale.y = model.width / 70;
					item.scale.z = model.height / 70;
					base = item;
				}
			})

			thresholdModel.traverse((item) => {
				if (item.name.includes("Front")) {
					const boundingBox2 = new THREE.Box3().setFromObject(base);
					const boundingBox3 = new THREE.Box3().setFromObject(item);
					const itemWidth = boundingBox3.max.z - boundingBox3.min.z;
					const itemHeight = boundingBox3.max.y - boundingBox3.min.y;
					item.position.y = boundingBox2.max.y + itemHeight / 2;
					item.position.z = boundingBox2.min.z + itemWidth / 2;
				}
			})


			//set min z aligned with frame min
			const boundingBox4 = new THREE.Box3().setFromObject(frameTop);
			const boundingBox5 = new THREE.Box3().setFromObject(gltf.scene);
			if (boundingBox5.max.z > boundingBox4.max.z) {
				const newWidth = (boundingBox5.max.z - boundingBox5.min.z) / 2;
				gltf.scene.position.z = -(boundingBox4.max.z - newWidth);

			}

			const thresholdHeight = boundingBox5.max.y - boundingBox5.min.y;

			thresholdModel.position.y = boundingBoxGlass.min.y - thresholdHeight / 2;
		})

		//resize a/c profile size
	}

	// code not in used for profile number
	// useEffect(() => {
	// 	console.log(allProfileRefSeq?.current, "allProfileRefSeq")

	// 	if (allProfileRefSeq.current && allProfileRefSeq.current.length > 0 && modelVisible) {
	// 		if (sceneRef.current && sceneRef.current.children && sceneRef.current.children.length > 0) {

	// 			// before adding sq numbers again, remove all from scene
	// 			var seqNumbers = ''

	// 			sceneRef?.current?.traverse(function (object) {
	// 				if (object.name.includes("seqNumbers")) {
	// 					object.visible = false;
	// 					seqNumbers = object
	// 				}
	// 			});

	// 			if (seqNumbers) {
	// 				sceneRef.current.remove(seqNumbers);
	// 			}
	// 		}

	// 		setTimeout(() => {
	// 			allProfileRefSeq.current.forEach((item, index) => {
	// 				addProfileNumber(sceneRef, item?.position, index, item.name, item)
	// 			})
	// 		}, 100);
	// 	} else {
	// 		if (sceneRef.current && sceneRef.current.children && sceneRef.current.children.length > 0) {
	// 			var seqNumbers = ''

	// 			sceneRef?.current?.traverse(function (object) {
	// 				if (object.name.includes("seqNumbers")) {
	// 					object.visible = false;
	// 					seqNumbers = object
	// 				}
	// 			});

	// 			if (seqNumbers) {
	// 				sceneRef.current.remove(seqNumbers);
	// 			}
	// 		}
	// 	}
	// }, [allProfileRefSeq.current]);

	const getSashRef = () => {
		sashRef = [];
		if (sashGroup?.current?.length < 1) {
			sashRef.push(glassGroup.current[0])
			testRef.current = sashRef;

			if (customModelData?.numberOfSash && (Array.isArray(customModelData.numberOfSash) || customModelData?.numberOfSash?.number === 0)) {
				if (transomFrame.current && transomFrame.current.length > 1) {
					testRef.current = transomFrame.current
				}
			} else {
				testRef.current = sashRef;
			}
		} else {
			sashGroup.current.forEach((child, index) => {
				child.name.includes('GlassPanel')
				const newObj = new THREE.Object3D();
				newObj.position.set(child.position.x, child.position.y, child.position.z);

				sashRef.push(newObj)

				if (customModelData?.numberOfSash && (Array.isArray(customModelData.numberOfSash) || customModelData?.numberOfSash?.number === 0)) {
					if (transomFrame.current && transomFrame.current.length > 1) {
						testRef.current = transomFrame.current
					}
				} else {
					testRef.current = sashRef;
				}
			})
		}

	}


	function getSashFrameRef() {
		sashRef = [];
		// sashList.current = [];
		testRef.current = []
		newFrameRefPoints.current = []
		// sashGroup.current.forEach((child) => {
		sceneRef.current.traverse((item) => {
			if (item?.name.includes("verticalBarRight") || item?.name.includes("verticalBarLeft") || item?.name.includes("horizontalBarTop") || item?.name.includes("horizontalBarBottom")) {
				var worldPosition = new THREE.Vector3();
				var emptyObject = new THREE.Object3D();
				item.getWorldPosition(worldPosition);
				emptyObject.position.set(item.position);
				sashRef.push(item);
				// sashList.current.push(item);
			}
		})
		if (headerSelectedItem.name === "Heritage Door") {
			testRef.current = sashRef
			newFrameRefPoints.current = transomFrame.current
		} else {
			testRef.current = sashRef
		}
	}


	function getSashList() {
		sashList.current = [];
		sashGroup.current.forEach((child) => {
			child.traverse((item) => {
				if (item?.name.includes("verticalBar") || item?.name.includes("horizontalBar")) {
					sashList.current.push(item);
				}
			})
		})

		// setApplySashInit(true)
	}

	function setSashGroupAlignment(sashIndex, alignIndex) {
		if (sashGroup.current[sashIndex]?.position) {
			if (alignIndex == 1) {
				sashGroup.current[sashIndex].position.z = 0.02;
			}
			if (alignIndex == 2) {
				sashGroup.current[sashIndex].position.z = 0;
			}
			if (alignIndex == 0) {
				sashGroup.current[sashIndex].position.z = -0.02;
			}
		}
	}

	function addHinge(sash, isMax, hingeModel) {

		let model = hingeModel?.hardwareStyles?.length ? hingeModel?.hardwareStyles[0]?.modelFilePath : hingeModel?.modelFilePath

		const gltfLoader = new GLTFLoader();
		gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${model}`, function (gltf) {
			var hinge = gltf.scene
			hinge.scale.x = 3;
			hinge.scale.y = 3;
			hinge.scale.z = 3;

			const boundingBox = new THREE.Box3().setFromObject(sash);

			if (isMax) {
				hinge.position.x = boundingBox.max.x;
			} else {
				hinge.position.x = boundingBox.min.x + 0.05;
			}
			hinge.position.z = boundingBox.max.z;
			hinge.rotation.y = Math.PI

			var clone = hinge.clone()
			clone.position.y = boundingBox.max.y - 0.2;

			var clone2 = hinge.clone()
			clone2.position.y = boundingBox.min.y + 0.2;
			hingeModels.current.push(hinge);
			hingeModels.current.push(clone);
			hingeModels.current.push(clone2);

			hingePosition.current.push(new THREE.Vector3(hinge.position.x, hinge.position.y, hinge.position.z))
			hingePosition.current.push(new THREE.Vector3(clone.position.x, clone.position.y, clone.position.z))
			hingePosition.current.push(new THREE.Vector3(clone2.position.x, clone2.position.y, clone2.position.z))

			const allHinges = hingeModels.current.map((item, index) => {
				return {
					id: hingeModel?.hardwareStyles?.length ? hingeModel?.hardwareStyles[0]?.id : hingeModel?.id,
					hexValue: customModelData?.frame?.externalColor?.hex,
					color: customModelData?.frame?.externalColor?.name,
					hingeModel: hingeModel?.hardwareStyles?.length ? hingeModel?.hardwareStyles[0]?.modelFilePath : hingeModel.modelFilePath,
					name: hingeModel?.hardwareStyles?.length ? hingeModel?.hardwareStyles[0]?.name : hingeModel?.name,
					type: "hinge",
					verticalPos: 0,
					horizontalPos: 0,
					price: hingeModel?.hardwareStyles?.length ? hingeModel?.hardwareStyles[0]?.price : hingeModel?.price,
				}
			})

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					hingeData: allHinges
				}
			}))


			sceneRef.current.add(hinge)
			sceneRef.current.add(clone)
			sceneRef.current.add(clone2)
			// hingesRef.current.push(hinge)
		});
	}



	function addTransom(glassIndex, horizontal, resized, isInitCall, data, position) {

		// if (glazingRef.current.includes(glassGroup.current[0])) {
		// 	glazingRef.current.splice(0, 1)
		// }

		let dimensions = {
			heightScale: data?.height,
			widthScale: data?.width
		};

		let transomeLength = 0

		transomData.current = dimensions;

		try {

			// loading glb model
			const gltfLoader = new GLTFLoader();

			gltfLoader.load(`${servicePath}/ThreeJSModel/Glb/${data?.modelFilePath}`, function (gltf) {

				var cuboid = gltf.scene;
				cuboid.rotation.y = Math.PI;
				var base
				gltf.scene.traverse((child) => {

					if (child.name.includes("Base")) {
						base = child;
						child.scale.z = transomData.current.widthScale / 20
						child.scale.y = transomData.current.heightScale / 20
					}
				})


				var baseBoundingBox


				if (base != null) {
					baseBoundingBox = new THREE.Box3().setFromObject(base);
				}

				gltf.scene.traverse((child) => {
					if (child.name.includes("Front")) {
						child.position.z = baseBoundingBox.min.z;
					}

					if (child.name.includes("SpacerBarTop")) {
						const bB2 = new THREE.Box3().setFromObject(child);
						const height = bB2.max.y - bB2.min.y;
						child.position.y = baseBoundingBox.max.y + height / 2;
						child.name += " External"
					}


					if (child.name.includes("SpacerBarBottom") || child.name.includes("SpacerBarTop")) {
						addBead(`${servicePath}/ThreeJSModel/Glb/${initialProfile?.bead[0].customePath}`, child, !horizontal, initialProfile?.bead[0])
						if (!isInitCall || !resized) {
							storeBead()

							let data = {
								id: defaultSpec?.spacerBar[0].id,
								name: defaultSpec?.spacerBar[0].name,
								color: defaultSpec?.spacerBar[0].hexValue,
								price: defaultSpec?.spacerBar[0].price,
								modelName: defaultSpec?.spacerBar[0].modelName

							}
							setCustomModelData((prevModelData) => ({
								...prevModelData,
								glazing: {
									...prevModelData.glazing,
									spacerbars: [
										...prevModelData.glazing.spacerbars || [],
										data
									]
								}
							}))
							modifySpacerBar(defaultSpec?.spacerBar[0], false)


						}
					}

					if (child.name.includes("SpacerBarBottom")) {
						const bB2 = new THREE.Box3().setFromObject(child);
						const height = bB2.max.y - bB2.min.y;
						child.position.y = baseBoundingBox.min.y - height / 2;
						child.name += " External"
					}
				})

				if (transomFrame?.current?.length) {
					cuboid.position.set(
						transomFrame.current[glassIndex].position.x,
						transomFrame.current[glassIndex].position.y,
						transomFrame.current[glassIndex].position.z
					);

					const boundingBox = new THREE.Box3().setFromObject(
						transomFrame.current[glassIndex]
					);

					const width = boundingBox.max.x - boundingBox.min.x;
					const height = boundingBox.max.y - boundingBox.min.y;

					const boundingBox2 = new THREE.Box3().setFromObject(cuboid);
					const width2 = boundingBox2.max.x - boundingBox2.min.x;


					if (horizontal) {
						transomeLength = width
						cuboid.scale.x *= width / width2;
					} else {
						transomeLength = height
						cuboid.rotation.z = Math.PI / 2;
						cuboid.scale.x *= height / width2;
					}

					//set min z aligned with frame min
					const boundingBox4 = new THREE.Box3().setFromObject(addFrameRef.current[0]);
					const boundingBox5 = new THREE.Box3().setFromObject(gltf.scene);

					if (boundingBox5.min.z < boundingBox4.min.z) {
						const newWidth = (boundingBox5.max.z - boundingBox5.min.z) / 2;
						gltf.scene.position.z = boundingBox4.min.z + newWidth;
					}


					if (!isInitCall) {
						if (!resized) {
							var queueItem = {
								'id': data?.id,
								'name': data?.name,
								'index': glassIndex,
								'horizontal': horizontal,
								'width': transomData.current.widthScale,
								'height': transomData.current.heightScale,
								'modelFilePath': data?.modelFilePath,
								'price': data?.price,
								'length': transomeLength,
								'orientation': horizontal ? 'horizontal' : 'vertical',
								'frameType': 'Transom profile',
								'externalPaintSurfaceArea': data?.externalPaintSurfaceArea,
								'internalPaintSurfaceArea': data?.internalPaintSurfaceArea,
								position
							}

							setCustomModelData((prevData) => ({
								...prevData,
								storedTransoms: [
									...prevData?.storedTransoms || [],
									queueItem
								]
							}))
						}
					}

					// assigning dynamic colors
					gltf.scene.traverse((child) => {
						if (child.name.includes("Bead")) {
							bead.current.push(child);
							if (!isInitCall || !resized) {
								let data = {
									id: beadList[0]?.id,
									name: beadList[0].name,
									price: beadList[0]?.price,
									imagePath: beadList[0]?.imagePath,
									filePath: beadList[0]?.filePath,
									externalPaintSurfaceArea: beadList[0]?.externalPaintSurfaceArea,
									internalPaintSurfaceArea: beadList[0]?.internalPaintSurfaceArea,
									profileTypePrice: beadList[0]?.profileTypePrice,
									frameType: "Bead",
								}
								setCustomModelData((prevModelData) => ({
									...prevModelData,
									bead: [
										...prevModelData.bead || [],
										data
									]
								}))
							}
						}


						if (child.name.includes("Internal")) {
							internalFrameRef.current.push(child);
						}

						if (child.name.includes("External")) {
							externalFrameRef.current.push(child);
							defaultFrameMaterial.current = child.material;
						}
					})



					setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)
					sceneRef.current.add(cuboid);
					transoms.current.push(cuboid);
					var pos = new THREE.Vector3(transoms.current[transoms.current.length - 1].position.x, transoms.current[transoms.current.length - 1].position.y, transoms.current[transoms.current.length - 1].position.z)
					transomPosition.current.push(pos);
					if (!resized) {
						devideTransomFrame(horizontal, glassIndex, cuboid);
					}
					setCheckSingleSide()
					beadSize()
				} else {
					console.error('Invalid glassGroup.current or glassIndex');
				}
			})
		} catch (error) {
			console.error('An error occurred in addTransom:', error);
		}
	}



	const transomDistanceAdded = (transomDist) => {

		if (transoms.current.length < 1) {
			return;
		}


		var distanceFactor = 0.01;
		var topTransomFrameIndex = transomFrame.current.length - 1;

		if (transoms.current[transoms.current.length - 1].rotation.z == 0) {
			transoms.current[transoms.current.length - 1].position.y = transomPosition.current[transoms.current.length - 1].y + transomDist * distanceFactor;

			const boundingBox = new THREE.Box3().setFromObject(transomFrame.current[topTransomFrameIndex]);
			const minY = boundingBox.min.y;
			const maxY = transoms.current[transoms.current.length - 1].position.y;
			const center = (minY + maxY) / 2;
			transomFrame.current[topTransomFrameIndex].position.y = center;
			transomFrame.current[topTransomFrameIndex].scale.y *= (maxY - minY) / (boundingBox.max.y - minY)

			const boundingBox2 = new THREE.Box3().setFromObject(transomFrame.current[previousTransomFrameRef.current]);
			const maxY2 = boundingBox2.max.y;
			const minY2 = transoms.current[transoms.current.length - 1].position.y;
			const center2 = (minY2 + maxY2) / 2;
			transomFrame.current[previousTransomFrameRef.current].position.y = center2;
			transomFrame.current[previousTransomFrameRef.current].scale.y *= (maxY2 - minY2) / (maxY2 - boundingBox2.min.y)


		} else {

			transoms.current[transoms.current.length - 1].position.x = transomPosition.current[transoms.current.length - 1].x + transomDist * distanceFactor;

			const boundingBox = new THREE.Box3().setFromObject(transomFrame.current[topTransomFrameIndex]);
			const minX = boundingBox.min.x;
			const maxX = transoms.current[transoms.current.length - 1].position.x;
			const center = (minX + maxX) / 2;
			transomFrame.current[topTransomFrameIndex].position.x = center;
			transomFrame.current[topTransomFrameIndex].scale.x *= (maxX - minX) / (boundingBox.max.x - minX)

			const boundingBox2 = new THREE.Box3().setFromObject(transomFrame.current[previousTransomFrameRef.current]);
			const maxX2 = boundingBox2.max.x;
			const minX2 = transoms.current[transoms.current.length - 1].position.x;
			const center2 = (minX2 + maxX2) / 2;
			transomFrame.current[previousTransomFrameRef.current].position.x = center2;
			transomFrame.current[previousTransomFrameRef.current].scale.x *= (maxX2 - minX2) / (maxX2 - boundingBox2.min.x)
		}

	}


	function removeTransom(index) {
		sceneRef.current.remove(transoms.current[index]);
		transoms.current.splice(index, 1)
		setDeleteItem()
		setMultiSelectRefPoints([])
		setDeleteRefPoints(transoms.current)
	}

	function removeAllTransom() {
		// setTansomsReference()

		// when sash count is zero
		setTansomsReference(transomFrame.current)
		addInitialTransomFrame()
		transoms.current.forEach((child, index) => {
			sceneRef.current.remove(transoms.current[index]);
		});
		transoms.current = [];
		testRef.current = []
		transomFrame.current = []
		setDeleteItem()
		// setCustomModelData((prevModelData) => ({
		// 	...prevModelData,
		// 	storedTransoms: []
		// }))
		getGlazingRef()
		testRef.current = glazingRef.current
	}

	function addTransomFrame() {
		sashGroup.current.forEach((child) => {

			// Create a new object with the same bounding box
			const newGeometry = new THREE.BoxGeometry();
			const newMaterial = new THREE.MeshBasicMaterial({ visible: false }); // { visible: false } { color: 0xff0000 }
			const newObject = new THREE.Mesh(newGeometry, newMaterial);
			sceneRef.current.add(newObject);

			newObject.position.set(child.position.x, child.position.y, child.position.z);

			const boundingBox = new THREE.Box3().setFromObject(child);
			const height = boundingBox.max.y - boundingBox.min.y;
			const width = boundingBox.max.x - boundingBox.min.x;

			const boundingBox2 = new THREE.Box3().setFromObject(newObject);
			const height2 = boundingBox2.max.y - boundingBox2.min.y;
			const width2 = boundingBox2.max.x - boundingBox2.min.x;


			newObject.scale.set(width / width2, height / height2, 0.1);

			//transomFrame.current.push(newObject);
			//setTransomFrameRef(transomFrame.current)
		});
	}


	function devideTransomFrame(horizontal, index, newCreatedtransom) {
		previousTransomFrameRef.current = index;

		if (horizontal) {
			const boundingBox = new THREE.Box3().setFromObject(transomFrame.current[index]);
			const height = boundingBox.max.y - boundingBox.min.y;
			transomFrame.current[index].scale.y = transomFrame.current[index].scale.y / 2;
			const clone = transomFrame.current[index].clone();
			transomFrame.current[index].position.y += height / 4;
			clone.position.y -= height / 4;
			transomFrame.current.push(clone);
			sceneRef.current.add(clone);
			setTansomsReference(transomFrame.current)
			testRef.current = transomFrame.current

			const nestedPair = new Map();
			nestedPair.set("Bottom", clone);

			const mainPair = new Map();
			mainPair.set(newCreatedtransom, nestedPair);

			transomRelativePosition.current.push(mainPair);

		} else {
			const boundingBox = new THREE.Box3().setFromObject(transomFrame.current[index]);
			const width = boundingBox.max.x - boundingBox.min.x;
			transomFrame.current[index].scale.x = transomFrame.current[index].scale.x / 2;
			const clone = transomFrame.current[index].clone();
			transomFrame.current[index].position.x += width / 4;
			clone.position.x -= width / 4;
			transomFrame.current.push(clone);
			sceneRef.current.add(clone);
			setTansomsReference(transomFrame.current)
			testRef.current = transomFrame.current

			const nestedPair = new Map();
			nestedPair.set("Left", clone);

			const mainPair = new Map();
			mainPair.set(newCreatedtransom, nestedPair);

			transomRelativePosition.current.push(mainPair);
		}
		transomFrame.current.forEach((splittedGlass) => {
			if (headerSelectedItem.name !== "Heritage Door") {
				splittedGlass.name = "GlassPanel"
			} else {
				splittedGlass.name = "GlassPanel LockingPlate"
			}
		})
	}

	const updateTransomPosition = () => {
		transomRelativePosition.current.forEach((mainPair) => {
			// Iterate over each mainPair (Map)
			mainPair.forEach((nestedPair, key) => {

				// Iterate over the nestedPair (Map)
				nestedPair.forEach((value, nestedKey) => {

					if (nestedKey == "Left") {
						const boundingBox = new THREE.Box3().setFromObject(value);
						key.position.x = boundingBox.max.x;
					}

					if (nestedKey == "Bottom") {
						const boundingBox = new THREE.Box3().setFromObject(value);
						key.position.y = boundingBox.max.y;
					}
				});
			});
		});
	}


	const sortTransomFrame = (horizontal) => {
		if (horizontal) {
			// Extract x positions from the objects and store them in an array
			const xPositions = transomFrame.current.map(obj => obj.position.x);

			// Sort the x positions array
			const sortedXPositions = xPositions.slice().sort((a, b) => a - b);

			// Create a new array of objects sorted by their x position
			transomFrameSortedX.current = sortedXPositions.map(xPos =>
				transomFrame.current.find(obj => obj.position.x === xPos)
			);
		} else {
			// Extract x positions from the objects and store them in an array
			const xPositions = transomFrame.current.map(obj => obj.position.y);

			// Sort the x positions array
			const sortedXPositions = xPositions.slice().sort((a, b) => a - b);

			// Create a new array of objects sorted by their x position
			transomFrameSortedY.current = sortedXPositions.map(xPos =>
				transomFrame.current.find(obj => obj.position.y === xPos)
			);
		}
	}

	const addPerpendicularFrame = (data) => {
		const emptyMesh = new THREE.Mesh();
		const emptyMeshVertical = new THREE.Mesh()
		data?.current.forEach((child) => {

			if (child.name === "FrameRight") {
				emptyMesh.position.set(child.position)
				emptyMesh.position.x = child.position.x + 3;
				emptyMesh.name = "FrameRightPerpendicular"
				addFrameRef.current.push(emptyMesh);
				setNewFrameData(prevFrameData => [...prevFrameData, emptyMesh])

			}
			if (child.name === "FrameLeft") {
				emptyMesh.position.set(child.position)
				emptyMesh.position.x = child.position.x - 3;
				emptyMesh.name = "FrameLeftPerpendicular"
				addFrameRef.current.push(emptyMesh);
				setNewFrameData(prevFrameData => [...prevFrameData, emptyMesh])
			}

			if (child.name === "FrameTop") {
				emptyMeshVertical.position.x = child.position.x
				emptyMeshVertical.position.y = child.position.y + 0.5;
				emptyMeshVertical.position.z = child.position.z;
				emptyMeshVertical.name = "FrameTopPerpendicular"
				addFrameRef.current.push(emptyMeshVertical);
				setNewFrameData(prevFrameData => [...prevFrameData, emptyMeshVertical])
			}

			if (child.name === "FrameBottom") {
				emptyMeshVertical.position.x = child.position.x
				emptyMeshVertical.position.y = child.position.y - 0.5;
				emptyMeshVertical.position.z = child.position.z;
				emptyMeshVertical.name = "FrameBottomPerpendicular"
				addFrameRef.current.push(emptyMeshVertical);
				setNewFrameData(prevFrameData => [...prevFrameData, emptyMeshVertical])
			}
		})

		addFrameRef.current = removeDuplicatesByPosition(addFrameRef.current);
	}


	//use this for rotating window (window animation)
	function rotateObjectAroundPoint(rotationAmount) {
		rotationAmount = -1 * rotationAmount;
		// Convert the rotation amount from degrees to radians
		const radians = THREE.MathUtils.degToRad(rotationAmount);
		const rotationPoint = new THREE.Vector3(0, 0, 0);
		const object = handleExternal.current[0];
		// Translate the object to the origin of the rotation point
		object.position.sub(rotationPoint);

		// Rotate the object around the y-axis
		object.rotateX(radians);

		// Translate the object back to its original position
		object.position.add(rotationPoint);
	}

	function setHandleCylinderColor(currentIndex, hex) {
		//create material
		const mat = new THREE.MeshBasicMaterial({
			color: hex,
			metalness: 1,
			roughness: 0.5
		});

		handleCylinder.current.forEach((cylinder, index) => {
			if (index == currentIndex * 2 || index == (currentIndex * 2) + 1) {
				//set cylinder material
				cylinder.material = mat;
			}
		});
	}

	function setHandleCylinderPosition(horizontal, units, currentIndex) {
		units = units * 0.01;
		handleCylinder?.current?.forEach((cylinder, index) => {
			if (index == currentIndex * 2 || index == (currentIndex * 2) + 1) {
				//move horizontally
				if (horizontal) {
					cylinder.position.x = cylinderPosition?.current[index]?.x + units;
				} else //move vertically
				{
					cylinder.position.y = cylinderPosition?.current[index]?.y + units;
				}
			}
		});
	}

	function setTrickleVentColor(currentIndex, hex) {
		//create material
		const mat = new THREE.MeshStandardMaterial({
			color: hex,
			metalness: 1,
			roughness: 0.5
		});

		tickleVentModels.current.forEach((handle, index) => {
			if (index == currentIndex) {
				handle.children[0].material = mat;
			}
		});

	}

	function setTrickleVentPosition(horizontal, currentIndex, units) {
		units = units * 0.01;
		tickleVentModels.current.forEach((handle, index) => {
			if (index == currentIndex) {
				if (horizontal) {
					if (handle && tickleVentPosition)
						handle.position.x = tickleVentPosition.current[index].x + units;
				} else {
					if (handle && handlePosition)
						handle.position.y = tickleVentPosition.current[index].y + units;
				}
			}
		});

	}

	const setHardwareColor = (hex, elementModels) => {
		const mat = new THREE.MeshStandardMaterial({
			color: hex,
			metalness: 1,
			roughness: 0.5
		});

		elementModels.current.forEach((spyHole, index) => {
			spyHole.children[0].material = mat;
		})
	}

	const setSpyHoleColor = (hex) => {
		setHardwareColor(hex, spyHoleModel)
	}

	const setLetterPlateColor = (hex) => {
		setHardwareColor(hex, letterPlateModel)
	}

	const setKnockerColors = (hex) => {
		setHardwareColor(hex, knockerModel)
	}

	function setPositionForElement(elementModels, elementPositions, horizontal, units) {
		units *= 0.01;
		elementModels.current.forEach((element, index) => {
			if (element) {
				if (horizontal) {
					if (elementPositions.current)
						element.position.x = elementPositions.current[index].x + units;
				} else {
					if (elementPositions.current)
						element.position.y = elementPositions.current[index].y + units;
				}
			}
		});
	}


	function setTextPosition(horizontal, units) {
		units *= 0.01;
		if (textMeshRef.current) {
			if (horizontal) {
				if (textMeshPos.current)
					textMeshRef.current.position.x = textMeshPos.current.x + units;
			} else {
				if (textMeshPos.current) {
					textMeshRef.current.position.y = textMeshPos.current.y + units
				}
			}
		}
	}

	function setSpyHolePosition(horizontal, units) {
		setPositionForElement(spyHoleModel, spyHolePosition, horizontal, units);
	}

	function setLetterPlatePosition(horizontal, units) {
		setPositionForElement(letterPlateModel, letterPlatePos, horizontal, units);
	}

	function setKnockerPosition(horizontal, units) {
		setPositionForElement(knockerModel, knockerPos, horizontal, units);
	}

	// function setSpyHolePosition(horizontal, units) {
	// 	
	// 	units = units * 0.01;
	// 	spyHoleModel.current.forEach((spyHole, index) => {
	// 		if (horizontal) {
	// 			if (spyHole && tickleVentPosition)
	// 				spyHole.position.x = spyHolePosition.current[index].x + units;
	// 		} else {
	// 			if (spyHole && handlePosition)
	// 				spyHole.position.y = spyHolePosition.current[index].y + units;
	// 		}

	// 	});
	// }

	// function setLetterPlatePosition(horizontal, units) {
	// 	
	// 	units = units * 0.01;
	// 	letterPlateModel.current.forEach((letterPlate, index) => {
	// 		if (horizontal) {
	// 			if (letterPlate && letterPlatePos)
	// 				letterPlate.position.x = letterPlatePos.current[index].x + units;
	// 		} else {
	// 			if (letterPlate && letterPlatePos)
	// 				letterPlate.position.y = letterPlatePos.current[index].y + units;
	// 		}

	// 	});
	// }


	// function deleteSpyHole(index) {
	// 	const newArray = [
	// 		...customModelData?.hardware?.spyhole?.slice(0, index),
	// 		...customModelData?.hardware?.spyhole?.slice(index + 1),
	// 	];
	// 	if (index >= 0 && index < spyHoleModel.current.length) {
	// 		spyHoleModel.current[index].visible = false;
	// 		sceneRef.current.remove(spyHoleModel.current[index]);
	// 		spyHoleModel.current.splice(index, 1);
	// 		spyHolePosition.current.splice(index, 1);
	// 	}
	// 	setCustomModelData((prevModelData) => ({
	// 		...prevModelData,
	// 		hardware: {
	// 			...prevModelData.hardware,
	// 			spyHole: newArray,
	// 		},
	// 	}));
	// }

	function deleteElement(type, index) {
		let newArray = [];
		switch (type) {
			case 'spyhole':
				newArray = [
					...customModelData?.hardware?.spyhole?.slice(0, index),
					...customModelData?.hardware?.spyhole?.slice(index + 1),
				];
				if (index >= 0 && index < spyHoleModel.current.length) {
					spyHoleModel.current[index].visible = false;
					sceneRef.current.remove(spyHoleModel.current[index]);
					spyHoleModel.current.splice(index, 1);
					spyHolePosition.current.splice(index, 1);
				}
				break;
			case 'letterPlate':
				newArray = [
					...customModelData?.hardware?.letterPlate?.slice(0, index),
					...customModelData?.hardware?.letterPlate?.slice(index + 1),
				];
				if (index >= 0 && index < letterPlateModel.current.length) {
					letterPlateModel.current[index].visible = false;
					sceneRef.current.remove(letterPlateModel.current[index]);
					letterPlateModel.current.splice(index, 1);
					letterPlatePos.current.splice(index, 1);
				}
				break;
			case 'knocker':
				newArray = [
					...customModelData?.hardware?.knocker?.slice(0, index),
					...customModelData?.hardware?.knocker?.slice(index + 1)
				]

				if (index >= 0 && index < knockerModel.current.length) {
					knockerModel.current[index].visible = false;
					sceneRef.current.remove(knockerModel.current[index]);
					knockerModel.current.splice(index, 1);
					knockerPos.current.splice(index, 1);
				}
				break;
			case 'numeral':
				setCustomModelData((prevModelData) => ({
					...prevModelData,
					hardware: {
						...prevModelData.hardware,
						numeral: [], // Remove the numeral key
					},
				}));
				setHardwareType('')
				if (textMeshRef.current) {
					sceneRef.current.remove(textMeshRef.current)
					textMeshRef.current = null
				}
				break;
			default:
				break;
		}
		if (type !== "numeral") {
			setCustomModelData((prevModelData) => {
				const { [type]: _, ...restHardware } = prevModelData.hardware;
				return {
					...prevModelData,
					hardware: restHardware,
				};
			});
		}
		setHardwareData();
		setElementDeleted(true)
	}

	function deleteTrickleVent(index) {
		const newArray = [
			...customModelData?.hardware?.trickleVent?.slice(0, index),
			...customModelData?.hardware?.trickleVent?.slice(index + 1),
		];
		if (index >= 0 && index < tickleVentModels.current.length) {
			tickleVentModels.current[index].visible = false;
			sceneRef.current.remove(tickleVentModels.current[index]);
			tickleVentModels.current.splice(index, 1);
			tickleVentPosition.current.splice(index, 1);
		}
		setCustomModelData((prevModelData) => ({
			...prevModelData,
			hardware: {
				...prevModelData.hardware,
				trickleVent: newArray,
			},
		}));
		setElementDeleted(true)
		setHardwareData()
	}

	function twoSideElementColor(external, internal, currentIndex, hex) {
		const mat = new THREE.MeshBasicMaterial({
			color: hex,
			metalness: 1,
			roughness: 0.5
		});

		external.current.forEach((handle, index) => {
			if (index == currentIndex) {
				handle.material = mat;
			}
		});

		internal.current.forEach((handle, index) => {
			if (index == currentIndex) {
				handle.material = mat;
			}
		});
	}


	function setTwoSidedElementPosition(side, horizontal, currentIndex, units, external, internal, pos) {
		units = units * 0.01;
		//inside
		if (side == 2) {
			internal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && pos.current.length)
							handle.position.x = units;
					} else {
						if (handle && pos.current.length)
							handle.position.y = units;
					}
				}
			});
		}

		//outside
		if (side == 1) {
			external.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && pos.current.length)
							handle.position.x = units;
					} else {
						if (handle && pos.current.length)
							handle.position.y = units;
					}
				}
			});
		}

		//both
		if (side == 3) {
			internal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && pos.current.length)
							handle.position.x = units;
					} else {
						if (handle && pos.current.length)
							handle.position.y = units;
					}
				}
			});

			external.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && pos.current.length)
							handle.position.x = units;
					} else {
						if (handle && pos.current.length)
							handle.position.y = units;
					}
				}
			});
		}
	}

	function setEscutecheonPosition(side, horizontal, currentIndex, units) {
		setTwoSidedElementPosition(side, horizontal, currentIndex, units, escutcheonExternalModel, escutcheonInternalModel, escutcheonExternalPos)
	}

	const setEscutcheonColor = (currentIndex, hex) => {
		twoSideElementColor(escutcheonExternalModel, escutcheonInternalModel, currentIndex, hex)
	}

	const setHingeColor = (hex) => {
		const mat = new THREE.MeshBasicMaterial({
			color: hex ? hex : customModelData.frame.externalColor.hex,
			metalness: 1,
			roughness: 0.5
		});
		hingeModels?.current?.forEach((hinge) => {
			hinge?.traverse((subChild) => {
				subChild.material = mat
			})
		})
	}

	const setHingePosition = (horizontal, currentIndex, units) => {
		units = units * 0.001;
		hingeModels.current.forEach((child, index) => {
			if (currentIndex <= 2 && index <= 2) {
				if (horizontal) {
					if (child && hingePosition)
						child.position.x = hingePosition.current[index].x + units;
				} else {
					if (child && hingePosition)
						child.position.y = hingePosition.current[index].y + units;
				}
			} else if (currentIndex > 2 && index > 2) {
				if (horizontal) {
					if (child && hingePosition)
						child.position.x = hingePosition.current[index].x + units;
				} else {
					if (child && hingePosition)
						child.position.y = hingePosition.current[index].y + units;
				}
			}


			// child.traverse((subChild) => {
			// 	if (currentIndex <= 2 && index <= 2) {
			// 		if (horizontal) {
			// 			if (subChild && hingePosition)
			// 				subChild.position.x = hingePosition.current[index].x + units;
			// 		} else {
			// 			if (subChild && hingePosition)
			// 				subChild.position.y = hingePosition.current[index].y + units;
			// 		}
			// 	} else if (currentIndex > 2 && index > 2) {
			// 		if (horizontal) {
			// 			if (subChild && hingePosition)
			// 				subChild.position.x = hingePosition.current[index].x + units;
			// 		} else {
			// 			if (subChild && hingePosition)
			// 				subChild.position.y = hingePosition.current[index].y + units;
			// 		}
			// 	}
			// })
		});
	}

	const deleteHinge = (index) => {
		const newArray = [
			...customModelData?.hardware?.hingeData?.slice(0, index),
			...customModelData?.hardware?.hingeData?.slice(index + 1),
		];
		if (index > -1 && index < hingeModels?.current?.length) {
			hingeModels.current[index].visible = false;
			gltfModel.current.remove(hingeModels.current[index]);
			sceneRef.current.remove(hingeModels.current[index]);
			hingeModels.current.splice(index, 1);
			// handlePosition.current.splice(index, 1);
		}
		setCustomModelData((prevData) => ({
			...prevData,
			hardware: {
				...prevData.hardware,
				hingeData: newArray
			}
		}))
	}


	function setHandleColor(currentIndex, hex) {
		//create material
		const mat = new THREE.MeshBasicMaterial({
			color: hex,
			metalness: 1,
			roughness: 0.5
		});

		handleExternal.current.forEach((handle, index) => {
			if (index == currentIndex) {
				handle.material = mat;
			}
		});

		handleInternal.current.forEach((handle, index) => {
			if (index == currentIndex) {
				handle.material = mat;
			}
		});
	}

	function setBarHandleColor(currentIndex, hex) {
		//create material
		const mat = new THREE.MeshBasicMaterial({
			color: hex,
			metalness: 1,
			roughness: 0.5
		});

		barHandlesOffsetExternal.current.forEach((handle, index) => {
			if (index == currentIndex) {
				handle.material = mat;
			}
		});

		barHandlesOffsetInternal.current.forEach((handle, index) => {
			if (index == currentIndex || index == currentIndex - 1) {
				handle.material = mat;
			}
		});

		if (hex && currentIndex) {

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					barHandlesOffset: prevModelData.hardware.barHandlesOffset.map((item, index) => {
						if (index === currentIndex) {
							return {
								...item,
								color: hex
							}
						}
						return item
					})
				}
			}))


		}
	}

	function setHandlePosition(side, horizontal, currentIndex, units, isStored) {
		units = units * 0.001;
		let handleCoordinates;
		//inside
		if (side == 1) {
			handleInternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && handlePosition)
							handle.position.x = handlePosition.current[index].x + units;
					} else {
						if (handle && handlePosition)
							handle.position.y = handlePosition.current[index].y + units;
					}
					handleCoordinates = handle.position
				}
			});
		}

		//outside
		if (side == 2) {
			handleExternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && handlePosition)
							handle.position.x = handlePosition.current[index].x + units;
					} else {
						if (handle && handlePosition)
							handle.position.y = handlePosition.current[index].y + units;
					}
					handleCoordinates = handle.position
				}
			});
		}

		//both
		if (side == 3) {
			handleInternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && handlePosition)
							handle.position.x = units;
					} else {
						if (handle && handlePosition)
							handle.position.y = units;
					}
				}
			});


			handleExternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && handlePosition)
							handle.position.x = units;
					} else {
						if (handle && handlePosition)
							handle.position.y = units;
					}
					handleCoordinates = handle.position

				}
			});
		}

		if (!isStored) {
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					handle: customModelData?.hardware?.handle.map((item) => {
						if (currentIndex === item.index) {
							return {
								...item,
								side: side,
								horizontalPos: horizontalPos,
								verticalPos: verticalPos,
								position: handleCoordinates

							}
						} else {
							return item
						}
					})
				}
			}))
		}

	}



	function setBarHandlePostion(side, horizontal, currentIndex, units) {
		let originalUnit = units
		units = units * 0.001;

		if (side == 2) {
			barHandlesOffsetInternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && barHandlesOffsetInternalPos)
							handle.position.x = barHandlesOffsetInternalPos.current[index].x + units;

					} else {
						if (handle && barHandlesOffsetInternalPos)
							handle.position.y = barHandlesOffsetInternalPos.current[index].y + units;
					}
					customModelData.hardware.barHandlesOffset.forEach((item) => {
						if (item.index === index && !item.isOutside) {
							setCustomModelData((prevModelData) => ({
								...prevModelData,
								hardware: {
									...prevModelData.hardware,
									barHandlesOffset: prevModelData.hardware.barHandlesOffset.map((offset) => {
										if (offset.index === index && offset.isOutside) {
											return {
												...offset,
												horizontalPos: horizontal ? originalUnit : offset.horizontalPos,
												verticalPos: !horizontal ? originalUnit : offset.verticalPos

											};
										}
										return offset;
									})
								}
							}))
						}
					})
				}
			});
		}

		if (side == 1) {
			barHandlesOffsetExternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle)
							handle.position.x = barHandlesOffsetExternalPos.current[index].x + units;;
					} else {
						if (handle)
							handle.position.y = barHandlesOffsetExternalPos.current[index].y + units;;
					}
					customModelData.hardware.barHandlesOffset.forEach((item) => {
						if (item.index === index && item.isOutside) {
							setCustomModelData((prevModelData) => ({
								...prevModelData,
								hardware: {
									...prevModelData.hardware,
									barHandlesOffset: prevModelData.hardware.barHandlesOffset.map((offset) => {
										if (offset.index === index && offset.isOutside) {
											return {
												...offset,
												horizontalPos: horizontal ? originalUnit : offset.horizontalPos,
												verticalPos: !horizontal ? originalUnit : offset.verticalPos

											};
										}
										return offset;
									})
								}
							}))
						}
					})
				}
			});
		}

		if (side == 3) {
			barHandlesOffsetInternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && handlePosition)
							handle.position.x = units;
					} else {
						if (handle && handlePosition)
							handle.position.y = units;
					}
					customModelData.hardware.barHandlesOffset.forEach((item) => {
						if (item.index === index && !item.isOutside) {
							setCustomModelData((prevModelData) => ({
								...prevModelData,
								hardware: {
									...prevModelData.hardware,
									barHandlesOffset: prevModelData.hardware.barHandlesOffset.map((offset) => {
										if (offset.index === index && !offset.isOutside) {
											return {
												...offset,
												horizontalPos: horizontal ? originalUnit : offset.horizontalPos,
												verticalPos: !horizontal ? originalUnit : offset.verticalPos

											};
										}
										return offset;
									})
								}
							}))
						}
					})
				}
			});


			barHandlesOffsetExternal.current.forEach((handle, index) => {
				if (index == currentIndex) {
					if (horizontal) {
						if (handle && handlePosition)
							handle.position.x = units;
					} else {
						if (handle && handlePosition)
							handle.position.y = units;
					}
					customModelData.hardware.barHandlesOffset.forEach((item) => {
						if (item.index === index && !item.isOutside) {
							setCustomModelData((prevModelData) => ({
								...prevModelData,
								hardware: {
									...prevModelData.hardware,
									barHandlesOffset: prevModelData.hardware.barHandlesOffset.map((offset) => {
										if (offset.index === index && offset.isOutside) {
											return {
												...offset,
												horizontalPos: horizontal ? originalUnit : offset.horizontalPos,
												verticalPos: !horizontal ? originalUnit : offset.verticalPos

											};
										}
										return offset;
									})
								}
							}))
						}
					})
				}
			});
		}

	}



	function deleteHandleCylinder(currentIndex) {

		if (!handleCylinder.current.length) {
			return;
		}

		let index = currentIndex + 1;
		if (currentIndex >= -1 && currentIndex <= handleExternal.current.length) {
			handleCylinder.current[currentIndex].visible = false;
			handleCylinder.current[index].visible = false;
			sceneRef.current.remove(handleCylinder.current[currentIndex]);
			sceneRef.current.remove(handleCylinder.current[index]);
			handleCylinder.current.splice(currentIndex, index + 1);

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					cylinder: customModelData.hardware.cylinder.filter((item, index) => index !== currentIndex)
				}

			}))
		}
	}

	function deleteHandle(index) {
		if (index > -1) {
			const newArray = [
				...customModelData?.hardware?.handle?.slice(0, index),
				...customModelData?.hardware?.handle?.slice(index + 1),
			];
			if (index > -1 && index < handleExternal.current.length) {
				handleExternal.current[index].visible = false;
				gltfModel.current.remove(handleExternal.current[index]);
				sceneRef.current.remove(handleExternal.current[index]);
				handleExternal.current.splice(index, 1);
				handlePosition.current.splice(index, 1);
			}

			if (index > -1 && index < handleInternal.current.length) {
				handleInternal.current[index].visible = false;
				gltfModel.current.remove(handleInternal.current[index]);
				sceneRef.current.remove(handleInternal[index]);
				handleInternal.current.splice(index, 1);
			}

			handleObjects.current.splice(index, 1);
			addedHandles.current.splice(index, 1);

			setCustomModelData((prevModelData) => ({
				...prevModelData,
				storedHandles: addedHandles
			}))
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					handle: newArray,
				},
			}));
			setHandleDeleted(true)
			setHardwareData()
		}
	}


	function deleteBarHandles(index, side) {
		if (side === 3) {
			const newArray = customModelData.hardware.barHandlesOffset.filter(item => item.index !== index);

			if (index > -1 && index < barHandlesOffsetExternal.current.length) {
				barHandlesOffsetExternal.current[index].visible = false;
				gltfModel.current.remove(barHandlesOffsetExternal.current[index]);
				sceneRef.current.remove(barHandlesOffsetExternal.current[index]);
				barHandlesOffsetExternal.current.splice(index, 1);
				barHandlesOffsetExternalPos.current.splice(index, 1);
			}

			if (index > -1 && index < barHandlesOffsetInternal.current.length) {
				barHandlesOffsetInternal.current[index].visible = false;
				gltfModel.current.remove(barHandlesOffsetInternal.current[index]);
				sceneRef.current.remove(barHandlesOffsetInternal.current[index]);
				barHandlesOffsetInternal.current.splice(index, 1);
				barHandlesOffsetInternalPos.current.splice(index, 1)
			}
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					barHandlesOffset: newArray,
				},
			}));
			setHandleDeleted(true)
			// setHardwareData()
		}
	}




	function deleteTwoSidedElement(index, type) {
		let newArray = [];
		switch (type) {
			case "escutcheon":
				newArray = [
					...customModelData?.hardware?.[type]?.slice(0, index),
					...customModelData?.hardware?.[type]?.slice(index + 1),
				];
				if (index >= 0 && index < escutcheonExternalModel.current.length) {
					escutcheonExternalModel.current[index].visible = false;
					gltfModel.current.remove(escutcheonExternalModel.current[index]);
					sceneRef.current.remove(escutcheonExternalModel.current[index]);
					escutcheonExternalModel.current.splice(index, 1);
					escutcheonExternalPos.current.splice(index, 1);
				}

				if (index >= 0 && index < escutcheonInternalModel.current.length) {
					escutcheonInternalModel.current[index].visible = false;
					gltfModel.current.remove(escutcheonInternalModel.current[index]);
					sceneRef.current.remove(escutcheonInternalModel[index]);
					escutcheonInternalModel.current.splice(index, 1);
				}

				// handleObjects.current.splice(index, 1);
				break;

			default:
				break;
		}

		setCustomModelData((prevModelData) => ({
			...prevModelData,
			hardware: {
				...prevModelData.hardware,
				[type]: newArray,
			},
		}));
		setHardwareData()
		setElementDeleted(true)
	}

	function setGlassDesignColumn(numOfColumn, modelPath, uniqueIndex = null) {

		let i = 0;
		for (i; i < glazingRef.current.length; i++) {
			if (uniqueIndex == null) {
				updateGlassDesignColumn(astragalColumnLeft, numOfColumn, i, uniqueIndex);
			} else {
				if (i == uniqueIndex) {
					updateGlassDesignColumn(astragalColumnLeft, Math.ceil(numOfColumn / (glazingRef.current.length)), i, uniqueIndex);
				}
			}
		}
	}


	//-0.38 to 0.4
	function updateGlassDesignRow(pathModel, numberOfRow, uniqueIndex = null) {
		if (numberOfRow <= 1) {
			// If number of rows is 0 or 1, do nothing
			return;
		}

		objectGroupRow.current = new THREE.Object3D();

		// Load the GLTF model (assuming you've already loaded the model and assigned it to a variable)
		const loader = new GLTFLoader();
		let gltfModel = pathModel;

		loader.load(pathModel, (gltf) => {
			
			gltfModel = gltf.scene;

			const boundingBox = new THREE.Box3().setFromObject(glassGroup.current[0]);
			const modelWidth = frameStyleRight.current.position.x - frameStyleLeft.current.position.x;

			const boundingBox2 = new THREE.Box3().setFromObject(gltf.scene);
			const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;

			// Calculate the scaling factor to match the width of the model
			const scaleFactor = gltf.scene.scale.x * (modelWidth / modelWidth2);

			gltf.scene.scale.set(scaleFactor, 1, 1);

			if (uniqueIndex === null) {
				gltfModel.position.set(glassGroup.current[0]?.position.x, 0, 0);
			} else {
				const boundingBoxGlazing = new THREE.Box3().setFromObject(glazingRef.current[uniqueIndex]);
			}


			objectGroupRow.current.add(gltfModel);
		
			if (numberOfRow === 2) {
				// If the number of rows is 2, do nothing
			} else {
				const boundingBox3 = new THREE.Box3().setFromObject(frameStyleBottom.current);
				const minPoint = boundingBox3.min.y;
				const maxPoint = frameStyleTop.current.position.y;
				const numberOfParts = numberOfRow;

				const partLength = (maxPoint - minPoint) / numberOfParts;
				gltfModel.position.set(0, 0, 0);
				let currentIndex = 0;
				uniqueSideRefernce.forEach((element) => {

					let glassRowGroup = new THREE.Object3D();


					let boundingBox

					if (uniqueIndex === null) {
						boundingBox = new THREE.Box3().setFromObject(glassGroup.current[0]);
					} else {
						boundingBox = new THREE.Box3().setFromObject(glazingRef.current[uniqueIndex]);
					}

					const width = boundingBox.max.x - boundingBox.min.x;
					gltfModel.scale.x = width / modelWidth2

					// Get the center of the bounding box
					const center = new THREE.Vector3();
					boundingBox.getCenter(center);


					const clonedModel1 = gltfModel.clone();
					clonedModel1.position.set(center.x, minPoint + partLength, 0); //element.position.x
					objectGroupRow.current.add(clonedModel1);


					// If the number of rows is greater than 2, spawn additional GLTF elements and position them accordingly
					for (let i = 1; i < numberOfRow - 1; i++) {
						const clonedModel = gltfModel.clone();
						const xPos = minPoint + (i + 1) * partLength; // Calculate position for each cloned model
						clonedModel.position.set(center.x, xPos, 0);
						objectGroupRow.current.add(clonedModel);
					}

					var originalWorldPosition = new THREE.Vector3();

					glassGroup.current[currentIndex].getWorldPosition(originalWorldPosition);
					glassGroup.current[currentIndex].add(glassRowGroup);

					glassRowGroup.position.x -= originalWorldPosition.x;

					currentIndex++;
				});
			}

			gltfModel.visible = false;
			if (uniqueIndex) {
				if (rowStorage.current.length) {
					const existingIndex = rowStorage.current.findIndex(item => item.index === uniqueIndex);
					if (existingIndex !== -1) {
						rowStorage.current[existingIndex] = { index: uniqueIndex, rows3D: objectGroupRow.current };
					}
				} else {
					rowStorage.current.push({ index: uniqueIndex, rows3D: objectGroupRow.current });
				}
			} else {
				// rowStorage.current = []
				glazingRef.current.forEach((item, index) => {
					rowStorage.current.push({ index: index, rows3D: objectGroupRow.current })
				})
			}

			

			sceneRef.current.add(objectGroupRow.current);
		});
	}




	function updateGlassDesignColumn(pathModel, numberOfRow, index, uniqueIndex = null) {

		if (numberOfRow <= 1) {
			return;
		}

		index = 0;

		objectGroupCol.current = new THREE.Object3D();

		let glassColGroup = new THREE.Object3D();

		const loader = new GLTFLoader();
		let gltfModel;

		let boundingBox;

		if (uniqueIndex === null) {
			boundingBox = new THREE.Box3().setFromObject(glassGroup.current[0]);
		} else {
			boundingBox = new THREE.Box3().setFromObject(glazingRef.current[uniqueIndex]);
		}
		const modelWidth = boundingBox.max.x - boundingBox.min.x;
		const modelHeight = boundingBox.max.y - boundingBox.min.y;

		const minPoint = boundingBox.min.x
		const maxPoint = boundingBox.max.x

		const boundingBox2 = new THREE.Box3().setFromObject(glassGroup.current[index]);

		const center = new THREE.Vector3();
		boundingBox2.getCenter(center);

		loader.load(pathModel, (gltf) => {
			const boundingBox3 = new THREE.Box3().setFromObject(gltf.scene);
			const sceneWidth = boundingBox3.max.x - boundingBox3.min.x;
			const sceneHeight = boundingBox3.max.y - boundingBox3.min.y;

			gltfModel = gltf.scene;
			gltfModel.position.set(center.x, 0, 0);
			gltfModel.scale.y *= modelHeight / sceneHeight
			tempBar.current = gltfModel;
			if (numberOfRow === 2 && !uniqueIndex) {
			} else {

				const numberOfParts = numberOfRow;
				const partLength = (maxPoint - minPoint) / numberOfParts;
				gltfModel.position.set((minPoint + (numberOfRow - 1) * partLength), 0, 0); //uniqueSideRefernce[index].position.x
				for (let i = 1; i < numberOfRow; i++) {
					const clonedModel = gltfModel.clone();
					const xPos = minPoint + (i) * partLength; // Calculate position for each cloned model
					clonedModel.position.set(xPos, 0, 0);
					objectGroupCol.current.add(clonedModel);
				}
			}

			if (uniqueIndex) {
				if (colStorage.current.length) {
					const existingIndex = colStorage.current.findIndex(item => item.index === uniqueIndex);
					if (existingIndex !== -1) {
						colStorage.current[existingIndex] = { index: uniqueIndex, cols3D: objectGroupCol.current };
					}
				} else {
					colStorage.current.push({ index: uniqueIndex, cols3D: objectGroupCol.current });
				}
			} else {
				// colStorage.current = []
				glazingRef.current.forEach((item, index) => {
					colStorage.current.push({ index: index, cols3D: objectGroupCol.current })
				})
			}
			sceneRef.current.add(objectGroupCol.current);

		});
	}



	const clearDesignCols = (index) => {
		if (!index) {
			colStorage.current.forEach((item) => {
				sceneRef.current.remove(item.cols3D)
			})
			colStorage.current = []
		} else {
			const findCols = colStorage.current.find((col) => col.index === index)
			if (findCols) {
				sceneRef.current.remove(findCols.cols3D)
			}
		}
	}

	const clearDesignRows = (index) => {
		if (!index) {
			rowStorage.current.forEach((item) => {
				sceneRef.current.remove(item.rows3D)
			})
			rowStorage.current = []
		} else {
			const findRows = rowStorage.current.find((row) => row.index === index)
			if (findRows) {
				sceneRef.current.remove(findRows.rows3D)
			}
		}

	}


	const getGlazingRef = () => {
		glazingRef.current = []

		sceneRef.current.traverse((child) => {
			if (child.name.includes("GlassPanel")) {
				glazingRef.current.push(child);
			}
		})
	}

	function addHardwareElement(data) {
		return new Promise((resolve, reject) => {
			if (data) {
				let hardwareData = data;
				let element = `${servicePath}/ThreeJSModel/Glb/${hardwareData?.modelFilePath}`;
				let hardwareType = "";
				const hardwareName = hardwareData?.name?.toLocaleLowerCase();
				const pos = getPosition(hardwareName);
				if (hardwareName === "letter") {
					// createText(0, 0)
				}
				else {
					const isBarHandle =
						hardwareName?.includes("offset") ||
						hardwareName?.includes("inline");
					hardwareType =
						hardwareName?.includes("spyhole")
							? "spyhole"
							: hardwareName?.includes("knob")
								? "door_knob"
								: hardwareName?.includes("escutcheon")
									? "escutcheon"
									: isBarHandle
										? "bar_offset"
										: "";
				}
				let posZ;
				let posY;

				if (hardwareData?.verticalOrigin !== "" && hardwareData?.horizontalOrigin !== "") {
					posZ = parseFloat(
						getAlignmentPosition(hardwareData?.horizontalOrigin, "horizontalOri") / 1000
					);
					posY = parseFloat(
						getAlignmentPosition(hardwareData?.verticalOrigin, "verticalOri") / 1000
					);
				} else if (hardwareData?.verticalAlignment !== "" && hardwareData?.horizontalAlignment !== "") {
					posZ = parseFloat(
						getAlignmentPosition(hardwareData?.horizontalAlignment, "horizontal") / 1000
					);
					posY = parseFloat(
						getAlignmentPosition(hardwareData?.verticalAlignment, "vertical") / 1000
					);
				} else if (hardwareData?.verticalAlignment !== "") {
					posZ = parseFloat(hardwareData?.horizontalPos / 1000);
					posY = parseFloat(
						getAlignmentPosition(hardwareData?.verticalAlignment, "vertical") / 1000
					);
				} else if (hardwareData?.horizontalAlignment !== "") {
					posZ = parseFloat(
						getAlignmentPosition(hardwareData?.horizontalAlignment, "horizontal") / 1000
					);
					posY = parseFloat(hardwareData?.verticalPos / 1000);
				} else if (hardwareData?.horizontalOrigin !== "") {
					posZ = parseFloat(
						getAlignmentPosition(hardwareData?.horizontalOrigin, "horizontalOri") / 1000
					);
					posY = parseFloat(hardwareData?.verticalPos / 1000);
				} else if (hardwareData?.verticalOrigin !== "") {
					posZ = parseFloat(hardwareData?.horizontalPos / 1000);
					posY = parseFloat(
						getAlignmentPosition(hardwareData?.verticalOrigin, "verticalOri") / 1000
					);
				} else {
					posZ = parseFloat(hardwareData?.horizontalPos / 1000);
					posY = parseFloat(hardwareData?.verticalPos / 1000);
				}
				const elementLoader = new GLTFLoader();
				if (element) {
					elementLoader.load(element, function (gltf) {
						const scale = new THREE.Vector3(pos?.scale?.posX * 3, pos?.scale?.posY * 3, pos?.scale?.posZ * 2);
						gltf.scene.scale.copy(scale);

						let posX = -0.03;
						if (hardwareType === "spyhole") {
							posX = -0.023
						} else if (hardwareType === "bar_offset") {
							posX = -0.059
						} else if (hardwareType === "door_knob") {
							posX = -0.05
						} else if (hardwareType === "escutcheon") {
							posX = -0.0099
						}

						const position = new THREE.Vector3(posX, 0.19, 0);
						gltf.scene.position.copy(position);
						// gltf.scene.rotation.set(0, 4.7, 0);
						gltf.scene.position.y += posY;
						gltf.scene.position.z -= posZ;
						gltf.scene.name = hardwareData?.type;

						resolve(gltf.scene);
					}, undefined, function (error) {
						reject(error);
					});
				} else {
					reject(new Error('Element is not defined'));
				}
			}
		});

	}

	const getAlignmentPosition = (alignment, type) => {
		let position = 0;

		switch (type) {
			case "vertical":
				position = alignment === "Centre" ? 0 : alignment === "Top" ? 300 : -300;
				break;
			case "horizontal":
				position =
					alignment === "Centre"
						? 0
						: alignment === "Left" || alignment === "Lock"
							? 380
							: -380;
				break;
			case "verticalOri":
			case "horizontalOri":
				position =
					alignment === "Slab"
						? 50
						: alignment === "Outside frame"
							? type === "horizontalOri"
								? 50
								: -50
							: 50;
				break;
		}
		return position;
	};

	useEffect(() => {
		if (applyPanel && customModelData?.glazing?.panel?.length > 0) {

			const panelItem = customModelData?.glazing?.panel?.find((p) => p.glassIndex == checkSingleSide)

			if (hardwareType === 'glazingPanel') {
				clearDesigns()
				addGlazingPanel(checkClickedProfile, checkSingleSide, false, panelItem, sceneRef, glazeModelRef, setPanelObject, setApplyPanel, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)
			}

			setPanelInitAdded(true)
		}
	}, [customModelData?.glazing?.panel]);

	useEffect(() => {
		if (modelInitialied && panelObject && panelObject.length > 0 && externalColor !== undefined && internalColor !== undefined) {
			if (glazingRef && glazingRef?.current?.length > 0) {
				panelObject?.forEach((item) => {
					addGlazingPanel(glazingRef?.current[item?.glassIndex], item?.glassIndex, true, item, sceneRef, glazeModelRef, setPanelObject, setApplyPanel, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)
				})
				setPanelInitAdded(true)
			}
		}
	}, [panelObject, glazingRef?.current, modelInitialied])

	useEffect(() => {
		if (deletePanel) {
			if (hardwareType === 'glazingPanel') {
				glazingPanelDelete(checkClickedProfile, checkSingleSide, sceneRef, panelDataSave, setPanelDataSave, externalFrameRef, internalFrameRef, setDeletePanel)
			}
		}
	}, [deletePanel]);


	const getSashHangingAndProfileRef = () => {
		let glass = []
		let sashProfileRefs = []
		testRef.current = []
		// sashList.current = []
		newFrameRefPoints.current = []
		// sashGroup.current.forEach((child) => {
		sceneRef.current.traverse((item) => {
			if (item?.name.includes("verticalBarRight") || item?.name.includes("verticalBarLeft") || item?.name.includes("horizontalBarTop") || item?.name.includes("horizontalBarBottom")) {
				var worldPosition = new THREE.Vector3();
				var emptyObject = new THREE.Object3D();
				item.getWorldPosition(worldPosition);
				emptyObject.position.set(item.position);
				// sashList.current.push(item);
				sashProfileRefs.push(item)
			}
		})
		newFrameRefPoints.current = sashProfileRefs
		// testRef.current = transomFrame.current


		if (sashGroup?.current?.length < 1) {
			glass.push(glassGroup.current[0])
			if (transomFrame.current && transomFrame.current.length > 1) {
				testRef.current = transomFrame.current
			} else {
				testRef.current = glass
			}
		} else {
			sashGroup.current.forEach((child) => {
				child.name.includes('GlassPanel')
				const newObj = new THREE.Object3D();
				newObj.position.set(child.position.x, child.position.y, child.position.z);

				glass.push(newObj)
				if (transomFrame.current && transomFrame.current.length > 1) {
					testRef.current = transomFrame.current
				} else {
					testRef.current = glass
				}
			})
		}
	}


	const handleHardwares = () => {
		const hardwareRefMap = {};
		const staticValues = {
			"Trickle vent": tickleVent,
			"Escutcheon": tickleVent, // Assuming both "Trickle vent" and "Escutcheon" share the same reference
			"Door Handle": doorHandleData,
			"Bar Handles Offset": doorHandleData,
			"Frame": newFrameData,
			"glazing": uniqueSideRefernce,
			"sashprofiles": hardwareType === "sashprofiles" && getSashFrameRef(), // Assuming getSashFrameRef returns a reference
			"sashHanging": hardwareType === "sashHanging" && handleUpdateSashOperation(), // Assuming updateSash is a function
			"Panel": panelRefPoints,
			"Spyhole": spyHoleRefPoints,
			"Letterplate": spyHoleRefPoints,
			"Knocker": spyHoleRefPoints,
			"Numeral": spyHoleRefPoints,
			"handleDirection": windowHandleRefPoint,
			"glazingPanel": glazingRef,
			"hinge": hingeRefPoints
		};


		if (hardwareType == "sashHangingProfile") {
			getSashHangingAndProfileRef()
		}

		if (hardwareType === "sashHanging" && collectionDetails.typeId == 1) {
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				numberOfSash: {
					number: sashHangingNo.toString()
				}
			}))
		}

		const lowercaseHardwareNames = allHardware.map(name => name?.name?.toLowerCase());

		// Populate hardwareRefMap with case-insensitive comparison
		Object.keys(staticValues).forEach(name => {
			if (lowercaseHardwareNames.includes(name.toLowerCase())) {
				hardwareRefMap[name?.toLocaleLowerCase()] = staticValues[name];
			}
		});

		if (hardwareType && hardwareRefMap[hardwareType?.name]) {
			testRef.current = hardwareRefMap[hardwareType?.name];
		} else if (hardwareType === "") {
			// forceUpdate();
			testRef.current = [];
			setPopoverIndex(null);
			newFrameRefPoints.current = []
			delete3DItems(false, '');
		}

		//setting transom refs points
		if (hardwareType && (hardwareType === "transomVertical" || hardwareType === 'transomHorizontal')) {
			if (transoms.current.length) {
				testRef.current = tansomsReference;
			} else {
				setTransomRefs(false)
			}
		}

		if (hardwareType && hardwareType.name === "Frame") {
			testRef.current = addFrameRef.current
		}

		//setting frame refs points
		if (hardwareType && hardwareType === "customize frame style" && !manufacturingToggle) {
			testRef.current = frameStyleRef.current
		} else if (hardwareType && hardwareType === "customize frame style" && manufacturingToggle) {
			getCornerRefPoints()
		}

		//handle Ref point
		if (hardwareType && hardwareType === "handleDirection") {
			testRef.current = windowHandleRefPoint.current
		}

		//handle Ref point
		if (hardwareType && hardwareType === "glazingPanel" || hardwareType === 'glazing') {
			testRef.current = glazingRef.current
		}
	}



	function resetAddedFrame() {
		addedFrame.current.forEach((frame) => {
			sceneRef.current.remove(frame);
		});
		addedFrame.current = [];

		const allElements = addedFramePosition?.current?.slice();
		addedFramePosition.current = [];
		allElements.forEach((pos) => {
			if (pos.includes("top")) {
				addFrameTop(true);
			}
			if (pos.includes("bottom")) {
				addFrameTop(false);
			}
			if (pos.includes("right") && !pos.includes("rightPerpendicular")) {
				addFrame(false);
			}
			if (pos.includes("left") && !pos.includes("leftPerpendicular")) {
				addFrame(true);
			}
		});
	}

	// use this function to add frame
	const frameClick = (element, pos) => {

		const bayPostData = bayPostList.find((item) => item.id === bayPostDeg)
		let angle = bayPostData && bayPostData.name === "Variable Bay Post 120°" ? bayPostAngleManual : 90
		let frameDirection = "";
		if (pos?.name.includes("FrameTop") && !pos?.name.includes("FrameTopPerpendicular")) {
			frameDirection = "FrameTop"
			addFrameTop(true, couplerData[0], frameDirection);
		}
		if (pos?.name.includes("FrameBottom") && !pos?.name.includes("FrameBottomPerpendicular")) {
			frameDirection = "FrameBottom"
			addFrameTop(false, couplerData[0], frameDirection);
		}
		if (pos?.name.includes("FrameRight") && !pos?.name.includes("FrameRightPerpendicular")) {
			frameDirection = "FrameRight"
			addFrame(false, couplerData[0], frameDirection);
		}
		if (pos?.name.includes("FrameLeft") && !pos?.name.includes("FrameLeftPerpendicular")) {
			frameDirection = "FrameLeft"
			addFrame(true, couplerData[0], frameDirection);
		}
		if (pos?.name.includes("FrameRightPerpendicular")) {
			frameDirection = "FrameRightPerpendicular"
			addFramePerpendicular(`${servicePath}/ThreeJSModel/Glb/${bayPostData?.customePath}`, true, angle, bayPostData, false, frameDirection);
		}
		if (pos?.name.includes("FrameLeftPerpendicular")) {
			frameDirection = "FrameLeftPerpendicular"
			addFramePerpendicular(`${servicePath}/ThreeJSModel/Glb/${bayPostData?.customePath}`, false, angle, bayPostData, false, frameDirection);
		}

		if (pos?.name.includes("FrameBottomPerpendicular")) {
			frameDirection = "FrameBottomPerpendicular"
			addFramePerpendicular(`${servicePath}/ThreeJSModel/Glb/${bayPostData?.customePath}`, false, angle, bayPostData, "bottom", frameDirection);
		}

		if (pos?.name.includes("FrameTopPerpendicular") && !pos?.name.includes("FrameLeftPerpendicular") && headerSelectedItem?.name !== "Eaves Frame") {
			frameDirection = "FrameTopPerpendicular"
			addFramePerpendicular(`${servicePath}/ThreeJSModel/Glb/${bayPostData?.customePath}`, false, angle, bayPostData, true, frameDirection);
		}

		if (pos?.name.includes("FrameTopPerpendicular") && !pos?.name.includes("FrameLeftPerpendicular") && headerSelectedItem?.name === "Eaves Frame") {
			frameDirection = "FrameTopPerpendicular"
			addEvesFrame(angle, "", frameDirection)
		}

		// if (pos?.name.includes("FrameLeftPerpendicular") && headerSelectedItem.name === "Oriel Window") {
		// 	frameDirection = "FrameLeftPerpendicular"
		// 	addEvesFrame(angle, "", frameDirection)
		// }



		// if (frameDirection === "FrameLeft" || frameDirection === "FrameRight" || frameDirection === "FrameBottom" || frameDirection === "FrameTop") {
		// 	let frameData = {
		// 		frameDirection,
		// 		coupler: couplerData[0],
		// 		angle: angle
		// 	}
		// 	let obj = {
		// 		id: addedFrameData?.frameStyle?.id,
		// 		name: addedFrameData?.frameStyle?.name,
		// 		frameProfile: addedFrameData?.frameProfileData,
		// 		height: overrideHeight ? overrideHeight : addedFrameData?.layoutFrame?.height,
		// 		width: overrideWidth ? overrideWidth : addedFrameData?.layoutFrame?.width,
		// 		glazingData: addedFrameData?.glazingData,
		// 		imagePath: "",
		// 		collectionId: allFrameCollection?.find((item => item?.id == addedFrameData?.collectionId))?.id,
		// 		collectionName: allFrameCollection?.find((item => item?.id == addedFrameData?.collectionId))?.name,
		// 		couplerData: frameData,
		// 		position:addedFrameData?.position,
		// 		price: allStyleCollection?.find((item => item?.id === addedFrameData?.frameStyle?.id))?.price,
		// 		installationPrice: allStyleCollection?.find((item => item?.id === addedFrameData?.frameStyle?.id))?.installationPrice
		// 	}
		// 	setCustomModelData((prevModelData) => ({
		// 		...prevModelData,
		// 		frame: {
		// 			...prevModelData.frame,
		// 			framesAndCoupler: [
		// 				...prevModelData.frame.framesAndCoupler || [],
		// 				frameData
		// 			]
		// 		},
		// 		addedFrames: [
		// 			...prevModelData?.addedFrames || [],
		// 			obj
		// 		]

		// 	}))

		// } else {
		// 	if (frameDirection.includes("Perpendicular") && bayPostData) {
		// 		let bayPostSave = {
		// 			frameDirection,
		// 			bayPost: `${servicePath}/ThreeJSModel/Glb/${bayPostData?.customePath}`,
		// 			angle: angle,
		// 			frameType: "Baypost",
		// 			profileTypePrice: bayPostData.profileTypePrice,
		// 			additionalArticles: bayPostData.additionalArticles,
		// 			internalPaintSurfaceArea: bayPostData.internalPaintSurfaceArea,
		// 			externalPaintSurfaceArea: bayPostData?.externalPaintSurfaceArea,
		// 			price: bayPostData.price,
		// 			height: bayPostData.height,
		// 			width: bayPostData.width,
		// 			name: bayPostData.name,
		// 			id: bayPostData.id
		// 		}


		// 		let obj = {
		// 			id: addedFrameData?.frameStyle?.id,
		// 			frameProfile: addedFrameData?.frameProfileData,
		// 			height: overrideHeight ? overrideHeight : addedFrameData?.layoutFrame?.height,
		// 			width: overrideWidth ? overrideWidth : addedFrameData?.layoutFrame?.width,
		// 			glazingData: addedFrameData?.glazingData,
		// 			baypostData: bayPostSave,
		// 			name: addedFrameData?.frameStyle?.name,
		// 			imagePath: "",
		// 			position:addedFrameData?.position,
		// 			collectionId: allFrameCollection?.find((item => item?.id == addedFrameData?.collectionId))?.id,
		// 			collectionName: allFrameCollection?.find((item => item?.id == addedFrameData?.collectionId))?.name,
		// 			price: allStyleCollection?.find((item => item?.id === addedFrameData?.frameStyle?.id))?.price,
		// 			installationPrice: allStyleCollection?.find((item => item?.id === addedFrameData?.frameStyle?.id))?.installationPrice
		// 		}

		// 		setCustomModelData((prevModelData) => ({
		// 			...prevModelData,
		// 			frame: {
		// 				...prevModelData.frame,
		// 				bayPost: [
		// 					...prevModelData.frame.bayPost || [],
		// 					bayPostSave
		// 				]
		// 			},
		// 			addedFrames: [
		// 				...prevModelData?.addedFrames || [],
		// 				obj
		// 			]
		// 		}))
		// 	}
		// }
		setLayoutSizing(true)
	}

	useEffect(() => {
		if (modelVisible && modelInitialied && sashGroup.current?.length > 0) {
			setUiLinesLocation('All')
		}
	}, [modelVisible, sashGroup.current])

	// Start: adding UI lines for sash
	useEffect(() => {

		if (modelInitialied) {
			// if (uiLinesLocation === '' || customModelData.hideDimentions) {
			// 	uiLinesRef.current = []
			// 	uiLinesRefBottom.current = []
			// 	removeLayoutLines(sceneRef)
			// }

			if (uiLinesLocation === '' || customModelData.hideDimentions) {
				uiLinesRef.current = []
				uiLinesRefBottom.current = []
				removeLayoutLines(sceneRef)
			} else {
				if (uiLinesLocation !== '') {
					// uiLinesRefFrame.current = []
					// removeFrameLines(sceneRef)

					if (customModelData?.numberOfSash?.number > 1) {
						uiLinesRef.current = []
						uiLinesRefBottom.current = []
						removeLayoutLines(sceneRef)

						// if ((uiLinesLocation === "All") && (sashList.current && sashList.current.length > 0) && transomFrame.current && transomFrame.current.length === 1) {

						// if ((uiLinesLocation === "All" || uiLinesLocation === "Vertical") && (sashList.current && sashList.current.length > 0)) {
						// 	uiLinesRef.current = []
						// 	addUiLinesBottom(sashList.current, uiLinesRefBottom, sceneRef, userDataContext?.company_color || '#44C8F5')
						// }

						if ((uiLinesLocation === "All") && (sashGroup.current && sashGroup?.current?.length > 0)) {
							uiLinesRef.current = []
							addUiLinesBottomTransom(sashGroup.current, uiLinesRefBottom, sceneRef, userDataContext?.company_color || '#44C8F5', frameStyleBottom.current)
						} else {
							removeLayoutLines(sceneRef)
						}

						// adding right lines based on transom
						// if ((uiLinesLocation === "All" || uiLinesLocation === "Horizontal") && (transomFrame.current && transomFrame.current.length > 1)) {
						// 	uiLinesRefBottom.current = []
						// 	addUiLinesRightTransom(transomFrame.current, uiLinesRef, sceneRef, userDataContext?.company_color || '#44C8F5', frameStyleLeft.current)
						// }

						// adding right lines based on transom
						// if ((uiLinesLocation === "All" || uiLinesLocation === "Vertical") && (transomFrame.current && transomFrame.current.length > 1)) {
						// 	uiLinesRef.current = []
						// 	addUiLinesBottomTransom(transomFrame.current, uiLinesRefBottom, sceneRef, userDataContext?.company_color || '#44C8F5', frameStyleBottom.current)
						// }
					}
				}
			}
		}
	}, [modelInitialied && uiLinesLocation, customModelData.sashSize, customModelData.hideDimentions]);

	// }, [uiLinesLocation, frameStyleRef.current, sashList.current, transomFrame.current, transomFrame.current.length, customModelData.sashSize, refreshUiLines]);
	// End: adding UI lines for sash

	// Start: this is for single glass on which overall frame height and width will be changed
	useEffect(() => {
		if (modelInitialied && frameStyleRef.current && frameStyleRef.current.length > 0) {
			if (customModelData.hideDimentions) {
				uiLinesRefFrame.current = []
				removeFrameLines(sceneRef)
			} else {
				if (frameStyleRef.current && frameStyleRef.current.length > 0) {
					addUiLinesFrameH(frameStyleRef.current, uiLinesRefFrame, sceneRef, userDataContext?.company_color || '#44C8F5')
					addUiLinesFrameV(frameStyleRef.current, uiLinesRefFrame, sceneRef, userDataContext?.company_color || '#44C8F5')
				}
			}
		}
	}, [frameStyleRef.current, modelInitialied, refreshUiLines, customModelData.hideDimentions]);
	// End: this is for single glass on which overall frame height and width will be changed

	// capture and save all images
	useEffect(() => {
		if (captureImages) {
			captureAndSaveImages(setFrameCameraView, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom, getAllProfileRef, allProfileRefSeq, glassRefSeq, frameStyleRef, sashList, sillRef, sashGroup, uiLinesRefFrame, uiLinesRefBottom, removeFrameLines, removeLayoutLines, sceneRef, setModelImages, modelWrap, setSaveAllImages, setCaptureImages, setRefreshUiLines, setUiLinesLocation, setRefreshAfterSave)
		}
	}, [captureImages]);

	useEffect(() => {
		if (refreshAfterSave) {
			setRefreshAfterSave(false)

			if (!customModelData?.hideDimentions) {
				setUiLinesLocation("All")
				setRefreshUiLines(true)
			}
		}
	}, [refreshAfterSave])


	const addBead = (beadModel, spacerBar, isVertical, data) => {
		return

		const loader = new GLTFLoader();

		const lastPart = beadModel.split('/').filter(Boolean).pop()

		if (lastPart.includes('.glb')) {
			loader.load(beadModel, (gltf) => {

				gltf.scene.traverse((child) => {
					child.name += " Internal"
					if (child.name.includes("Internal")) {
						internalFrameRef.current.push(child);
					}
				})
				const worldPosition = new THREE.Vector3();
				spacerBar.getWorldPosition(worldPosition);

				gltf.scene.position.x = worldPosition.x;
				gltf.scene.position.y = worldPosition.y;


				const bB = new THREE.Box3().setFromObject(spacerBar);
				let width = bB.max.x - bB.min.x;
				if (isVertical) {
					width = bB.max.y - bB.min.y;
				}

				const bB2 = new THREE.Box3().setFromObject(gltf.scene);

				const width2 = bB2.max.x - bB2.min.x;
				const depth2 = bB2.max.z - bB2.min.z;
				const height2 = bB2.max.y - bB2.min.y;

				gltf.scene.scale.z = data.width / 30; //for base bead widthScale is width/20
				gltf.scene.scale.y = data.height / 20; //for base bead heightScale is height/20

				gltf.scene.scale.x *= width / width2;

				if (isVertical) {
					gltf.scene.rotation.z = Math.PI / 2;
				}
				gltf.scene.position.z = bB.min.z - depth2 / 2;
				setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)
				sceneRef.current.add(gltf.scene);
			})
		}

	}

	const storeBead = () => {
		let bead = initialProfile?.bead[0]
		let beadData = {
			id: bead.id,
			modelUrl: `${servicePath}/ThreeJSModel/Glb/${initialProfile?.bead[0].customePath}`,
			height: bead.height,
			width: bead.width,
			price: bead.price
		}
		setCustomModelData((prevModelData) => ({
			...prevModelData,
			beads: [
				...prevModelData.beads || [],
				beadData
			]
		}))
	}


	function addSashStyle(element, targetPosition, targetName, data, isPersisted, index) {

		var gIndex = Math.floor(index / 4);

		let side = '';
		let width = 1;
		let height = 1;


		if (data?.width) {
			width = (data?.width / 35);
		}

		if (data?.height) {
			height = (data?.height / 40);
		}


		var base, trackLeft, trackRight;
		var bB, bB2, boundingBox3;
		var baseMax
		// Instantiate a loader
		const loader = new GLTFLoader();
		// Load a GLTF model
		//	element = sashTest;
		if (element) {
			loader.load(element, (gltf) => {

				removeReplacedSash.current.push(gltf.scene)

				gltf.scene.traverse((child) => {
					// if (child.name.includes("Bead")) {
					// 	bead.current.push(item)
					// 	if (!isPersisted) {
					// 		let data = {
					// 			id: beadList[0]?.id,
					// 			name: beadList[0].name,
					// 			price: beadList[0]?.price,
					// 			imagePath: beadList[0]?.imagePath,
					// 			filePath: beadList[0]?.filePath,
					// 			additionalArticles: beadList[0]?.additionalArticles,
					// 			externalPaintSurfaceArea: beadList[0]?.externalPaintSurfaceArea,
					// 			internalPaintSurfaceArea: beadList[0]?.internalPaintSurfaceArea,
					// 			profileTypePrice: beadList[0]?.profileTypePrice,
					// 			frameType: "Bead",
					// 		}
					// 		setCustomModelData((prevModelData) => ({
					// 			...prevModelData,
					// 			bead: [
					// 				...prevModelData.bead || [],
					// 				data
					// 			]
					// 		}))
					// 	}

					// }

					if (child.name.includes("Base")) {
						base = child;
						if (base) {
							bB = new THREE.Box3().setFromObject(base);
							baseMax = bB.max.y;
						}

						child.scale.z = height;
						child.scale.y = width
						if (base) {
							bB = new THREE.Box3().setFromObject(base);

							//	base.position.y = baseMax - ((bB.max.y - bB.min.y) / 2);
						}
					}
				})

				gltf.scene.traverse((child) => {

					if (child.name.includes("Track") && child.name.includes("Left")) {
						bB2 = new THREE.Box3().setFromObject(child);
						const width = bB2.max.z - bB2.min.z;
						child.position.z = bB.max.z - width / 2;

						trackLeft = child;
					}

					if (child.name.includes("Track") && child.name.includes("Right")) {
						bB2 = new THREE.Box3().setFromObject(child);
						const width = bB2.max.z - bB2.min.z;
						child.position.z = bB.min.z + width / 2;

						trackRight = child;
					}

					if (child.name.includes("Top")) {
						bB2 = new THREE.Box3().setFromObject(child);
						const height = bB2.max.y - bB2.min.y;
						child.position.y = bB.max.y + height / 2;
					}

					if (child.name.includes("Spacer")) {
						bB2 = new THREE.Box3().setFromObject(child);
						const height = bB2.max.y - bB2.min.y;
						child.position.y = bB.max.y + height / 2;
						if ((targetPosition?.name?.includes("Bottom") || targetPosition?.name?.includes("Top")) && initialProfile?.bead?.length) {
							addBead(`${servicePath}/ThreeJSModel/Glb/${initialProfile?.bead[0]?.customePath}`, child, false, initialProfile?.bead[0])
							if (!isPersisted) {
								storeBead()
							}
						} else if ((targetPosition?.name?.includes("Right") || targetPosition?.name?.includes("Left")) && initialProfile?.bead?.length) {
							addBead(`${servicePath}/ThreeJSModel/Glb/${initialProfile?.bead[0]?.customePath}`, child, true, initialProfile?.bead[0])
							if (!isPersisted) {
								storeBead()
							}
						}

						if (!isPersisted && defaultSpec?.spacerBar[0]) {
							let data = {
								id: defaultSpec?.spacerBar[0].id,
								name: defaultSpec?.spacerBar[0].name,
								color: defaultSpec?.spacerBar[0].hexValue,
								price: defaultSpec?.spacerBar[0].price,
								modelName: defaultSpec?.spacerBar[0].modelName

							}
							setCustomModelData((prevModelData) => ({
								...prevModelData,
								glazing: {
									...prevModelData.glazing,
									spacerbars: [
										...prevModelData.glazing.spacerbars || [],
										data
									]
								}
							}))
							modifySpacerBar(defaultSpec?.spacerBar[0], false)

						}
						child.name += " External"
					}

					if (child.name.includes("Bottom")) {
						bB2 = new THREE.Box3().setFromObject(child);
						const height = bB2.max.y - bB2.min.y;
						child.position.y = bB.min.y - height / 2;
					}
				})

				const boundingBox = new THREE.Box3().setFromObject(sashGroup.current[gIndex]);

				// Calculate the width of the bounding box
				const modelWidth = boundingBox.max.x - boundingBox.min.x;
				const modelHeight = boundingBox.max.y - boundingBox.min.y;

				const boundingBox2 = new THREE.Box3().setFromObject(gltf.scene);
				const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;
				const modelHeight2 = boundingBox2.max.z - boundingBox2.min.z;

				const modelHeight3 = bB.max.y - bB.min.y;

				// Calculate the scaling factor to match the width of the model
				const scaleFactor = modelWidth / modelWidth2;
				const scaleFactor2 = modelHeight / modelWidth2;

				const worldPosition = new THREE.Vector3();

				if (targetPosition) {
					targetPosition.getWorldPosition(worldPosition);
				}

				var sashIndex, itemIndex, finalItemIndex;
				itemIndex = 0;
				sashGroup.current.forEach((child, index) => {
					child.traverse((item) => {

						const childWorldPos = new THREE.Vector3();
						item.getWorldPosition(childWorldPos);
						if (childWorldPos.x + childWorldPos.y === worldPosition.x + worldPosition.y) {
							sceneRef.current.remove(item)
							item.visible = false
							sashIndex = index;
							finalItemIndex = itemIndex;
						}
						itemIndex++;
					})
				})

				gltf.scene.position.set(worldPosition.x, worldPosition.y, worldPosition.z);

				const boundingBox3 = new THREE.Box3().setFromObject(sashGroup?.current[sashIndex]);

				const boundingBox4 = new THREE.Box3().setFromObject(addFrameRef.current[0]);
				const boundingBox5 = new THREE.Box3().setFromObject(gltf.scene);
				if (boundingBox5.min.z < boundingBox4.min.z) {
					const newWidth = (boundingBox5.max.z - boundingBox5.min.z) / 2;
					gltf.scene.position.z = boundingBox4.min.z + newWidth;
				}


				if (targetPosition.name.includes("Left")) {
					gltf.scene.rotation.z = -1 * Math.PI / 2;
					gltf.scene.position.x = boundingBox3.min.x + (modelHeight3 / 2);
					gltf.scene.scale.x = scaleFactor2;
					side = 'Left';
				}

				if (targetPosition.name.includes("Right")) {
					gltf.scene.rotation.z = Math.PI / 2;
					gltf.scene.position.x = boundingBox3.max.x - (modelHeight3 / 2);
					gltf.scene.scale.x = scaleFactor2;
					side = 'Right';
				}

				if (targetPosition.name.includes("Top")) {
					gltf.scene.scale.x = scaleFactor;
					gltf.scene.scale.y = -1;
					gltf.scene.position.y = boundingBox3.max.y - modelHeight2 / 2;
					side = 'Top'
				}

				if (targetPosition.name.includes("Bottom")) {
					gltf.scene.scale.x = scaleFactor;
					gltf.scene.position.y = boundingBox3.min.y + modelHeight2 / 2;
					side = 'Bottom'
				}

				// applying sash colors for internal and external layers
				gltf.scene.traverse((child) => {
					if (child.isMesh) {
						if (child.name.includes("External")) {
							externalFrameRef.current.push(child);
						} else {
							internalFrameRef.current.push(child);
						}
					}
				})

				setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

				// Add the element to the scene
				storeSashes.current.push({ index, scene: gltf.scene })

				const seen = new Set();
				storeSashes.current = storeSashes.current.filter(item => {
					const duplicate = seen.has(item.index);
					seen.add(item.index);
					return !duplicate;
				});
				sceneRef.current.add(gltf.scene);

				//fix removing of exisiting frame

				if (addedSash.current.some(item => item.finalItemIndex === finalItemIndex)) {
					// Key exists in addedSash
					var item = addedSash.current.find(item => item.finalItemIndex === finalItemIndex);
					sceneRef.current.remove(item.scene); // Remove the scene object
					addedSash.current = addedSash.current.filter(item => item.finalItemIndex !== finalItemIndex);
				} else {
					console.log('key doesn exist')
				}

				addedSash.current.push({ finalItemIndex, scene: gltf.scene, index })

				targetPosition.visible = false;

				//setting size of bead in added sash
				beadSize();
				//	Frame.current.push(gltf.scene);
				setMultiSelectRefPoints([])
			});
		}
	}

	//test with replaced sash in window model
	function setSashWindowSize(data) {
		const width = (data?.width / 100); //replace 100 by default width
		const height = (data?.height / 40); //replace 40 by default height

		windowSash.current.traverse((child) => {
			if (child.name.includes("Base")) {
				//do it for each side 
				const bB = new THREE.Box3().setFromObject(child);
				const baseMax = bB.max.z;

				child.scale.z = width;
				child.scale.y = height;

				const bB2 = new THREE.Box3().setFromObject(child);
				const baseMax2 = bB2.max.z;
				child.position.z -= baseMax2
			}

			if (child.name.includes("Center")) {
				const bB = new THREE.Box3().setFromObject(child);
				const baseMax = bB.max.z;
				child.position.z -= baseMax
			}
		})
	}

	function getFrameStyleRef1(shapeRef) {
		if (frameStyleTop.current && frameStyleTop.current.name === shapeRef) {
			return frameStyleTop.current;
		}
		else if (frameStyleLeft.current && frameStyleLeft.current.name === shapeRef) {
			return frameStyleLeft.current;
		}
		else if (frameStyleRight.current && frameStyleRight.current.name === shapeRef) {
			return frameStyleRight.current;
		}
		else if (frameStyleBottom.current && frameStyleBottom.current.name === shapeRef) {
			return frameStyleBottom.current;
		}
	}

	function getFrameStyleRef2(shapeRef) {
		if (frameStyleTop.current && frameStyleTop.current.name === shapeRef) {
			return frameStyleTop.current;
		}
		else if (frameStyleLeft.current && frameStyleLeft.current.name === shapeRef) {
			return frameStyleLeft.current;
		}
		else if (frameStyleRight.current && frameStyleRight.current.name === shapeRef) {
			return frameStyleRight.current;
		}
		else if (frameStyleBottom.current && frameStyleBottom.current.name === shapeRef) {
			return frameStyleBottom.current;
		}
	}


	// function cloneShapedFrame(element, targetPosition, targetName, data, shapeRefPt1, shapeRefPt2, newHeight, newWidth, modelWid, modelHt) {
	// 	return new Promise((resolve, reject) => {
	// 		let addedNewFrame;

	// 		if (targetName?.includes(shapeRefPt1)) {
	// 			const loader = new GLTFLoader();

	// 			loader.load(element, (gltf) => {

	// 				addedNewFrame = gltf.scene;
	// 				// sceneRef.current.add(gltf.scene)

	// 				gltf.scene.traverse((child) => {
	// 					if (child.name.includes("External")) {
	// 						externalFrameRef.current.push(child);
	// 					} else {
	// 						internalFrameRef.current.push(child);
	// 					}
	// 					setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal);
	// 				});
	// 				let scaleFactor2 = 0;

	// 				let maxXTop, maxYTop, cloneInitialLength, scaleFacH, scaleFacW;
	// 				const shapeRef = getFrameStyleRef2(shapeRefPt1); // assuming getFrameStyleRef is the function that contains the above code
	// 				const shapeRefR = getFrameStyleRef2(shapeRefPt2); // assuming getFrameStyleRef is the function that contains the above code

	// 				const boundingBox = new THREE.Box3().setFromObject(shapeRef);
	// 				const boundingBoxR = new THREE.Box3().setFromObject(shapeRefR);
	// 				const modelWidthT = boundingBox.max.x - boundingBox.min.x;
	// 				// const boundingBox2 = new THREE.Box3().setFromObject(gltf.scene);
	// 				// const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;
	// 				// const scaleFactor = modelWidth / modelWidth2;
	// 				// 
	// 				gltf.scene.position.set(targetPosition.x, targetPosition.y, targetPosition.z);
	// 				// gltf.scene.scale.x = scaleFactor;
	// 				let clonedObject = gltf.scene

	// 				const shapeRef2 = getFrameStyleRef1(shapeRefPt2);
	// 				const shapeRef1 = getFrameStyleRef2(shapeRefPt1)

	// 				let boundingBox0, boundingBox1, bBoxFrame

	// 				if (shapeRef2) {

	// 					scaleFacH = (modelHt - newHeight) / modelHt
	// 					shapeRef2.scale.x *= scaleFacH;

	// 					const bBox0 = new THREE.Box3().setFromObject(shapeRef2);
	// 					const WidthYLeft2 = bBox0.max.y - bBox0.min.y;

	// 					if (shapeRefPt1 === 'FrameTop') {
	// 						shapeRef2.position.y = (boundingBoxR.min.y + WidthYLeft2 / 2);
	// 					}
	// 					else {
	// 						shapeRef2.position.y = -(boundingBoxR.min.y + WidthYLeft2 / 2);
	// 					}
	// 					boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);
	// 				}


	// 				if (clonedObject) {

	// 					scaleFacW = (modelWid - newWidth) / modelWid
	// 					shapeRef1.scale.x *= scaleFacW;

	// 					let bBox2 = new THREE.Box3().setFromObject(shapeRef1)
	// 					const WidthX2 = bBox2.max.x - bBox2.min.x;

	// 					if (shapeRefPt2 === 'FrameRight') {
	// 						shapeRef1.position.x = -(boundingBox.min.x + WidthX2 / 2);
	// 					}
	// 					else {
	// 						shapeRef1.position.x = (boundingBox.min.x + WidthX2 / 2);
	// 					}


	// 					boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);

	// 					clonedObject.name = `FrameSlant ${shapeRefPt1} ${shapeRefPt1}`
	// 					const modelWidth = boundingBox.max.y - boundingBox.min.y;

	// 					const boundingBox2 = new THREE.Box3().setFromObject(clonedObject);

	// 					const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;
	// 					cloneInitialLength = modelWidth2;

	// 					scaleFactor2 = modelWidth / modelWidth2;

	// 					clonedObject.position.y = 0;
	// 					clonedObject.visible = true

	// 					setTimeout(() => {
	// 						if (shapeRef2) {
	// 							if (shapeRefPt1 === 'FrameTop' && shapeRefPt2 === 'FrameLeft') {

	// 								boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);

	// 								boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);

	// 								let bBoxClone = new THREE.Box3().setFromObject(clonedObject)

	// 								const topLength = modelWidthT - (boundingBox1.max.x - boundingBox1.min.x);

	// 								const rightLength = (boundingBoxR.max.y - boundingBoxR.min.y) - (boundingBox0.max.y - boundingBox0.min.y);

	// 								let angleInRadians = Math.atan(newHeight / newWidth);

	// 								let worldPosition = new THREE.Vector3();
	// 								clonedObject.rotation.z = -angleInRadians;
	// 								const xCenter = (boundingBox1.max.x + shapeRef2.position.x) * (0.5);
	// 								const yCenter = (boundingBox0.max.y + shapeRef1.position.y) * (0.5);
	// 								var jPos;
	// 								sceneRef.current.traverse(function (object) {
	// 									if (object.name.includes(`ProfileJoints ${shapeRefPt1} ${shapeRefPt2}`)) {
	// 										jPos = object.getWorldPosition(worldPosition);
	// 									}
	// 								});
	// 								clonedObject.position.x = xCenter;
	// 								clonedObject.position.y = yCenter;
	// 								var point2 = clonedObject.position;
	// 								const profileCenter = new THREE.Vector3();
	// 								// let centerDis = boundingBox.getCenter(profileCenter)
	// 								var point1 = jPos
	// 								// let constant = clonedObject.position.dot(point1);

	// 								const hypotenuse = Math.sqrt(Math.pow(topLength, 2) + Math.pow(rightLength, 2));

	// 								clonedObject.scale.x *= hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) + (hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) * 0.025)
	// 								clonedObject.scale.y = -1

	// 								let bBoxCloneNew = new THREE.Box3().setFromObject(clonedObject)

	// 								var pointA1 = new THREE.Vector3(0, 0, 0);
	// 								var pointA2 = new THREE.Vector3(xCenter, yCenter, 0);
	// 								var distC1 = pointA1.distanceTo(pointA2)
	// 								var median = Math.sqrt((2 * (Math.pow(topLength, 2)) + 2 * (Math.pow(rightLength, 2)) - Math.pow(hypotenuse, 2)) / 4) * 0.84;
	// 								const clipData = {
	// 									x: -shapedHeight,
	// 									y: -shapedWidth,
	// 									d: median,
	// 									dist: dist,
	// 									distC1: distC1,
	// 								}
	// 								setGlazing("#ADD8E6", null, shapeFrame, setClipGlaze, glazingRef, clipGlaze, clipData)
	// 								sceneRef.current.add(clonedObject)

	// 							}
	// 							if (shapeRefPt1 === 'FrameBottom' && shapeRefPt2 === 'FrameRight') {
	// 								const boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);

	// 								const boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);
	// 								let bBoxClone = new THREE.Box3().setFromObject(clonedObject)

	// 								const bottomLength = modelWidthT - (boundingBox1.max.x - boundingBox1.min.x);

	// 								const leftLength = (boundingBoxR.max.y - boundingBoxR.min.y) - (boundingBox0.max.y - boundingBox0.min.y);

	// 								let angleInRadians = Math.atan(newHeight / newWidth);

	// 								clonedObject.rotation.z = -angleInRadians;

	// 								const xCenter = (boundingBox1.min.x + shapeRef2.position.x) * 0.5;
	// 								const yCenter = (boundingBox0.min.y + shapeRef1.position.y) * 0.5;


	// 								clonedObject.position.x = xCenter;
	// 								clonedObject.position.y = yCenter;

	// 								const hypotenuse = Math.sqrt(Math.pow(bottomLength, 2) + Math.pow(leftLength, 2));
	// 								var point1 = new THREE.Vector3(0, 0, 1);
	// 								var point2 = new THREE.Vector3(xCenter, yCenter, clonedObject.position.z);
	// 								var median = Math.sqrt((2 * (Math.pow(bottomLength, 2)) + 2 * (Math.pow(leftLength, 2)) - Math.pow(hypotenuse, 2)) / 4) * 0.75;
	// 								var dist = point1.distanceTo(point2)

	// 								clonedObject.scale.x *= hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) + (hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) * 0.025)
	// 								var pointA1 = new THREE.Vector3(0, 0, 0);
	// 								var pointA2 = new THREE.Vector3(xCenter, yCenter, 0);
	// 								var distC1 = pointA1.distanceTo(pointA2)
	// 								const clipData = {
	// 									x: shapedHeight,
	// 									y: shapedWidth,
	// 									d: median,
	// 									dist: dist,
	// 									distC1: distC1,
	// 								}
	// 								setGlazing("#ADD8E6", null, shapeFrame, setClipGlaze, glazingRef, clipGlaze, clipData)
	// 								sceneRef.current.add(clonedObject)
	// 							}
	// 							if (shapeRefPt1 === 'FrameTop' && shapeRefPt2 === 'FrameRight') {

	// 								const boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);

	// 								const boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);
	// 								let bBoxClone = new THREE.Box3().setFromObject(clonedObject)

	// 								const bottomLength = modelWidthT - (boundingBox1.max.x - boundingBox1.min.x);;

	// 								const leftLength = (boundingBoxR.max.y - boundingBoxR.min.y) - (boundingBox0.max.y - boundingBox0.min.y);

	// 								let angleInRadians = Math.atan(newHeight / newWidth);

	// 								clonedObject.rotation.z = angleInRadians;

	// 								const xCenter = (boundingBox1.min.x + shapeRef2.position.x) * 0.5;
	// 								const yCenter = (boundingBox0.max.y + shapeRef1.position.y) * 0.5;


	// 								clonedObject.position.x = xCenter;
	// 								clonedObject.position.y = yCenter;

	// 								const hypotenuse = Math.sqrt(Math.pow(bottomLength, 2) + Math.pow(leftLength, 2));
	// 								var median = Math.sqrt((Math.pow((bottomLength), 2) + Math.pow(leftLength, 2) - Math.pow(hypotenuse, 2)) / 4);
	// 								var point1 = new THREE.Vector3(0, 0, 1);
	// 								var point2 = new THREE.Vector3(xCenter, yCenter, clonedObject.position.z);
	// 								var dist = point1.distanceTo(point2)
	// 								clonedObject.scale.x *= hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) + (hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) * 0.025)
	// 								var pointA1 = new THREE.Vector3(0, 0, 0);
	// 								var pointA2 = new THREE.Vector3(xCenter, yCenter, 0);
	// 								var distC1 = pointA1.distanceTo(pointA2)
	// 								const clipData = {
	// 									x: shapedHeight,
	// 									y: -shapedWidth,
	// 									d: median,
	// 									dist: dist,
	// 									distC1: distC1,
	// 								}
	// 								setGlazing("#ADD8E6", null, shapeFrame, setClipGlaze, glazingRef, clipGlaze, clipData)
	// 								sceneRef.current.add(clonedObject)
	// 							}
	// 							if (shapeRefPt1 === 'FrameBottom' && shapeRefPt2 === 'FrameLeft') {
	// 								const boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);

	// 								const boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);
	// 								let bBoxClone = new THREE.Box3().setFromObject(clonedObject)

	// 								const bottomLength = modelWidthT - (boundingBox1.max.x - boundingBox1.min.x);;

	// 								const leftLength = (boundingBoxR.max.y - boundingBoxR.min.y) - (boundingBox0.max.y - boundingBox0.min.y);

	// 								let angleInRadians = Math.atan(newHeight / newWidth);

	// 								clonedObject.rotation.z = angleInRadians;

	// 								const xCenter = (boundingBox1.max.x + shapeRef2.position.x) / 2;
	// 								const yCenter = (boundingBox0.min.y + shapeRef1.position.y) / 2;


	// 								clonedObject.position.x = xCenter;
	// 								clonedObject.position.y = yCenter;

	// 								const hypotenuse = Math.sqrt(Math.pow(bottomLength, 2) + Math.pow(leftLength, 2));

	// 								var point1 = new THREE.Vector3(0, 0, 1);
	// 								var point2 = new THREE.Vector3(xCenter, yCenter, clonedObject.position.z);
	// 								var dist = point1.distanceTo(point2)
	// 								clonedObject.scale.x *= hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) + (hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) * 0.025)
	// 								var pointA1 = new THREE.Vector3(0, 0, 0);
	// 								var pointA2 = new THREE.Vector3(xCenter, yCenter, 0);
	// 								var distC1 = pointA1.distanceTo(pointA2)
	// 								const clipData = {
	// 									x: -shapedHeight,
	// 									y: shapedWidth,
	// 									// d: median,
	// 									dist: dist,
	// 									distC1: distC1,
	// 								}
	// 								setGlazing("#ADD8E6", null, shapeFrame, setClipGlaze, glazingRef, clipGlaze, clipData)

	// 							}
	// 						}
	// 					}, 1);
	// 				}

	// 				resolve(gltf.scene);
	// 				// if (sceneRef.current) {
	// 				// 	let obj;
	// 				// 	sceneRef.current.traverse(function (object) {
	// 				// 		if (object.name.includes(`ProfileJoints ${shapeRefPt1} ${shapeRefPt2}`)) {
	// 				// 			object.visible = false;
	// 				// 			obj = object;
	// 				// 		}
	// 				// 	});
	// 				// 	if (obj) {
	// 				// 		sceneRef.current.remove(obj);
	// 				// 	}
	// 				// }

	// 			}, undefined, (error) => {
	// 				reject(error);
	// 			});

	// 			setMultiSelectRefPoints([]);
	// 		} else {
	// 			reject(new Error('targetName does not include "FrameBottom"'));
	// 		}
	// 	});
	// }

	// async function addShapedFrame(shapeRefPt1, shapeRefPt2, shapedH, shapedW, modelW, modelH, shape) {


	// 	let clonedObject = null;
	// 	let clonedItem = null;

	// 	customModelData?.frameProfileData.forEach((item) => {
	// 		if (item.side === shapeRefPt1) {
	// 			clonedItem = item
	// 		}
	// 	})

	// 	if (clonedItem) {
	// 		try {

	// 			if (modelScene && cornerJointModel && sceneRef.current) {
	// 				addCornerJoint(modelScene, cornerJointModel, sceneRef, frameStyleTop, frameStyleLeft, frameStyleBottom, frameStyleRight, profileJointDetails, internalFrameRef, externalFrameRef, rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal, headerSelectedItem)
	// 			}

	// 			if (shape === 'Chamfer') {
	// 				clonedObject = await cloneShapedFrame(clonedItem.url, frameStyleRef.current[clonedItem?.index]?.position, frameStyleRef?.current[clonedItem.index]?.name, clonedItem, shapeRefPt1, shapeRefPt2, shapedH, shapedW, modelW, modelH);
	// 			}

	// 			else if (shape === 'Radius') {
	// 				clonedObject = await cloneRadiusFrame(radiusModel, frameStyleRef.current[clonedItem?.index]?.position, frameStyleRef?.current[clonedItem.index]?.name, clonedItem, shapeRefPt1, shapeRefPt2, shapedH, shapedW, modelW, modelH);
	// 			}
	// 			else if (shape === 'Ellipse') {
	// 				clonedObject = await cloneEllipseFrame(radiusModelWithoutBones, frameStyleRef.current[clonedItem?.index]?.position, frameStyleRef?.current[clonedItem.index]?.name, clonedItem, shapeRefPt1, shapeRefPt2, shapedH, shapedW, modelW, modelH)
	// 			}
	// 		} catch (error) {
	// 			console.error('Failed to clone shaped frame:', error);
	// 		}

	// 		const obj = {
	// 			shape: shape,
	// 			shapedCorner: shapeRefPt1,
	// 			shapedCorner2: shapeRefPt2,
	// 			modelHeight: parseFloat(modelH),
	// 			modelWidth: parseFloat(modelW),
	// 			shapeHeight: parseFloat(shapedH),
	// 			shapeWidth: parseFloat(shapedWidth),
	// 			name: checkClickedProfile?.name
	// 		}

	// 		setCustomModelData((prevState) => {
	// 			const shapedFrame = Array.isArray(prevState.shapedFrame) ? prevState.shapedFrame : [];
	// 			const existingIndex = shapedFrame?.findIndex(item => item.name === obj.name);

	// 			let updatedShapedFrame;
	// 			if (existingIndex !== -1) {
	// 				// Replace the existing object with the new object
	// 				updatedShapedFrame = [...shapedFrame];
	// 				updatedShapedFrame[existingIndex] = obj;
	// 			} else {
	// 				// Add the new object to the array
	// 				updatedShapedFrame = [...shapedFrame, obj];
	// 			}

	// 			return {
	// 				...prevState,
	// 				shapedFrame: updatedShapedFrame
	// 			};
	// 		});
	// 	}
	// }

	// function isBoneInRange(boneName) {
	// 	const match = boneName.match(/Bone(\d+)/);
	// 	if (match) {
	// 		const boneIndex = parseInt(match[1], 10);
	// 		return boneIndex >= 3 && boneIndex <= 21;
	// 	}
	// 	return false;
	// }

	// function cloneRadiusFrame(element, targetPosition, targetName, data, shapeRefPt1, shapeRefPt2, newHeight, newWidth, modelWid, modelHt) {

	// 	return new Promise((resolve, reject) => {
	// 		let addedNewFrame;
	// 		if (targetName?.includes(shapeRefPt1)) {
	// 			const loader = new GLTFLoader();

	// 			loader.load(element, (gltf) => {

	// 				addedNewFrame = gltf.scene;
	// 				sceneRef.current.add(gltf.scene)
	// 				let bones = [];


	// 				let scaleFactor2 = 0;

	// 				let maxXTop, maxYTop, cloneInitialLength, scaleFacH, scaleFacW;
	// 				const shapeRef = getFrameStyleRef2(shapeRefPt1); // assuming getFrameStyleRef is the function that contains the above code
	// 				const shapeRefR = getFrameStyleRef2(shapeRefPt2); // assuming getFrameStyleRef is the function that contains the above code
	// 				const boundingBox = new THREE.Box3().setFromObject(shapeRef);
	// 				const boundingBoxR = new THREE.Box3().setFromObject(shapeRefR);
	// 				const modelWidthT = boundingBox.max.x - boundingBox.min.x;
	// 				const boundingBox2 = new THREE.Box3().setFromObject(gltf.scene);
	// 				const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;
	// 				const scaleFactor = modelWidth / modelWidth2;

	// 				gltf.scene.position.set(targetPosition.x, targetPosition.y, targetPosition.z);
	// 				gltf.scene.scale.x = scaleFactor;
	// 				let clonedObject = gltf.scene

	// 				const shapeRef2 = getFrameStyleRef1(shapeRefPt2);
	// 				const shapeRef1 = getFrameStyleRef2(shapeRefPt1);


	// 				let boundingBox0, boundingBox1, bBoxFrame

	// 				if (shapeRef2) {

	// 					scaleFacH = (modelHt - newHeight) / modelHt
	// 					shapeRef2.scale.x *= scaleFacH;

	// 					const bBox0 = new THREE.Box3().setFromObject(shapeRef2);
	// 					const WidthYLeft2 = bBox0.max.y - bBox0.min.y;

	// 					if (shapeRefPt1 === 'FrameTop') {
	// 						shapeRef2.position.y = (boundingBoxR.min.y + WidthYLeft2 / 2);
	// 					}
	// 					else {
	// 						shapeRef2.position.y = -(boundingBoxR.min.y + WidthYLeft2 / 2);
	// 					}
	// 					boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);

	// 				}

	// 				if (clonedObject) {

	// 					scaleFacW = (modelWid - newWidth) / modelWid
	// 					shapeRef1.scale.x *= scaleFacW;

	// 					let bBox2 = new THREE.Box3().setFromObject(shapeRef1)
	// 					const WidthX2 = bBox2.max.x - bBox2.min.x;


	// 					if (shapeRefPt2 === 'FrameRight') {
	// 						shapeRef1.position.x = -(boundingBox.min.x + WidthX2 / 2);
	// 					}
	// 					else {
	// 						shapeRef1.position.x = (boundingBox.min.x + WidthX2 / 2);
	// 					}

	// 					boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);

	// 					clonedObject.name = `FrameSlant ${shapeRefPt1} ${shapeRefPt1}`
	// 					const modelWidth = boundingBox.max.y - boundingBox.min.y;

	// 					const boundingBox2 = new THREE.Box3().setFromObject(clonedObject);

	// 					const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;
	// 					cloneInitialLength = modelWidth2;

	// 					scaleFactor2 = modelWidth / modelWidth2;

	// 					clonedObject.position.y = 0;
	// 					clonedObject.position.x = 1
	// 					clonedObject.visible = true


	// 					setTimeout(() => {
	// 						if (shapeRef2) {
	// 							if (shapeRefPt1 === 'FrameTop' && shapeRefPt2 === 'FrameLeft') {
	// 								boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);

	// 								boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);

	// 								let bBoxClone = new THREE.Box3().setFromObject(clonedObject)


	// 								const topLength = modelWidthT - (boundingBox1.max.x - boundingBox1.min.x);

	// 								const rightLength = (boundingBoxR.max.y - boundingBoxR.min.y) - (boundingBox0.max.y - boundingBox0.min.y);

	// 								let angleInRadians = Math.atan(newHeight / newWidth);

	// 								// clonedObject.rotation.z = -angleInRadians;
	// 								// const xCenter = (boundingBox1.max.x/2 + shapeRef2.position.x) * (0.7);
	// 								// const yCenter = (shapeRef1.position.y) * (1);
	// 								const xCenter = (shapeRef2.position.x) * 0.5
	// 								const yCenter = (shapeRef1.position.y) * 1.0024


	// 								clonedObject.position.x = xCenter;
	// 								clonedObject.position.y = yCenter;
	// 								var point1 = new THREE.Vector3(0, 0, 1);
	// 								var point2 = new THREE.Vector3(xCenter, yCenter, 0);
	// 								const profileCenter = new THREE.Vector3();
	// 								let centerDis = boundingBox.getCenter(profileCenter)
	// 								var dist = centerDis.distanceTo(point2)
	// 								let constant = clonedObject.position.dot(point1);

	// 								const hypotenuse = (((topLength + rightLength) / 2) * Math.PI * 90) / 180;

	// 								clonedObject.scale.x *= (hypotenuse / (bBoxClone.max.x - bBoxClone.min.x)) * 0.098
	// 								clonedObject.scale.y = -1
	// 								clonedObject.scale.z += clonedObject.scale.z * 0.2
	// 								bBoxClone = new THREE.Box3().setFromObject(clonedObject)
	// 								// let helper2 = new THREE.Box3Helper(bBoxClone, 0xff0000); 
	// 								// sceneRef.current.add(helper2)

	// 								// let helper3 = new THREE.Box3Helper(boundingBox0, 0xff0000); 
	// 								// sceneRef.current.add(helper3); 
	// 								let worldPosition = new THREE.Vector3();
	// 								let wPos = clonedObject.getWorldPosition(worldPosition);
	// 								var pointA1 = new THREE.Vector3(0, 0, 0);
	// 								var pointA2 = new THREE.Vector3(xCenter, yCenter, 0);
	// 								var distC1 = pointA1.distanceTo(pointA2)

	// 								const clipData = {
	// 									x: -shapedHeight,
	// 									y: -shapedWidth,
	// 									d: dist,
	// 									distC1: distC1 * 0.9,
	// 								}
	// 								let worldPosR = new THREE.Vector3();
	// 								gltf.scene.traverse((child) => {
	// 									if (child instanceof THREE.Bone) {
	// 										if (child.name === 'Bone022') {
	// 											// child.rotation.z += Math.PI / 30;
	// 											child.getWorldPosition(worldPosR);
	// 										}
	// 									}
	// 								});
	// 								let bBoxClone2 = new THREE.Box3().setFromObject(clonedObject)
	// 								// 1) Assuming 0.2 radian change in value with every 100 units increase in height and width of the model
	// 								// 2) Calculating the perunit change in radians change in angle/change in height * change in width ie. change per 100 unit is 0.2
	// 								// 3) now formula is change in value per unit * (h + w)
	// 								let initialWidth = 1050
	// 								let initialHeigt = 950
	// 								let initialModelWidth = 2500
	// 								let changeInModelWidth = modelWid - initialModelWidth
	// 								// let changeInPosition = 0.0024 * changeInModelWidth
	// 								let angleModifier = 0.001 * changeInModelWidth

	// 								let changeInHeight = newHeight - initialHeigt
	// 								let changeInWidth = newWidth - initialWidth
	// 								let changeInAngle = 0.001 * (changeInHeight + changeInWidth)
	// 								let angle = 2.8
	// 								// clonedObject.scale.x -= 0.
	// 								let angle2 = 153
	// 								// while(!boundingBox0.intersectsBox(bBoxClone2)){
	// 								while (true) {

	// 									// angle++
	// 									clonedObject.traverse((child) => {
	// 										if (child instanceof THREE.Bone) {
	// 											// if (child.name === 'Bone001') {
	// 											// 	child.position.y -= 0.1
	// 											// }

	// 											if (isBoneInRange(child.name)) {
	// 												child.rotation.z += Math.PI / 180 * angle;
	// 											}

	// 											// if (child.name === 'Bone004') {
	// 											// 	child.rotation.z += Math.PI / 180 * angle;
	// 											// }
	// 											if (child.name === 'Bone022') {
	// 												child.rotation.z += Math.PI / 180 * 25 - 0.3;
	// 												child.scale.y += 1.5
	// 												child.getWorldPosition(worldPosR);
	// 											}
	// 										}
	// 										if (child.name.includes("External")) {
	// 											externalFrameRef.current.push(child);
	// 										} else {
	// 											internalFrameRef.current.push(child);
	// 										}


	// 										setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal);
	// 									});

	// 									// bBoxClone2 = new THREE.Box3().setFromObject(clonedObject)
	// 									// let helper = new THREE.Box3Helper(bBoxClone2, 0x0000ff); 
	// 									// sceneRef.current.add(helper)
	// 									let cubeAdded, cube;
	// 									if (!cubeAdded) {
	// 										let geometry = new THREE.BoxGeometry(0.05, 0.05, 0.05);
	// 										let material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
	// 										cube = new THREE.Mesh(geometry, material);
	// 										// sceneRef.current.add(cube);
	// 										cubeAdded = true;
	// 									}
	// 									cube.position.set(worldPosR.x, worldPosR.y, 0);
	// 									let bBoxC = new THREE.Box3().setFromObject(cube)
	// 									let helperC = new THREE.Box3Helper(bBoxC, 0x0000ff);
	// 									// 	// sceneRef.current.add(helperC)


	// 									// sceneRef.current.add(cube);
	// 									// return
	// 									// if (bBoxC.intersectsBox(boundingBox0)) {
	// 									if (shapeRef2.position.x >= bBoxC.min.x || boundingBox0.max.y >= bBoxC.max.y) {
	// 										break;
	// 									}
	// 									angle = angle + 1;

	// 								}
	// 								setGlazing("#ADD8E6", null, shapeFrame, setClipGlaze, glazingRef, clipGlaze, clipData)
	// 								sceneRef.current.add(clonedObject)
	// 							}
	// 							// else if (shapeRefPt1 === 'FrameBottom' && shapeRefPt2 === 'FrameRight') {
	// 							// 	const boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);

	// 							// 	const boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);
	// 							// 	let bBoxClone = new THREE.Box3().setFromObject(clonedObject)

	// 							// 	const bottomLength = modelWidthT - (boundingBox1.max.x - boundingBox1.min.x);

	// 							// 	const leftLength = (boundingBoxR.max.y - boundingBoxR.min.y) - (boundingBox0.max.y - boundingBox0.min.y);

	// 							// 	let angleInRadians = Math.atan(newHeight / newWidth);

	// 							// 	clonedObject.rotation.z = -angleInRadians;

	// 							// 	const xCenter = (boundingBox1.min.x + shapeRef2.position.x) * 0.5;
	// 							// 	const yCenter = (boundingBox0.min.y + shapeRef1.position.y) * 0.5;


	// 							// 	clonedObject.position.x = xCenter;
	// 							// 	clonedObject.position.y = yCenter;

	// 							// 	const hypotenuse = Math.sqrt(Math.pow(bottomLength, 2) + Math.pow(leftLength, 2));
	// 							// 	var point1 = new THREE.Vector3(0, 0, 1);
	// 							// 	var point2 = new THREE.Vector3(xCenter, yCenter, clonedObject.position.z);
	// 							// 	var dist = point1.distanceTo(point2)
	// 							// 	clonedObject.scale.x *= hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) + (hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) * 0.025)
	// 							// 	const clipData = {
	// 							// 		x: 1,
	// 							// 		y: 1,
	// 							// 		d: hypotenuse / cloneInitialLength,
	// 							// 		sec: dist
	// 							// 	}
	// 							// 	setGlazing("#ADD8E6", null, shapeFrame, setClipGlaze, glazingRef, clipGlaze, clipData)

	// 							// }
	// 							// else if (shapeRefPt1 === 'FrameTop' && shapeRefPt2 === 'FrameRight') {
	// 							// 	const boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);

	// 							// 	const boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);
	// 							// 	let bBoxClone = new THREE.Box3().setFromObject(clonedObject)

	// 							// 	const bottomLength = modelWidthT - (boundingBox1.max.x - boundingBox1.min.x);;

	// 							// 	const leftLength = (boundingBoxR.max.y - boundingBoxR.min.y) - (boundingBox0.max.y - boundingBox0.min.y);

	// 							// 	let angleInRadians = Math.atan(newHeight / newWidth);

	// 							// 	console.log("angle ", angleInRadians, customModelData?.frameProfileData, clonedObject)

	// 							// 	clonedObject.rotation.z = angleInRadians;

	// 							// 	const xCenter = (boundingBox1.min.x + shapeRef2.position.x) * 0.5;
	// 							// 	const yCenter = (boundingBox0.max.y + shapeRef1.position.y) * 0.5;


	// 							// 	clonedObject.position.x = xCenter;
	// 							// 	clonedObject.position.y = yCenter;

	// 							// 	const hypotenuse = Math.sqrt(Math.pow(bottomLength, 2) + Math.pow(leftLength, 2));
	// 							// 	var point1 = new THREE.Vector3(0, 0, 1);
	// 							// 	var point2 = new THREE.Vector3(xCenter, yCenter, clonedObject.position.z);
	// 							// 	var dist = point1.distanceTo(point2)
	// 							// 	clonedObject.scale.x *= hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) + (hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) * 0.025)
	// 							// 	const clipData = {
	// 							// 		x: 1,
	// 							// 		y: -1,
	// 							// 		d: hypotenuse / cloneInitialLength,
	// 							// 		sec: dist
	// 							// 	}
	// 							// 	setGlazing("#ADD8E6", null, shapeFrame, setClipGlaze, glazingRef, clipGlaze, clipData)
	// 							// }
	// 							// else if (shapeRefPt1 === 'FrameBottom' && shapeRefPt2 === 'FrameLeft') {
	// 							// 	const boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);

	// 							// 	const boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);
	// 							// 	let bBoxClone = new THREE.Box3().setFromObject(clonedObject)

	// 							// 	const bottomLength = modelWidthT - (boundingBox1.max.x - boundingBox1.min.x);;

	// 							// 	const leftLength = (boundingBoxR.max.y - boundingBoxR.min.y) - (boundingBox0.max.y - boundingBox0.min.y);

	// 							// 	let angleInRadians = Math.atan(newHeight / newWidth);

	// 							// 	clonedObject.rotation.z = angleInRadians;

	// 							// 	const xCenter = (boundingBox1.max.x + shapeRef2.position.x) / 2;
	// 							// 	const yCenter = (boundingBox0.min.y + shapeRef1.position.y) / 2;


	// 							// 	clonedObject.position.x = xCenter;
	// 							// 	clonedObject.position.y = yCenter;

	// 							// 	const hypotenuse = Math.sqrt(Math.pow(bottomLength, 2) + Math.pow(leftLength, 2));

	// 							// 	var point1 = new THREE.Vector3(0, 0, 1);
	// 							// 	var point2 = new THREE.Vector3(xCenter, yCenter, clonedObject.position.z);
	// 							// 	var dist = point1.distanceTo(point2)
	// 							// 	clonedObject.scale.x *= hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) + (hypotenuse / (bBoxClone.max.x - bBoxClone.min.x) * 0.025)
	// 							// 	const clipData = {
	// 							// 		x: 1,
	// 							// 		y: 1,
	// 							// 		d: hypotenuse / cloneInitialLength,
	// 							// 		sec: dist
	// 							// 	}
	// 							// 	setGlazing("#ADD8E6", null, shapeFrame, setClipGlaze, glazingRef, clipGlaze, clipData)
	// 							// }
	// 						}
	// 					}, 1);
	// 				}

	// 				resolve(gltf.scene);
	// 				if (sceneRef.current) {
	// 					let obj;
	// 					sceneRef.current.traverse(function (object) {
	// 						if (object.name.includes(`ProfileJoints ${shapeRefPt1} ${shapeRefPt2}`)) {
	// 							object.visible = false;
	// 							obj = object;
	// 						}
	// 					});
	// 					if (obj) {
	// 						sceneRef.current.remove(obj);
	// 					}
	// 				}

	// 			}, undefined, (error) => {
	// 				reject(error);
	// 			});

	// 			setMultiSelectRefPoints([]);
	// 		} else {
	// 			reject(new Error('targetName does not include "FrameBottom"'));
	// 		}
	// 	});
	// }


	// function cloneEllipseFrame(element, targetPosition, targetName, data, shapeRefPt1, shapeRefPt2, newHeight, newWidth, modelWid, modelHt) {
	// 	return new Promise((resolve, reject) => {
	// 		// let addedNewFrame;)
	// 		if (targetName?.includes(shapeRefPt1)) {
	// 			const loader = new GLTFLoader();

	// 			loader.load(element, (gltf) => {
	// 				// addedNewFrame = gltf.scene;
	// 				sceneRef.current.add(gltf.scene)

	// 				let scaleFactor2 = 0;

	// 				let maxXTop, maxYTop, cloneInitialLength, scaleFacH, scaleFacW;
	// 				const shapeRef = getFrameStyleRef2(shapeRefPt1); // assuming getFrameStyleRef is the function that contains the above code
	// 				const shapeRefR = getFrameStyleRef2(shapeRefPt2); // assuming getFrameStyleRef is the function that contains the above code

	// 				const boundingBox = new THREE.Box3().setFromObject(shapeRef);
	// 				const boundingBoxR = new THREE.Box3().setFromObject(shapeRefR);
	// 				const modelWidthT = boundingBox.max.x - boundingBox.min.x;
	// 				const boundingBox2 = new THREE.Box3().setFromObject(gltf.scene);
	// 				const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;
	// 				// const scaleFactor = modelWidth / modelWidth2;

	// 				gltf.scene.position.set(targetPosition.x, targetPosition.y, targetPosition.z);
	// 				// gltf.scene.scale.x = scaleFactor;
	// 				let clonedObject = gltf.scene

	// 				const shapeRef2 = getFrameStyleRef1(shapeRefPt2);
	// 				const shapeRef1 = getFrameStyleRef2(shapeRefPt1);

	// 				let boundingBox0, boundingBox1, bBoxFrame

	// 				if (shapeRef2) {

	// 					scaleFacH = (modelHt - newHeight) / modelHt

	// 					shapeRef2.scale.x *= scaleFacH;

	// 					const bBox0 = new THREE.Box3().setFromObject(shapeRef2);
	// 					const WidthYLeft2 = bBox0.max.y - bBox0.min.y;

	// 					if (shapeRefPt1 === 'FrameTop') {
	// 						shapeRef2.position.y = (boundingBoxR.min.y + WidthYLeft2 / 2);
	// 					}
	// 					else {
	// 						shapeRef2.position.y = -(boundingBoxR.min.y + WidthYLeft2 / 2);
	// 					}

	// 					boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);

	// 				}

	// 				if (clonedObject) {

	// 					scaleFacW = (modelWid - newWidth) / modelWid
	// 					shapeRef1.scale.x *= scaleFacW;

	// 					let bBox2 = new THREE.Box3().setFromObject(shapeRef1)
	// 					const WidthX2 = bBox2.max.x - bBox2.min.x;


	// 					if (shapeRefPt2 === 'FrameRight') {
	// 						shapeRef1.position.x = -(boundingBox.min.x + WidthX2 / 2);
	// 					}
	// 					else {
	// 						shapeRef1.position.x = (boundingBox.min.x + WidthX2 / 2);
	// 					}

	// 					boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);

	// 					clonedObject.name = `FrameSlant ${shapeRefPt1} ${shapeRefPt1}`

	// 					clonedObject.visible = true


	// 					setTimeout(() => {
	// 						if (shapeRef2) {
	// 							if (shapeRefPt1 === 'FrameTop' && shapeRefPt2 === 'FrameLeft') {
	// 								boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);

	// 								boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);

	// 								let bBoxClone = new THREE.Box3().setFromObject(clonedObject)


	// 								const topLength = modelWidthT - (boundingBox1.max.x - boundingBox1.min.x);

	// 								const rightLength = (boundingBoxR.max.y - boundingBoxR.min.y) - (boundingBox0.max.y - boundingBox0.min.y);


	// 								const xCenter = (shapeRef2.position.x) * 0.5
	// 								const yCenter = (shapeRef1.position.y) * 1.0024


	// 								clonedObject.position.x = boundingBox0.min.x - 0.09;
	// 								clonedObject.position.y = shapeRef1.position.y
	// 								var point1 = new THREE.Vector3(0, 0, 1);
	// 								var point2 = new THREE.Vector3(xCenter, yCenter, 0);
	// 								const profileCenter = new THREE.Vector3();
	// 								let centerDis = boundingBox.getCenter(profileCenter)
	// 								var dist = centerDis.distanceTo(point2)
	// 								let constant = clonedObject.position.dot(point1);

	// 								const hypotenuse = (((topLength + rightLength) / 2) * Math.PI * 90) / 180;

	// 								clonedObject.scale.x *= (hypotenuse / (bBoxClone.max.x - bBoxClone.min.x)) * 0.098
	// 								clonedObject.scale.y = -1
	// 								clonedObject.scale.z += clonedObject.scale.z * 0.2
	// 								bBoxClone = new THREE.Box3().setFromObject(clonedObject)

	// 								let worldPosition = new THREE.Vector3();
	// 								let wPos = clonedObject.getWorldPosition(worldPosition);
	// 								var pointA1 = new THREE.Vector3(0, 0, 0);
	// 								var pointA2 = new THREE.Vector3(xCenter, yCenter, 0);
	// 								var distC1 = pointA1.distanceTo(pointA2)

	// 								const clipData = {
	// 									x: -shapedHeight,
	// 									y: -shapedWidth,
	// 									d: dist,
	// 									distC1: distC1 * 0.9,
	// 								}


	// 								let worldPosR = new THREE.Vector3();
	// 								let lastBone;
	// 								if (gltf?.scene) {
	// 									gltf.scene.traverse((child) => {
	// 										if (child instanceof THREE.Bone) {
	// 											if (child.name === 'Bone017') {
	// 												lastBone = child
	// 												lastBone.getWorldPosition(worldPosR);
	// 											}
	// 										}
	// 									});
	// 								}

	// 								const minPoint = boundingBox0.min;
	// 								const maxPoint = worldPosR;

	// 								const dx = minPoint.x - maxPoint.x;
	// 								const dy = minPoint.y - maxPoint.y;

	// 								// const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);

	// 								const newAngle = Math.atan2(dy, dx);
	// 								let bBoxClone2 = new THREE.Box3().setFromObject(clonedObject)
	// 								let angle = 2.8

	// 								while (true) {
	// 									if (clonedObject) {
	// 										clonedObject.traverse((child) => {
	// 											if (child instanceof THREE.Bone) {

	// 												if (child.name.includes('Bone')) {
	// 													child.rotation.z -= (Math.PI / 180 * newAngle);
	// 													child.getWorldPosition(worldPosR);
	// 												}
	// 												if (child.name.includes('Bone022') || child.name.includes('Bone021') || child.name.includes('Bone020') || child.name.includes('Bone019') || child.name.includes('Bone018')) {
	// 													child.scale.set(0, 0, 0)
	// 												}
	// 											}
	// 											if (child.name.includes("External")) {
	// 												externalFrameRef.current.push(child);
	// 											} else {
	// 												internalFrameRef.current.push(child);
	// 											}


	// 											setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal);
	// 										});
	// 									}



	// 									let cubeAdded, cube;
	// 									if (!cubeAdded) {
	// 										let geometry = new THREE.BoxGeometry(0.05, 0.05, 0.05);
	// 										let material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
	// 										cube = new THREE.Mesh(geometry, material);
	// 										// sceneRef.current.add(cube);
	// 										cubeAdded = true;
	// 									}
	// 									cube.position.set(worldPosR.x, worldPosR.y, 0);
	// 									let bBoxC = new THREE.Box3().setFromObject(cube)
	// 									let helperC = new THREE.Box3Helper(bBoxC, 0x0000ff);

	// 									if (shapeRef2.position.x >= bBoxC.min.x || boundingBox0.max.y >= bBoxC.max.y) {
	// 										break;
	// 									}
	// 									angle = angle + 1;

	// 								}

	// 								setGlazing("#ADD8E6", null, shapeFrame, setClipGlaze, glazingRef, clipGlaze, clipData)
	// 								sceneRef.current.add(clonedObject)
	// 							}
	// 						}
	// 					}, 1);
	// 				}

	// 				resolve(gltf.scene);
	// 				if (sceneRef.current) {
	// 					let obj;
	// 					sceneRef.current.traverse(function (object) {
	// 						if (object.name.includes(`ProfileJoints ${shapeRefPt1} ${shapeRefPt2}`)) {
	// 							object.visible = false;
	// 							obj = object;
	// 						}
	// 					});
	// 					if (obj) {
	// 						sceneRef.current.remove(obj);
	// 					}
	// 				}

	// 			}, undefined, (error) => {
	// 				reject(error);
	// 			});

	// 			setMultiSelectRefPoints([]);
	// 		} else {
	// 			reject(new Error('targetName does not include "FrameBottom"'));
	// 		}
	// 	});
	// }



	// function cloneEllipseFrame(element, targetPosition, targetName, data, shapeRefPt1, shapeRefPt2, newHeight, newWidth, modelWid, modelHt) {
	// 	return new Promise((resolve, reject) => {
	// 		if (targetName?.includes(shapeRefPt1)) {
	// 			const loader = new GLTFLoader();


	// 			// var extrudeSettings = {
	// 			// 	steps: 20,
	// 			// 	depth: 1 - 0.2,
	// 			// 	bevelEnabled: true,
	// 			// 	bevelThickness: 1,
	// 			// 	bevelSize: 0.1,
	// 			// 	bevelOffset: 0,
	// 			// 	bevelSegments: 1
	// 			// };


	// 			loader.load(element, (gltf) => {
	// 				// Convert the geometry to BufferGeometry

	// 				// Create a shape that matches the bounding box dimensions
	// 				const bevelShape = new THREE.Shape();

	// 				const extrudeSettings = {
	// 					steps: 20,
	// 					depth: 1,
	// 					bevelEnabled: true,
	// 					bevelThickness: 0.1 ,
	// 					bevelSize: 0.1,
	// 					bevelOffset: 0,
	// 					bevelSegments: 1
	// 				};
	// 				gltf.scene.traverse((child) => {
	// 					if (child.isMesh) {
	// 						console.log(child , "8214");
	// 						const bufferGeometry = new THREE.ExtrudeGeometry(bevelShape, extrudeSettings);
	// 						bufferGeometry.copy(child.geometry);
	// 						child.geometry = bufferGeometry;
	// 					}
	// 				});


	// 				// Adding the converted object to the scene
	// 				sceneRef.current.add(gltf.scene);

	// 				let scaleFactor2 = 0;
	// 				let maxXTop, maxYTop, cloneInitialLength, scaleFacH, scaleFacW;
	// 				const shapeRef = getFrameStyleRef2(shapeRefPt1);
	// 				const shapeRefR = getFrameStyleRef2(shapeRefPt2);

	// 				const boundingBox = new THREE.Box3().setFromObject(shapeRef);
	// 				const boundingBoxR = new THREE.Box3().setFromObject(shapeRefR);
	// 				const modelWidthT = boundingBox.max.x - boundingBox.min.x;
	// 				const boundingBox2 = new THREE.Box3().setFromObject(gltf.scene);
	// 				const modelWidth2 = boundingBox2.max.x - boundingBox2.min.x;

	// 				gltf.scene.position.set(targetPosition.x, targetPosition.y, targetPosition.z);
	// 				let clonedObject = gltf.scene;

	// 				const shapeRef2 = getFrameStyleRef1(shapeRefPt2);
	// 				const shapeRef1 = getFrameStyleRef2(shapeRefPt1);

	// 				let boundingBox0, boundingBox1, bBoxFrame;

	// 				if (shapeRef2) {
	// 					scaleFacH = (modelHt - newHeight) / modelHt;
	// 					shapeRef2.scale.x *= scaleFacH;

	// 					const bBox0 = new THREE.Box3().setFromObject(shapeRef2);
	// 					const WidthYLeft2 = bBox0.max.y - bBox0.min.y;

	// 					if (shapeRefPt1 === 'FrameTop') {
	// 						shapeRef2.position.y = (boundingBoxR.min.y + WidthYLeft2 / 2);
	// 					} else {
	// 						shapeRef2.position.y = -(boundingBoxR.min.y + WidthYLeft2 / 2);
	// 					}

	// 					boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);
	// 				}

	// 				if (clonedObject) {
	// 					scaleFacW = (modelWid - newWidth) / modelWid;
	// 					shapeRef1.scale.x *= scaleFacW;

	// 					let bBox2 = new THREE.Box3().setFromObject(shapeRef1);
	// 					const WidthX2 = bBox2.max.x - bBox2.min.x;

	// 					if (shapeRefPt2 === 'FrameRight') {
	// 						shapeRef1.position.x = -(boundingBox.min.x + WidthX2 / 2);
	// 					} else {
	// 						shapeRef1.position.x = (boundingBox.min.x + WidthX2 / 2);
	// 					}

	// 					boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);
	// 					clonedObject.name = `FrameSlant ${shapeRefPt1} ${shapeRefPt1}`;
	// 					clonedObject.visible = true;

	// 					setTimeout(() => {
	// 						if (shapeRef2) {
	// 							if (shapeRefPt1 === 'FrameTop' && shapeRefPt2 === 'FrameLeft') {
	// 								boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);
	// 								boundingBox1 = new THREE.Box3().setFromObject(shapeRef1);

	// 								clonedObject.rotation.z = Math.PI
	// 								clonedObject.position.x = boundingBox1.max.x + 1

	// 								// clonedObject.position.y = shapeRef1.position.y


	// 								const removedPortionLength = (boundingBox0.max.x - boundingBox0.min.x) + (boundingBox1.max.x - boundingBox1.min.x);
	// 								const cloneInitialLength = boundingBox2.max.x - boundingBox2.min.x;


	// 								// Adjust the scale of the cloned object to match the removed portion length
	// 								clonedObject.scale.x *= (removedPortionLength / cloneInitialLength) * 2
	// 								clonedObject.scale.z = shapeRef1.scale.z
	// 								const startPoint = boundingBox1.getCenter(new THREE.Vector3());
	// 								const endPoint = boundingBox0.getCenter(new THREE.Vector3());

	// 								// Define the curve
	// 								const curve = new THREE.QuadraticBezierCurve3(
	// 									startPoint,
	// 									new THREE.Vector3((startPoint.x + endPoint.x) / 2, startPoint.y + 5, (startPoint.z + endPoint.z) / 2), // Control point
	// 									endPoint
	// 								);

	// 								// Create a tube geometry along the curve
	// 								clonedObject.traverse((child) => {
	// 									if (child.isMesh) {
	// 										const geometry = child.geometry;
	// 										const positionAttribute = geometry.getAttribute('position');
	// 										const vertex = new THREE.Vector3();

	// 										for (let i = 0; i < positionAttribute.count; i++) {
	// 											vertex.fromBufferAttribute(positionAttribute, i);
	// 											const pointOnCurve = curve.getPointAt(i / positionAttribute.count);
	// 											vertex.x = pointOnCurve.x;
	// 											vertex.y = pointOnCurve.y;
	// 											vertex.z = pointOnCurve.z;
	// 											positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);
	// 										}

	// 										positionAttribute.needsUpdate = true;
	// 										geometry.computeBoundingBox();
	// 										geometry.computeBoundingSphere();
	// 									}
	// 								});

	// 								// sceneRef.current.add(clonedObject)
	// 							}
	// 						}
	// 					}, 1);
	// 				}

	// 				resolve(clonedObject);
	// 				if (sceneRef.current) {
	// 					let obj;
	// 					sceneRef.current.traverse(function (object) {
	// 						if (object.name.includes(`ProfileJoints ${shapeRefPt1} ${shapeRefPt2}`)) {
	// 							object.visible = false;
	// 							obj = object;
	// 						}
	// 					});
	// 					if (obj) {
	// 						sceneRef.current.remove(obj);
	// 					}
	// 				}
	// 			}, undefined, (error) => {
	// 				reject(error);
	// 			});

	// 			setMultiSelectRefPoints([]);
	// 		} else {
	// 			reject(new Error('targetName does not include "FrameBottom"'));
	// 		}
	// 	});
	// }


	async function addShapedCorner(shapeRefPt1, shapeRefPt2, newHeight, newWidth, modelHt, modelWid) {
		let scaleFacH = (modelHt - newHeight) / modelHt
		let scaleFacW = (modelWid - newWidth) / modelWid
		const shapeRef2 = getFrameStyleRef1(shapeRefPt2);
		const shapeRef1 = getFrameStyleRef2(shapeRefPt1);
		if (shapeRef2) {

			shapeRef2.scale.x *= 1 / (scaleFacH);
			const boundingBox0 = new THREE.Box3().setFromObject(shapeRef2);
			const WidthYLeft = boundingBox0.max.y - boundingBox0.min.y;
			if (shapeRefPt1 === 'FrameTop') {
				shapeRef2.position.y = 0;
			}
			else {
				shapeRef2.position.y = 0;
			}

		}
		if (shapeRef1) {
			shapeRef1.scale.x *= 1 / (scaleFacW);
			const boundingBox = new THREE.Box3().setFromObject(shapeRef1);

			const WidthX = boundingBox.max.x - boundingBox.min.x;

			if (shapeRefPt2 === 'FrameRight') {
				shapeRef1.position.x = 0;
			}
			else {
				shapeRef1.position.x = 0;
			}

			if (sceneRef.current) {
				let obj;
				sceneRef.current.traverse(function (object) {
					if (object.name.includes(`FrameSlant ${shapeRefPt1} ${shapeRefPt1}`)) {
						object.visible = false;
						obj = object
					}
				});
				if (obj) {
					sceneRef.current.remove(obj);
				}
			}
			setGlazing("#ADD8E6", null, shapeFrame, setClipGlaze, glazingRef, clipGlaze)
		}

	}

	async function addShapedCornerResize(shapeRefPt1, shapeRefPt2, newHeight, newWidth, modelHt, modelWid) {

		const shapeRef2 = getFrameStyleRef1(shapeRefPt2);
		const shapeRef1 = getFrameStyleRef2(shapeRefPt1);
		if (shapeRef2) {

			if (shapeRefPt1 === 'FrameTop') {
				shapeRef2.position.y = 0;
			}
			else {
				shapeRef2.position.y = 0;
			}

		}
		if (shapeRef1) {
			if (shapeRefPt2 === 'FrameRight') {
				shapeRef1.position.x = 0;
			}
			else {
				shapeRef1.position.x = 0;
			}

			if (sceneRef.current) {
				// let obj;
				// sceneRef.current.traverse(function (object) {
				// 	if (object.name.includes(`FrameSlant ${shapeRefPt1} ${shapeRefPt1}`)) {
				// 		object.visible = false;
				// 		obj = object
				// 	}
				// });
				// if (obj) {
				// 	sceneRef.current.remove(obj);
				// }
				// setCustomModelData(prevState => ({
				// 	...prevState,
				// 	layoutFrame: {
				// 		...prevState.layoutFrame,
				// 		shapeHeight: newHeight,
				// 		shapeWidth: newWidth,
				// 	}
				// }))
			}
			setGlazing("#ADD8E6", null, shapeFrame, setClipGlaze, glazingRef, clipGlaze)
			setSaveHeightWidth(false)
		}

	}


	// useEffect(() => {
	// 	let valX, valY
	// 	if (shapeFrame && checkClickedProfile && hardwareType === "Shaped Frame") {
	// 		setShapeAdded(checkClickedProfile)
	// 		setTimeout(async () => {
	// 			const parts = checkClickedProfile.name.split(' ');
	// 			console.log(parts , "8513");

	// 			if (parts.length >= 3) {
	// 				try {
	// 					addShapedCornerResize(parts[1], parts[2], shapedHeight, shapedWidth, modelHeight, modelWidth)
	// 						.then(async () =>
	// 							setTimeout(async () => {
	// 								console.log(parts[1] , parts[2] , "8518");

	// 								await addShapedFrame(parts[1], parts[2], shapedHeight, shapedWidth, modelWidth, modelHeight, shapeFrame)
	// 							}, 100))
	// 						.catch(error => console.error('Failed to add shaped frame:', error));
	// 				} catch (error) {
	// 					console.error('Failed to add shaped frame:', error);
	// 				}
	// 				// setSaveHeightWidth(false)
	// 			}


	// 			if (shapeFrame === 'Corner') {
	// 				if (parts.length >= 3) {
	// 					await addShapedCorner(parts[1], parts[2], shapedHeight, shapedWidth, modelHeight, modelWidth);
	// 				}
	// 			}

	// 		}, 5);
	// 	}

	// }, [shapeFrame, frameStyleRef])

	function openWindow(inValue) {
		// windowRef.current.position.x = posX;
		windowRef.current.forEach((window, index) => {
			// Do something with each glassMesh (e.g., log its properties)

			var posX = mapCustom(inValue, currentModel?.maxValues[index], currentModel?.minValues[index]);
			window.position.x = posX;

			// if(index==1)
			// {
			//   var posX = mapCustom(inValue,-0.587, 0.357);
			// window.position.x = posX;
			// }
		});
	}

	//x value of window changes from -0.195 (closed) to 0.2 (open)
	function mapCustom(value, outputMax, outputMin) {
		const inputMin = 0;
		const inputMax = 100;

		// Ensure the input is within the specified range
		const clampedInput = Math.min(Math.max(value, inputMin), inputMax);

		// Perform a custom mapping
		const mappedValue = outputMin + (outputMax - outputMin) * (clampedInput / inputMax);

		return mappedValue;
	}

	//x value of window changes from -0.195 (closed) to 0.2 (open)
	function mapRange(value, inMin, inMax, outMin, outMax) {
		return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
	}

	function setInternal(hex) {
		if (hex) {
			const material = new THREE.MeshStandardMaterial({
				color: hex,
				metalness: 1,
				roughness: 0.5,
				clippingPlanes: clippedPlanes.current,
			});

			if (internalFrameRef?.current?.length > 0) {
				internalFrameRef.current.map((child) => {
					if (child?.material?.clippingPlanes != null) {
						child?.material?.color.set(hex)
					} else {
						child.material = material
					}
				})
			}
		}
	}

	function setExternal(hex) {
		if (hex) {
			const material = new THREE.MeshStandardMaterial({
				color: hex,
				metalness: 1,
				roughness: 0.5,
				clippingPlanes: clippedPlanes.current,
			});

			if (externalFrameRef?.current?.length > 0) {
				externalFrameRef.current.map((child) => {
					if (child?.material?.clippingPlanes != null) {
						child?.material?.color.set(hex)
					} else {
						child.material = material
					}
				})
			}
		}
	}

	function initRenderer(container) {
		if (sceneRef.current)
			sceneRef.current.traverse(object => {
				if (object.material) {
					object.material.dispose();
				}
				if (object.geometry) {
					object.geometry.dispose();
				}
				if (object.texture) {
					object.texture.dispose();
				}
			});

		// Clear the old scene
		if (sceneRef.current)
			sceneRef.current.children.length = 0;

		// Create a new renderer
		renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
		renderer.localClippingEnabled = true;
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(window.innerWidth, window.innerHeight);
		renderer.setClearColor(0xffffff);
		renderer.shadowMap.enabled = true;
		renderer.shadowMap.type = THREE.PCFSoftShadowMap;

		// Remove the old canvas from the container
		if (container) {
			while (container.firstChild) {
				container.removeChild(container.firstChild);
			}
			// Append the new renderer canvas to the container
			container.appendChild(renderer.domElement);
		}
	}

	function initScene() {
		if (cameraRef.current) {
			cameraRef.current.position.set(0, 0.3, -3.9);
		}

		// Soft white light
		const ambientLight = new THREE.AmbientLight(0xffffff);
		if (sceneRef.current) {
			sceneRef.current.add(ambientLight);
		}

		// const hemisphereLight = new THREE.HemisphereLight(0xffffff, 10);
		// hemisphereLight.position.set(-3, 0, 0);
		// if (sceneRef.current) {
		// 	sceneRef.current.add(hemisphereLight);
		// }

		// const hemisphereLight2 = new THREE.HemisphereLight(0xffffff, 10);
		// hemisphereLight2.position.set(0, 0, -3);
		// if (sceneRef.current) {
		// 	sceneRef.current.add(hemisphereLight2);
		// }

		const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5);
		directionalLight.position.set(1, 5, 5);
		if (sceneRef.current) {
			sceneRef.current.add(directionalLight);
		}

		const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1.5);
		directionalLight2.position.set(-1, 5, -5);

		if (sceneRef.current) {
			sceneRef.current.add(directionalLight2);
		}

		// added new light in front
		const directionalLight3 = new THREE.DirectionalLight(0xffffff, .8);
		directionalLight3.position.set(0, 0, 5);

		if (sceneRef.current) {
			sceneRef.current.add(directionalLight3);
		}

		// added new light in back
		const directionalLight4 = new THREE.DirectionalLight(0xffffff, .8);
		directionalLight4.position.set(0, 0, -5);

		if (sceneRef.current) {
			sceneRef.current.add(directionalLight4);
		}

		// light from left
		const directionalLight5 = new THREE.DirectionalLight(0xffffff, 1.5);
		directionalLight5.position.set(-5, 0, 2);

		if (sceneRef.current) {
			sceneRef.current.add(directionalLight5);
		}

		// light from right
		const directionalLight6 = new THREE.DirectionalLight(0xffffff, 1.5);
		directionalLight6.position.set(5, 0, 2);

		if (sceneRef.current) {
			sceneRef.current.add(directionalLight6);
		}
	}

	function initControls() {
		if (cameraRef.current) {
			const controls = new OrbitControls(cameraRef.current, renderer.domElement);
			controls.addEventListener('change', render);
			controls.minDistance = 1;
			controls.maxDistance = 15;
			controls.target.set(0, 0, 0);
			controls.update();
			orbitControlRef.current = controls;
			window.addEventListener('resize', onWindowResize);

			controls.addEventListener('start', cameraMoved);
			controls.addEventListener('end', cameraStopped);
		}
	}

	function cameraStopped() {
		const elements = document.getElementById('elements')
		forceUpdate()

		elements.classList.remove("hide")
		// elements.style.display = 'block'
	}

	function cameraMoved() {
		elementRef.current.hidden = false
		const elements = document.getElementById('elements')
		// elements.style.display = 'none'

		elements.classList.add("hide")
	}

	function onWindowResize() {
		camera.aspect = window.innerWidth / window.innerHeight;
		camera.updateProjectionMatrix();
		renderer.setSize(window.innerWidth, window.innerHeight);
		render();
	}

	function addHandle(element, objectPosition, isAlreadyAdded, price, image, id) {

		handlePath.current = element;
		const handleLoader = new GLTFLoader()
		handleObjects.current.push(objectPosition);
		var finalIndex = 0;
		sashGroup?.current?.forEach((child, index) => {
			if (child == objectPosition) {
				finalIndex = index;
			}
		});

		if (element) {
			handleLoader?.load(element, function (gltf) {
				const scale = new THREE.Vector3(1, 1, 1);
				gltf.scene.scale.copy(scale);
				const boundingBox = new THREE.Box3().setFromObject(objectPosition);
				let position = new THREE.Vector3(boundingBox.min.x + 0.03, objectPosition.position.y, objectPosition.position.z);
				// const position = new THREE.Vector3(boundingBox.max.x - 0.03, objectPosition.position.y, objectPosition.position.z);

				if (headerSelectedItem.name === "Heritage Door") {
					position = new THREE.Vector3(boundingBox.min.x + 0.05, objectPosition.position.y, objectPosition.position.z + 0.05);
				}

				gltf.scene.position.copy(position);
				sceneRef.current.add(gltf.scene);
				gltfModel.current.add(gltf.scene);



				gltf.scene.traverse((child) => {
					if (child.name.includes("HandleExt")) {
						handleExternal.current.push(child);
						handlePosition.current.push(new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z));
					}

					if (child.name.includes("HandleInt")) {
						handleInternal.current.push(child);
						// handlePositionInternal.current.push(new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z))
					}

					if (child.name.includes("Cylinder") || child.name.includes("cylinder")) {
						handleCylinder.current.push(child);

					}

				});
				setHardwareType('')
				//  windowRef.current.add(gltf.scene);
				render();
			});
		}

		// if (!isAlreadyAdded) {
		// setCustomModelData((prevModelData) => ({
		// 	...prevModelData,
		// 	hardware: {
		// 		...prevModelData.hardware,
		// 		handle: [...(prevModelData.hardware?.handle || []),
		// 		getHandleObject("handle", element, objectPosition, id, horizontalPos, verticalPos, price, image)],
		// 	},
		// }));

		// 	setCustomModelData((prevModelData) => ({
		// 		...prevModelData,
		// 		hardware: {
		// 			...prevModelData.hardware,
		// 			cylinder: [...(prevModelData.hardware?.cylinder || []),
		// 			getHandleObject("cylinder", cylinder?.hardwareStyles[0]?.modelFilePath, objectPosition, cylinder?.hardwareStyles[0]?.id, horizontalPos, verticalPos, cylinder?.hardwareStyles[0]?.price, cylinder?.hardwareStyles[0].image)],
		// 		},
		// 	}));
		// }

		else {
			setIsHardwareAdded((prevAdded) => ({
				...prevAdded,
				handle: true
			}))
		}
	}

	function addBarHandleOffset(element, objectPosition, data) {
		addBarHandles(element, objectPosition, data, false, "bar handles offset")

	}





	function addBarHandles(element, objectPosition, data, alreadyAdded, type) {

		const barHandleLoader = new GLTFLoader()

		if (element) {
			barHandleLoader.load(element, function (gltf) {
				const scale = new THREE.Vector3(1, 1, 1);
				gltf.scene.scale.copy(scale);
				const position = new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z + 0.05);
				gltf.scene.position.copy(position);
				setHardwareType('')
				const alreadyAdded = addedBarHandlesPos?.current?.length && addedBarHandlesPos.current.some((pos) => pos.equals(position))
				addedBarHandlesPos.current.push(position)

				if (alreadyAdded) {
					const clonedModel = gltf.scene.clone();
					const oppositePosition = position.clone();
					oppositePosition.z = -oppositePosition.z;
					clonedModel.position.copy(oppositePosition);
					clonedModel.name += ` ${type}internal`
					clonedModel.rotation.x += Math.PI;
					sceneRef.current.add(clonedModel);
					barHandlesOffsetInternal.current.push(clonedModel)
					barHandlesOffsetInternalPos.current.push(oppositePosition)
				} else {
					gltf.scene.name += ` ${type}external`
					sceneRef.current.add(gltf.scene);
					barHandlesOffsetExternal.current.push(gltf.scene)
					barHandlesOffsetExternalPos.current.push(position)
				}
			});
		}
	}

	function addDoubleSidedElement(element, objectPosition, data, alreadyAdded) {
		const doubleSideModelLoader = new GLTFLoader()

		if (element) {
			doubleSideModelLoader.load(element, function (gltf) {
				const scale = new THREE.Vector3(1, 1, 1);
				gltf.scene.scale.copy(scale);
				const position = new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z - 0.01);
				gltf.scene.position.copy(position);


				gltf.scene.traverse((child) => {
					if (child.name.includes("External")) {
						escutcheonExternalModel.current.push(child);
						escutcheonExternalPos.current.push(new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z));
					}

					if (child.name.includes("Internal")) {
						escutcheonInternalModel.current.push(child);
						// escutcheonInternalPos.current.push(new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z))
					}

					// if (child.name.includes("Cylinder")) {
					// 	handleCylinder.current.push(child);
					// 	// handlePosition.current.push(new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z));
					// }

				});
				setHardwareType('')
				//  windowRef.current.add(gltf.scene);
				// render();
				sceneRef.current.add(gltf.scene);

				if (!alreadyAdded) {
					setCustomModelData(prevModelData => {
						let escutcheonArray = prevModelData.hardware?.escutcheon ?? []; // Retrieve the existing array or initialize to empty array
						return {
							...prevModelData,
							hardware: {
								...prevModelData.hardware,
								escutcheon: [
									...escutcheonArray,
									{
										id: data?.id, // Assuming length is defined elsewhere
										name: data?.name, // Store the element in the name array
										color: "Anthracite grey",
										type: "escutcheon",
										position: { position: objectPosition?.position },
										file: element,
										price: data?.price,
										imagePath: data?.imagePath
									}
								]
							}
						};
					});
				}
				else {
					setIsHardwareAdded((prevAdded) => ({
						...prevAdded,
						escutcheon: true
					}))
				}
			});
		}
	}

	function addElement(element, objectPosition, isAlreadyAdded, data) {
		const elementLoader = new GLTFLoader()

		if (element) {
			elementLoader.load(element, function (gltf) {
				const scale = new THREE.Vector3(1, 1, 1);
				gltf.scene.scale.copy(scale);

				var position = new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z + 0.06);

				if (collectionDetails && collectionDetails.typeId == 2) {
					position = new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z + 0.035);
				}

				gltf.scene.position.copy(position);
				if (element.includes("TickleVent")) {
					ticklePath.current = element;
					tickleObjects.current.push(objectPosition);
					tickleVentModels.current.push(gltf.scene);
					tickleVentPosition.current.push(new THREE.Vector3(gltf.scene.position.x, gltf.scene.position.y, gltf.scene.position.z));
				}

				setHardwareType('')
				//  gltf.scene.rotation.set(0,90,0); 
				sceneRef.current.add(gltf.scene);
				// gltfModel.current.add(gltf.scene);
				// render();
			});

		}

		if (!isAlreadyAdded) {
			setCustomModelData((prevModelData) => {
				const trickleArrayy = prevModelData?.hardware?.trickleVent || [];
				return {
					...prevModelData,
					hardware: {
						...prevModelData.hardware,
						trickleVent: [
							...(trickleArrayy.length > 0 ? trickleArrayy : []),
							getObjectData("trickle", element, objectPosition, data)
						],
					},
				};
			});
		}
		else {
			setIsHardwareAdded((prevAdded) => ({
				...prevAdded,
				trickleVent: true
			}))
		}
	}

	//   helper function to store data in the get object function
	function updateCustomModelData(type, element, objectPosition, data) {
		setCustomModelData(prevModelData => ({
			...prevModelData,
			hardware: {
				...prevModelData?.hardware,
				[type]: [
					...(Array.isArray(prevModelData?.hardware?.[type]) ? prevModelData?.hardware?.[type] : []),
					getObjectData(type?.toLowerCase(), element, objectPosition, data)
				]
			}
		}));
	}

	function addTopElements(element, objectPosition, data, isAlreadyAdded) {
		const elementLoader = new GLTFLoader();

		if (element) {
			elementLoader.load(element, function (gltf) {
				var scale = new THREE.Vector3(6, 6, 6);
				gltf.scene.scale.copy(scale);

				var position = new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z - 0.05);
				gltf.scene.position.copy(position);

				if (element.includes("Spyhole")) {
					const newSpyHole = gltf.scene;
					spyHoleModel.current.push(newSpyHole);
					position = new THREE.Vector3(objectPosition.position.x, objectPosition.position.y, objectPosition.position.z - 0.02);
					gltf.scene.position.copy(position);
					spyHolePosition.current.push(new THREE.Vector3(newSpyHole.position.x, newSpyHole.position.y, newSpyHole.position.z));
					if (!isAlreadyAdded) {
						// updateCustomModelData('spyhole', element, objectPosition);
						setCustomModelData((prevModelData) => {
							const spyholeArray = prevModelData?.hardware?.spyHole || [];
							return {
								...prevModelData,
								hardware: {
									...prevModelData.hardware,
									spyhole: [
										...(spyholeArray.length > 0 ? spyholeArray : []),
										getObjectData("spyhole", element, objectPosition, data)
									],
								},
							};
						});
					}
					else {
						setIsHardwareAdded((prevAdded) => ({
							...prevAdded,
							spyhole: true
						}))
					}
				}

				if (element.includes("Letterplate")) {
					const newLetterPlate = gltf.scene;
					letterPlateModel.current.push(newLetterPlate);
					letterPlatePos.current.push(new THREE.Vector3(newLetterPlate.position.x, newLetterPlate.position.y, newLetterPlate.position.z));
					updateCustomModelData('letterPlate', element, objectPosition, data);
				}

				if (element.includes("Knocker")) {
					const newKnocker = gltf.scene;
					knockerModel.current.push(newKnocker);
					knockerPos.current.push(new THREE.Vector3(newKnocker.position.x, newKnocker.position.y, newKnocker.position.z));
					if (!isAlreadyAdded) {
						updateCustomModelData('knocker', element, objectPosition, data);
					}
					else {
						setIsHardwareAdded((prevAdded) => ({
							...prevAdded,
							knocker: true
						}))
					}
				}

				setHardwareType('');
				sceneRef.current.add(gltf.scene);
			});
		}
	}

	function createText(text, position, hex, isAlreadyAdded, element) {

		let textMesh1
		let textGeo
		const loader = new FontLoader()

		if (textMeshRef.current && gltfModel.current) {
			sceneRef.current.remove(textMeshRef.current)
		}

		loader.load("https://jackodiamond.github.io/babylonGroundTracking/Roboto_Regular.json", function (font) {
			textGeo = new TextGeometry(text?.toString(), {
				font: font,
				size: 0.7,
				height: 0.7,
			});

			const materials = new THREE.MeshStandardMaterial({ color: hex ? hex : 0x373f43, metalness: 1 }) // side

			textMesh1 = new THREE.Mesh(textGeo, materials);
			let units = 0.01
			textMesh1.position.x = position.position?.x;
			textMesh1.position.z = position.position?.z - 0.07;
			textMesh1.position.y = position.position?.y - 0.06;
			// textMesh1.position.x = element==="no"?position.position?.x - 0.07:(parseInt(element?.horizontalPos))*units;
			// textMesh1.position.z = position.position?.z - 0.07;
			// textMesh1.position.y = element==="no"?position.position?.y - 0.06:(parseInt(element?.verticalPos))*units;
			textMesh1.scale.x = 0.1;
			textMesh1.scale.y = 0.1;
			textMesh1.scale.z = 0.03;

			textMeshRef.current = textMesh1;
			textMeshPos.current = new THREE.Vector3(textMesh1.position.x, textMesh1.position.y, textMesh1.position.z)
			if (textMeshRef.current) {
				sceneRef.current.add(textMeshRef.current)
			}
		})
		if (isAlreadyAdded) {
			setIsHardwareAdded((prevAdded) => ({
				...prevAdded,
				numeral: true
			}))
		}
		// else{
		// 	setIsHardwareAdded((prevAdded) => ({
		// 		...prevAdded,
		// 		numeral: false
		// 	}))
		// }

	}

	// sceneRef.current.traverse((child) =>{
	// 	if(child.name.includes("Glass")){
	// 		
	// 	}
	// })


	function addNumerals(element, objectPosition, isAlreadyAdded, data) {

		createText(element, objectPosition, '', false, "no")
		if (!isAlreadyAdded) {
			setCustomModelData((prevModelData) => ({
				...prevModelData,
				hardware: {
					...prevModelData.hardware,
					numeral: [...(prevModelData.hardware?.numeral || []),
					getObjectData("numeral", element, objectPosition, data)],
				},
			}));
		}
		setHardwareType('')
	}

	function addFrameTop(isTop, data, direction) {
		//replace with couplerWidth = couplerData.width/20, same for couplerHeight
		const couplerWidth = data?.width / 20
		const couplerHeight = data?.height / 20;
		var countTop = 1
		var countBottom = 1
		sceneRef.current.traverse((item) => {
			if (item?.name.includes("parallel top")) {
				countTop++
			}
			if (item?.name.includes("parallel bottom")) {
				countBottom++
			}
		})
		const loader = new GLTFLoader()
		loader.load(`${servicePath}/ThreeJSModel/Glb/${data?.customePath}`, function (gltf) {
			sceneRef.current.add(gltf.scene);
			const boundingBox4 = new THREE.Box3().setFromObject(gltf.scene);
			const boundingBox2 = new THREE.Box3().setFromObject(gltfModel.current);
			const width4 = boundingBox4.max.y - boundingBox4.min.y;
			const width2 = boundingBox2.max.x - boundingBox2.min.x;
			const gltfModelDepth = boundingBox2.max.z - boundingBox2.min.z;

			gltf.scene.scale.y = width2 / width4;

			gltf.scene.rotation.z = Math.PI / 2;

			gltf.scene.position.z = boundingBox2.max.z;

			var base;
			gltf.scene.traverse((child) => {
				if (child.name.includes("Base")) {
					base = child;
					base.scale.x *= couplerWidth;
					base.scale.z *= couplerHeight;
					const boundingBoxBase = new THREE.Box3().setFromObject(base);
					const baseDepth = boundingBoxBase.max.z - boundingBoxBase.min.z;
					base.position.z -= baseDepth / 2;
					child.name = "BaseInternal";
					internalFrameRef.current.push(child);
				}

				if (child.name.includes("Front")) {
					child.name = "FrontExternal";
					externalFrameRef.current.push(child);
				}
			})

			const boundingBoxBase = new THREE.Box3().setFromObject(base);
			const baseWidth = boundingBoxBase.max.x - boundingBoxBase.min.x;
			const baseDepth = boundingBoxBase.max.z - boundingBoxBase.min.z;

			if (isTop) {
				gltf.scene.position.y = boundingBox2.max.y + baseWidth / 2;
			} else {
				gltf.scene.position.y = boundingBox2.min.y - baseWidth / 2;
			}

			if (baseDepth < gltfModelDepth / 2) {
				const backSideCoupler = gltf.scene.clone();

				backSideCoupler.scale.z = -1;
				backSideCoupler.position.z = boundingBox2.min.z;
				sceneRef.current.add(backSideCoupler)

				backSideCoupler.traverse((child) => {

					if (child.name.includes("Front")) {
						child.name = "FrontInternal";
						internalFrameRef.current.push(child);
					}
				})
			}

			newFrameRefPoints.current.push(gltfModel.current)

			extraStorage.current.push(gltfModel.current)

			const clonedModel = gltfModel.current.clone();
			//	clonedModel.scale.y = 0.3;
			const boundingBox = new THREE.Box3().setFromObject(gltfModel.current);
			const boundingBox5 = new THREE.Box3().setFromObject(clonedModel);
			const height = boundingBox5.max.y - boundingBox5.min.y;
			// Set the position and scale of the cloned model (adjust as needed)
			if (isTop) {
				clonedModel.name += ` parallel top ${countTop}`
				clonedModel.position.set(0, (boundingBox.max.y + baseWidth + height / 2) * countTop, 0);
				addedFramePosition.current.push("top");
			} else {
				clonedModel.name += ` parallel bottom ${countBottom}`
				clonedModel.position.set(0, (boundingBox.min.y - baseWidth - height / 2) * countBottom, 0);
				addedFramePosition.current.push("bottom");
			}


			addedFrame.current.push(clonedModel);
			//clonedModel.scale.y = gltfModel.current.scale.y;
			sceneRef.current.add(clonedModel);

			clonedModel.traverse((child) => {
				if (child.name === "FrameLeft" || child.name === "FrameRight" || child.name === "FrameTop" || child.name === "FrameBottom") {
					getUpdatedFrameRefs.current.push(child)
				}
			})

			if (addedFrameData) {
				setTimeout(() => {
					updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, false, false, setInternal, setExternal, externalFrameRef, internalFrameRef)
				}, 100);
			}
			newFrameRefPoints.current.push(...newFrameRefPoints.current, clonedModel)
			extraStorage.current.push(...extraStorage.current, clonedModel)
			testRef.current.push(...getUpdatedFrameRefs.current, ...addFrameRef.current)
			// Saving data 
			saveAdditionalFrameData(setCustomModelData, addedFrameData, couplerData[0], direction, allFrameCollection, allStyleCollection, clonedModel, "", "parallel")
		})

	}


	function addFrame(isLeft, data, direction) {

		//replace with couplerWidth = couplerData.width/20, same for couplerHeight
		const couplerWidth = data && data?.width / 20
		const couplerHeight = data && data?.height / 20;
		const emptyMesh = new THREE.Mesh();
		let tempObj = {};
		const loader = new GLTFLoader()
		var countLeft = 1
		var countRight = 1
		sceneRef.current.traverse((item) => {
			if (item?.name.includes("parallel left")) {
				countLeft++
			}
			if (item?.name.includes("parallel right")) {
				countRight++
			}
		})
		if (data)
			loader.load(`${servicePath}/ThreeJSModel/Glb/${data?.customePath}`, function (gltf) {
				sceneRef.current.add(gltf.scene);
				const boundingBox4 = new THREE.Box3().setFromObject(gltf.scene);
				const boundingBox2 = new THREE.Box3().setFromObject(gltfModel.current);
				const width4 = boundingBox4.max.y - boundingBox4.min.y;
				const width2 = boundingBox2.max.y - boundingBox2.min.y;
				const gltfModelDepth = boundingBox2.max.z - boundingBox2.min.z;

				gltf.scene.scale.y = width2 / width4;


				gltf.scene.position.z = boundingBox2.max.z;

				var base;
				gltf.scene.traverse((child) => {
					if (child.name.includes("Base")) {
						base = child;
						base.scale.x *= couplerWidth;
						base.scale.z *= couplerHeight;
						const boundingBoxBase = new THREE.Box3().setFromObject(base);
						const baseDepth = boundingBoxBase.max.z - boundingBoxBase.min.z;
						base.position.z -= baseDepth / 2;
						child.name += " Internal";

					}

					if (child.name.includes("Front")) {
						child.name += " External";
					}
				})

				gltf.scene.traverse((child) => {
					if (child.name.includes("Internal")) {
						internalFrameRef.current.push(child);
					} else if (child.name.includes("External")) {
						externalFrameRef.current.push(child);

					}
				})

				const boundingBoxBase = new THREE.Box3().setFromObject(base);
				const baseWidth = boundingBoxBase.max.x - boundingBoxBase.min.x;
				const baseDepth = boundingBoxBase.max.z - boundingBoxBase.min.z;

				if (isLeft) {
					gltf.scene.position.x = boundingBox2.max.x + baseWidth / 2;
				} else {
					gltf.scene.position.x = boundingBox2.min.x - baseWidth / 2;
				}

				if (baseDepth < gltfModelDepth / 2) {
					const backSideCoupler = gltf.scene.clone();

					backSideCoupler.scale.z = -1;
					backSideCoupler.position.z = boundingBox2.min.z;
					sceneRef.current.add(backSideCoupler)

					backSideCoupler.traverse((child) => {

						if (child.name.includes("Front")) {
							child.name = "FrontInternal";
							internalFrameRef.current.push(child);
						}
					})
				}

				extraStorage.current.push(gltfModel.current)

				// Clone the original model
				const clonedModel = gltfModel.current.clone();
				newFrameRefPoints?.current.push(gltfModel.current)
				const boundingBox = new THREE.Box3().setFromObject(gltfModel.current);
				const width = boundingBox.max.x - boundingBox.min.x;
				// Set the position and scale of the cloned model (adjust as needed)
				if (isLeft) {
					clonedModel.position.set((boundingBox.max.x + baseWidth + width / 2) * (countLeft), 0, 0);//0.42
					if (addedFrameData) {
						setAddedFrameData((prevData => ({
							...prevData,
							position: clonedModel?.position
						})))
					}
					addedFramePosition.current.push("left");
					clonedModel.name += ` parallel left ${countLeft}`
				} else {
					clonedModel.position.x += (boundingBox.min.x - baseWidth - width / 2) * countRight
					if (addedFrameData) {
						setAddedFrameData((prevData => ({
							...prevData,
							position: clonedModel?.position
						})))
					}
					addedFramePosition.current.push("right");
					clonedModel.name += ` parallel right ${countRight}`

				}
				addedFrame.current.push(clonedModel);
				sceneRef.current.add(clonedModel);
				clonedModel.traverse((child) => {
					const boundingBox = new THREE.Box3().setFromObject(child);
					// Check conditions before pushing the frame into the getUpdatedFrameRefs array
					if (child.name === "FrameLeft" && isLeft) {
						// Don't push its right child
						emptyMesh.position.set(child.position)
						emptyMesh.position.x = child.position.x + boundingBox.max.x
						emptyMesh.name = "FrameLeftPerpendicular"
						getUpdatedFrameRefs.current.push(child, emptyMesh);
					} else if (child.name === "FrameRight" && !isLeft) {
						// Don't push its left child
						emptyMesh.position.set(child.position)
						emptyMesh.position.x = child.position.x + boundingBox.min.x
						emptyMesh.name = "FrameRightPerpendicular"

						getUpdatedFrameRefs.current.push(child, emptyMesh);
					} else if (child.name === "FrameTop") {
						// Don't push its bottom child
						emptyMesh.position.set(child.position)
						emptyMesh.position.y = child.position.y - boundingBox.min.y + 0.5
						emptyMesh.name = "FrameTopPerpendicular"

						getUpdatedFrameRefs.current.push(child, emptyMesh);
					} else if (child.name === "FrameBottom") {
						// Don't push its top child

						emptyMesh.position.set(child.position)
						emptyMesh.position.y = child.position.y - boundingBox.max.y - 0.5
						emptyMesh.name = "FrameBottomPerpendicular"
						getUpdatedFrameRefs.current.push(child, emptyMesh);
					}
					if (child.name.includes("Frame")) {
						setNewFrameData(prevFrameData => [...prevFrameData, child])
					}
				});


				// 

				// Object.keys(tempObj).forEach((key) => {
				// 	const existingIndex = getUpdatedFrameRefs.current.findIndex(frame => frame.name === key);
				// 	if (existingIndex !== -1) {
				// 		getUpdatedFrameRefs.current[existingIndex] = tempObj[key];
				// 	} else {
				// 		getUpdatedFrameRefs.current.push(tempObj[key]);
				// 	}
				// });



				newFrameRefPoints?.current?.push(...newFrameRefPoints.current, clonedModel)
				testRef.current.push(...getUpdatedFrameRefs.current)
				setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

				if (addedFrameData) {
					setTimeout(() => {
						updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, false, false, setInternal, setExternal, externalFrameRef, internalFrameRef)
					}, 100);
				}
				//saving data
				saveAdditionalFrameData(setCustomModelData, addedFrameData, couplerData[0], direction, allFrameCollection, allStyleCollection, clonedModel, "", "parallel")
			});
	}

	function addEvesFrame(angle, data, direction) {

		// Clone the original model
		const clonedModel = gltfModel.current.clone();
		newFrameRefPoints.current.push(gltfModel.current)
		extraStorage.current.push(gltfModel.current)
		const boundingBox = new THREE.Box3().setFromObject(gltfModel.current);
		const width = boundingBox.max.x - boundingBox.min.x;
		const widthZ = boundingBox.max.z - boundingBox.min.z


		if (angle === "" || angle === undefined || angle === null || angle < 90) {
			angle = 90
		}


		angle = 100


		// if (headerSelectedItem.name !== "Oriel Window") {
		clonedModel.rotation.y = (180 - angle) * (Math.PI / 180);
		// }


		const m = (0.11 - 0.04) / (180 - 90)
		const b = 0.04
		const offset = m * (angle - 90) + b

		if (headerSelectedItem.name !== "Oriel Window") {
			clonedModel.rotation.x = Math.PI / 2;

		}
		clonedModel.rotation.y = 0;

		clonedModel.rotation.z = Math.PI;

		newFrameRefPoints?.current.push(gltfModel.current)
		extraStorage.current.push(gltfModel.current)

		const boundingBox6 = new THREE.Box3().setFromObject(frameStyleTop.current);
		const boundingBoxLeft = new THREE.Box3().setFromObject(frameStyleRight.current)
		clonedModel.rotation.x = (angle) * (Math.PI / 180);
		const boundingBoxClone = new THREE.Box3().setFromObject(clonedModel);


		let adjustment = headerSelectedItem.name === "Oriel Window" ? 0.05 : 0
		const yFinalIncrement = (clonedModel.position.y - boundingBoxClone.min.y) - adjustment;
		const xFinalIncrement = clonedModel.position.x - boundingBoxClone.min.x

		const zFinalIncrement = clonedModel.position.z - boundingBoxClone.min.z;

		let pitchDisplacement = 0.18
		const height = boundingBox.max.y - boundingBox.min.y;

		// clonedModel.rotation.y = Math.PI;


		clonedModel.scale.x *= -1




		if (angle === 90) {
			clonedModel.position.set(0, boundingBox6.min.y + yFinalIncrement, (boundingBox6.max.z - (height * 0.5)));

			clonedModel.scale.z = -1

			const tiltAngle = THREE.MathUtils.degToRad(angle)
			clonedModel.rotation.x = tiltAngle


			if (addedFrameData) {
				setAddedFrameData((prevData => ({
					...prevData,
					position: clonedModel?.position
				})))
			}
		} else {
			clonedModel.position.set(0, frameStyleTop.current.position.y + yFinalIncrement, boundingBox6.max.z - zFinalIncrement);

			if (addedFrameData) {
				setAddedFrameData((prevData => ({
					...prevData,
					position: clonedModel?.position
				})))
			}
		}



		clonedModel.name = " topPerpendicular"
		addedFramePosition.current.push("topPerpendicular");

		if (headerSelectedItem.name !== "Oriel Window") {
			clonedModel.traverse((child) => {
				if (child.name.includes("Bottom")) {
					child.visible = false;

				}
			})
		}




		clonedModel.traverse((child) => {
			if (child.isMesh) {
				if (child.name.includes("External")) {
					externalFrameRef.current.push(child);
				}
				if (child.name.includes("Internal")) {
					internalFrameRef.current.push(child);
				}
			}
		});
		saveAdditionalFrameData(setCustomModelData, addedFrameData, data, direction, allFrameCollection, allStyleCollection, clonedModel, angle, "perpendicuar", true)
		newFrameRefPoints?.current?.push(...newFrameRefPoints.current, clonedModel)
		extraStorage.current.push(...extraStorage.current, clonedModel)
		setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

		sceneRef.current.add(clonedModel)

	}





	function addFramePerpendicular(modelUrl, isLeft, angle, data, isTop, direction) {

		const loader = new GLTFLoader()
		var countTop = 1
		var countBottom = 1
		var countLeft = 1
		var countRight = 1
		sceneRef.current.traverse((item) => {
			if (item?.name.includes("topPerpendicular")) {
				countTop += 1.9
			}
			if (item?.name.includes("bottomPerpendicular")) {
				countBottom += 1.9
			}
			if (item?.name.includes("leftPerpendicular")) {
				countLeft += 1.9
			}
			if (item?.name.includes("rightPerpendicular")) {
				countRight += 1.9
			}
		})

		var topFrameBoundingBox;





		if (modelUrl.includes(undefined)) {
			modelUrl = "https://testorbitapp.augursapps.com/api/ThreeJSModel/Glb/SquareFramelessJoint90°.glb"
		}


		loader.load(modelUrl, function (gltf) {
			if (headerSelectedItem.name !== "Oriel Window") {
				sceneRef.current.add(gltf.scene);
				storeBayPost.current = gltf.scene
			}
			const boundingBox4 = new THREE.Box3().setFromObject(gltf.scene);
			const boundingBox2 = new THREE.Box3().setFromObject(gltfModel.current);
			const width4 = boundingBox4.max.y - boundingBox4.min.y;
			const width2 = boundingBox2.max.y - boundingBox2.min.y;
			const width3 = boundingBox2.max.x - boundingBox2.min.x;

			const gltfModelDepth = boundingBox2.max.z - boundingBox2.min.z;

			gltf.scene.scale.y = width2 / width4;

			//	gltf.scene.scale.x = data.width * 0.05;
			//	gltf.scene.scale.z = data.height * 0.05;

			const boundingBox7 = new THREE.Box3().setFromObject(gltf.scene);
			const depth7 = boundingBox7.max.z - boundingBox7.min.z;
			const xWidth7 = boundingBox7.max.x - boundingBox7.min.x;
			gltf.scene.position.z = boundingBox4.max.z - depth7 / 2;

			gltf.scene.traverse((child) => {
				child.name += " External"
			});

			gltf.scene.traverse((child) => {
				if (child.name.includes("External")) {
					externalFrameRef.current.push(child);
				}
			});

			// Clone the original model
			const clonedModel = gltfModel.current.clone();
			newFrameRefPoints.current.push(gltfModel.current)
			extraStorage.current.push(gltfModel.current)
			const boundingBox = new THREE.Box3().setFromObject(gltfModel.current);

			const width = boundingBox.max.x - boundingBox.min.x;
			const widthZ = boundingBox.max.z - boundingBox.min.z;
			const height = boundingBox.max.y - boundingBox.min.y;

			// load the baypost
			// set its position to right of the bounding box
			// to add it to its left use this formula boundingBox.max.x - ((addOnBoundingBox.max.x - addOnBoundingBox.min.x) / 2);
			// bounding box of baypost
			// x position of clonedModel baypostboundingbox.max.x - (cloneModel.max.z - addOnBoundingBox.min.z) / 2)
			// Set the position and scale of the cloned model (adjust as needed)

			if (angle === "" || angle === undefined || angle === null || angle < 90) {
				angle = 90
			}

			//angle =150

			clonedModel.rotation.y = (180 - angle) * (Math.PI / 180);
			const boundingBoxClone = new THREE.Box3().setFromObject(clonedModel);
			const xFinalIncrement = clonedModel.position.x - boundingBoxClone.min.x;
			const zFinalIncrement = clonedModel.position.z - boundingBoxClone.min.z;
			const yHeight = boundingBoxClone.max.y - boundingBoxClone.min.y;
			const zWidth = boundingBoxClone.max.z - boundingBoxClone.min.z;

			const m = (0.11 - 0.04) / (180 - 90)
			const b = 0.04
			const offset = m * (angle - 90) + b

			if (isTop && isTop !== "bottom") {

				gltf.scene.scale.y = width3 / width4;
				clonedModel.rotation.x = Math.PI / 2;
				clonedModel.rotation.y = 0;
				clonedModel.rotation.z = Math.PI;
				gltf.scene.rotation.z = Math.PI / 2;
				gltf.scene.position.y = boundingBox2.max.y + xWidth7 / 2;
				const boundingBox6 = new THREE.Box3().setFromObject(gltf.scene);
				clonedModel.rotation.x = (angle) * (Math.PI / 180);
				const boundingBoxClone = new THREE.Box3().setFromObject(clonedModel);
				const xFinalIncrement = clonedModel.position.x - boundingBoxClone.min.x;
				let adjustment = headerSelectedItem.name === "Oriel Window" ? 0.12 : 0
				let zAdjustment = headerSelectedItem.name === "Oriel Window" ? 0.05 : 0
				const yFinalIncrement = clonedModel.position.y - boundingBoxClone.min.y - adjustment;

				const zFinalIncrement = clonedModel.position.z - boundingBoxClone.min.z;
				const yHeight = boundingBoxClone.max.y - boundingBoxClone.min.y;
				const zWidth = boundingBoxClone.max.z - boundingBoxClone.min.z;

				let pitchDisplacement = 0
				if (headerSelectedItem.name === "Oriel Window") {
					pitchDisplacement = 0.18
				}

				if (angle === 90) {
					clonedModel.position.set(0, boundingBox6.min.y + yFinalIncrement + pitchDisplacement - 0.03, (((boundingBox6.min.z - height / 2) * countTop) + zAdjustment));
					if (headerSelectedItem.name === "Oriel Window") {
						const tiltAngle = THREE.MathUtils.degToRad(95)
						clonedModel.rotation.x = tiltAngle
					}
					if (addedFrameData) {
						setAddedFrameData((prevData => ({
							...prevData,
							position: clonedModel?.position
						})))
					}
				} else {
					clonedModel.position.set(0, gltf.scene.position.y + yFinalIncrement, boundingBox6.max.z - zFinalIncrement);
					// clonedModel.position.y = boundingBox6.max.y + yFinalIncrement
					if (addedFrameData) {
						setAddedFrameData((prevData => ({
							...prevData,
							position: clonedModel?.position
						})))
					}
				}



				topFrameBoundingBox = new THREE.Box3().setFromObject(clonedModel);
				topLeftCorner.current = new THREE.Vector3(topFrameBoundingBox.min.x, topFrameBoundingBox.max.y, topFrameBoundingBox.min.z)
				topRightCorner.current = new THREE.Vector3(topFrameBoundingBox.max.x, topFrameBoundingBox.max.y, topFrameBoundingBox.min.z)
				// const geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
				// const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
				// const cube = new THREE.Mesh(geometry, material);
				// cube.position.set(topRightCorner.current.x, topRightCorner.current.y, topRightCorner.current.z)

				// // Add the cube to the scene
				// sceneRef.current.add(cube);
				clonedModel.name = " topPerpendicular"

				addedFramePosition.current.push("topPerpendicular");

			} else if (isTop === "bottom") {
				gltf.scene.scale.y = width3 / width4;
				clonedModel.rotation.x = Math.PI / 2;
				clonedModel.rotation.y = 0;
				clonedModel.rotation.z = Math.PI;
				gltf.scene.rotation.z = Math.PI / 2;
				gltf.scene.position.y = boundingBox2.min.y - xWidth7 / 2;
				const boundingBox6 = new THREE.Box3().setFromObject(gltf.scene);

				if (angle === 90) {
					clonedModel.position.set(0, -boundingBox2.max.y, ((boundingBox6.min.z - height / 2) * (countBottom)));
					if (addedFrameData) {
						setAddedFrameData((prevData => ({
							...prevData,
							position: clonedModel?.position
						})))
					}

				} else {
					clonedModel.position.set(0, -boundingBox2.max.y + 0.05, (boundingBox6.min.z - yHeight / 2) * countBottom);
					if (addedFrameData) {
						setAddedFrameData((prevData => ({
							...prevData,
							position: clonedModel?.position
						})))
					}
				}
				clonedModel.name = " bottomPerpendicular"
				addedFramePosition.current.push("bottomPerpendicular");
			}
			else {
				if (isLeft) {
					gltf.scene.position.x = boundingBox2.max.x + xWidth7 / 2;
					const boundingBox6 = new THREE.Box3().setFromObject(gltf.scene);

					if (angle === 90) {
						clonedModel.position.set((boundingBox6.min.x + widthZ / 2 - 0.05), 0, (boundingBox6.max.z - width / 2));
						if (addedFrameData) {
							setAddedFrameData((prevData => ({
								...prevData,
								position: clonedModel?.position
							})))
						}
					} else {
						clonedModel.position.set((boundingBox6.min.x + xFinalIncrement - widthZ / 2 - 0.05), 0, (boundingBox6.max.z - zFinalIncrement + offset) * countLeft);
						if (addedFrameData) {
							setAddedFrameData((prevData => ({
								...prevData,
								position: clonedModel?.position
							})))
						}
					}



					addedFramePosition.current.push("leftPerpendicular");
					clonedModel.name += " leftPerpendicular"


				} else {


					clonedModel.rotation.y = (angle) * (Math.PI / 180);
					const boundingBoxClone = new THREE.Box3().setFromObject(clonedModel);
					const xFinalIncrement = clonedModel.position.x - boundingBoxClone.min.x;
					const zFinalIncrement = clonedModel.position.z - boundingBoxClone.min.z;

					gltf.scene.rotation.y = Math.PI;
					gltf.scene.position.x = boundingBox2.min.x - xWidth7 / 2;
					const boundingBox6 = new THREE.Box3().setFromObject(gltf.scene);
					if (angle === 90) {
						clonedModel.position.set(boundingBox6.min.x + widthZ / 2 + 0.05, 0, (boundingBox6.max.z - width / 2));
						if (addedFrameData) {
							setAddedFrameData((prevData => ({
								...prevData,
								position: clonedModel?.position
							})))
						}


					} else {
						clonedModel.position.set(boundingBox6.max.x - xFinalIncrement - widthZ / 2 + 0.05, 0, (boundingBox6.max.z - zFinalIncrement + offset) * countRight);
						if (addedFrameData) {
							setAddedFrameData((prevData => ({
								...prevData,
								position: clonedModel?.position
							})))
						}

					}

					clonedModel.name += " rightPerpendicular"
					addedFramePosition.current.push("rightPerpendicular");
				}
			}

			clonedModel.traverse((child) => {
				if (child instanceof THREE.Mesh) {
					// Do something with each child 
					if (child.name.includes("GlassPanel")) {
						glassGroup.current.push(child);
						defaultGlassMaterial.current = child.material;
						const newObject = new THREE.Object3D();
						const worldPosition = new THREE.Vector3();
						child.getWorldPosition(worldPosition);
						newObject.position.copy(worldPosition);
						setUniqueSideRefernce((prevReference) => [...prevReference, newObject])
					}
				}


				addedFrame.current.push(clonedModel);

				if (child.isMesh) {
					if (child.name.includes("External")) {
						externalFrameRef.current.push(child);
					}
					if (child.name.includes("Internal")) {
						internalFrameRef.current.push(child);
					}
				}
			});

			setInitialColors(rgbStringToHex, internalRAL, internalColor, externalRAL, externalColor, customModelData, setInternal, setExternal)

			if (!isLeft || isTop === "top") {
				clonedModel.scale.z *= -1;
			}

			if (isLeft || isTop === "bottom") {
				clonedModel.scale.z *= 1;
			}

			if (headerSelectedItem.name === "Oriel Window") {
				if (isTop && isTop !== "bottom") {
					resizeAdditionalFrame(clonedModel, true, true, false, customModelData.layoutFrame.width, customModelData.layoutFrame.width, headerSelectedItem, topLeftCorner, sceneRef, topRightCorner)
				}
			}

			if (headerSelectedItem.name === "Oriel Window" && addedFramePosition.current.includes("topPerpendicular") && (addedFramePosition.current.includes("leftPerpendicular") || addedFramePosition.current.includes("rightPerpendicular"))) {
				scaleAddedFrames(isLeft, isTop)
			}

			sceneRef.current.add(clonedModel);

			// if (isTop && isTop !== "bottom") {
			// 	if (addedFrameData) {
			// 		setTimeout(() => {
			// 			updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, false, true, setInternal, setExternal, externalFrameRef, internalFrameRef)
			// 		}, 100);
			// 	}
			// }
			// if (isLeft) {
			// 	if (addedFrameData) {
			// 		setTimeout(() => {
			// 			updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, true, false, setInternal, setExternal, externalFrameRef, internalFrameRef)
			// 		}, 100);
			// 	}
			// }
			// if (!isLeft) {
			// 	if (addedFrameData) {
			// 		setTimeout(() => {
			// 			updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, true, false, setInternal, setExternal, externalFrameRef, internalFrameRef)
			// 		}, 100);
			// 	}
			// }
			// if (isTop && isTop !== "bottom") {
			// 	if (addedFrameData) {
			// 		setTimeout(() => {
			// 			updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, false, true, setInternal, setExternal, externalFrameRef, internalFrameRef)
			// 		}, 100);
			// 	}
			// }
			// if (isLeft) {
			// 	if (addedFrameData) {
			// 		setTimeout(() => {
			// 			updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, true, false, setInternal, setExternal, externalFrameRef, internalFrameRef)
			// 		}, 100);
			// 	}
			// }
			// if (!isLeft) {
			// 	if (addedFrameData) {
			// 		setTimeout(() => {
			// 			updateAddedFrame(clonedModel, addedFrameData?.frameProfileData[2], addedFrameData?.frameProfileData[3], addedFrameData?.frameProfileData[1], addedFrameData?.frameProfileData[0], sceneRef, true, false, setInternal, setExternal, externalFrameRef, internalFrameRef)
			// 		}, 100);
			// 	}
			// }

			clonedModel.traverse((child) => {
				if (child.name === "FrameLeft" || child.name === "FrameRight" || child.name === "FrameTop" || child.name === "FrameBottom") {
					getUpdatedFrameRefs.current.push(child)
				}
			})
			newFrameRefPoints.current.push(...newFrameRefPoints.current, clonedModel)
			extraStorage.current.push(...extraStorage.current, clonedModel)
			getUpdatedFrameRefs.current = removeDuplicatesByPosition(getUpdatedFrameRefs.current)
			testRef.current.push(...getUpdatedFrameRefs.current, ...addFrameRef.current)
			testRef.current = removeDuplicatesByPosition(testRef.current)
			setBayPostDeg(0)

			let isOriel = headerSelectedItem.name === "Oriel Window" ? true : false

			// Saving data
			saveAdditionalFrameData(setCustomModelData, addedFrameData, data, direction, allFrameCollection, allStyleCollection, clonedModel, angle, "perpendicuar", isOriel)
		});
	}


	const handleFrameSingleSide = (data, index, checked) => {
		if (checked) {
			setCheckSingleSide(index)
			setCheckClickedProfile(data)
			setIsUniqueSide(checked)
		} else {
			setCheckSingleSide()
			setCheckClickedProfile()
			setIsUniqueSide(checked)
		}
	}

	function render() {
		renderer.render(scene, camera);
	}

	function animate() {
		requestAnimationFrame(animate);

		updateOverlayPosition(gltfModel, camera, pointInScreen, testRef, newFrameRefPoints, uiLinesRef, uiLinesRefBottom, uiLinesRefFrame, allProfileRefSeq, glassRefSeq)

		if (mixer) {
			mixer.update(0.01);
		}
		render();
	}

	useEffect(() => {
		if (isPanelOpen) {
			if (cameraRef.current) {
				setFrameCameraView(-1, 1, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom);
			}
		} else {
			if (cameraRef.current) {
				setFrameCameraView(0, 1, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom);
			}

			testRef.current = []
		}
	}, [isPanelOpen])

	useEffect(() => {
		if (colorActiveTab === 'internal') {
			if (cameraRef.current) {
				setFrameCameraView(-1, -1, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom);
			}
		} else {
			if (cameraRef.current) {
				setFrameCameraView(-1, 1, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom);
			}
		}
	}, [colorActiveTab])

	useEffect(() => {
		if (modelVisible && gltfModel.current && cameraRef.current) {
			getModelRenderedSize(gltfModel, cameraRef, setRenderedSize)
		}
	}, [modelVisible, gltfModel, cameraRef]);

	// useEffect(() => {
	// 	if(modelVisible) {
	// 		// setFrameCameraView(0, 1, gltfModel, cameraRef, windowWidth, windowHeight, collectionDetails, maxZoom);

	// 		setTimeout(() => {
	// 			captureAndDownloadExt(setModelImages, modelWrap, 1);
	// 		}, 200);

	// 		// console.log(new Date().getTime(), "time check end")
	// 	}
	// }, [modelVisible]);

	return (
		<div className='position-relative model_wrap'>
			<div ref={modelWrap}
				style={{
					width: `${renderedSize?.width + 100}px`,
					height: `${renderedSize?.height + 100}px`,
					marginLeft: '-100px',
					marginTop: '-80px'
				}}
			>
				<div id='container' ref={containerRef}>
				</div>

				<div ref={elementRef} id='elements'>
					{(testRef?.current?.length > 0) &&
						<ConfigurationPoints newFrameRefPoints={newFrameRefPoints} bayPostAngleManual={bayPostAngleManual} setBayPostAngleManual={setBayPostAngleManual} setBayPostDeg={setBayPostDeg} bayPostDeg={bayPostDeg} headerSelectedItem={headerSelectedItem} multiSelectRefPoints={multiSelectRefPoints} handleDeleteObject={handleDeleteObject} hardwareType={hardwareType} testRef={testRef} setPopoverIndex={setPopoverIndex} popoverIndex={popoverIndex} elementData={elementData} handleAddHardware={handleAddHardware} frameDrop={frameDrop} toggleFrameDrop={toggleFrameDrop} checkSingleSide={checkSingleSide} handleFrameSingleSide={handleFrameSingleSide} frameType={frameType} currentModel={currentModel} handleFrameDrop={handleFrameDrop} manufacturingToggle={manufacturingToggle} allFrameCollection={allFrameCollection} allStyleCollection={allStyleCollection} toggleStyleDrop={toggleStyleDrop} styleDrop={styleDrop} handleStyleDrop={handleStyleDrop} styleType={styleType} />
					}

					{(allProfileRefSeq?.current?.length > 0) &&
						<SeqNumberRefs profileRef={allProfileRefSeq} glassRef={glassRefSeq} />
					}

					{(uiLinesRef?.current?.length > 0) &&
						<EditSashSizeModel uiLinesRef={uiLinesRef} savedData={customModelData} type="V" />
					}

					{(uiLinesRefBottom?.current?.length > 0) &&
						<EditSashSizeModel uiLinesRef={uiLinesRefBottom} savedData={customModelData} type="H" />
					}

					{(uiLinesRefFrame?.current?.length > 0) &&
						<EditFrameSizeModel
							uiLinesRef={uiLinesRefFrame}
							accessToken={accessToken}
							setLayoutSizing={setLayoutSizing}
							collectionId={modelId}
							setLoading={setLoading}
							quotationId={quotationId}
							receivedProductId={receivedProductId}
							frameStyleProdId={selectedAnimation}
							frameProfileDefault={frameProfileDefault}
						/>
					}
				</div>
			</div>
		</div>
	);
})

export default PanelDesign;