import React, { useEffect, useState } from "react";
import { Form, Image } from "react-bootstrap";
import {
	Row,
	Col,
	Button,
	FormGroup,
	Label,
	Input,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from "reactstrap";
import NumberInput from "../../../../../components/NumberInput";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MenuItem, OutlinedInput, Select, FormControl, FormControlLabel, Radio, RadioGroup } from "material-ui-core";
import { MenuProps } from "../../../../../utility/data";
import { useRightContext } from "../../../../../context/rightMenuContext";
import UsedInProducts from "../../../../../components/UsedInProducts";
import DropZone from "../../../../../components/DropZone";
import { getProfileTypeMaster } from "../../../../../services/commonServices";
import Editprices from "../../../../../components/Editprices";
import UploadedPhotoPreview from "../../../../../components/UploadedPhotoPreview";
import { getAllColoursCollection } from "../../../../../services/productServices";
import { GoCircleSlash } from "react-icons/go";
import ProfileAdditionalArticles from "../../../../../components/ProfileAdditionalArticles";
import { getProfileModelMasters } from "../../../../../services/productProfileServiceV";

const ProfileLeft = ({ setLoading, profileId, accessToken, pageType, selectItemDetails, usedProducts, updateItemDetailsServices, handleFormReset, deleteFrameProductProfilesService, RemoveProfileInUsedService }) => {

	var acceptFileType = {
		'image/png': ['.jpg', '.jpeg', '.png', '.svg', '.gif'],
	}

	const { timestamp, selectedRightItem, selected, setSelected, handleEdit, handleDescEdit, setDescUpdate, handleFileUpdate } = useRightContext()

	const [masterProfileOptions, setMasterProfileOptions] = useState([])
	const [profileTypeOptions, setProfileTypeOptions] = useState([])

	const [activeTab, setActiveTab] = useState("General")

	const [name, setName] = useState('')
	const [itemId, setItemId] = useState('')
	const [description, setDescription] = useState('')
	const [productCode, setProductCode] = useState('')

	const [profileType, setProfileType] = useState("1")

	const [profileColor, setProfileColor] = useState("")
	const [profileModelName, setProfileModelName] = useState("")

	const [visibleSurface, setVisibleSurface] = useState('External')
	const [uploadedFile, setUploadedFile] = useState('')
	const [filePreview, setFilePreview] = useState('')

	const [externalVS, setExternalVS] = useState("0")
	const [internalVS, setInternalVS] = useState("0")
	const [refDimension, setRefDimension] = useState("0")

	const [width, setWidth] = useState("40")
	const [height, setHeight] = useState('25')

	const [isStock, setIsStock] = useState(false)

	// const [surfaceArea, setSurfaceArea] = useState("0")
	const [externalSurfaceArea, setExternalSurfaceArea] = useState(0);
	const [internalSurfaceArea, setInterrnalSurfaceArea] = useState(0);
	const [weight, setWeight] = useState('0')
	const [maxWeight, setMaxWeight] = useState('0')
	const [barLength, setBarLength] = useState("0")

	const [usableLength, setUsableLength] = useState("0")

	const [customPath, setCustomPath] = useState('')

	const [nameError, setNameError] = useState(false)
	const [descriptionError, setDescriptionError] = useState(false)

	const [availableProfileColurData, setAvailaleProfileColurData] = useState([])

	useEffect(() => {
		GetProfileTypeOptions()
		getProfileColourData()
	}, [])

	useEffect(() => {
		if (profileId) {
			GetProfileModelMasters(profileId)
		}
	}, [profileId])

	useEffect(() => {

		setSelected(selectItemDetails?.name)

		setName(selectItemDetails?.name || '')
		setItemId(selectItemDetails?.id || '')

		setDescription(selectItemDetails?.description || '')

		setProductCode(selectItemDetails?.productCode || '')
		// setProfileType(selectItemDetails?.profileType || "1")

		setExternalVS(selectItemDetails?.externalVisibleSurface || 0)
		setInternalVS(selectItemDetails?.internalVisibleSurface || 0)
		setRefDimension(selectItemDetails?.referenceDimensions || 0)

		setWidth(selectItemDetails?.width || 0)
		setHeight(selectItemDetails?.height || 0)
		setCustomPath(selectItemDetails?.customePath || '')
		setIsStock(selectItemDetails?.stock?.toString() || false)

		// setSurfaceArea(selectItemDetails?.paintSurfaceArea)
		setExternalSurfaceArea(selectItemDetails?.externalPaintSurfaceArea)
		setInterrnalSurfaceArea(selectItemDetails?.internalPaintSurfaceArea)
		setWeight(selectItemDetails?.weight)
		setMaxWeight(selectItemDetails?.maxWeight)

		setBarLength(selectItemDetails?.barLength || 0)
		setUsableLength(selectItemDetails?.usableLength || 0)

		setUploadedFile('')
		setFilePreview(selectItemDetails?.imagePath || '')

		setNameError(false)
		setDescriptionError(false)
	}, [selectItemDetails])

	useEffect(() => {
		if (availableProfileColurData && availableProfileColurData.length > 0) {
			setProfileColor(selectItemDetails?.availableProfileColour ? selectItemDetails?.availableProfileColour : availableProfileColurData[0]?.id)
		}
	}, [selectItemDetails, availableProfileColurData])

	useEffect(() => {
		if (masterProfileOptions && masterProfileOptions.length > 0) {
			setProfileModelName(selectItemDetails?.customePath ? selectItemDetails?.customePath : masterProfileOptions[0]?.modelName)
		}
	}, [selectItemDetails, masterProfileOptions])

	useEffect(() => {
		if (profileTypeOptions && profileTypeOptions.length > 0) {
			setProfileType(selectItemDetails?.profileType ? selectItemDetails?.profileType : profileTypeOptions[0]?.id)
		}
	}, [selectItemDetails, profileTypeOptions])

	useEffect(() => {
		if (filePreview) {
			handleFileUpdate(filePreview)
		}
	}, [filePreview])

	useEffect(() => {
		if (profileId != 2 && width > 0 && height > 0) {
			handleFileUpdate(filePreview)
		} else if (profileId == 2 && width > 0) {
			handleFileUpdate(filePreview)
		} else {
			handleFileUpdate('')
		}
	}, [width, height])

	// get Profile type from master API
	const GetProfileModelMasters = async (fetchId) => {
		const res = await getProfileModelMasters(accessToken, fetchId);

		if (res && res && res.status === 200) {
			if (res?.data?.statusCode === 200) {
				if (res?.data?.entity !== null) {
					if (res?.data?.entity?.length > 0) {
						setMasterProfileOptions(res?.data?.entity)
					}
				}
			}
		}
	}

	// get Profile type from master API
	const GetProfileTypeOptions = async () => {
		const res = await getProfileTypeMaster(accessToken);

		if (res && res && res.status === 200) {
			if (res?.data?.statusCode === 200) {
				if (res?.data?.entity !== null) {
					if (res?.data?.entity?.length > 0) {
						setProfileTypeOptions(res?.data?.entity)
					}
				}
			}
		}
	}

	const handleNameChange = (e) => {
		const value = e.target.value

		if (e.target.value?.length <= 50) {
			setName(value)
			handleEdit(value)
			setSelected(value)
		}

		if (value.length > 50) {
			setNameError(true)
		} else {
			setNameError(false)
		}
	}

	const handleDescriptionChange = (e) => {
		const value = e.target.value

		if (e.target.value?.length <= 80) {
			setDescription(value)
			handleDescEdit(value)
			setDescUpdate(value)
		}

		if (value.length > 80) {
			setDescriptionError(true)
		} else {
			setDescriptionError(false)
		}
	}

	const handleCustomPathChange = (e) => {
		const value = e.target.value
		setCustomPath(value)
	}

	const handleProfileTypeChange = (e) => {
		const value = e.target.value

		setProfileType(value)
	}

	const handleProfileColourChange = (e) => {
		const value = e.target.value

		setProfileColor(value)
	}

	const handleActiveTab = (tab) => {
		setActiveTab(tab)
	}

	const handleMasterProfile = (e) => {

		setProfileModelName(e.target.value)
		const itemData = masterProfileOptions?.find((p) => p.modelName == e.target.value)

		console.log(itemData, "item master profile")
		if (itemData?.filePath) {
			setFilePreview(itemData?.filePath)
		}
	}

	const handleFormSubmit = () => {

		const formDataValues = {
			"id": 14,
			"type": pageType,

			"name": name,
			"description": description || '',

			'productCode': productCode || '',
			'profileType': profileType || '1',

			"externalVS": externalVS || 0,
			"internalVS": internalVS || 0,
			"refDimension": refDimension || 0,

			"width": width || 0,
			"height": height || 0,
			// "surfaceArea": surfaceArea || 0,
			"weight": weight || 0,
			"maxWeight": maxWeight || 0,
			"customePath": customPath || '',
			"externalPaintSurfaceArea": externalSurfaceArea || 0,
			"internalPaintSurfaceArea": internalSurfaceArea || 0,

			"filePath": uploadedFile || '',
			"imagePath": filePreview,
			"barLength": barLength || 0,
			"usableLength": usableLength || 0,
			"availableProfileColour": profileColor || '',
			"profileModelName": profileModelName || '',
			"isStock": isStock || false,

			// These fields are removed from the UI
			"angle": 0,
			"feature": '',
			"minimum": 0,
			"maximum": 0,
			"radius": 0,
			"radiusHeight": 0,
			'radiusWidth': 0,
			"shape": "1",
			"side": "Inner",
			'special': '1',
			"stepWidth": 0,
			"stepHeight": 0,
			"xAxis": 0,
			"yAxis": 0,
		}

		updateItemDetailsServices(formDataValues)
	}

	const getProfileColourData = async () => {

		const res = await getAllColoursCollection(1, accessToken);

		if (res && res.status === 200) {
			if (res?.data?.entity?.length > 0) {
				setAvailaleProfileColurData(res?.data?.entity);
			}
		}
	};


	const editNavData = { url: `/pricing/profiles`, stateValue: pageType }

	return (
		<div className="d-flex flex-column h-100">
			<div className="d-flex align-items-center mb-3 flex-wrap gap-2 left_header">
				{selected && (
					<h5 className="mb-0 text-wrap text-break me-4 main_title">{selected}</h5>
				)}

				<Editprices data={editNavData} hasState={true} />
			</div>
			<Nav tabs className="details_header border-0 nav-tabs-custom">
				<NavItem>
					<NavLink
						className={`${activeTab === "General" && 'active'}`}
						onClick={() => { handleActiveTab("General") }}
					>
						General
					</NavLink>
				</NavItem>
				{/* <NavItem>
					<NavLink
						className={`${activeTab === "Additional Articles" && 'active'}`}
						onClick={() => { handleActiveTab("Additional Articles") }}
					>
						Additional Articles
					</NavLink>
				</NavItem> */}
			</Nav>
			<TabContent activeTab={activeTab} className='flex-grow-1 d-flex'>
				<TabPane tabId="General" className='flex-grow-1 w-100'>
					<Form className={`h-100 d-flex flex-column flex-grow-1 justify-content-between ${selectedRightItem?.isSelf ? "" : "not_allowed"}`}>
						{/* has_tab has_btns has_head -> add respective class of included compoenent in div of class inside_scroll for proper scroll */}
						<div className="inside_scroll has_btns has_head">
							<Row className="border-bottom mb-4">
								<Col lg="6">
									<FormGroup className="form-group">
										<Label for="name">
											Name
										</Label>
										<Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='name' value={name || ''} onChange={(e) => handleNameChange(e)} />

										{nameError && timestamp && itemId && (
											<div className='mt-1 text-muted text-small'>Max 50 characters are allowed</div>
										)}

										{(!name && timestamp && itemId) && (
											<div className='mt-1 text-danger text-small'>Name is required</div>
										)}
									</FormGroup>
								</Col>

								<Col lg="6">
									<FormGroup className="form-group">
										<Label for="description">
											Description
										</Label>
										<Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='description' value={description || ''} onChange={(e) => handleDescriptionChange(e)} />

										{(descriptionError && timestamp && itemId) && (
											<div className='mt-1 text-muted text-small'>Max 80 characters are allowed</div>
										)}
									</FormGroup>
								</Col>

								<Col lg="6">
									<FormGroup className="form-group">
										<Label for="productCode">
											Product code
										</Label>
										<Input disabled={!selectedRightItem?.isSelf} className='mb-1' name='productCode' value={productCode || ''} onChange={(e) => setProductCode(e.target.value)} />

										{(!productCode && timestamp && itemId) && (
											<div className='mt-1 text-danger text-small'>Product code is required</div>
										)}
									</FormGroup>
								</Col>



								<Col lg="6">
									<FormGroup className="form-group">
										<Label for="profileType">
											Profile type
										</Label>

										<Select
											disabled={!selectedRightItem?.isSelf}
											labelId="profileType"
											id="profileType"
											value={profileType}
											label="profileType"
											onChange={(e) => { handleProfileTypeChange(e) }}
											input={<OutlinedInput className='w-100' name='profileType' />}

											MenuProps={MenuProps}

											IconComponent={() => (
												<MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
											)}
										>
											{profileTypeOptions && profileTypeOptions.map((o, index) => (
												<MenuItem key={index} value={o?.id}>
													{o?.name}
												</MenuItem>
											))}
										</Select>
									</FormGroup>
								</Col>

								{/* <Col lg="6">
							<FormGroup className="form-group">
								<Label for="VisibleSurface">Visible surface</Label>

								<FormControl className="mb-0 w-100">
									<RadioGroup
										aria-labelledby="feature-label"
										name="VisibleSurface"
										className='flex-row flex-wrap custom_x_gap'
										value={visibleSurface}
										onChange={(e) => handleVisibleSurface(e)}
									>
										<FormControlLabel value="External" control={<Radio />} label="External" />

										<FormControlLabel value="Internal" control={<Radio />} label="Internal" />
									</RadioGroup>
								</FormControl>
							</FormGroup>
						</Col> */}

								<Col lg="6" className={`${!selectedRightItem?.isSelf ? "not_allowed" : ""}`}>
									<FormControl className="form-group">
										<Label for="pageSize">
											Is stock colour ?
										</Label>

										<RadioGroup
											className="flex-row gap-4"
											aria-labelledby="pageSize-label"
											name="pageSize"
											value={isStock}
											onChange={(e) => setIsStock(e.target.value)}
										>
											<FormControlLabel
												value="false" control={<Radio />} label="False" />
											<FormControlLabel
												value="true" control={<Radio />} label="True" />

										</RadioGroup>
									</FormControl>
								</Col>

								{profileId !== 12 && (
									<Col lg="6">
										<FormGroup className="form-group">
											<Label for="profileModelName">
												Master profile
											</Label>

											<Select
												labelId="profileModelName"
												id="profileModelName"
												value={profileModelName || ''}
												label="profileModelName"
												onChange={(e) => { handleMasterProfile(e) }}
												input={<OutlinedInput className='w-100' name='profileModelName' />}

												MenuProps={MenuProps}

												IconComponent={() => (
													<MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
												)}
											>
												{masterProfileOptions && masterProfileOptions.map((o, index) => (
													<MenuItem key={index} value={o?.modelName}>
														<div className="d-flex align-items-center gap-3 select_with_img">
															<div className="img_wrap size_lg">
																{o && o.filePath && o.modelName ? (
																	<img src={o.filePath} alt="photo" />
																) : (
																	<GoCircleSlash size={40} color="#989898" />
																)}
															</div>
															<span>
																{o?.name}
															</span>
														</div>
													</MenuItem>
												))}
											</Select>
										</FormGroup>
									</Col>
								)}

								{selectedRightItem?.isSelf && (
									<Col lg="6">
										<FormGroup className="form-group">
											<Label for="uploadFile">Upload image</Label>

											<DropZone
												acceptFileType={acceptFileType}
												multiple={false}
												uploadedFile={uploadedFile}
												setUploadedFile={setUploadedFile}
												filePreview={filePreview}
												setFilePreview={setFilePreview}
											/>
										</FormGroup>
									</Col>
								)}

							</Row>

							<Row className="border-bottom mb-4">

								{(profileId !== 12 && filePreview) && (
									<>
										<h4 className="main_title sub_title">Profile Photo</h4>

										<Col lg="12" className="mb-4">
											{(profileId == 2 && width > 0) || (profileId != 2 && width > 0 && height > 0) ? (
												<UploadedPhotoPreview
													uploadedFile={uploadedFile}
													setUploadedFile={setUploadedFile}
													filePreview={filePreview}
													setFilePreview={setFilePreview}
												/>
											) : (
												<div>
													<GoCircleSlash size={50} color="#989898" />
												</div>
											)}

										</Col>
									</>
								)}

								{profileId !== 12 && (
									<Col md="6" lg="6">
										<FormGroup className="form-group">
											<Label for="width">Width</Label>

											<NumberInput disabled={!selectedRightItem?.isSelf}
												name="width"
												value={width}
												setValue={setWidth}
											/>
										</FormGroup>
									</Col>
								)}


								<Col md="6" lg="6">
									<FormGroup className="form-group">
										<Label for="externalSurfaceArea">External paint surface area(mm²)</Label>

										<NumberInput disabled={!selectedRightItem?.isSelf} Decimal
											name="externalSurfaceArea"
											value={externalSurfaceArea}
											setValue={setExternalSurfaceArea}
										/>
									</FormGroup>
								</Col>

								<Col md="6" lg="6">
									<FormGroup className="form-group">
										<Label for="internalSurfaceArea">Internal paint surface area(mm²)</Label>

										<NumberInput disabled={!selectedRightItem?.isSelf} Decimal
											name="internalSurfaceArea"
											value={internalSurfaceArea}
											setValue={setInterrnalSurfaceArea}
										/>
									</FormGroup>
								</Col>
								{profileId == 2 || profileId == 12 ?
									"" : (
										<Col md="6" lg="6">
											<FormGroup className="form-group">
												<Label for="height">
													Height
												</Label>

												<NumberInput disabled={!selectedRightItem?.isSelf} name="height" value={height} setValue={setHeight} />
											</FormGroup>
										</Col>)}

								<Col md="6" lg="6">
									<FormGroup className="form-group">
										<Label for="weight">Weight(kg)</Label>

										<NumberInput disabled={!selectedRightItem?.isSelf} Decimal
											name="weight"
											value={weight}
											setValue={setWeight}
										/>
									</FormGroup>
								</Col>

								{profileId == 8 ? <Col md="6" lg="6">
									<FormGroup className="form-group">
										<Label for="max-Weight">Max Weight(kg)</Label>

										<NumberInput disabled={!selectedRightItem?.isSelf} Decimal
											name="max-Weight"
											value={maxWeight}
											setValue={setMaxWeight}
										/>
									</FormGroup>
								</Col> : null}

								{profileId !== 12 && (
									<Col md="6" lg="6">
										<FormGroup className="form-group">
											<Label for="bar-length">Bar length</Label>

											<NumberInput disabled={!selectedRightItem?.isSelf}
												name="bar-length"
												value={barLength}
												setValue={setBarLength}
											/>
										</FormGroup>
									</Col>
								)}


								<Col lg="6">
									<FormGroup className="form-group">
										<Label for="profileColor">
											Available profile colour
										</Label>

										<Select
											labelId="profileColor"
											id="profileColor"
											value={profileColor || ''}
											label="profileColor"
											onChange={(e) => { handleProfileColourChange(e) }}
											input={<OutlinedInput className='w-100' name='profileColor' />}

											MenuProps={MenuProps}

											IconComponent={() => (
												<MdOutlineKeyboardArrowDown size={20} className='me-1 MuiSvgIcon-root MuiSelect-icon' />
											)}
										>
											{availableProfileColurData && availableProfileColurData.map((o, index) => (
												<MenuItem key={index} value={o?.id}>
													{o?.name}
												</MenuItem>
											))}
										</Select>
									</FormGroup>
								</Col>


								{profileId !== 12 && (
									<>
										<Col md="6" lg="6">
											<FormGroup className="form-group">
												<Label for="externalVS">Internal surface</Label>

												<NumberInput disabled={!selectedRightItem?.isSelf} Decimal
													name="externalVS"
													value={externalVS}
													setValue={setExternalVS}
												/>
											</FormGroup>
										</Col>


										<Col md="6" lg="6">
											<FormGroup className="form-group">
												<Label for="internalVS">External surface</Label>

												<NumberInput disabled={!selectedRightItem?.isSelf} Decimal
													name="internalVS"
													value={internalVS}
													setValue={setInternalVS}
												/>
											</FormGroup>
										</Col>



										<Col md="6" lg="6">
											<FormGroup className="form-group">
												<Label for="usable-length">Usable length</Label>

												<NumberInput disabled={!selectedRightItem?.isSelf}
													name="usable-length"
													value={usableLength}
													setValue={setUsableLength}
												/>
											</FormGroup>
										</Col>


										{/* remove */}

										<Col md="6" lg="6">
											<FormGroup className="form-group">
												<Label for="refDimension">Reference dimension</Label>

												<NumberInput disabled={!selectedRightItem?.isSelf}
													name="refDimension"
													value={refDimension}
													setValue={setRefDimension}
												/>
											</FormGroup>
										</Col>

									</>
								)}

								{/* <Col md="6" lg="6">
									<FormGroup className="form-group">
										<Label for="customPath">
											Custom path
										</Label>

										<Input
											type="textarea"
											className='mb-1'
											rows="5"
											name="customPath"
											onChange={(e) => handleCustomPathChange(e)}
											value={customPath || ''}
											disabled={!selectedRightItem?.isSelf}
										/>

										<a target="_blank" rel="noreferrer" href="https://svg-path-visualizer.netlify.app/#M2%2C8%20L5%2C2%20L8%2C8">More info</a>
									</FormGroup>
								</Col> */}
							</Row>

							{(profileId !== 12) &&
								<div>
									<h4 className="main_title sub_title">Used in products</h4>

									<UsedInProducts data={usedProducts} handleRemove={RemoveProfileInUsedService} />
								</div>
							}
						</div>

						{/* Start: space for buttons at the bottom */}
						{selectedRightItem?.isSelf && (
							<div className="d-flex gap-2 gap-xl-3 main_buttons">
								<Button color="primary" size="md" className="px-4"
									onClick={() => handleFormSubmit()}
									disabled={!timestamp || !name.trim() || !productCode}
								>
									Save Changes
								</Button>

								<Button color="primary" size="md" outline className="px-4" disabled={!timestamp} onClick={() => handleFormReset()} >
									Reset
								</Button>
							</div>
						)}
						{/* End: space for buttons at the bottom */}
					</Form>
				</TabPane>

				<TabPane tabId="Additional Articles" className='flex-grow-1 w-100'>
					<ProfileAdditionalArticles isDisabled={!selectedRightItem?.isSelf} setLoading={setLoading} accessToken={accessToken} activeTab={activeTab} selectItemDetails={selectItemDetails} />
				</TabPane>
			</TabContent>
		</div>
	);
};

export default ProfileLeft;
