import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  DropdownToggle,
  Dropdown
} from "reactstrap";
import {
  getFrameData,
  getSashHangingData,
  getSashProfileData,
  setModelData,
} from "../services/3dModelServices";
import { Image } from "react-bootstrap";
import { GoCircleSlash } from "react-icons/go";
import { BiSolidEditAlt, BiTrash } from "react-icons/bi";
import {
  MdInfoOutline,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from "react-icons/md";
import ManufacturingEditComp from "./ManufacturingEditComp";
import { useDesigner } from "../context/designerContext";
import {
  getAdditionalHardware,
  getAdditionalProfiles,
  getProfileJointsService,
  setUpdateProfileJointsStatus,
  setUpdateProfileStatus,
  setUpdateSashHangingProfileStatus,
  updateAdditionalHardwareStatus,
  updateAdditionalProfileStatus,
} from "../services/ManufacturingServices.js";
import ProfileJoints from "../../src/assets/img/profile-joints.svg";
import ManufacturingHardware from "./ManufacturingHardware.js";
import { updateProfileJointsData } from "../utility/helper.js";
import { useMessage } from "../context/messageContext.js";
import { useNavigate } from "react-router-dom";
import ManufacturingAdditionalProfile from "./ManufacturingAdditionalProfile.js";

const ManufacturingComp = ({
  accessToken,
  collectionId,
  sashProductId,
  manufacturingToggle,
  setHardwareType,
  frameStyleProdId,
  setLoading,
  allHardware,
  setAllHardware,
  handleHardwareOptionData,
  frameProductDetails,
  allTagData,
}) => {
  const [activeTab, setActiveTab] = useState("Sash");
  const [sashData, setSashData] = useState([]);
  const [frameData, setFrameData] = useState([]);
  const [enable, setEnable] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [referencePoint, setReferencePoint] = useState(null);
  const [currentFrameProfile, setCurrentFrameProfile] = useState(null);
  const [currentSashProfile, setCurrentSashProfile] = useState(null);
  const [sashHangingData, setSashHangingData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [profileJointsData, setProfileJointsData] = useState([]);
  const [updatedFrameData, setUpdatedFrameData] = useState([]);
  const [isSave, setIsSave] = useState(false);
  const [open, setOpen] = useState("0");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isAdditionalProfileEdit, setIsAdditionalProfileEdit] = useState(false);
  const [additionalProfiles, setAdditionalProfiles] = useState([])
  const [allProfileLoading, setAllProfileLoading] = useState(false)
  const [additionalHardware, setAdditionalHardware] = useState([])

  const navigate = useNavigate();

  const {
    multiSelectRefPoints,
    setMultiSelectRefPoints,
    setCheckSingleSide,
    delete3DItems,
    setCustomModelData,
    customModelData,
  } = useDesigner();

  const { setMessage, setMessageType } = useMessage();



  useEffect(() => {
    if (
      activeTab === "Sash" &&
      sashProductId &&
      referencePoint !== null &&
      referencePoint?.data?.name?.includes("LockingPlate")
    ) {
      getSashData(referencePoint?.index);
      getSashHangingDataService(referencePoint?.index);
      setEnable(!enable);
      setIsEdit(false);
    } else if (
      activeTab === "Sash" &&
      sashProductId &&
      referencePoint !== null &&
      referencePoint?.data?.name?.includes("Bar")
    ) {
      getSashData(referencePoint?.index);
      setEnable(!enable);
      setIsEdit(false);
    } else if (
      activeTab === "Sash" &&
      sashProductId &&
      referencePoint !== null &&
      (referencePoint?.data?.name?.includes("GlassPanel") ||
        referencePoint?.data?.name === "")
    ) {
      getSashHangingDataService(referencePoint?.index);
      setIsEdit(false);
    } else if (activeTab === "Frame" && referencePoint !== null) {
      if (
        referencePoint?.data?.name?.includes("Frame") &&
        !referencePoint?.data?.name?.includes("ProfileJoints")
      ) {
        getFameData(referencePoint?.index);
      } else {
        getProfileJointsData(referencePoint?.index);
      }
      setEnable(!enable);
      setIsEdit(false);
    } else if (activeTab === "Hardware") {
      // if (referencePoint === null) {
      // setHardwareType("");
      // setMultiSelectRefPoints([]);

      // }
    } else {
    }
  }, [activeTab, sashProductId, referencePoint]);



  useEffect(() => {
    if (referencePoint?.index > -1 || referencePoint?.index !== undefined) {
      if (manufacturingToggle && activeTab === "Sash") {
        getAdditionalProfileServices(2, referencePoint?.index, frameStyleProdId?.id)
      } else if (manufacturingToggle && activeTab === "Frame") {
        getAdditionalProfileServices(1, referencePoint?.index, frameStyleProdId?.id)
      } else if (manufacturingToggle && activeTab === "Hardware") {
        getAdditionalHardwareService(referencePoint?.index)
      }
    }
  }, [activeTab, referencePoint?.index])




  useEffect(() => {
    if (manufacturingToggle && activeTab === "Sash") {
      setHardwareType("sashHangingProfile");
    } else if (manufacturingToggle && activeTab === "Frame") {
      setHardwareType("customize frame style");
    } else if (!manufacturingToggle) {
      setHardwareType("");
      setMultiSelectRefPoints([]);
      setReferencePoint(null);
    }
  }, [manufacturingToggle, activeTab]);

  // Here setting the reference points array as empty so that on changing the tab the selected reference points should not come checked under the frame tab if the currently selected tab is sash and vice-versa
  useEffect(() => {
    setMultiSelectRefPoints([]);
    setCheckSingleSide();
    setReferencePoint(null);
    setIsEdit(false);
  }, [activeTab]);



  useEffect(() => {
    if (multiSelectRefPoints.length === 1) {
      setReferencePoint(multiSelectRefPoints[0]);
    } else if (multiSelectRefPoints?.length > 1) {
      let lastElement = multiSelectRefPoints.pop();
      setMultiSelectRefPoints([lastElement]);
      setReferencePoint(lastElement);
    } else if (multiSelectRefPoints?.length === 0) {
      setReferencePoint(null);
    }
  }, [multiSelectRefPoints]);

  // This will update the data to be shown on the basis of the selected refrence point
  useEffect(() => {
    if (referencePoint?.data?.name?.includes("LockingPlate")) {
      setUpdatedData(sashData);
    } else if (
      referencePoint?.data?.name?.includes("GlassPanel") ||
      referencePoint?.data?.name === ""
    ) {
      setUpdatedData(sashHangingData);
    } else {
      setUpdatedData(sashData);
    }
  }, [sashData, sashHangingData]);

  // This will update the data to be shown on the basis of the selected refrence point
  useEffect(() => {
    if (
      referencePoint?.data?.name?.includes("Frame") &&
      !referencePoint?.data?.name?.includes("ProfileJoints")
    ) {
      setUpdatedFrameData(frameData);
    } else {
      setUpdatedFrameData(profileJointsData);
    }
  }, [referencePoint, frameData, profileJointsData]);

  useEffect(() => {
    if (isSave) {
      saveProfileJointsData(customModelData);

      setTimeout(() => {
        setIsSave(false);
      }, [100]);
    }
  }, [isSave]);



  const handleActiveTab = (value) => {
    setActiveTab(value);
  };

  const toggleAccordion = (id) => {
    if (open === id) {
      setOpen("0");
    } else {
      setOpen(id);
    }
  };

  const handleAccordionClick = (e) => {
    e.stopPropagation();
  };

  const getSashData = async (referencePoint) => {
    let data = {
      collectionId: collectionId,
      sashProductId: sashProductId?.id,
      frameStyleId: frameStyleProdId?.id,
      referencePoint: [referencePoint],
      type: 2,
    };
    const res = await getSashProfileData(accessToken, data);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setSashData(res?.data?.entity?.sash);
      }
    }
  };

  const getFameData = async (referencePoint) => {
    const res = await getFrameData(
      accessToken,
      collectionId,
      frameStyleProdId?.id,
      [referencePoint],
      2
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setFrameData(res?.data?.entity?.frame);
      }
    }
  };


  const getAdditionalProfileServices = async (type, index, frameStyleId) => {

    setAllProfileLoading(true)
    const data = {
      type,
      frameStyleId,
      index
    }
    const res = await getAdditionalProfiles(accessToken, data)
    if (res && res?.data?.statusCode) {
      setAdditionalProfiles(res.data.entity)
      setAllProfileLoading(false)
    }
  }

  const getAdditionalHardwareService = async (referencePoint) => {
    setAllProfileLoading(true)
    const res = await getAdditionalHardware(accessToken, frameStyleProdId?.id, collectionId, referencePoint)
    if (res.data.statusCode === 200) {
      setAdditionalHardware(res.data.entity)
      setAllProfileLoading(false)
    }
  }

  const additionalProfileStatusServices = async (data) => {
    let typeId;
    if (activeTab === "Frame") {
      typeId = 1;
    } else if (activeTab === "Sash") {
      typeId = 2
    }
    const res = await updateAdditionalProfileStatus(accessToken, referencePoint.index, data, frameStyleProdId?.id, typeId)
    if (res.data.statusCode === 200) {
      getAdditionalProfileServices(typeId, referencePoint?.index, frameStyleProdId?.id)
    }
  }


  const additionalHardwareStatusServices = async (data) => {

    const res = await updateAdditionalHardwareStatus(accessToken, referencePoint.index, data, frameStyleProdId?.id)
    if (res.data.statusCode === 200) {
      getAdditionalHardwareService(referencePoint?.index)
    }
  }



  const getProfileJointsData = async (referencePoint) => {
    const res = await getProfileJointsService(
      accessToken,
      frameStyleProdId?.id,
      referencePoint
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        let temp = [];
        res?.data?.entity?.forEach((element) => {
          element.imagePath = ProfileJoints;
          element.customePath = "joints";
          temp.push(element);
        });
        setProfileJointsData(temp);
      }
    }
  };

  const getSashHangingDataService = async (referencePoint) => {
    let data = {
      collectionId: collectionId,
      sashProductId: sashProductId?.id,
      frameStyleId: frameStyleProdId?.id,
      referencePoint: referencePoint,
    };
    const res = await getSashHangingData(accessToken, data);
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setSashHangingData(res?.data?.entity);
      }
    }
  };

  const toggleDropDown = (e) => {
    setDropdownOpen(!dropdownOpen);
    e.stopPropagation();
  }

  const makeProfileEnable = async (item, type) => {
    let updatedStatus = item?.status;
    if (!item?.hasOwnProperty("hangingName")) {
      if (
        referencePoint !== null &&
        !referencePoint?.data?.name?.includes("Joints")
      ) {
        let data = {
          id: 0,
          frameProfileId: item?.id,
          frameStyleId: frameStyleProdId?.id,
          referencePoint: referencePoint?.index,
          heightFormula: null,
          widthFormula: null,
          status: !updatedStatus,
        };
        const res = await setUpdateProfileStatus(accessToken, data);
        if (res && res?.statusCode == 200) {
          if (type === "sash") {
            getSashData(referencePoint?.index);
          } else {
            getFameData(referencePoint?.index);
          }
        } else {
          if (type === "sash") {
            getSashData(referencePoint?.index);
          } else {
            getFameData(referencePoint?.index);
          }
        }
      } else {
        let data = {
          id: 0,
          profileJointId: item?.id,
          frameStyleId: frameStyleProdId?.id,
          referencePoint: referencePoint?.index,
          status: !updatedStatus,
        };
        const res = await setUpdateProfileJointsStatus(accessToken, data);
        if (res && res?.data?.statusCode === 200) {
          getProfileJointsData(referencePoint?.index);
          const referenceName = referencePoint?.data?.name;
          const data = {
            name: item?.name,
            id: item?.id,
            frame: item?.frame,
            referencePoint: referenceName,
          };

          if (!updatedStatus) {
            setCustomModelData((prevData) =>
              updateProfileJointsData(prevData, data, referencePoint)
            );
          } else {
            setCustomModelData((prevData) => ({
              ...prevData,
              profileJointsData: Array.isArray(prevData.profileJointsData)
                ? prevData.profileJointsData.filter(
                  (item) => item.referencePoint !== referenceName
                )
                : [],
            }));
          }
          setIsSave(true);
        } else {
          getProfileJointsData(referencePoint?.index);
        }
      }
    } else {
      let data = {
        id: 0,
        sashHangingId: item?.id,
        frameStyleId: frameStyleProdId?.id,
        referencePoint: referencePoint?.index,
        heightFormula: null,
        widthFormula: null,
        status: !updatedStatus,
      };
      const res = await setUpdateSashHangingProfileStatus(accessToken, data);
      if (res?.data?.statusCode === 200) {
        getSashHangingDataService(referencePoint?.index);
      } else {
        getSashHangingDataService(referencePoint?.index);
      }
    }
  };

  const AddiitionProfileAccordion = ({ additionalItems, getData, refetchData, updateStatusServices, additionalItemType }) => {

    return (
      <Accordion
        open={open}
        toggle={toggleAccordion}
        onClick={(e) => handleAccordionClick(e)}
        tag="div"
        className="mt-2"
      >
        <AccordionItem tag="div">
          {!isAdditionalProfileEdit &&
            referencePoint?.data?.name !== "" &&
            referencePoint?.data?.name !== "GlassPanel034" && (
              <AccordionHeader targetId="2">
                {activeTab === "Hardware" ? "Additional Hardware" : "Additional Profiles"}
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropDown}
                  className="ms-auto"
                >
                  <DropdownToggle
                    color="none"
                    className="border-0 p-0 py-1 d-block text-muted"
                    tag="span"
                  ></DropdownToggle>
                </Dropdown>
              </AccordionHeader>
            )}
          <AccordionBody accordionId="2">
            {referencePoint ?
              <ManufacturingAdditionalProfile
                additionalProfileList={currentSashProfile?.additionalArticles}
                isAdditionalProfileEdit={isAdditionalProfileEdit}
                setIsAdditionalProfileEdit={setIsAdditionalProfileEdit}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
                setOpen={setOpen}
                referencePoint={referencePoint}
                frameStyleId={frameStyleProdId?.id}
                accessToken={accessToken}
                currentItem={currentSashProfile}
                getData={getData}
                additionalItems={additionalItems}
                updateStatusServices={updateStatusServices}
                allProfileLoading={allProfileLoading}
                getSashHangingData={getSashHangingDataService}
                collectionId={collectionId}
                frameProductDetails={frameProductDetails}
                frameStyleProdId={frameStyleProdId}
                setLoading={setLoading}
                activeTab={activeTab}
                refetchData={refetchData}
                allTagData={allTagData}
              /> :
              <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
                <span>
                  <MdInfoOutline size={18} />
                </span>
                <span>Select one or more Profile</span>
              </Alert>
            }
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    )
  }

  const saveProfileJointsData = async (data) => {
    setLoading(true);
    const res = await setModelData(
      accessToken,
      collectionId,
      frameStyleProdId?.id,
      data
    );
    if (res && res.status === 200) {
      if (res?.data?.statusCode === 200) {
        setLoading(false);
      } else if (res?.data?.statusCode !== 200) {
        setMessageType("error");
        setMessage(
          res?.data?.message ? res?.data?.message : "Something went wrong !"
        );
        setLoading(false);
      }
    } else {
      setLoading(false);
      setMessageType("error");
      setMessage(
        res?.data?.message ? res?.data?.message : "Something went wrong !"
      );
      if (res?.response?.status === 401) {
        navigate("/unauthorized");
        localStorage.removeItem("access_token");
      }
      if (res?.response?.status == 404) {
        navigate("/error/503");
      }
      if (res?.message === "Network Error") {
        navigate("/error/network");
      }
    }
  };



  return (
    <div className="d-flex flex-column h-100">
      <Nav
        tabs
        className="details_header border-0 nav-tabs-custom bg-none align-items-center"
      >
        <NavItem className="ms-1">
          <NavLink
            className={`${activeTab === "Sash" && "active"}`}
            onClick={() => {
              handleActiveTab("Sash");
            }}
          >
            Sash
          </NavLink>
        </NavItem>
        <NavItem className="ms-1">
          <NavLink
            className={`${activeTab === "Frame" && "active"}`}
            onClick={() => {
              handleActiveTab("Frame");
            }}
          >
            Frame
          </NavLink>
        </NavItem>
        <NavItem className="ms-1">
          <NavLink
            className={`${activeTab === "Hardware" && "active"}`}
            onClick={() => {
              handleActiveTab("Hardware");
            }}
          >
            Hardware
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="flex-grow-1 d-flex">
        <TabPane tabId="Sash" className="flex-grow-1 w-100">
          {!isEdit ? (
            <>
              {multiSelectRefPoints && multiSelectRefPoints?.length > 0 ? (

                <div className="mt-1 right_list">
                  <Button
                    color="light"
                    size="sm"
                    className="px-4  py-2 w-100 d-flex justify-content-between fw-small mb-2"
                    onClick={() => delete3DItems(true, "sash")}
                  >
                    <BiTrash size={20} />
                    Delete
                    <span></span>
                  </Button>

                  {updatedData && updatedData.length > 0 ? (
                    <>
                      {updatedData?.map((item, index) => (
                        <div
                          key={index}
                          className="position-relative d-flex align-items-center right_item_wrap has_delete has_copy"
                        >
                          <div className={`right_item w-100`}>
                            <span
                              className={`me-2 image_icon ${item?.status || item.defaultProfile
                                ? ""
                                : "opacity-50"
                                }`}
                            >
                              {item.imagePath ? (
                                <Image src={item.imagePath} />
                              ) : (
                                <svg viewBox="0 0 100 50" width="100" height="50">
                                  <path
                                    className="stroke"
                                    stroke="#989898"
                                    fill="#fff"
                                    strokeWidth="2"
                                    d={`M 0,50 0,0 100,0 100,50 Z`}
                                  ></path>
                                </svg>
                              )}
                            </span>
                            <div className="right_title">
                              <span
                                className={`text-truncate ${item?.status || item.defaultProfile
                                  ? "text-dark"
                                  : "text-muted-light"
                                  }`}
                              >
                                {item.name}
                              </span>
                              <span className="text-truncate d-block text-small">
                                {item.description}
                              </span>
                            </div>
                            <span className="position-absolute action_btns">
                              {!item.defaultProfile && (
                                <NavLink
                                  onClick={() => makeProfileEnable(item, "sash")}
                                >
                                  {item?.status ? (
                                    <MdOutlineVisibility
                                      className="text-muted"
                                      size={18}
                                    />
                                  ) : (
                                    <MdOutlineVisibilityOff
                                      className="text-muted"
                                      size={18}
                                    />
                                  )}
                                </NavLink>
                              )}

                              {item?.status && (
                                <NavLink
                                  onClick={() => {
                                    setIsEdit(true);
                                    setCurrentSashProfile(item);
                                  }}
                                >
                                  <BiSolidEditAlt
                                    className="text-muted"
                                    size={18}
                                  />
                                </NavLink>
                              )}
                            </span>
                          </div>
                        </div>
                      ))}

                      <AddiitionProfileAccordion
                        additionalItems={additionalProfiles}
                        getData={getSashData}
                        refetchData={getAdditionalProfileServices}
                        updateStatusServices={additionalProfileStatusServices}
                        additionalItemType={"sash"}
                      />
                    </>


                  ) : (
                    <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
                      <span>
                        <MdInfoOutline size={18} />
                      </span>
                      <span>No sash profiles to show</span>
                    </Alert>
                  )}
                </div>
              ) : (
                <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
                  <span>
                    <MdInfoOutline size={18} />
                  </span>
                  <span>Select one or more sash to edit</span>
                </Alert>
              )}
            </>
          ) : (
            <ManufacturingEditComp
              setIsEdit={setIsEdit}
              frameStyleProdId={frameStyleProdId}
              referencePoint={referencePoint}
              accessToken={accessToken}
              currentItem={currentSashProfile}
              setLoading={setLoading}
              getData={getSashData}
              getSashHangingData={getSashHangingDataService}
              collectionId={collectionId}
              frameProductDetails={frameProductDetails}
              isAdditionProfile={false}
              allTagData={allTagData}
            />
          )}
        </TabPane>
        <TabPane tabId="Frame" className="flex-grow-1 w-100">
          {!isEdit ? (
            <>
              {multiSelectRefPoints && multiSelectRefPoints?.length > 0 ? (
                <div className="mt-1 right_list">
                  {updatedFrameData && updatedFrameData?.length > 0 ? (

                    <>
                      {updatedFrameData?.map((item, index) => (
                        <div
                          key={index}
                          className="position-relative d-flex align-items-center right_item_wrap has_delete has_copy"
                        >
                          <div className={`right_item w-100`}>
                            <span
                              className={`me-2 image_icon ${item?.status || item.defaultProfile
                                ? ""
                                : "opacity-50"
                                }`}
                            >
                              {(item.width > 0 &&
                                item.height > 0 &&
                                item.customePath) ||
                                item?.customePath === "joints" ? (
                                <>
                                  {item.imagePath ? (
                                    <Image src={item.imagePath} />
                                  ) : (
                                    <svg
                                      viewBox="0 0 100 50"
                                      width="100"
                                      height="50"
                                    >
                                      <path
                                        className="stroke"
                                        stroke="#989898"
                                        fill="#fff"
                                        strokeWidth="2"
                                        d={`M 0,50 0,0 100,0 100,50 Z`}
                                      ></path>
                                    </svg>
                                  )}
                                </>
                              ) : (
                                <GoCircleSlash size={40} color="#989898" />
                              )}
                            </span>
                            <div className="right_title">
                              <span
                                className={`text-truncate ${item?.status || item.defaultProfile
                                  ? "text-dark"
                                  : "text-muted-light"
                                  }`}
                              >
                                {item.name}
                              </span>
                              <span className="text-truncate d-block text-small">
                                {item.description}
                              </span>
                              <span className="position-absolute action_btns">
                                {!item?.defaultProfile && (
                                  <NavLink
                                    onClick={() =>
                                      makeProfileEnable(item, "frame")
                                    }
                                  >
                                    {item?.status ? (
                                      <MdOutlineVisibility
                                        className="text-muted"
                                        size={18}
                                      />
                                    ) : (
                                      <MdOutlineVisibilityOff
                                        className="text-muted"
                                        size={18}
                                      />
                                    )}
                                  </NavLink>
                                )}

                                {!referencePoint?.data?.name?.includes(
                                  "Joints"
                                ) && (
                                    <>
                                      {item?.status && (
                                        <NavLink
                                          onClick={() => {
                                            setIsEdit(true);
                                            setCurrentFrameProfile(item);
                                          }}
                                        >
                                          <BiSolidEditAlt
                                            className="text-muted"
                                            size={18}
                                          />
                                        </NavLink>
                                      )}
                                    </>
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                      <AddiitionProfileAccordion
                        additionalItems={additionalProfiles}
                        getData={getFameData}
                        refetchData={getAdditionalProfileServices}
                        updateStatusServices={additionalProfileStatusServices}
                        additionalItemType={"frame"}
                      />
                    </>
                  ) : (
                    <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
                      <span>
                        <MdInfoOutline size={18} />
                      </span>
                      <span>No frame profiles to show</span>
                    </Alert>
                  )}
                </div>
              ) : (
                <Alert color="secondary" className="d-flex gap-2 p-2 my-2">
                  <span>
                    <MdInfoOutline size={18} />
                  </span>
                  <span>Select one or more frame profile to edit</span>
                </Alert>
              )}
            </>
          ) : (
            <ManufacturingEditComp
              setIsEdit={setIsEdit}
              frameStyleProdId={frameStyleProdId}
              referencePoint={referencePoint}
              accessToken={accessToken}
              currentItem={currentFrameProfile}
              setLoading={setLoading}
              getData={getFameData}
              getSashHangingData={getSashHangingDataService}
              collectionId={collectionId}
              isAdditionProfile={false}
              frameProductDetails={frameProductDetails}
              allTagData={allTagData}
            />
          )}
        </TabPane>
        <TabPane tabId="Hardware" className="flex-grow-1 w-100">
          <ManufacturingHardware
            activeTab={activeTab}
            hardwareListing={allHardware}
            setAllHardware={setAllHardware}
            handleHardwareOptionData={handleHardwareOptionData}
            referencePoint={referencePoint}
            manufacturingToggle={manufacturingToggle}
            frameStyleProdId={frameStyleProdId}
            accessToken={accessToken}
            collectionId={collectionId}
            open = {open}
          />

          <AddiitionProfileAccordion
            additionalItems={additionalHardware}
            getData={getFameData}
            refetchData={getAdditionalHardwareService}
            updateStatusServices={additionalHardwareStatusServices}
            additionalItemType={"hardware"}
          />


        </TabPane>
      </TabContent>
    </div>
  );
};

export default ManufacturingComp;
