import axios from "axios";
import { servicePath } from "../utility/data";

export const pricingDefault = (accessToken) => {

  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }

  return axios
    .get(`${servicePath}/General/GetProjectDefaults`, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const costPriceMaster = (accessToken) => {
  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }

  return axios
    .get(`${servicePath}/Master/GetCostPriceList`, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const addUpdatePricingDefault = (accessToken, data) => {
  const formData = new FormData()
  formData.append('Id', data?.id ? data?.id : "")
  formData.append('Currency', data?.currency)
  formData.append('HidePricesWhileEditing', data?.hidePrice)
  formData.append('VAT', data?.vat)
  formData.append('Installation', data?.installation)
  formData.append('AllowPriceOverride', data?.allowPriceOverried)
  formData.append('WastageMarkup', data?.wastageMarkup)



  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }

  return axios
    .post(`${servicePath}/General/AddUpdateProjectDefaults`, formData, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const getAllVat = (accessToken) => {
  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axios
    .get(`${servicePath}/General/GetAllGeneralTaxes`, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const addUpdateVat = (accessToken, data) => {
  const formData = new FormData()
  formData.append('Id', data?.id ? data?.id : "")
  formData.append('Name', data?.name)
  formData.append('Description', data?.desc)
  formData.append('Percentage', data?.percent || 0)
  formData.append('Default', data?.default)
  formData.append('SequenceNo', "")


  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }

  return axios
    .post(`${servicePath}/General/AddUpdateGeneralTax`, formData, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const deleteTaxes = (accessToken, id) => {
  const formData = new FormData()
  formData.append('Id', id)

  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }

  return axios
    .post(`${servicePath}/General/DeleteGeneralTax`, formData, { headers })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export const getQuotationPriceList = (
  accessToken,
  fetchId,
  quotationId
) => {
  const formData = new FormData();
  formData.append("PrintId", fetchId);
  formData.append("QuotationId", quotationId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return axios
    .post(`${servicePath}/Document/GetQuotationPriceList`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const addNewFrameAncillaryService = (accessToken, data) => {
  const formData = new FormData();
  formData.append("Id", data?.id || 0);
  formData.append("QuotationId", data?.quotationId);
  formData.append("AncillariesId", data?.ancillariesId);
  formData.append("AncillariesName", data?.ancillariesName);
  formData.append("AncillariesPrice", data?.ancillariesPrice || 0);
  formData.append("Quantity", data?.quantity || 1);
  formData.append("Percentage", data?.percentage || 0);
  formData.append("Discountable", data?.discountable);
  formData.append(
    "HideWithInFrameWhenPrinting",
    data?.hideWithInFrameWhenPrinting
  );

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return axios
    .post(`${servicePath}/Document/AddUpdateQuotationAncillaries`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const deleteFrameAncillaries = (accessToken, id) => {
  const formData = new FormData();
  formData.append("Id", id);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return axios
    .post(`${servicePath}/Document/DeleteQuotationAncillaries`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
export const getFrameAncillariesPricelist = (accessToken) => {

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return axios
    .get(`${servicePath}/ThreeJSModel/GetFrameAncillariesWithpPrice`, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const GetQuotationProjectType = (accessToken, quotationId) => {
    const formData = new FormData();
    formData.append("QuotationId", quotationId);
  
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  
    return axios
    .post(`${servicePath}/Document/GetQuotationProjectType`, formData, {
        headers,
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
};

export const getProjectAncillariesPricelist = (accessToken) => {

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return axios
    .get(`${servicePath}/ThreeJSModel/GetProjectAncillariesWithpPrice`, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const addUpdateQuotationPromotionService = (accessToken, data) => {
  const formData = new FormData();
  formData.append("Id", data?.id);
  formData.append("PromotionId", data?.promotionId);
  formData.append("Name", data?.name);
  formData.append("Default", data?.default);
  formData.append("QuotationId", data?.quotationId);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return axios
    .post(`${servicePath}/Document/AddUpdateQuotationPromotion`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const deletePromotionsService = (accessToken, id) => {
  const formData = new FormData();
  formData.append("Id", id);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  return axios
    .post(`${servicePath}/Document/DeleteQuotationPromotion`, formData, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};